import React from "react";
import moment from "moment";
import styled from "styled-components";
import FeedbackScores from "common/debriefScores";
import Avatar from "components/Avatar";
import { Card } from "components/Card";
import FeedbackDialog from "./FeedbackDialog";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import { isTablet } from "common/styles";

const FeedbackContainer = styled(Card)`
	margin-bottom: 30px;
	text-align: center;
	padding: 10px;
	margin-left: 2%;
	margin-right: 2%;
	margin-bottom: 4%;
	height: 285px;
	justify-content: space-between;
	align-items: center;
`;

const EmotionContainer = styled.div`
	cursor: pointer;
	transition: transform 250ms ease;
	transform: scale(1);
	&:hover {
		transform: scale(1.5);
	}
`;

const FeedbackDate = styled.strong`
	font-size: 12px;
	position: absolute;
	right: 10px;
	top: 5px;
`;

const Emotions = styled.div`
	display: flex;
	margin-top: 25px;
	> div {
		width: 30px;
		margin-right: 3px;
		margin-left: 3px;
	}
`;

const AvatarContainer = styled.div`
	margin: auto;
	margin-top: ${isTablet() ? 15 : 40}px;
	margin-bottom: 15px;
`;

export default class Feedback extends React.Component {
	state = {
		addComment: false,
		isHappy: false,
	};

	get lastShift() {
		const { shifts } = this.props;

		return shifts.sort(
			(a, b) =>
				moment(a.endTime).format("YYYYMMDD") -
				moment(b.endTime).format("YYYYMMDD"),
		)[0];
	}

	close(saved) {
		this.setState({ addComment: false });
		if (!saved) {
			return;
		}

		dispatch(
			addAlert(
				"Retrouvez votre feedback sur la mission dans votre onglet planning",
			),
		);
	}

	render() {
		const { addComment, isHappy, score } = this.state;
		const { name, mediumAvatarURL, job, contractId } = this.props;

		return (
			<FeedbackContainer className="three">
				{!isTablet() ? (
					<FeedbackDate>
						Dernière mission le{" "}
						{moment(this.lastShift.endTime).format("DD MMMM")}
					</FeedbackDate>
				) : null}
				<AvatarContainer>
					<Avatar radius={55} image={mediumAvatarURL} />
				</AvatarContainer>
				<strong>{name}</strong>
				<p>{job.name}</p>
				<Emotions>
					{FeedbackScores.map(({ score, label, Emotion }) => (
						<EmotionContainer
							key={score}
							onClick={() =>
								this.setState({ addComment: true, isHappy: score > 1, score })
							}
						>
							<Emotion />
						</EmotionContainer>
					))}
				</Emotions>
				<FeedbackDialog
					visible={addComment}
					isHappy={isHappy}
					score={score}
					name={name}
					contractId={contractId}
					onDismiss={saved => this.close(saved)}
				/>
			</FeedbackContainer>
		);
	}
}
