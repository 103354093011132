import SalesPersonSelector from "./customFields/SalesPersonSelector";
import CategorySelector from "./customFields/CategorySelector";
import { isSet } from "../Form/isCompleted";

const isType = type => (key, categories) =>
	key && categories.find(category => category.key === key).type === type;

const isHotel = isType("hotel");
const isRestaurant = isType("restaurant");

export default {
	label: "Autres",
	fields: ({ categoryKey, specifiesGrossRate }, { businessCategories }) => ({
		...(specifiesGrossRate
			? {
					foodCompensatoryAllowance: {
						label: "Indemnité de nourriture (€)",
						type: "rate",
					},
					providesNaturalAdvantage: {
						label: "Le Business fournit un repas ",
						type: "checkBox",
					},
			  }
			: {
					billingFee: { label: "Commission (%)", type: "percentage" },
			  }),
		salesPeople: {
			custom: SalesPersonSelector,
			checkField: ({ hunterId }) => isSet(hunterId),
		},
		canCreateJobPosts: {
			label: "Offres fixes",
			type: "checkBox",
		},
		adminTagline: {
			label: "Notre avis sur le business (affiché avec les offres fixes)",
			optional: true,
		},
		categoryKey: {
			custom: CategorySelector,
		},
		...(isHotel(categoryKey, businessCategories)
			? {
					hotelRoomCount: {
						label: "Nombre de chambres",
						type: "int",
						optional: true,
					},
			  }
			: {}),
		...(isRestaurant(categoryKey, businessCategories)
			? {
					restaurantSeatCount: {
						label: "Nombre de couverts",
						type: "int",
						optional: true,
					},
			  }
			: {}),
	}),
};
