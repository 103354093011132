import React, { Component } from "react";
import Lightbox from "components/Lightbox";
import Button from "components/Button";
import Request from "common/Request";
import styled from "styled-components";
import CheckboxesAndTextField from "components/CheckboxesAndTextField";

const checkboxesLabel = [
	"L'impression générale",
	"L'expérience du worker",
	"L'avis de Gofer",
	"Les avis des autres établissements",
	"Le worker est déjà venu chez nous",
];

export class CheckboxContainer extends Component {
	state = {
		value: null,
		loading: false,
	};

	async sendAnswer() {
		const { sendAnswer } = this.props;
		this.setState({ loading: true });
		await sendAnswer({ reason: this.state.value });
		this.setState({ loading: false });
	}

	render() {
		const {
			props: { name },
		} = this;

		return (
			<CheckboxForm>
				<h2>Vous avez sélectionné {name}</h2>

				<CheckboxesAndTextField
					labels={checkboxesLabel}
					title="Pouvez vous nous dire ce qui a influencé votre choix ?"
					textFieldLabel={"Autre raison"}
					input={{
						onChange: value => {
							this.setState({ value });
						},
					}}
				/>
				<div class="buttons-line">
					<Button
						disabled={!this.state.value}
						loading={this.state.loading}
						onClick={async () => await this.sendAnswer()}
					>
						Envoyer
					</Button>
				</div>
			</CheckboxForm>
		);
	}
}

export default ({ showPopUp, contractId, jsonTargets, dismiss, name }) => {
	const sendAnswer = async ({ reason } = {}) => {
		await Request.mutation(
			/* GraphQL */ `
				mutation($contractId: ID!, $reason: String, $jsonTargets: String!) {
					business {
						captureContextOfSelection(
							contractId: $contractId
							reason: $reason
							jsonTargets: $jsonTargets
						) {
							id
						}
					}
				}
			`,
			{ contractId, reason, jsonTargets },
		);
	};

	return (
		<Lightbox
			visible={showPopUp}
			onDismiss={async () => {
				dismiss();
				await sendAnswer().catch(error => console.error(error));
			}}
		>
			<CheckboxContainer
				sendAnswer={async ({ reason }) => {
					try {
						await sendAnswer({ reason });
						dismiss();
					} catch (error) {
						console.error(error);
					}
				}}
				name={name}
			/>
		</Lightbox>
	);
};

const CheckboxForm = styled.div`
	font-size: 16px;
	padding: 30px;

	h2 {
		margin-bottom: 35px;
	}

	.buttons-line {
		display: flex;
		justify-content: flex-end;
	}
`;
