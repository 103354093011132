const range = (arg1, arg2, step = 1) => {
	let start, end;
	if (arg2) {
		start = arg1;
		end = arg2;
	} else {
		start = 0;
		end = arg1;
	}
	const range = [];
	for (let i = start; i < end; i += step) {
		range.push(i);
	}
	return range;
};

export default range;
