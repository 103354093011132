import styled from "styled-components";
import { color } from "common/styles";

export const marginTop = 20;

export const Document = styled.div`
	margin-top: ${marginTop}px;
	display: flex;
	align-items: flex-start;
	position: relative;

	& form {
		flex: 12;
		align-self: flex-start;
		padding: 0;

		& input {
			padding: 10px 0;
			margin-top: ${marginTop}px;
		}
	}

	& p {
		color: ${color("black", "bright")};
	}
`;
export const DocumentTitle = styled.h2`
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
	color: ${props =>
		props.color
			? color(props.color, props.opacity ? props.opacity : "normal")
			: color("important")};

	& svg {
		@media (min-width: 700px) {
			position: absolute;
			left: -30px;
			color: ${props =>
				props.color && props.color === "attention"
					? color("black", "bright")
					: "inherit"};
		}
	}
`;
