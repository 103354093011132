import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import humanizeTotalHours from "utils/humanizeTotalHours";
import formatCurrency from "utils/formatCurrency";
import Button from "components/Button";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import Request from "common/Request";
import { DEV } from "common/constants";
import Avatar from "components/Avatar";
import PopUpWindow from "./PopUpWindow";

const legalStatusName = {
	freelance: "Prestataire",
	interim: "Employé",
};

const jobName = {
	freelance: "Type",
	interim: "Poste",
};

const details = {
	freelance: firstName =>
		`Vous recevrez un contrat de prestation de service engageant votre établissement et ${firstName}.`,
	interim: firstName =>
		`Nous nous occupons de la Déclaration Préalable à l’Embauche, du contrat de travail et de la paie de ${firstName}.`,
};

export const Confirm = ({
	worker: { name, firstName, mediumAvatarURL },
	query: {
		getJob: { name: job },
		grossRateSpecifiedByBusiness,
		totalHours,
		shifts = [],
	},
	onConfirm,
	target,
	signing,
}) => {
	const { legalStatus, provisionalBillingAmount } = target;

	const duration = humanizeTotalHours(totalHours);
	const { startTime, endTime, breakMinutes } = shifts[0];
	return (
		<Container>
			<StyledAvatar radius={75} image={mediumAvatarURL} />
			<Name>{`${legalStatusName[legalStatus]} : ${name} - ${
				jobName[legalStatus]
			} : ${job}`}</Name>
			<Shift>
				{`Le
            ${moment(startTime).format("ddd D MMM YYYY")}
            de ${moment(startTime).format("HH[h]mm")} à
            ${moment(endTime).format("HH[h]mm")} ${
					breakMinutes ? `(${breakMinutes} minutes de pause)` : ``
				} soit ${duration}`}
			</Shift>
			<Cost>
				<Title>
					<div>
						Total facturé{" "}
						{!grossRateSpecifiedByBusiness
							? `(${formatCurrency(provisionalBillingAmount / totalHours)}/h)`
							: null}{" "}
						:
					</div>
					<div>{formatCurrency(provisionalBillingAmount)}</div>
				</Title>
			</Cost>
			<Details>{details[legalStatus](firstName)}</Details>
			<StyledButton loading={signing} onClick={onConfirm}>
				Proposer la mission
			</StyledButton>
		</Container>
	);
};

export default class extends Component {
	state = { signing: false, showPopUp: false, contractId: null };

	onConfirm = async () => {
		this.setState({ signing: true });

		try {
			const { businessSelectedWorkerAtLeastOnce } = this.props.target.matching;
			const {
				selectQueryWorker: { id },
			} = await Request.mutation(
				/* GraphQL */ `
					mutation($queryId: String!, $workerId: String!) {
						selectQueryWorker(queryId: $queryId, workerId: $workerId) {
							id
						}
					}
				`,
				{
					queryId: this.props.query.id,
					workerId: this.props.worker.id,
				},
			);
			this.setState({ contractId: id });
			dispatch(addAlert("La mission a été proposée."));
			if (
				!businessSelectedWorkerAtLeastOnce &&
				this.props.query.getTargets.length > 1
			) {
				this.setState({ showPopUp: true });
			} else {
				this.props.dismiss();
			}
		} catch (error) {
			DEV && console.error(error);
			dispatch(addAlert("La sélection du worker a échouée."));
		}
		this.setState({ signing: false });
	};

	render() {
		const { signing, contractId, showPopUp } = this.state;

		return (
			<div>
				<Confirm {...this.props} signing={signing} onConfirm={this.onConfirm} />
				<PopUpWindow
					contractId={contractId}
					jsonTargets={JSON.stringify(this.props.query.getTargets)}
					name={this.props.target.worker.name}
					dismiss={() => {
						this.setState({ showPopUp: false });
						this.props.dismiss();
					}}
					showPopUp={showPopUp}
				/>
			</div>
		);
	}
}

const space = `margin-bottom: 18px;
color: #575757`;
const lineStyle = `display: flex;
  width: 30%;
  font-size: 15px;
  margin: auto;
  ${space};
  justify-content: space-between;`;

const Container = styled.div`
	text-align: center;
`;
const Name = styled.div`
	font-weight: bold;
	font-size: 25px;
	${space};
`;
const Shift = styled.div`
	font-size: 18px;
	${space};
`;

const Cost = styled.div`
	${space};
`;
const Details = styled.div`
	font-size: 18px;
	${space};
`;
const Title = styled.div`
	${lineStyle}
	font-weight: bold;
`;
const StyledAvatar = styled(Avatar)`
	margin: auto;
	${space};
`;
const StyledButton = styled(Button)`
	${space};
	justify-content: center;
`;
