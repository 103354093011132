import moment from "moment";
import Request from "common/Request";
import store from "common/store";
import { addAlert } from "actions/alerts";
import axios from "axios";
import { baseURL } from "common/constants";

export const format = {
	date: date => (date ? moment(date).toDate() : date),
	error: (date, message) => (date ? null : message),
	status: status => (status ? "valid" : "important"),
	text: text => (text ? text : ""),
};

export const bankModel = data => ({
	...data,
	obfuscatedIBAN: format.text(data.obfuscatedIBAN),
	bic: format.text(data.bic),
	accountHolder: format.text(data.accountHolder),
	errors: [
		format.error(data.validatedAt, "fichier d'IBAN"),
		format.error(data.obfuscatedIBAN, "IBAN"),
		format.error(data.bic, "BIC"),
		format.error(data.accountHolder, "Titulaire du compte"),
	],
	status: data.firstTime ? "attention" : format.status(data.validatedAt),
});

export const identityModel = data => ({
	...data,
	number: format.text(data.number),
	expiresAt: format.date(data.expiresAt),
	nationality: data.belongsToEU
		? "nationalité zone EEE"
		: "nationalité hors zone EEE",
	documentRequested: data.belongsToEU
		? "Carte d’identité (recto verso) ou passeport"
		: "Titre de séjour",
	errors: [
		format.error(data.validatedAt, "pièce d'identité"),
		format.error(data.expiresAt, "date d'expiration de votre pièce d'identité"),
	],
	status: data.firstTime ? "attention" : format.status(data.validatedAt),
});

export const socialSecurityModel = data => ({
	...data,
	expiresAt: format.date(data.expiresAt),
	errors: [format.error(data.validatedAt, "justificatif de sécurité sociale")],
	status: data.firstTime ? "attention" : format.status(data.validatedAt),
});

export default data => {
	const {
		name,
		obfuscatedIBAN,
		belongsToEU,
		documents: { lastReviewedAt, files, bank, socialSecurity, identity },
	} = data;

	const firstTime = !Boolean(lastReviewedAt);
	const bankData = bankModel({ ...bank, obfuscatedIBAN, firstTime });
	const identityData = identityModel({ ...identity, belongsToEU, firstTime });
	const socialSecurityData = socialSecurityModel({
		...socialSecurity,
		firstTime,
	});
	const errors = [
		...bankData.errors,
		...socialSecurityData.errors,
		...identityData.errors,
	].filter(Boolean);

	return {
		name,
		errors,
		documents: {
			files,
			bank: bankData,
			socialSecurity: socialSecurityData,
			identity: identityData,
		},
	};
};

export const updateInformations = async ({ token, documents }) => {
	try {
		await Request.mutation(
			/* GraphQL */ `
				mutation($token: String!, $documents: Worker_WorkerDocumentsInput!) {
					updateWorkerDocuments(token: $token, documents: $documents) {
						id
					}
				}
			`,
			{
				token,
				documents,
			},
		);
		store.dispatch(addAlert("Vos informations ont été mises à jour."));
	} catch (error) {
		store.dispatch(
			addAlert("Vos informations n'ont pas pu être mises à jour."),
		);
	}
};

export const uploadDocuments = async ({ token, form }) => {
	store.dispatch(addAlert("Upload du document en cours."));
	try {
		const data = new FormData(form);
		data.append("token", token);

		const request = axios.create({
			baseURL,
		});
		await request.post("/worker/documents/upload", data);
		store.dispatch(addAlert("Votre fichier a bien été uploadé."));
	} catch (error) {
		store.dispatch(
			addAlert("Il y a eu un problème lors de l'upload du document."),
		);
	}
};

export const validateGlobalForm = forms => {
	let flag = true;
	if (
		!forms.bankValidation ||
		!forms.identityValidation ||
		!forms.socialSecurityValidation
	) {
		flag = false;
	} else {
		const fields = ["iban", "bic", "accountHolder"];
		for (const field of fields) {
			if (forms.bankValidation.values[field] === "") {
				flag = false;
			}
		}
		if (!forms.identityValidation.values.belongsToEU) {
			if (forms.identityValidation.values.number === "") {
				flag = false;
			}
			if (forms.identityValidation.values.expiresAt === "") {
				flag = false;
			}
		}
	}

	return flag;
};
