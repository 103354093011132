import React, { useState } from "react";
import { GraphQL } from "common/graphql";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Form from "./Form";
import saveBusiness from "./EditBusiness/saveBusiness";
import styled from "styled-components";
import { color } from "common/styles";
import { MdError } from "react-icons/md";
import createBusiness from "./createBusiness";
import formatData from "./EditBusiness/formatData";
import Loading from "components/Loading";
import apolloClient from "common/apolloClient";

export const billingReferences = [
	{ name: "FRONT-OFFICE" },
	{ name: "RESTAURANT" },
	{ name: "CUISINE" },
	{ name: "SALLE" },
	{ name: "F&B" },
	{ name: "PETIT DEJEUNER" },
	{ name: "BANQUET" },
	{ name: "ÉTABLISSEMENT" },
];

const contextQuery = gql`
	query contextQuery {
		jobs {
			key
			name
		}
		countries {
			name
		}
		admin {
			owners(role: salesPerson) {
				id
				name
			}
		}
		currentUser {
			id
			roles
		}
		businessCategories {
			key
			name
			type
		}
	}
`;

const businessQuery = gql`
	query EditBusiness($businessId: ID!) {
		business(id: $businessId) {
			id
			name
			brief
			nafCode
			locked
			farmer {
				id
			}
			hunter {
				id
			}
			config {
				locations
			}
			briefLink
			farmingStartedAt
			managedByGofer
			wantSpecifyLocation
			wantSpecifyReason
			billingReference
			overtimeWeeklyHoursThreshold
			guarantee
			billingEmailAddress
			verified
			defaultBreakMinutes
			specifiesGrossRate
			providesNaturalAdvantage
			foodCompensatoryAllowance
			billingFee
			canCreateJobPosts
			adminTagline
			owner {
				defaultQueries {
					job {
						key
					}
					rate
					conditions {
						grossRate
						billingRate
						validFrom
					}
					breakMinutes
					comment
				}
				id
				lastName
				firstName
				phoneNumber
				email
			}
			location {
				streetName
				postCode
				city
				country
			}
			company {
				siret
				name
				address {
					streetName
					postCode
					city
					country
				}
			}
			contacts {
				firstName
				lastName
				phoneNumber
				email
				position
			}
			autoValidateTracking
			category {
				key
			}
			hotelRoomCount
			restaurantSeatCount
		}
	}
`;

export default props => {
	const { dismiss } = props;
	const [businessId, setBusinessId] = useState(props.businessId);
	const [error, setError] = useState();
	const { error: businessQueryError, data, loading, refetch } = useQuery(
		businessQuery,
		{
			variables: { businessId },
			skip: !businessId,
			client: apolloClient,
		},
	);
	const businessInDB = formatData(data && data.business);
	if (businessId && !(data && data.business)) {
		return <Loading />;
	}
	return (
		<>
			<GraphQL noPoll={true} query={contextQuery}>
				{({
					jobs,
					countries,
					admin: { owners },
					currentUser,
					businessCategories,
				}) => (
					<Form
						businessInDB={businessInDB}
						saveBusiness={async business => {
							if (businessId) {
								await saveBusiness(businessInDB, business);
								await refetch();
							} else if (business.name) {
								setBusinessId(await createBusiness(business.name));
							}
						}}
						canValidate={currentUser.roles.includes("canValidateBusiness")}
						dismiss={dismiss}
						setError={setError}
						jobs={jobs}
						billingReferences={billingReferences}
						countries={countries}
						salesPeople={owners}
						businessAsBeenLoaded={Boolean(businessInDB.id)}
						businessCategories={businessCategories}
					/>
				)}
			</GraphQL>
			{(error || businessQueryError) && (
				<SubmitError>
					<MdError /> {error || businessQueryError}
				</SubmitError>
			)}
			{loading ? <Loading /> : null}
		</>
	);
};

const SubmitError = styled.div`
	color: ${color("important")};
	padding: 10px 0;
	display: flex;
	align-items: center;

	& svg {
		width: 20px;
		margin-right: 5px;
	}
`;
