import React from "react";
import { Switch, Route } from "react-router-dom";
import WorkerExperiences from "./ReferenceValidator/WorkerExperiences";
import GoToZoho from "./ReferenceValidator/GoToZoho";
import SearchOrganizationsPipedriveQuerry from "./ReferenceValidator/SearchOrganizationsPipedriveQuery";
import Navbar from "architecture/Navbar";

const Routes = [
	{
		label: "Workers à valider",
		route: "/",
	},
	{
		label: "Recherche établissement",
		route: "/search-organizations-pipedrive",
	},
];

const Component = ({ match }) => (
	<Navbar routes={Routes}>
		<Switch>
			<Route
				path="/worker-validation/:workerId"
				component={WorkerExperiences}
			/>
			<Route path="/" exact component={GoToZoho} />
			<Route
				path="/search-organizations-pipedrive"
				component={SearchOrganizationsPipedriveQuerry}
			/>
		</Switch>
	</Navbar>
);

export const referenceValidator = { Component, Routes };
