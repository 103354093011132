import React, { Component } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import styled from "styled-components";
import { link } from "common/styles";
import { scope, toggle } from "common/utilities";

export default class HiddenBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			view: false,
		};
	}

	open = () => {
		this.setState({ view: true });
	};

	close = () => {
		this.setState({ view: false });
	};

	toggle = () => {
		this.setState(scope("view")(toggle));
	};

	render() {
		const { view } = this.state;
		const { title, children, className } = this.props;
		return (
			<Block className={className}>
				<Title onClick={this.toggle}>
					{title}
					<Arrow>
						{view ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
					</Arrow>
				</Title>
				{view ? children : null}
			</Block>
		);
	}
}

const Block = styled.div``;
const Title = styled.div`
	${link} display: flex;
	align-items: center;
`;
const Arrow = styled.div`
	margin: 10px;
	font-size: 20px;
`;
