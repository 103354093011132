import React from "react";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";
import moment from "moment";

export default ({ isBadge, children, hideLoading }) => (
	<GraphQL
		hideLoading={hideLoading}
		query={gql`
     query getAdminTrackingsToValidate($endBefore: DateTime) {
        admin {
          contracts(
          trackingValidated: false
          ${isBadge ? "tracked: true" : ""}
          canceled: false
          signed: true
          orderBy: tracking
          isGofer: false
          endBefore: $endBefore
        ) {
            btp
            id
            ${
							isBadge
								? ""
								: `
            getWorker {
              id
              name
              locked
            }
            getBusiness {
              id
              name
            }
            getJob {
              key
              name
            }
            startTime
            trackedShiftsDescription
            shiftsDescription
            trackedHours
            totalHours
            businessTracked
            businessAutoTracked
            workerAutoTracked
            tracked
            businessRejectedAutoTracking
            autoTrackingRejectionBusinessComment`
						}
          }
        }
      }
    `}
		variables={{
			endBefore: moment()
				.startOf("hour")
				.toISOString(),
		}}
	>
		{({ admin: { contracts } }) => children(contracts.filter(c => !c.btp))}
	</GraphQL>
);
