import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const getHistory = gql`
	query getHistory($workerId: ID!) {
		worker(id: $workerId) {
			id
			goferExperiences {
				business {
					anonymousName
					name
				}
				job {
					key
					name
				}
				segments {
					businessDebriefs {
						collectedAt
						score
						comment
					}
					pastContractCount
					startTime
					endTime
				}
			}
		}
	}
`;

export const useHistory = workerId => {
	const { data, loading, error } = useQuery(getHistory, {
		variables: { workerId },
	});

	console.log(data);
	console.log(error);

	if (loading || !data || !data.worker) {
		return { loading: true, goferExperiences: [] };
	}

	return {
		loading,
		goferExperiences: data.worker.goferExperiences,
	};
};
