import Raven from "raven-js";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import createRavenMiddleware from "raven-for-redux";
import reducer from "reducers/root";
import saga from "sagas/root";
import { sentryDSN } from "common/constants";

Raven.config(sentryDSN, {
	autoBreadcrumbs: true,
}).install();

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	reducer,
	composeEnhancers(
		applyMiddleware(sagaMiddleware, createRavenMiddleware(Raven)),
	),
);

sagaMiddleware.run(saga).done.catch(error => console.error(error));

export const { dispatch } = store;

export default store;
