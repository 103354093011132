import React from "react";
import styled from "styled-components";
import moment from "moment";
import { color } from "common/styles";
import Button from "components/Button";
import objectFromEntries from "utils/objectFromEntries";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import diff from "object-diff";
import { reduxForm, Field, FieldArray, FormSection } from "redux-form";
import { Checkbox, SelectField, TextField } from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import gql from "graphql-tag";
import { GraphQL } from "common/graphql";
import capitalize from "utils/capitalize";

export const Enum = values => ({
	$type: "enum",
	values,
});

const Form = reduxForm({ form: "adminAutoForm", enableReinitialize: true })(
	({ handleSubmit, schema, ...props }) => (
		<form onSubmit={handleSubmit}>
			<Schema schema={schema} />
			<Button submit>Sauvegarder</Button>
		</form>
	),
);

export const recursiveDiff = (before, after) => {
	if (typeof after !== "object" || before === undefined || before === null) {
		return after;
	}
	if (Array.isArray(after)) {
		return after.map((after, index) => recursiveDiff(before[index], after));
	}
	const difference = diff(before || {}, after || {});
	for (const key of Object.keys(difference)) {
		const value = difference[key];
		difference[key] = recursiveDiff(before[key], value);
	}
	return difference;
};

export const formatMutationVariables = (schema, value) => {
	if (typeof value !== "object") {
		if (schema === Date) {
			return moment(value, "YYYY-MM-DD[T]HH:mm");
		}
		if (schema === Number) {
			return Number(value);
		}
		return value;
	}
	if (Array.isArray(value)) {
		return value.map(value => formatMutationVariables(schema[0], value));
	}
	const output = {};
	for (const key of Object.keys(value)) {
		output[key] = formatMutationVariables(schema[key], value[key]);
	}
	return output;
};
class FormWithSubmit extends React.Component {
	submit = async values => {
		const { initialValues, dismiss, mutation, schema } = this.props;
		try {
			await mutation(
				formatMutationVariables(schema, {
					...recursiveDiff(initialValues, values),
					id: initialValues.id,
				}),
			);
			dismiss();
			dispatch(addAlert("Sauvegarde effectuée"));
		} catch (error) {
			console.error(error);
			dispatch(addAlert("Une erreur s'est produite"));
		}
	};

	render() {
		return <Form {...this.props} onSubmit={this.submit} />;
	}
}

export default ({ id, schema, queryEndpoint, ...props }) => (
	<GraphQL query={makeQuery({ schema, queryEndpoint })} variables={{ id }}>
		{({ admin: { item } }) => (
			<FormWithSubmit initialValues={item} schema={schema} {...props} />
		)}
	</GraphQL>
);

const Schema = ({ schema, prefix = "" }) => (
	<Section>
		{Object.entries(schema).map(([property, type]) => (
			<Property
				key={property}
				name={`${prefix ? `${prefix}.` : ""}${property}`}
				label={property}
				type={type}
			/>
		))}
	</Section>
);

const ArraySubSchema = ({ fields, fieldName, type }) => (
	<Section>
		<Header>{makeLabel(fieldName)}</Header>
		{fields.map(member =>
			defaultValueForType(type) ? (
				<Schema key={member} schema={type} prefix={member} />
			) : (
				<Property
					key={member}
					name={member}
					label={makeLabel(fieldName)}
					type={type}
				/>
			),
		)}
		<Button onClick={() => fields.push(defaultValueForType(type))}>+</Button>
	</Section>
);

const defaultValueForType = type => {
	switch (type) {
		case String:
		case Date:
		case Number:
		case Boolean:
			return undefined;
		default:
			if (type.$type === "enum") {
				return undefined;
			}
			return {};
	}
};

const makeLabel = string => capitalize(string.replace(/([A-Z])/g, " $1"));

const Property = ({ name, label, type }) => {
	const field = (
		component,
		props = {},
		{ skipFloatingLabelText, skipFullWidth } = {},
	) => {
		const otherProps = {};
		if (!skipFloatingLabelText) {
			otherProps.floatingLabelText = makeLabel(label);
		}
		if (!skipFullWidth) {
			otherProps.fullWidth = true;
		}
		return (
			<Field
				name={name}
				label={makeLabel(label)}
				component={component}
				{...props}
				{...otherProps}
			/>
		);
	};

	switch (type) {
		case String:
			return field(TextField, { disabled: name === "id" });
		case Date:
			return field(TextField, {
				type: "datetime-local",
				format: value => moment(value).format("YYYY-MM-DD[T]HH:mm"),
			});
		case Number:
			return field(TextField, { type: "number", step: "any" });
		case Boolean:
			return field(
				Checkbox,
				{},
				{ skipFloatingLabelText: true, skipFullWidth: true },
			);
		default:
			if (Array.isArray(type)) {
				return (
					<FieldArray
						name={name}
						component={ArraySubSchema}
						type={type[0]}
						fieldName={name}
					/>
				);
			}
			if (type.$type === "enum") {
				let values = type.values;
				if (Array.isArray(values)) {
					values = objectFromEntries(values.map(value => [value, value]));
				}
				return field(SelectField, {
					children: Object.entries(values).map(([key, value]) => (
						<MenuItem key={key} value={key} primaryText={value} />
					)),
				});
			}
			return (
				<FormSection name={name}>
					<Header>{makeLabel(name)}</Header>
					<Schema schema={type} />
				</FormSection>
			);
	}
};

export const makeQuery = ({ schema, queryEndpoint }) => {
	const makeSubQuery = subSchema =>
		`{ ${Object.entries(subSchema).map(
			([key, type]) => `${key} ${makeType(type)}\n`,
		)} }`;
	const makeType = type => {
		switch (type) {
			case String:
			case Date:
			case Number:
			case Boolean:
				return "";
			default:
				if (Array.isArray(type)) {
					return makeType(type[0]);
				}
				if (type.$type === "enum") {
					return "";
				}
				return makeSubQuery(type);
		}
	};
	return gql`
    query($id: ID!) {
      admin {
        item: ${queryEndpoint}(id: $id) ${makeSubQuery(schema)}
      }
    }
  `;
};

export const Section = styled.div`
	border: 1px solid ${color("black", "pale")};
	padding: 10px;
	display: flex;
	flex-direction: column;
	margin: 5px 0;
	border-radius: 5px;
`;
export const Header = styled.h3`
	margin-top: 25px;
`;
