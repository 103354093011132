export default fun => {
	const results = new Map();
	return arg => {
		if (results.has(arg)) {
			return results.get(arg);
		}
		const result = fun(arg);
		results.set(arg, result);
		return result;
	};
};
