import memoize from "./memoize";

const isEmpty = value => value === null || value === undefined;

export default (getter, { ascending, asc } = {}) => {
	const memoizedGetter = memoize(getter);
	return (a, b) => {
		const aValue = memoizedGetter(a);
		const bValue = memoizedGetter(b);

		if (typeof aValue === "string" && typeof bValue === "string") {
			return aValue.localeCompare(bValue) * (ascending || asc ? 1 : -1);
		}

		if (isEmpty(aValue)) {
			if (isEmpty(bValue)) {
				return 0;
			}
			return 1;
		}
		if (isEmpty(bValue)) {
			return -1;
		}
		return (
			(aValue > bValue ? 1 : bValue > aValue ? -1 : 0) *
			(ascending || asc ? 1 : -1)
		);
	};
};
