import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import styled from "styled-components";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import capitalize from "utils/capitalize";
import { registerLocale } from "react-datepicker";
import frFR from "date-fns/locale/fr";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("fr-FR", frFR);

const DateButton = styled.button`
	border: none;
	border-bottom: 1px solid #bfbfbf;
	background: transparent;
	height: 30px;
	font-size: 16px;
	padding-left: 0;
	margin-top: 11px;
	margin-bottom: 8px;
	width: 100%;
	text-align: left;
	outline: 0;
	cursor: pointer;
`;

const DateFieldHeader = ({ date, decreaseMonth, increaseMonth }) => {
	const previousMonth = e => {
		setTimeout(() => document.activeElement.blur(), 700);
		decreaseMonth();
	};

	const nextMonth = e => {
		setTimeout(() => document.activeElement.blur(), 700);
		increaseMonth();
	};

	return (
		<div>
			<div className="datepicker-header-date">
				<div className="datepicker-header-year">
					{moment(date).format("YYYY")}
				</div>
				<div className="datepicker-header-day">
					{capitalize(
						moment(date)
							.format("ddd, DD MMM")
							.replace(/\./, ""),
					)}
				</div>
			</div>
			<div className="datepicker-header-month">
				<button
					type="button"
					className="datepicker-header-navicon"
					onClick={e => previousMonth(e)}
				>
					<MdKeyboardArrowLeft size={25} />
				</button>
				{capitalize(moment(date).format("MMMM YYYY"))}
				<button
					type="button"
					className="datepicker-header-navicon"
					onClick={e => nextMonth(e)}
				>
					<MdKeyboardArrowRight size={25} />
				</button>
			</div>
		</div>
	);
};

export default class DateField extends React.Component {
	state = {
		opened: false,
		closing: false,
		pickedDate: this.props.defaultValue,
	};

	componentWillReceiveProps(props) {
		const { value, onChange } = props.input || {};
		const { defaultValue, date, onDateChange } = props;

		if (value || date) {
			this.setState({
				pickedDate: moment(value || date).toDate(),
			});
		} else if (defaultValue) {
			onChange &&
				onChange(
					moment(defaultValue)
						.utc()
						.startOf("day")
						.toISOString(),
				);
			onDateChange &&
				onDateChange(
					moment(defaultValue)
						.utc()
						.startOf("day")
						.toISOString(),
				);
		}
	}

	close() {
		this.setState({ closing: true });
		setTimeout(() => this.setState({ opened: false }), 500);
	}

	handleChange(date) {
		const { pickedDate } = this.state;
		const { onChange } = this.props.input || {};
		const { onDateChange } = this.props;

		if (pickedDate === date) {
			return;
		}

		onChange && onChange(moment(date).toISOString());
		onDateChange && onDateChange(moment(date).toISOString());
		this.setState({ pickedDate: date });
		this.close();
	}

	render() {
		const { input, meta, minDate } = this.props;
		const { opened, pickedDate, closing } = this.state;

		return (
			<div
				className={`container datepicker-container-${
					closing ? "closing" : "opened"
				}`}
			>
				{meta && meta.error && <span className="error">{meta.error}</span>}
				<DateButton
					className="example-custom-input"
					onClick={() => this.setState({ opened: true, closing: false })}
					type="button"
				>
					{pickedDate
						? moment(pickedDate).format("DD/MM/YYYY")
						: "Choisir une date"}
				</DateButton>
				{input ? (
					<input
						type="hidden"
						name={input.name}
						value={
							pickedDate ? moment(pickedDate).format("DD/MM/YYYY") : undefined
						}
					/>
				) : null}
				{opened && (
					<DatePicker
						selected={moment(pickedDate).toDate()}
						onChange={date => this.handleChange(date)}
						minDate={minDate}
						locale="fr-FR"
						withPortal
						inline
						renderCustomHeader={props => <DateFieldHeader {...props} />}
						onClickOutside={() => this.close()}
					/>
				)}
			</div>
		);
	}
}
