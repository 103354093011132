import React from "react";
import * as Roles from "./Home/Roles";
import useCurrentUser from "common/useCurrentUser";
import Loading from "components/Loading";

export default ({ route }) => {
	const user = useCurrentUser();
	if (!user) {
		return <Loading />;
	}
	const Role = Roles[user.primaryRole];
	console.log(route);
	return <Role.Component />;
};
