import React from "react";
import Button from "components/Button";
import styled from "styled-components";
import axios from "axios";
const url = "https://api.pipedrive.com/v1/";

const OrganizationsContactsApi = async (
	contact,
	organizationId,
	token,
	dismiss,
) => {
	const FonctionKey = "4db2426384ccfa8eee37d44838f1840b8bcdf4d6";
	const { name, email, phone, fonction } = contact;
	await axios.post(`${url}persons?api_token=${token}`, {
		name,
		email,
		phone,
		org_id: organizationId,
		[FonctionKey]: fonction,
	});
	dismiss();
};

const Input = ({ label, name, onChange, values, type }) => (
	<div>
		<label>{label}</label>
		<div>
			<input
				name={name}
				placeholder={label}
				onChange={onChange}
				value={values[name] || ""}
				type={type}
			/>
		</div>
	</div>
);

const ContactForm = ({ onChange, values }) => (
	<div>
		<Input
			label={"Nom complet"}
			name={`name`}
			onChange={onChange}
			values={values}
			type={"text"}
		/>
		<Input
			label={"Email"}
			name={`email`}
			onChange={onChange}
			values={values}
			type={"email"}
		/>
		<Input
			label={"Téléphone"}
			name={`phone`}
			onChange={onChange}
			values={values}
			type={"phone"}
		/>
		<Input
			label={"Fontion"}
			name={"foncion"}
			onChange={onChange}
			values={values}
			tupe={"text"}
		/>
	</div>
);
export default class AddOrganizationsContactsForm extends React.Component {
	state = {};
	onChange = event =>
		this.setState({ [event.target.name]: event.target.value });
	render() {
		const { organizationId, token } = this.props;
		return (
			<div>
				<ContactsContainer>
					<ContactForm onChange={this.onChange} values={this.state} />
				</ContactsContainer>
				<Button
					onClick={() =>
						OrganizationsContactsApi(
							this.state,
							organizationId,
							token,
							this.props.dismiss,
						)
					}
				>
					Send
				</Button>
			</div>
		);
	}
}

const ContactsContainer = styled.div`
	display: flex;
`;
