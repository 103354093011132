import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import Bank from "./Bank";
import Drive from "./Drive";
import Identity from "./Identity";
import SocialSecurity from "./SocialSecurity";
import Information from "./Information";
import Refusal from "./Refusal";
import { reduxForm, FormSection } from "redux-form";
import {
	updateInformations,
	prepareForSubmit,
} from "models/admin/workerDocuments";

const Form = props => {
	const { initialValues, submitting, handleSubmit } = props;

	const submit = async (
		{ bank, identity, refusal, socialSecurity },
		...other
	) => {
		const params = prepareForSubmit(
			{ bank, identity, refusal, socialSecurity },
			initialValues.worker.id,
		);
		await updateInformations(params);
		props.dismiss();
	};

	return (
		<Container>
			<form onSubmit={handleSubmit(submit)}>
				<Drive initialValues={initialValues.drive} />
				<Information {...props} />
				<FormSection name="bank">
					<Bank {...props} />
				</FormSection>
				<FormSection name="identity">
					<Identity {...props} />
				</FormSection>
				<FormSection name="socialSecurity">
					<SocialSecurity {...props} />
				</FormSection>
				<FormSection name="refusal">
					<Refusal {...props} />
				</FormSection>
				<Button submit loading={submitting}>
					Soumettre
				</Button>
			</form>
		</Container>
	);
};

export default reduxForm({
	form: "adminWorkerDocuments",
})(Form);

const Container = styled.div`
	display: flex;
	flex-direction: row;
`;
