import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Loading from "components/Loading";
import Lightbox from "components/Lightbox";

const getSurveyTargets = gql`
	query AdminGetSurvey($id: ID!) {
		admin {
			survey(id: $id) {
				id
				targetedUsers {
					id
					name
				}
				blacklistedUsers {
					id
					name
				}
			}
		}
	}
`;

export default ({ id, onDismiss }) => {
	const { loading, data } = useQuery(getSurveyTargets, {
		variables: { id },
	});

	return (
		<Lightbox visible={true} onDismiss={onDismiss}>
			{loading || !data || !data.admin ? (
				<Loading />
			) : (
				<article>
					<section>
						<h3>Utilisateurs inclus dans le sondage</h3>
						{data.admin.survey.targetedUsers.map(u => (
							<div>
								<strong>{u.name}</strong> {u.id}
							</div>
						))}
					</section>
					<section>
						<h3>Utilisateurs exclus du sondage</h3>
						<p>
							{data.admin.survey.blacklistedUsers.map(u => (
								<div>
									<strong>{u.name}</strong> {u.id}
								</div>
							))}
						</p>
					</section>
				</article>
			)}
		</Lightbox>
	);
};
