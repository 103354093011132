/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import styled from "styled-components";
import { color, tabletBreakpoint, isTablet } from "common/styles";
import { Link } from "react-router-dom";
import { MdPowerSettingsNew } from "react-icons/md";
import Notifications from "./Notifications";
import useCurrentUser from "common/useCurrentUser";
import useAuth0 from "common/useAuth0";

const Bar = styled.div`
	background: ${color("primaryDark")};
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 1;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
	flex-shrink: 0;
	color: #fff;

	.icons {
		position: absolute;
		display: flex;
		right: 0;
		padding-right: 15px;

		@media screen and (max-width: ${tabletBreakpoint}px) {
			width: 100%;
			justify-content: space-between;
			flex-direction: row-reverse;
			padding-left: 5px;
		}

		svg {
			height: 50px;
			width: 38px;
			padding-top: 14px;
			padding-bottom: 14px;
		}

		> div {
			background: transparent;
			transition: all 350ms ease;
			cursor: pointer;
			&:hover {
				background: ${color("primaryLight")};
			}
		}
	}

	.username {
		font-size: 14px;
		position: absolute;
		display: none;
		left: 27px;
		top: 6px;
		line-height: 18px;
		@media screen and (min-width: ${tabletBreakpoint}px) {
			display: block;
		}
	}

	@media print {
		display: none;
	}
`;

const Brand = styled.div`
	background: url(/logo.png) ${color("primary")} no-repeat center;
	background-size: contain;
	height: 70px;
	width: 70px;
	border-radius: 50px;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
	position: relative;
	margin-left: -50px;
	margin-right: -50px;
	margin-top: -10px;
	background-position: 1px 21px;
	z-index: 2;

	@media screen and (max-width: ${tabletBreakpoint}px) {
		height: 50px;
		width: 50px;
		top: 10px;
		background-position: center;
	}
`;

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	@media print {
		&.has-modal {
			display: none;
		}
	}
`;

const Renderer = styled.div`
	flex: 1;
	height: calc(100% - 50px);
	overflow: auto;
	display: flex;
	flex-direction: column;
`;

const SlantedLink = styled.div`
  height: 100%;
  display: flex;
  width: 230px;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: ${tabletBreakpoint}px) {
    width: 145px;
  }

  &:hover {
      &::before {
          background-color: ${color("actionDark")};
      }
  }

  > a {
    position: relative;
    color: #fff;
    font-weight: bold;
    margin-${props => (props.right ? "left" : "right")}: 35px;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: ${tabletBreakpoint}px) {
      font-size: 14px;
      margin-${props => (props.right ? "left" : "right")}: 50px;
    }
  }

  &::before {
    content: " ";
    display: block;
    height: 100%;
    background: rgba(246, 153, 0, 1);
    position: absolute;
    width: 100%;
    transform: skewX(${props => (props.right ? "-" : "")}30deg);
    z-index: 1;
    transition: all 350ms ease;
  }
`;

const RightNav = styled.div`
	left: 16px;

	a {
		padding-left: 56px;
	}
`;

const LeftNav = styled.div`
	right: 16px;

	a {
		padding-right: 56px;
	}
`;

export default ({ children, left, right }) => {
	const { logout } = useAuth0();
	const currentUser = useCurrentUser();

	if (!currentUser.business) {
		return null;
	}

	return (
		<Container className="split-nav">
			<Bar>
				<div class="username">
					<strong>{currentUser.business.name}</strong>
					<br />
					{currentUser.firstName} {currentUser.lastName}
				</div>
				<SlantedLink className="drop-nav-opener">
					{!isTablet() || !left.links ? (
						<Link to={left.route}>{left.label}</Link>
					) : (
						<a>{left.label}</a>
					)}
					{left.links ? (
						<LeftNav className="left-nav drop-nav">
							{left.links.map(link => (
								<Link to={link.route}>{link.label}</Link>
							))}
						</LeftNav>
					) : null}
				</SlantedLink>
				<Brand />
				<SlantedLink right className="drop-nav-opener">
					{!isTablet() || !right.links ? (
						<Link to={right.route}>{right.label}</Link>
					) : (
						<a>{right.label}</a>
					)}
					{right.links ? (
						<RightNav className="right-nav drop-nav">
							{right.links.map(link => (
								<Link to={link.route}>{link.label}</Link>
							))}
						</RightNav>
					) : null}
				</SlantedLink>
				<div class="icons">
					<Notifications />

					<div class="logout" onClick={() => logout()}>
						<MdPowerSettingsNew />
					</div>
				</div>
			</Bar>
			<Renderer className="split-nav-renderer">{children}</Renderer>
		</Container>
	);
};
