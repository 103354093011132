import { addAlert } from "actions/alerts";
import store from "common/store";
import React from "react";
import Button from "components/Button";

export default class RequestButton extends React.Component {
	state = {
		validating: false,
	};

	handleClick = async () => {
		this.setState({ validating: true });
		try {
			await this.props.onClick();
		} catch (error) {
			console.error(error);
			store.dispatch(addAlert("Une erreur est survenue."));
			this.setState({ validating: false });
		}
	};

	render() {
		const { validating } = this.state;
		const { label } = this.props;
		return (
			<Button onClick={this.handleClick} loading={validating}>
				{label}
			</Button>
		);
	}
}
