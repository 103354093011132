import React from "react";
import NumberField from "./NumberField";
import round from "utils/round";
import formatCurrency from "utils/formatCurrency";
import parseCurrency from "utils/parseCurrency";

export default props => (
	<NumberField
		format={value => (value ? formatCurrency(value * 100) : "")}
		convertToUserInput={value => (value ? round(value / 100, 2) : "")}
		parseUserInput={currency => round(parseCurrency(currency) * 100, 0)}
		{...props}
	/>
);
