import React from "react";
import styled from "styled-components";
import { Document, DocumentTitle } from "./shared";
import { TextField } from "redux-form-material-ui";
import { Field } from "redux-form";

export default props => {
	const worker = props.initialValues.worker;
	return (
		<Document>
			<DocumentTitle>Motif de Refus</DocumentTitle>
			<PMargin>
				Bonjour {worker.name},
				<Field
					name="adminMessage"
					fullWidth={true}
					component={TextField}
					props={{
						multiLine: true,
						rows: 3,
					}}
				/>
				Veuillez compléter vos documents ici : {"<URL>"}
				<br />
				L'équipe Gofer`
			</PMargin>
		</Document>
	);
};

const PMargin = styled.div`
	margin-bottom: 20px;
`;
