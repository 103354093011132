import React from "react";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";

export default ({ isBadge, children, hideLoading }) => (
	<GraphQL
		hideLoading={hideLoading}
		query={gql`
       query getAdminWorkersWithDocumentsToValidate(
        $needingDocumentsReview: Boolean
      ) {
      admin {
        workers(
          orderBy: documentsLastValidatedAt
          needingDocumentsReview: $needingDocumentsReview
        ) {
            id
            ${
							isBadge
								? ""
								: `
              name
              obfuscatedIBAN
              belongsToEU
              documentsLastUploadedAt
              referenceVerifiedAt
              documents {
                googleFolderId
                bank {
                  validatedAt
                  bic
                  accountHolder
                }
                identity {
                  validatedAt
                  expiresAt
                  number
                }
                socialSecurity {
                  validatedAt
                  expiresAt
                }
              }`
						}
          }
        }
      }
    `}
		variables={{
			needingDocumentsReview: true,
		}}
	>
		{({ admin: { workers } }) => children(workers)}
	</GraphQL>
);
