import { range } from "lodash";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";

import { color } from "common/styles";
import Avatars from "./Avatars";

const Contracts = ({
	contracts = [],
	hasAvailableWorkers = false,
	hasStarted = false,
	notFoundCount = 0,
	onClick,
}) => {
	const firstContract = useMemo(() => contracts[0], [contracts]);
	const missing = useMemo(
		() =>
			firstContract && notFoundCount > 0
				? `${notFoundCount} manquant${notFoundCount > 1 ? "s" : ""}`
				: "",
		[firstContract, notFoundCount],
	);
	const others = useMemo(
		() =>
			contracts.length > 1
				? `${contracts.length - 1} autre${contracts.length > 2 ? "s" : ""}`
				: "",
		[contracts.length],
	);

	return (
		<Contract onClick={onClick}>
			<Avatars contracts={contracts} notFoundCount={notFoundCount} />
			<Description>
				{firstContract ? (
					<Name>{firstContract.getWorker.name}</Name>
				) : (
					<Name empty>
						{hasStarted
							? "Expirée"
							: hasAvailableWorkers
							? "Pas encore sélectionné"
							: "En attente de réponses"}
					</Name>
				)}
				<AdditionalInformation>
					{[others, missing].filter(Boolean).join(", ")}
				</AdditionalInformation>
			</Description>
		</Contract>
	);
};

const ContractsExtended = ({
	contracts = [],
	grouped,
	hasAvailableWorkers = false,
	hasStarted = false,
	maxWorkers = 1,
	notFoundCount = 0,
	onClick,
	onClickContract,
}) => (
	<Extended count={grouped && contracts.length ? contracts.length : maxWorkers}>
		{contracts.map(contract => (
			<Contracts
				contracts={[contract]}
				key={contract.id}
				onClick={() => {
					if (typeof onClickContract === "function") {
						onClickContract(contract);
					}
				}}
			/>
		))}
		<NotFound count={notFoundCount} onClick={onClick}>
			{range(notFoundCount).map(index => (
				<Contracts
					hasAvailableWorkers={hasAvailableWorkers}
					hasStarted={hasStarted}
					key={index}
				/>
			))}
		</NotFound>
	</Extended>
);

export default props => {
	const { contracts = [], maxWorkers = 1 } = props;

	const notFoundCount = useMemo(
		() => Math.max(maxWorkers - contracts.length, 0),
		[maxWorkers, contracts.length],
	);

	console.log("notFoundCount", notFoundCount);

	return props.extended ? (
		<ContractsExtended {...props} notFoundCount={notFoundCount} />
	) : (
		<Contracts {...props} notFoundCount={notFoundCount} />
	);
};

const contractHeight = 40;

const contractsContainer = count => css`
	align-items: stretch;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	height: ${count * contractHeight}px;
`;

const AdditionalInformation = styled.div`
	color: ${color("black", "bright")};
	font-size: 0.5em;
`;
const Contract = styled.div`
	align-items: stretch;
	display: flex;
	height: ${contractHeight}px;
`;
const Description = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	margin-left: 5px;
`;
const Extended = styled.div`
	${({ count }) => contractsContainer(count)};
`;
const Name = styled.div`
	font-size: 0.7em;
	padding: 2px 0;
	${({ empty }) =>
		empty &&
		css`
			color: ${color("black", "light")};
			font-size: 0.5em;
			font-style: italic;
		`};
`;
const NotFound = styled.div`
	${({ count }) => contractsContainer(count)};
`;
