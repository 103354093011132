import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/Button";
import Request from "common/Request";
import { isSet } from "../Form/isCompleted.js";

const validateBusiness = async (updateValue, businessId) => {
	await Request.mutation(
		/* GraphQL */ `
			mutation($businessId: ID!) {
				validateBusiness(businessId: $businessId) {
					id
				}
			}
		`,
		{
			businessId,
		},
		{
			successMessage: "Business validé avec succès",
			errorMessage: "Une erreur s'est produite. Veuillez réessayer.",
		},
	);
	updateValue(true);
};

const validationCondition = ({
	location,
	defaultBreakMinutes,
	owner: { firstName, lastName, email, phoneNumber },
	company: { address, siret },
}) =>
	isSet(location.streetName) &&
	isSet(location.postCode) &&
	isSet(location.city) &&
	isSet(location.country) &&
	isSet(address.streetName) &&
	isSet(address.postCode) &&
	isSet(address.city) &&
	isSet(address.country) &&
	isSet(firstName) &&
	isSet(lastName) &&
	isSet(email) &&
	isSet(phoneNumber) &&
	isSet(defaultBreakMinutes) &&
	isSet(siret);

export default {
	label: "Activation du compte",
	fields: () => ({
		validateBusiness: { checkField: business => validationCondition(business) },
	}),
	Component: ({ value: business, updateValue }) => {
		const updateFieldValue = (fieldValue, field) => {
			updateValue({ ...business, [field]: fieldValue });
		};
		const { verified, locked } = business;
		return verified ? (
			<FormControlLabel
				control={
					<Checkbox
						checked={locked || false}
						onChange={() => updateFieldValue(!locked, "locked")}
					/>
				}
				label="bloquer"
			/>
		) : (
			<Button
				disabled={!validationCondition(business)}
				asynchronous
				onClick={async () =>
					await validateBusiness(
						value => updateFieldValue(value, "verified"),
						business.id,
					)
				}
			>
				Valider business
			</Button>
		);
	},
};
