import React from "react";
import { Document, DocumentTitle } from "./shared";
import { MdFolder } from "react-icons/md";
import styled from "styled-components";
import { color } from "common/styles";

export default props => {
	const { initialValues } = props;
	return (
		<Document>
			<DocumentTitle>Documents</DocumentTitle>
			<Link
				target="worker-documents-folder"
				href={`https://drive.google.com/drive/folders/${
					initialValues.googleFolderId
				}`}
			>
				<MdFolder />
			</Link>
		</Document>
	);
};

const Link = styled.a`
	font-size: 2em;
	color: ${color("primary")};
`;
