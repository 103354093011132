import { call, put, take, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
	addAlert,
	mountAlert,
	removeAlert,
	unmountAlert,
} from "actions/alerts";
import { getAlert } from "selectors/alerts";

export function* watchAddAlert() {
	while (true) {
		const alertPending = yield select(getAlert);
		if (!alertPending) {
			yield take(addAlert().type);
		}
		yield call(delay, 10);
		yield put(mountAlert());
		yield call(delay, 5000);
		yield put(unmountAlert());
		yield call(delay, 400);
		yield put(removeAlert());
	}
}
