import React from "react";
import { addModal } from "architecture/Modals";
import Confirm from "modals/Confirm";

export const makeTarget = query => target => ({
	...target,
	queryId: query.id,

	onSelect() {
		addModal(({ dismiss }) => (
			<Confirm
				worker={target.worker}
				query={query}
				target={target}
				dismiss={dismiss}
			/>
		));
	},
});
