import React from "react";
import Table from "components/Table";
import formatCurrency from "utils/formatCurrency";
import LineBrokenText from "components/LineBrokenText";
import { addModal } from "architecture/Modals";
import AdminQuery from "modals/AdminQuery";
import Chip from "@material-ui/core/Chip";

export default ({ queries = [] }) => {
	const columns = [
		{
			label: "Identifiant",
			getter: ({ id }) => id,
		},
		{
			label: "Business",
			getter: ({ business: { name } }) => name,
		},
		{
			label: "",
			getter: ({ businessDelegatesSelectionToGofer }) =>
				businessDelegatesSelectionToGofer ? <Chip label="Déléguée" /> : null,
		},
		{
			label: "Poste",
			getter: ({ getJob: { name } }) => name,
		},
		{
			numeric: true,
			label: "Taux horaire net",
			sortGetter: ({ rate }) => rate,
			getter: ({ rate }) => formatCurrency(rate),
		},
		{
			label: "Shifts",
			sortGetter: ({ startTime }) => startTime,
			getter: ({ shiftsDescription }) => (
				<LineBrokenText>{shiftsDescription}</LineBrokenText>
			),
		},
		{
			numeric: true,
			label: "Quantité totale",
			getter: ({ maxWorkers }) => maxWorkers,
		},
		{
			numeric: true,
			label: "Contracts non signés",
			getter: ({ contracts }) =>
				contracts
					.filter(({ signed }) => !signed)
					.map(({ getWorker: { name } }) => name)
					.join(", ") || "aucun",
		},
		{
			numeric: true,
			label: "Contrats signés",
			getter: ({ contracts }) =>
				contracts.filter(({ signed }) => signed).length,
		},
	];

	return (
		<Table
			id="QuerisTable"
			columns={columns}
			rows={queries.map(query => ({
				...query,
				onClick: () =>
					addModal(() => <AdminQuery actualQuery={query} columns={columns} />),
			}))}
			orderBy={5}
		/>
	);
};
