import React from "react";
import Button from "components/Button";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import Request from "common/Request";
import { DEV } from "common/constants";

export default class AdminCancelQuery extends React.Component {
	state = {
		loading: false,
		disabled: false,
	};

	adminCancelQuery = async () => {
		try {
			this.setState({ loading: true });
			await Request.mutation(
				/* GraphQL */ `
					mutation($id: ID) {
						adminCancelQuery(queryId: $id) {
							id
						}
					}
				`,
				{
					id: this.props.queryId,
				},
			);
			dispatch(addAlert("La mission a bien été annulée !"));
			this.setState({ loading: false, disabled: true });
		} catch (error) {
			DEV && console.error(error);
			dispatch(
				addAlert(
					"Une erreur s'est produite et la mission n'a pas été annulée !",
				),
			);
			this.setState({ loading: false });
		}
	};

	render() {
		const { loading, disabled } = this.state;
		return (
			<Button
				onClick={this.adminCancelQuery}
				loading={loading}
				disabled={disabled}
			>
				Supprimer la demande
			</Button>
		);
	}
}
