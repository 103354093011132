import React from "react";
import ContractsToBriefTable from "scenes/Home/Admin/ContractsToBriefTable";
import { makeContract } from "models/contract";
import ContractsToBriefQuery from "./ContractsToBriefQuery";

export default () => (
	<ContractsToBriefQuery>
		{contracts => (
			<ContractsToBriefTable contracts={(contracts || []).map(makeContract)} />
		)}
	</ContractsToBriefQuery>
);
