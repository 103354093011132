import { css, keyframes } from "styled-components";

export function color(name, opacity = "normal", alpha = 1) {
	const isBtp = window.location.href.indexOf("/btp") !== -1;

	let opacity500 =
		{
			thick: 850,
			dense: 600,
			normal: 500,
			intense: 400,
			light: 250,
			bright: 135,
			pale: 67,
			translucent: 20,
		}[opacity] || opacity;
	let rgb = {
		primary: isBtp ? [81, 44, 168] : [11, 115, 146],
		primaryDark: [7, 85, 109],
		primaryLight: isBtp ? [170, 134, 253] : [108, 198, 226],
		action: isBtp ? [249, 168, 37] : [246, 153, 0],
		accent: isBtp ? [249, 168, 37] : [246, 153, 0],
		actionDark: [210, 130, 0],
		important: [221, 46, 68],
		attention: [255, 196, 0],
		black: [0, 0, 0],
		white: [255, 255, 255],
		border: [225, 225, 225],
		info: [3, 169, 244],
		valid: [133, 194, 72],
		almostValid: [202, 198, 64],
		appBackground: [248, 248, 248],
		text: [70, 70, 70],
	}[name] || [0, 0, 0];
	let opacifiedTint = tint => {
		let result = 255 - ((255 - tint) * opacity500) / 500;
		if (result < 0) {
			result = 0;
		}
		return Math.floor(result);
	};
	return `rgba(${rgb.map(tint => opacifiedTint(tint)).join(", ")}, ${alpha})`;
}

export const link = css`
	&:hover {
		cursor: pointer;
		opacity: 0.54;
	}

	&:active {
		opacity: 0.36;
	}
`;

export const disabledLink = css`
	&:hover {
		cursor: not-allowed;
		opacity: 1;
	}

	&:active {
		opacity: 1;
	}
`;

export const circle = radius => css`
	width: ${radius}px;
	height: ${radius}px;
	border-radius: ${radius}px;
`;

export const card = css`
	border-radius: 2px;
	background-color: white;
	box-shadow: 1px 1px 4px ${color("black", "normal", 0.25)};
	padding: 20px;
	margin-bottom: 2px;
`;

export const input = css`
	background-color: transparent;
	border-style: none;
	padding-left: 0;

	&:focus {
		outline-color: transparent;
		outline-style: none;
	}
`;
export const ellipsis = maxWidth => css`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: ${maxWidth};
`;
export const ease = `cubic-bezier(0.4, 0, 0.2, 1)`;
export const desceleration = `cubic-bezier(0, 0, 0.2, 1)`;
export const acceleration = `cubic-bezier(0.4, 0, 1, 1)`;

export const rotate = css`
	animation: ${keyframes`
  	from {
  		transform: rotate(360deg);
  	}

  	to {
  		transform: rotate(0deg);
  	}
  `} 1s linear infinite;
`;

export const mobileBreakpoint = 500;
export const tabletBreakpoint = 1100;

export const isMobile = () => window.innerWidth < mobileBreakpoint;
export const isTablet = () => window.innerWidth < tabletBreakpoint;

export const ripple = (modifier, color) => `
  &:${modifier}, &:focus {
    &::after {
      transition: all 700ms ease;
    }
  }

  &::after {
    content: " ";
    display: block;
    position: absolute;
    left: calc(50% - 20px);
    top: 0;
    background: ${
			color === "light" ? "rgba(255,255,255,0.3)" : "rgba(0, 0, 0, 0.2)"
		};
    height: 40px;
    width: 40px;
    border-radius: 20px;
    transform: scale(0);
    opacity: 1;
  }
  &:${modifier}, &:focus {
    &::after {
      transform: scale(2);
      opacity: 0;
    }
  }
`;
