import React from "react";
import styled from "styled-components";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import { TextField, SelectField, Checkbox } from "redux-form-material-ui";
import { reduxForm, Field } from "redux-form";
import Button from "components/Button";
import MenuItem from "material-ui/MenuItem";
import moment from "moment";
import gql from "graphql-tag";
import graphql, { GraphQL } from "common/graphql";
import Request from "common/Request";
import LineBrokenText from "components/LineBrokenText";
import Table from "components/Table";
import pluralize from "utils/pluralize.js";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import CheckboxesAndTextField from "components/CheckboxesAndTextField";

const roles = {
	worker: "Par le worker",
	business: "Par le business",
	admin: "Par Gofer",
};

const reasons = {
	business: [
		"Baisse d'activité",
		"Changement de planning",
		"A préféré un autre worker",
		"Le Worker est blacklisté",
		"La première vacation s'est mal passée",
		"Renvoyé chez lui / chez elle",
	],
	admin: [
		"Doublon",
		"Erreur technique",
		"Worker rejeté",
		"Choix de l'équipe opérationnelle",
	],
};

const warnedByMeansOfLabels = [
	"appel",
	"email",
	"message vocal",
	"sms tel d’astreinte",
	"sms twilio",
	"facebook",
	"commentaire sur la mission",
];

const Form = props => {
	const {
		handleSubmit,
		canceledBy,
		lessThan24Hours,
		contract,
		comment,
	} = props;

	return (
		<form onSubmit={handleSubmit}>
			<Title>
				Contrat de {contract.getJob.name} entre {contract.getWorker.name} et{" "}
				{contract.getBusiness.name}
				{contract.cancelledAt
					? ` (annulé ${moment(contract.cancelledAt).fromNow()})`
					: ""}
			</Title>
			<LineBrokenText>{contract.shiftsDescription}</LineBrokenText>
			<Field
				fullWidth
				name="canceledBy"
				component={SelectField}
				floatingLabelText="Qui annule la mission ?"
			>
				{Object.entries(roles).map(([role, label]) => (
					<MenuItem key={role} value={role} primaryText={label} />
				))}
			</Field>
			<Field
				name="lessThan24Hours"
				component={Checkbox}
				label="annulation moins de 24h avant le début de la mission"
			/>
			<Field name="requery" component={Checkbox} label="Relancer une demande" />
			{canceledBy === "worker" ? (
				<React.Fragment>
					{lessThan24Hours ? (
						<Field
							name="justification"
							component={TextField}
							hintText={
								"Justificatif fourni par le worker (laisser vide si pas encore de justificatif)"
							}
							fullWidth
						/>
					) : null}
					<Field
						name="noShow"
						component={Checkbox}
						label="No show (n'a pas prévenu Gofer avant le début de la mission)"
					/>
					<Field
						name="didInformBusiness"
						component={Checkbox}
						label="a prévenu le business avant le début de la mission"
					/>
				</React.Fragment>
			) : ["business", "admin"].includes(canceledBy) ? (
				<Field
					fullWidth
					name="reason"
					component={SelectField}
					floatingLabelText="Raison"
				>
					{reasons[canceledBy].map(reason => (
						<MenuItem key={reason} value={reason} primaryText={reason} />
					))}
				</Field>
			) : null}
			{canceledBy === "worker" || canceledBy === "business" ? (
				<Field
					name="warnedByMeansOf"
					component={CheckboxesAndTextField}
					title="Prévenu par :"
					labels={warnedByMeansOfLabels}
					textFieldLabel={"autre"}
				/>
			) : null}
			<Field
				name="comment"
				component={TextField}
				multiLine
				hintText={"Commentaire *"}
				fullWidth
				rows={3}
			/>
			<Button disabled={!comment} submit>
				Enregistrer
			</Button>
		</form>
	);
};

const formIdentifier = "Admin Contracts Cancel";

const createReduxForm = compose(
	withProps(({ contract }) => ({
		initialValues: {
			lessThan24Hours: moment(contract.startTime)
				.subtract(24, "hours")
				.isBefore(moment()),
		},
	})),
	connect(state => {
		const { canceledBy, lessThan24Hours, requery, comment, warnedByMeansOf } =
			((state.form || {})[formIdentifier] || {}).values || {};
		return {
			canceledBy,
			lessThan24Hours,
			requery,
			comment,
			warnedByMeansOf,
		};
	}),
	reduxForm({
		form: formIdentifier,
	}),
);

export const Cancel = createReduxForm(Form);

class ConnectedCancel extends React.Component {
	state = { isCanceled: false };
	submit = async ({
		canceledBy,
		lessThan24Hours,
		requery,
		comment,
		reason,
		justification,
		noShow,
		didInformBusiness,
		warnedByMeansOf,
	}) => {
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation(
						$contractId: ID!
						$cancelation: ContractCancelationInput
						$requery: Boolean
					) {
						adminCancelContract(
							contractId: $contractId
							cancelation: $cancelation
							requery: $requery
						) {
							id
						}
					}
				`,
				{
					requery,
					contractId: this.props.contract.id,
					cancelation: {
						canceledBy,
						lessThan24Hours,
						comment,
						reason,
						justification,
						noShow,
						didInformBusiness,
						warnedByMeansOf,
					},
				},
			);
			this.setState({ isCanceled: true });
		} catch (error) {
			console.error(error);
			dispatch(
				addAlert(
					"Une erreur s'est produite et le contrat n'a pas été annulé !",
				),
			);
		}
	};

	render() {
		if (this.state.isCanceled) {
			return (
				<FutureContract
					workerId={this.props.contract.getWorker.id}
					businessName={this.props.contract.getBusiness.name}
					workerName={this.props.contract.getWorker.name}
				/>
			);
		}
		return (
			<Cancel
				{...this.props}
				contract={this.props.contract || {}}
				onSubmit={this.submit}
			/>
		);
	}
}

const Title = styled.h3`
	font-weigth: bold;
	font-size: 1.4em;
	margin-bottom: 10px;
`;

export default graphql(
	gql`
		query getContractBeforeAdminCancel($contractId: ID!) {
			contract(id: $contractId) {
				id
				startTime
				shiftsDescription
				getWorker {
					id
					name
				}
				getBusiness {
					id
					name
				}
				getJob {
					name
				}
				cancelledAt
			}
		}
	`,
	({ contractId }) => ({ variables: { contractId } }),
)(ConnectedCancel);

const FutureContract = props => (
	<GraphQL
		query={gql`
			query($workerId: ID!) {
				admin {
					workerFutureContracts(workerId: $workerId) {
						id
						shiftsDescription
						startTime
						signed
						getBusiness {
							id
							name
						}
						getJob {
							name
						}
						getWorker {
							id
							name
						}
					}
				}
			}
		`}
		variables={{ workerId: props.workerId }}
	>
		{({ admin: { workerFutureContracts } }) => (
			<ContractsInfo
				contracts={workerFutureContracts}
				workerName={props.workerName}
				businessName={props.businessName}
			/>
		)}
	</GraphQL>
);

const ContractsInfo = props => (
	<div>
		<ConfirmCancelation
			workerName={props.workerName}
			businessName={props.businessName}
		/>
		<br />
		<ContractsTitle contracts={props.contracts} />
		<br />
		<ContractsTable contracts={props.contracts} />
	</div>
);

const ConfirmCancelation = props => {
	const { workerName, businessName } = props;
	return (
		<Typography variant="h5">
			Le Contract entre {workerName} et {businessName} a bien été annulé !
		</Typography>
	);
};

const ContractsTitle = props => {
	const contracts = props.contracts;

	if (contracts.length > 0) {
		return (
			<Typography variant="h4">
				Ce worker est positionné sur {contracts.length}{" "}
				{pluralize(contracts, "autre mission")}
			</Typography>
		);
	} else {
		return (
			<Typography variant="h4">
				Ce worker n'est positionné sur aucun autre contrat
			</Typography>
		);
	}
};

const ContractsTable = ({ contracts }) => (
	<Table
		id="ContractCancelWorkerFutureContractsTable"
		columns={[
			{
				label: "Identifiant",
				getter: ({ id }) => id,
			},
			{
				label: "",
				getter: ({ isSigned }) =>
					isSigned ? null : <Chip label="Non Signé" />,
			},
			{
				label: "Poste",
				getter: ({ getJob: { name } }) => name,
			},
			{
				label: "Business",
				getter: ({ getBusiness: { name } }) => name,
			},
			{
				label: "Shifts",
				sortGetter: ({ startTime }) => startTime,
				getter: ({ shiftsDescription }) => (
					<LineBrokenText>{shiftsDescription}</LineBrokenText>
				),
			},
		]}
		rows={contracts}
		orderBy={4}
		getId={({ id }) => id}
	/>
);
