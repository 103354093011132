import gql from "graphql-tag";
import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import useCurrentUser from "common/useCurrentUser";
import Request from "common/Request";

const BusinessUpdateSelf = gql`
	mutation(
		$description: String
		$benefitsAmount: Int
		$benefitsDescription: String
	) {
		business {
			updateSelf(
				description: $description
				benefitsAmount: $benefitsAmount
				benefitsDescription: $benefitsDescription
			) {
				id
			}
		}
	}
`;

export const useUpdateSelf = () => {
	const {
		business: { description, benefitsDescription, mediaUrls },
	} = useCurrentUser();
	const [business, setBusiness] = useState({
		description,
		benefitsDescription,
		mediaUrls,
	});

	const [saveSelf, { mutationLoading }] = useMutation(BusinessUpdateSelf);

	const saveUpdateSelf = async () => {
		await saveSelf({
			variables: {
				...business,
				benefitsAmount: business.benefitsAmount
					? business.benefitsAmount * 100
					: 0,
			},
		});
		Request.refreshAll();
	};

	return {
		mutationLoading,
		business,
		setBInfos: val => setBusiness({ ...business, ...val }),
		saveUpdateSelf,
	};
};
