import { MdPerson } from "react-icons/md";
import React from "react";
import styled, { css } from "styled-components";
import { circle, color } from "common/styles";

const Avatar = ({ image, indicator, children, className, radius }) => {
	const filler = !children && !image;
	return (
		<Container
			image={image}
			filler={filler}
			radius={radius}
			hasChildren={!!children}
			className={`${className || ""} avatar`}
		>
			{children}
			{filler && <MdPerson />}
			{indicator && <Indicator>{indicator}</Indicator>}
		</Container>
	);
};

export default Avatar;

const Container = styled.div`
  border-radius: 50%;
  color: white;
  text-align: center;
  svg {
    height: 80%;
    width: 80%;
  }
  ${({ hasChildren, radius }) =>
		!hasChildren
			? css`
					padding-bottom: ${radius ? `0px` : "100%"};
					height: 0;
			  `
			: css`
					display: flex;
					align-items: center;
					justify-content: center;
			  `}
  ${({ radius }) =>
		radius
			? css`
					width: ${radius * 2}px;
					height: ${radius * 2}px;
			  `
			: css`
					width: 100%;
					> svg {
						opacity: 0.8;
						position: absolute;
						width: 90%;
						height: 90%;
						left: 5%;
						top: 5%;
					}
			  `}
  ${({ image }) =>
		image
			? css`
					background-image: url(${image});
					background-size: cover;
					background-position: center center;
			  `
			: css`
					background-color: ${color("black", "pale")};
					border: solid 2px white;
			  `};
  position: relative;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
`;
const Indicator = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	${circle(10)};
	bottom: 0;
	right: 0;
	background-color: white;
	font-size: 0.2em;
`;

const AvatarFiller = styled.div`
	height: 100px;
	width: 100px;
	border-radius: 50px;
	border: 10px solid ${color("action")};
	background: #efefef;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 56px;
	color: #bbb;
	font-weight: bold;
`;

const AvatarCount = styled.div`
	height: 65px;
	width: 65px;
	border-radius: 32px;
	border: 8px solid ${color("action")};
	background: #efefef;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	color: #bbb;
	font-weight: bold;
`;

const Flower = styled.div`
	height: 100px;
	width: 100px;
	position: relative;
	> * {
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
	}
	&.two-avatars {
		display: flex;
		> div:nth-child(2) {
			margin-left: -30px;
			margin-top: 35px;
		}
	}
	&.three-avatars {
		> *:first-child {
			position: absolute;
			left: -8px;
			bottom: -8px;
		}
		> *:nth-child(2) {
			position: absolute;
			top: -8px;
			left: calc(50% - 32px);
		}
		> *:nth-child(3) {
			position: absolute;
			right: -8px;
			bottom: -8px;
		}
	}
`;

export const AvatarsFlower = ({ avatars }) => (
	<Flower
		className={
			avatars.length === 2
				? "two-avatars"
				: avatars.length >= 3
				? "three-avatars"
				: ""
		}
	>
		{avatars.length === 0 && <AvatarFiller>?</AvatarFiller>}
		{avatars.slice(0, 2).map(a => (
			<Avatar image={a} radius={avatars.length >= 2 ? 32 : 50} />
		))}
		{avatars.length > 2 && <AvatarCount>+{avatars.length - 2}</AvatarCount>}
	</Flower>
);
