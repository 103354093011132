import React from "react";
import EditBDescription from "modals/EditBDescription";
import EditJobPost from "modals/JobPosts/EditJobPost";
import { addModal } from "architecture/Modals";
import Button from "components/Button";
import Loading from "components/Loading";
import useCurrentUser from "common/useCurrentUser";

export default () => {
	const currentUser = useCurrentUser();

	if (!currentUser.business) {
		return <Loading />;
	}

	return (
		<div class="col expand">
			<article class="centered vertical-centered expand">
				<div class="desktop-grid">
					<div class="eight from-three">
						<h2>Premier recrutement pour un poste fixe</h2>
						{!currentUser.business.description ? (
							<React.Fragment>
								<paragraph>
									Avant d'ajouter une première offre, décrivez votre
									établissement. N'hésitez pas à mettre en avant les avantages à
									travailler chez vous.
								</paragraph>
								<paragraph>
									Vous pouvez envoyer des photos de votre établissement à
									<strong>
										<a href="mailto:hello@gofer.fr"> hello@gofer.fr</a>
									</strong>
									, elles seront affichées à nos workers.
								</paragraph>
							</React.Fragment>
						) : (
							<paragraph>
								Votre présentation est prête ! Il est temps de créer votre
								première offre. Vous pouvez à tout moment modifier la
								description de l'établissement.
							</paragraph>
						)}
						<paragraph>
							<Button
								secondary
								className="outline right"
								onClick={() =>
									addModal(({ dismiss }) => (
										<EditBDescription dismiss={dismiss} />
									))
								}
							>
								Mon établissement
							</Button>
							{currentUser.business.description ? (
								<Button
									onClick={() =>
										addModal(({ dismiss }) => <EditJobPost dismiss={dismiss} />)
									}
								>
									Nouvelle offre
								</Button>
							) : null}
						</paragraph>
					</div>
				</div>
			</article>
			<div class="desktop-grid">
				<div class="row four from-five">
					<div class="desktop-grid">
						<img class="eight from-three" src="/JobPosts.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};
