import React from "react";
import { Document, DocumentTitle } from "./shared";
import { DatePicker } from "redux-form-material-ui";
import { reduxForm, Field } from "redux-form";
import {
	updateInformations,
	socialSecurityModel,
} from "models/worker/documents";
import store from "common/store";

const submit = ({ expiresAt, token }) => {
	const socialSecurity = { expiresAt };
	updateInformations({
		token,
		documents: { socialSecurity },
	});
	store.dispatch({
		type: "redux-form/INITIALIZE",
		form: "socialSecurityValidation",
		data: { ...socialSecurityModel(socialSecurity), token },
		fields: Object.keys(socialSecurity),
	});
};

const SocialSecurity = props => {
	const { handleSubmit, initialValues } = props;

	return (
		<Document>
			<form>
				<DocumentTitle color={initialValues.status}>
					Carte vitale (recto) ou attestation de sécurité sociale
				</DocumentTitle>
				<p>
					Si vous n’avez pas de carte vitale, merci de renseigner la date
					d’expiration de l’attestation de sécurité sociale
				</p>
				<Field
					name="expiresAt"
					floatingLabelText=" "
					fullWidth={true}
					component={DatePicker}
					type="text"
					disabled={Boolean(initialValues.validatedAt)}
					props={{
						minDate: new Date(),
						onChange: date => {
							setTimeout(handleSubmit(submit));
						},
					}}
					format={value => (value === "" ? null : value)}
				/>
			</form>
		</Document>
	);
};

export default reduxForm({
	form: "socialSecurityValidation",
	enableReinitialize: true,
})(SocialSecurity);
