import React from "react";
import { reduxForm } from "redux-form";
import Button from "components/Button";
import { Field } from "redux-form";
import styled from "styled-components";
import AsyncSearch from "components/AsyncSearch";
import adminWorkers from "components/AsyncSearch/adminWorkers";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const createReduxForm = reduxForm({
	form: "addTarget",
});

let AddTarget = ({ handleSubmit }) => (
	<form onSubmit={handleSubmit}>
		<Container>
			<Field
				name="workerId"
				component={AsyncSearch}
				fetchOptions={adminWorkers}
				placeHolder={"Worker"}
				fullWidth
			/>
			<Button submit>Ajouter la target</Button>
		</Container>
	</form>
);

AddTarget = createReduxForm(AddTarget);

export default AddTarget;
