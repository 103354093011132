import React from "react";
import { SelectField } from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import { Field } from "redux-form";
import range from "utils/range";
import { isMobile } from "common/styles";

const MaxWorkers = props => (
	<Field
		name="maxWorkers"
		component={SelectField}
		floatingLabelText="Nombre de postes"
		fullWidth={isMobile()}
	>
		{range(9).map((_, index) => (
			<MenuItem key={index} value={index + 1} primaryText={index + 1} />
		))}
	</Field>
);

export default MaxWorkers;
