import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { withStyles } from "@material-ui/core/styles";
import compare from "utils/compare";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { isTablet, color } from "common/styles";

const StyledPaper = styled(Paper)`
	overflow: auto;
	@media print {
		overflow: visible;
		box-shadow: none;
	}

	table {
		td {
			img {
				max-height: 50px;
			}
		}
	}
`;

const StyledTitle = styled.div`
	margin: 20px;
	font-size: 1.2em;
	font-weight: bold;
`;
const ascendingDefault = true;

const memoizedOrderBy = {};

const MobileColLabel = styled.h2`
	font-weight: bold;
	font-size: 14px;
	color: #444;
	margin-top: 0px;
	margin-bottom: 5px;
`;

const MobileContainer = styled.div``;

const MobileCell = styled.div`
	padding: 20px;
	font-size: 14px;
	button {
		width: 220px;
		justify-content: center;
		margin: auto;
	}
	&:nth-child(2n) {
		background-color: ${color("primaryLight", "normal", 0.2)};
	}
`;

const MobileCellLine = styled.div`
	margin-bottom: 13px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const StyledTableRow = withStyles(theme => ({
	hover: {
		transition: "background 500ms ease",
		"&:hover": {
			backgroundColor: `${color("primaryLight", "normal", 0.6)} !important`,
		},
	},
	root: {
		"&:nth-child(2n)": {
			backgroundColor: color("primaryLight", "normal", 0.15),
		},
	},
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
	body: {
		borderColor: "transparent",
	},
}))(TableCell);

export default class EnhancedTable extends React.Component {
	state =
		this.props.id && memoizedOrderBy[this.props.id]
			? memoizedOrderBy[this.props.id]
			: {
					ascending:
						this.props.ascending === undefined
							? ascendingDefault
							: this.props.ascending,
					orderBy: this.props.orderBy,
			  };

	handleRequestSort = orderBy => () => {
		if (this.state.orderBy === orderBy) {
			this.setState({
				ascending: !this.state.ascending,
			});
		} else {
			this.setState({
				orderBy,
				ascending: ascendingDefault,
			});
		}
	};

	componentDidUpdate() {
		if (this.props.id) {
			memoizedOrderBy[this.props.id] = this.state;
		}
	}

	get sortedRows() {
		const { columns, rows } = this.props;
		const { ascending, orderBy } = this.state;

		if (orderBy === undefined || orderBy === null) {
			return [...rows];
		}

		const getSortGetter = ({ getter, sortGetter }) => sortGetter || getter;
		const sortGetter = getSortGetter(columns[orderBy]);

		return [...rows].sort(compare(sortGetter, { ascending }));
	}

	renderMobile() {
		const { columns, title, className, getId } = this.props;

		return (
			<StyledPaper className={className}>
				{title ? <StyledTitle>{title}</StyledTitle> : ""}
				<MobileContainer>
					{this.sortedRows.map((row, index) => (
						<MobileCell
							key={getId ? getId(row) : row.id || index}
							onClick={row.onClick}
						>
							{columns
								.map(({ getter, label }, index) => (
									<MobileCellLine key={index}>
										<MobileColLabel>{label}</MobileColLabel>
										{(() => {
											try {
												return getter(row);
											} catch (error) {
												console.error(error);
												return "";
											}
										})()}
									</MobileCellLine>
								))
								.filter(Boolean)}
						</MobileCell>
					))}
				</MobileContainer>
			</StyledPaper>
		);
	}

	renderDesktop() {
		const { columns, title, className, getId } = this.props;
		const { ascending, orderBy } = this.state;

		return (
			<StyledPaper className={className}>
				{title ? <StyledTitle>{title}</StyledTitle> : ""}
				<Table>
					<TableHead>
						<TableRow>
							{columns.map(
								(
									{ disablePadding, label, onHeaderClick, active },
									columnIndex,
								) => (
									<TableCell
										key={columnIndex}
										padding={disablePadding}
										sortDirection={
											orderBy === columnIndex || active
												? ascending
													? "asc"
													: "desc"
												: false
										}
									>
										<TableSortLabel
											active={orderBy === columnIndex || active}
											direction={ascending ? "asc" : "desc"}
											onClick={
												onHeaderClick
													? onHeaderClick
													: this.handleRequestSort(columnIndex)
											}
										>
											{label}
										</TableSortLabel>
									</TableCell>
								),
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{this.sortedRows.map((row, index) => (
							<StyledTableRow
								hover
								key={getId ? getId(row) : row.id || index}
								onClick={row.onClick}
								style={{ cursor: row.onClick ? "pointer" : "default" }}
							>
								{columns
									.map(({ getter, numeric, disablePadding }, colIndex) => (
										<StyledTableCell
											key={
												getId
													? getId(row) + colIndex.toString()
													: index.toString() + colIndex.toString()
											}
											align={numeric ? "right" : "inherit"}
											padding={disablePadding}
										>
											{(() => {
												try {
													return getter(row);
												} catch (error) {
													console.error(error);
													return "";
												}
											})()}
										</StyledTableCell>
									))
									.filter(Boolean)}
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</StyledPaper>
		);
	}

	render() {
		return isTablet() ? this.renderMobile() : this.renderDesktop();
	}
}
