import React from "react";
import { computeJobsExperience, computeJobPercent } from "models/worker/resume";
import Bar from "components/Bar";

export default ({ experiences }) => {
	const jobs = computeJobsExperience(experiences);

	return (
		<section>
			{jobs
				.filter(job => job.name)
				.map(job => {
					let label = `${(Math.round((job.nbMonths * 10) / 12) / 10)
						.toString()
						.replace(".", ",")} an${job.nbMonths > 23 ? "s" : ""}`;
					if (job.nbMonths >= 24) {
						label = `${Math.round(job.nbMonths / 12)} ans`;
					}
					if (job.nbMonths < 12) {
						label = `${job.nbMonths} mois`;
					}
					return (
						<Bar
							key={job.key}
							value={computeJobPercent(job)}
							max={1}
							title={job.name}
							label={label}
						/>
					);
				})}
		</section>
	);
};
