import React from "react";
import range from "utils/range";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";

export default ({ label, onChange, value }) => (
	<div class="row">
		<FormControl fullWidth={true}>
			<InputLabel id={label + "-selectlabel"}>{label}</InputLabel>
			<Select
				labelId={label + "-selectlabel"}
				value={moment(value).hour()}
				onChange={e =>
					onChange(
						moment(value)
							.hours(e.target.value)
							.toISOString(),
					)
				}
				fullWidth={true}
			>
				{range(24).map(hour => (
					<MenuItem value={hour} key={hour}>
						{hour}
					</MenuItem>
				))}
			</Select>
		</FormControl>
		<FormControl fullWidth={true}>
			<InputLabel id={label + "-selectlabel2"}> </InputLabel>
			<Select
				value={moment(value).minute()}
				onChange={e =>
					onChange(
						moment(value)
							.minutes(e.target.value)
							.toISOString(),
					)
				}
				fullWidth={true}
			>
				{range(60 / 5)
					.map(m => m * 5)
					.map(minutes => (
						<MenuItem value={minutes} key={minutes}>
							{minutes + (minutes < 10 ? "0" : "")}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	</div>
);
