import React from "react";
import DarkBox from "components/DarkBox";
import { LogoBadge, QuoteIcon } from "components/Decorations";
import History from "./History";
import FeedbacksBreakdown from "./FeedbacksBreakdown";
import AnswerStatus from "./AnswerStatus";
import moment from "moment";
import Button from "components/Button";
import { useRequestAdminReview } from "models/worker/adminReview";
import { isTablet } from "common/styles";

export default ({ answer, worker, anonymizeFeedbacks }) => {
	const {
		id,
		adminReview,
		businessDebriefs,
		featuredDebrief,
		name,
		debriefScoreCounts,
		pastContractsCount,
		verifiedAt,
		medianDebriefScore,
	} = worker;
	const { requestReview, hasRequestedReview } = useRequestAdminReview(worker);

	return (
		<div class="col">
			{answer && answer.id ? <AnswerStatus answer={answer} /> : null}

			<DarkBox
				className="section card"
				illustration={() => (adminReview ? <LogoBadge /> : null)}
				illustrationPosition="bottom"
			>
				{featuredDebrief ? (
					<article class="centered col">
						{featuredDebrief.comment ? (
							<section class="centered">
								<div>
									<QuoteIcon />
								</div>
								<div>
									<em>{featuredDebrief.comment}</em>
								</div>
								<div>
									<strong>Un client Gofer</strong>
								</div>
							</section>
						) : null}
						<FeedbacksBreakdown
							debriefScoreCounts={debriefScoreCounts}
							businessDebriefs={businessDebriefs}
							medianDebriefScore={medianDebriefScore}
						/>
						<History
							id={id}
							anonymizeFeedbacks={anonymizeFeedbacks}
							pastContractsCount={pastContractsCount}
							businessDebriefs={businessDebriefs}
						/>
					</article>
				) : (
					<article class="centered">
						{name} n'a pas encore été évalué·e sur Gofer
					</article>
				)}
				<section class="centered col">
					<strong>L'avis de Gofer</strong>
					<div>
						<em>
							{adminReview && adminReview.comment
								? adminReview.comment
								: "Nous n'avons pas encore indiqué notre avis."}
						</em>
					</div>
					{!adminReview || !adminReview.comment ? (
						<section>
							{hasRequestedReview ? (
								"Notre équipe a pris en compte votre demande."
							) : (
								<Button
									className="outline"
									secondary
									onClick={() => requestReview()}
								>
									{isTablet() ? "Demander l'avis" : "Demander l'avis de Gofer"}
								</Button>
							)}
						</section>
					) : null}
				</section>
				<section class="centered">
					{pastContractsCount > 0
						? `${pastContractsCount} missions réalisées chez Gofer`
						: `Inscrit·e ${moment(
								verifiedAt,
						  ).fromNow()}, proposez-lui sa première mission !`}
				</section>
			</DarkBox>
		</div>
	);
};
