import React, { useState } from "react";
import Button from "components/Button";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import { DEV } from "common/constants";
import Request from "common/Request";

export const DeleteFromTargets = ({ workerId, queryId }) => {
	const [isDeleting, setIsDeleting] = useState(false);

	const onDelete = async () => {
		if (window.confirm("Confirmer la suppression?")) {
			setIsDeleting(true);
			try {
				await Request.mutation(
					/* GraphQL */ `
						mutation($queryId: ID!, $workerId: ID!) {
							removeTarget(queryId: $queryId, workerId: $workerId) {
								id
							}
						}
					`,
					{
						queryId,
						workerId,
					},
				);
			} catch (error) {
				DEV && console.error(error);
				dispatch(addAlert("Delete failed"));
			} finally {
				setIsDeleting(false);
			}
		}
	};

	return (
		<Button onClick={onDelete} className="delete" loading={isDeleting}>
			Supprimer
		</Button>
	);
};
