import React from "react";
import JobPost from "./JobPost";
import JobPostDetails from "modals/JobPostDetails";
import { addModal } from "architecture/Modals";
import { useModalOnPath } from "architecture/Modals";

export default ({ closedJobPosts, openJobPosts, history }) => {
	const { openModal: openJobPost } = useModalOnPath({
		history,
		path: "/job-posts/job-post/",
		modal: id => ({ dismiss }) => <JobPostDetails dismiss={dismiss} id={id} />,
	});

	return (
		<div class="desktop-grid">
			<div class="twelve">
				{openJobPosts.length > 0 ? (
					<section>
						<h1>Offres en cours</h1>
						<div class="desktop-grid with-gap mobile-col">
							{openJobPosts.map(jobPost => (
								<JobPost {...jobPost} onClick={() => openJobPost(jobPost.id)} />
							))}
						</div>
					</section>
				) : null}

				{closedJobPosts.length > 0 ? (
					<section>
						<h1>Offres fermées</h1>
						<div class="desktop-grid with-gap mobile-col">
							{closedJobPosts.map(jobPost => (
								<JobPost
									{...jobPost}
									onClick={() =>
										addModal(({ dismiss }) => (
											<JobPostDetails dismiss={dismiss} {...jobPost} />
										))
									}
								/>
							))}
						</div>
					</section>
				) : null}
			</div>
		</div>
	);
};
