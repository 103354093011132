import ReinitializePassword from "./customFields/ReinitializePassword";

export default {
	label: "Informations du manager",
	fields: () => ({
		owner: {
			firstName: { label: "Prénom" },
			lastName: { label: "Nom" },
			phoneNumber: { label: "Téléphone" },
			email: { label: "Email" },
			reinitializePassword: {
				custom: ReinitializePassword,
				optional: true,
			},
		},
	}),
};
