import * as React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Link } from "react-router-dom";
import styled from "styled-components";

const DrawerLink = styled(Link)`
	text-decoration: none;
	height: 50px;
	line-height: 50px;
	padding-left: 35px;
	padding-right: 35px;
	color: #444;
	background-color: transparent;
	transition: all 500ms ease;
	display: block;

	&:hover {
		background-color: #eee;
	}
`;

export default ({ routes, onClose, onOpen, opened }) => (
	<SwipeableDrawer
		anchor="left"
		open={opened}
		onClose={() => onClose && onClose()}
		onOpen={() => onOpen && onOpen()}
	>
		<div
			tabIndex={0}
			role="button"
			onClick={() => onClose && onClose()}
			onKeyDown={() => onClose && onClose()}
		>
			{routes.map((route, i) => (
				<DrawerLink key={i} to={route.route}>
					{route.label}
				</DrawerLink>
			))}
		</div>
	</SwipeableDrawer>
);
