import React, { useState, useEffect } from "react";
import Stepper from "./Stepper";
import Button from "components/Button";
import name from "./steps/name";
import billing from "./steps/billing";
import defaultQueries from "./steps/defaultQueries";
import other from "./steps/other";
import operational from "./steps/operational";
import owner from "./steps/owner";
import company from "./steps/company";
import validateBusiness from "./steps/validateBusiness";
import AutoFields from "./Form/AutoFields";
import isCompleted from "./Form/isCompleted";
import getCompanyInformations from "./steps/customFields/getCompanyInformations";
import styled from "styled-components";
import { color } from "common/styles";
import client from "common/apolloClient";

const steps = [
	name,
	billing,
	owner,
	company,
	operational,
	defaultQueries,
	other,
	validateBusiness,
].map(({ fields, Component, ...rest }) => ({
	Component:
		Component ||
		(props => <AutoFields fields={fields(props.value, props)} {...props} />),
	isCompleted: (business, context) =>
		isCompleted(business, fields(business, context)),
	...rest,
}));

export default ({
	businessInDB,
	saveBusiness,
	countries,
	dismiss,
	billingReferences,
	salesPeople,
	jobs,
	setError,
	canValidate,
	businessAsBeenLoaded,
	businessCategories,
}) => {
	const [business, updateBusiness] = useState(businessInDB);
	useEffect(() => updateBusiness(businessInDB), [businessAsBeenLoaded]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const stepsToDisplay = canValidate ? steps : steps.slice(0, steps.length - 1);
	return (
		<div>
			<Stepper
				steps={stepsToDisplay.map(
					({ label, Component, isCompleted, beforeNext }, index) => ({
						label,
						render: () => (
							<Component
								openConfirmDialog={openConfirmDialog}
								setOpenConfirmDialog={setOpenConfirmDialog}
								value={business}
								updateValue={updateBusiness}
								countries={countries}
								billingReferences={billingReferences}
								getCompanyInformations={siret =>
									getCompanyInformations({ updateBusiness, siret, business })
								}
								salesPeople={salesPeople}
								saveBusiness={async () => {
									await saveBusiness(business);
								}}
								jobs={jobs}
								businessCategories={businessCategories}
							/>
						),
						completed: isCompleted(business, { businessCategories }),
						beforeNext: async next => {
							try {
								setError(null);
								if (beforeNext) {
									await beforeNext(businessInDB, business);
								}
								await saveBusiness(business);
								if (index === stepsToDisplay.length - 1) {
									await client.reFetchObservableQueries();
									dismiss();
								}
								next();
							} catch (error) {
								if (error.message === "businessAlreadyExists") {
									setOpenConfirmDialog(true);
								} else {
									setError(error.message);
								}
								return;
							}
						},
					}),
				)}
			/>
			{business.id ? (
				<>
					<Button
						asynchronous
						onClick={async () => {
							await saveBusiness(business);
							await client.reFetchObservableQueries();
							dismiss();
						}}
					>
						Enregistrer et quitter
					</Button>
					<IDContainer>
						Identifiant : <ID>{businessInDB.id}</ID>
					</IDContainer>
				</>
			) : null}
		</div>
	);
};

const ID = styled.code`
	color: ${color("important")};
	background-color: ${color("black", "translucent")};
	font-family: Monaco, Menlo, Consolas, Courier New, monospace;
	font-size: 12px;
	padding: 2px 3px 1px;
	border: 1px solid ${color("black", "pale")};
	border-radius: 3px;
`;

const IDContainer = styled.p`
	margin-top: 50px;
`;
