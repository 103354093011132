import React, { useState } from "react";
import styled from "styled-components";
import { MdPowerSettingsNew, MdMenu } from "react-icons/md";
import { color, link } from "common/styles";
import NavbarLinks from "./NavbarLinks";
import DrawerNav from "./DrawerNav";
import { isMobile, mobileBreakpoint } from "../common/styles";
import useAuth0 from "common/useAuth0";
import useCurrentUser from "common/useCurrentUser";

export default ({ children, routes, className }) => {
	const isBtp = window.location.href.indexOf("/btp") !== -1;
	const { logout } = useAuth0();
	const user = useCurrentUser();
	const business = user.business;
	const [opened, setOpened] = useState(false);

	const hasHiddenRoutes =
		routes.find(r => r.drawer) !== undefined || isMobile();

	return (
		<Container>
			{hasHiddenRoutes ? (
				<DrawerNav
					opened={opened}
					onClose={() => setOpened(false)}
					routes={routes.filter(r => r.drawer)}
				/>
			) : null}
			<Bar className={`navbar ${className ? className : ""}`}>
				<Brand />
				{hasHiddenRoutes ? <Menu onClick={() => setOpened(true)} /> : null}
				<NavbarLinks routes={routes} />
				<UserContainer>
					{!isMobile() ? (
						<div>
							<User>{business ? business.name : ""}</User>
							<User>
								{user ? `${user.firstName || ""} ${user.lastName || ""}` : ""}
							</User>
						</div>
					) : null}
					<Logout onClick={() => logout()}>
						<MdPowerSettingsNew />
					</Logout>
				</UserContainer>
			</Bar>
			<Content className={`col ${isBtp ? "btp" : ""}`}>{children}</Content>
		</Container>
	);
};

const navbarHeight = 53;

const Menu = styled(MdMenu)`
	color: #fff;
	height: 40px;
	width: 40px;
	cursor: pointer;
	transition: color 500ms ease;
	margin-right: 15px;
	&:hover {
		color: ${color("action")};
	}
`;

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	z-index: 0;
	position: relative;
	height: 100%;
`;
const Bar = styled.div`
	color: white;
	padding: 10px 15px 10px 100px;
	display: flex;
	background-color: ${color("primary")};
	align-items: center;
	justify-content: flex-start;
	box-shadow: 0 1px 2px 1px ${color("black", "normal", 0.25)};
	z-index: 1;
	height: ${navbarHeight}px;
	@media screen and (max-width: ${mobileBreakpoint}px) {
		padding-left: 10px;
	}
	@media print {
		display: none;
	}
`;

const UserContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: auto;
`;

const User = styled.div`
	text-align: right;
	font-size: 1.05em;
`;
const Brand = styled.div`
	background: url(/logo.png) ${color("primary")} no-repeat center;
	background-size: contain;
	height: 70px;
	width: 70px;
	position: absolute;
	top: -5px;
	left: 10px;
	border-radius: 50px;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
	@media screen and (max-width: ${mobileBreakpoint}px) {
		display: none;
	}
`;
const Logout = styled.div`
	padding-left: 25px;
	${link};
	font-size: 1.5em;
`;
const Content = styled.div`
	display: flex;
	align-items: stretch;
	flex: 1;
	z-index: 0;
	flex-direction: column;
	overflow: auto;
	height: calc(100% - ${navbarHeight}px);
	position: relative;
`;
