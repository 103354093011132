import React from "react";
import graphql from "common/graphql";
import gql from "graphql-tag";
import PaymentsToValidateTable from "./PaymentsToValidateTable";

export default graphql(
	gql`
		query getAdminPaymentsToValidate {
			admin {
				paymentsToValidate {
					id
					worker {
						firstName
						lastName
					}
					amount
				}
			}
		}
	`,
)(({ admin: { paymentsToValidate } }) => (
	<PaymentsToValidateTable payments={paymentsToValidate} />
));
