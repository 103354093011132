import React from "react";
import ChangeMaxWorker from "./ManageQuery/ChangeMaxWorkers";
import AdminCancelQuery from "./ManageQuery/AdminCancelQuery";
import AddTarget from "./ManageQuery/AddTarget";
import WorkerAvailable from "./ManageQuery/WorkerAvailable";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import Request from "common/Request";
import { DEV } from "common/constants";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const ManageQuery = ({ query }) => {
	const submitChangeMaxWorkers = async ({ maxWorkers }) => {
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation($id: ID, $maxWorkers: Int) {
						updateQuery(queryId: $id, maxWorkers: $maxWorkers) {
							id
						}
					}
				`,
				{
					id: query.id,
					maxWorkers,
				},
			);
			dispatch(addAlert("Le nombre de postes demandés a bien été modifié !"));
		} catch (error) {
			DEV && console.error(error);
			dispatch(
				addAlert(
					"Une erreur s'est produite et le nombre de postes n'a pas été modifié !",
				),
			);
		}
	};

	const addTargetMutation = async ({ workerId, accept }) =>
		await Request.mutation(
			/* GraphQL */ `
				mutation($queryId: ID, $workerId: ID, $accept: Boolean) {
					addTarget(queryId: $queryId, workerId: $workerId, accept: $accept) {
						id
					}
				}
			`,
			{
				queryId: query.id,
				workerId,
				accept,
			},
		);

	const addTarget = async ({ workerId }) => {
		try {
			await addTargetMutation({ workerId, accept: false });
			dispatch(addAlert("Le worker a bien été ajouté aux targets !"));
		} catch (error) {
			DEV && console.error(error);
			dispatch(
				addAlert(
					"Une erreur s'est produite et le worker n'a pas été ajouté aux targets !",
				),
			);
		}
	};

	const workerAvailable = async ({ workerId }) => {
		try {
			await addTargetMutation({ workerId, accept: true });
			dispatch(addAlert("Le worker a bien été positionné !"));
		} catch (error) {
			DEV && console.error(error);
			dispatch(
				addAlert(
					"Une erreur s'est produite et le worker n'a pas été positionné !",
				),
			);
		}
	};

	return (
		<Container>
			<ChangeMaxWorker
				onSubmit={submitChangeMaxWorkers}
				initialValues={{ maxWorkers: query.maxWorkers }}
			/>
			<AddTarget onSubmit={addTarget} />
			<WorkerAvailable onSubmit={workerAvailable} />
			<AdminCancelQuery queryId={query.id} />
		</Container>
	);
};

export default ManageQuery;
