import gql from "graphql-tag";

export default gql`
	mutation(
		$contractId: ID!
		$score: Int
		$comment: String
		$shouldImprove: ShouldImproveInput
	) {
		debrief(
			contractId: $contractId
			score: $score
			comment: $comment
			shouldImprove: $shouldImprove
		) {
			id
		}
	}
`;
