import React from "react";
import { Document, DocumentTitle } from "./shared";
import { DatePicker, TextField } from "redux-form-material-ui";
import { reduxForm, Field } from "redux-form";
import { updateInformations, identityModel } from "models/worker/documents";
import store from "common/store";

const submit = async ({ expiresAt, number, token }) => {
	const identity = { expiresAt, number };
	await updateInformations({
		token,
		documents: { identity },
	});
	store.dispatch({
		type: "redux-form/INITIALIZE",
		form: "identityValidation",
		data: { ...identityModel(identity), token },
		fields: Object.keys(identity),
	});
};

const Identity = props => {
	const { handleSubmit, initialValues } = props;

	const expiresAt = initialValues.belongsToEU ? null : (
		<Field
			name="expiresAt"
			floatingLabelText="Date d'expiration (obligatoire)"
			fullWidth={true}
			component={DatePicker}
			type="text"
			disabled={Boolean(initialValues.validatedAt)}
			props={{
				minDate: new Date(),
				onChange: date => {
					setTimeout(handleSubmit(submit));
				},
			}}
			format={value => (value === "" ? null : value)}
		/>
	);
	const number = initialValues.belongsToEU ? null : (
		<Field
			name="number"
			floatingLabelText="Numéro de titre de séjour (obligatoire)"
			fullWidth={true}
			component={TextField}
			type="text"
			disabled={Boolean(initialValues.validatedAt)}
			props={{ onBlur: handleSubmit(submit) }}
		/>
	);

	return (
		<Document>
			<form>
				<DocumentTitle color={initialValues.status}>
					Pièce d'identité recto/verso ({initialValues.nationality})
				</DocumentTitle>
				{expiresAt}
				{number}
			</form>
		</Document>
	);
};

export default reduxForm({
	form: "identityValidation",
	enableReinitialize: true,
})(Identity);
