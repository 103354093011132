import React from "react";
import styled from "styled-components";
import splitArray from "utils/splitArray";
import compare from "utils/compare";
import sum from "utils/sum";
import formatCurrency from "utils/formatCurrency";
import Checkbox from "@material-ui/core/Checkbox";
import humanizeTotalHours from "utils/humanizeTotalHours";
import formatShifts from "utils/formatShifts";
import { color } from "common/styles";
import { ellipsis } from "polished";
import Table from "components/Table";
import Loading from "components/Loading";
import Button from "components/Button";
import Request from "common/Request";
import { Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

export default ({ contracts }) => {
	const workers = Object.values(
		splitArray(
			contracts.sort(compare(contract => contract.shifts[0])),
			contract => contract.getWorker.id,
		),
	)
		.map(contracts => {
			const worker = { ...contracts[0].getWorker };
			worker.contracts = contracts;
			worker.advancePaymentAmount = sum(
				contracts
					.filter(contract => contract.payment)
					.map(contract => contract.advancePaymentAmount),
			);
			return worker;
		})
		.sort(compare(worker => worker.name));
	const { withBankWorkers, withoutBankWorkers } = splitArray(
		workers,
		worker =>
			`${worker.documents.bank.validatedAt ? "with" : "without"}BankWorkers`,
		["withBankWorkers", "withoutBankWorkers"],
	);
	return (
		<Container>
			<WorkersToPayTable
				workers={withBankWorkers}
				paymentsCount={contracts.filter(contract => contract.payment).length}
			/>
			{withoutBankWorkers.length ? (
				<WorkersWithoutBank workers={withoutBankWorkers} />
			) : null}
		</Container>
	);
};

class WorkersToPayTable extends React.Component {
	state = {
		loading: false,
	};

	handleClick = async () => {
		this.setState({ loading: true });
		try {
			await sendPayments();
		} catch (error) {
			console.error(error);
		}
		this.setState({ loading: false });
	};

	render() {
		const { workers, paymentsCount } = this.props;
		const { loading } = this.state;
		return (
			<List>
				{workers.map(worker => (
					<Worker key={worker.id} {...worker} disabled={loading} />
				))}
				<Button
					loading={loading}
					disabled={paymentsCount === 0}
					onClick={this.handleClick}
				>
					Envoyer les acomptes
				</Button>
			</List>
		);
	}
}

const Worker = ({
	name,
	overpayment,
	contracts,
	advancePaymentAmount,
	disabled,
	locked,
}) => (
	<ListItem>
		<WorkerContainer>
			<Name>{name}</Name>
			{overpayment ? (
				<Overpayment>(trop perçu {formatCurrency(overpayment)})</Overpayment>
			) : null}
			{advancePaymentAmount > 0 && advancePaymentAmount < overpayment ? (
				<Warning>
					Il faut informer le worker que son trop perçu va passer à{" "}
					{formatCurrency(overpayment - advancePaymentAmount)}
				</Warning>
			) : null}
			{advancePaymentAmount > overpayment ? (
				<AdvancePaymentAmountTotal>
					{formatCurrency(advancePaymentAmount - overpayment)} d'acompte
				</AdvancePaymentAmountTotal>
			) : null}
			{locked ? <Chip label="Worker Rejeté" /> : null}
		</WorkerContainer>
		{contracts.map(contract => (
			<Contract key={contract.id} {...contract} disabled={disabled} />
		))}
	</ListItem>
);

const Contract = ({
	id,
	getBusiness: { name: businessName },
	shifts,
	rate,
	trackedHours,
	advancePaymentAmount,
	payment,
	disabled,
}) => (
	<ContractContainer>
		<Toggle
			disabled={disabled}
			key={Boolean(payment)}
			checked={payment}
			onCheck={addPayment(id)}
			onUncheck={removePayment(id)}
		/>
		<Identifier>{id}</Identifier>
		<Business>{businessName}</Business>
		<Shifts>{formatShifts(shifts, { skipHours: true })}</Shifts>
		<HourlyRate>
			{formatCurrency(rate)}
			/h
		</HourlyRate>
		<TotalHours>
			{humanizeTotalHours(trackedHours, { compact: true })}
		</TotalHours>
		<AdvancePaymentAmount>
			{formatCurrency(advancePaymentAmount)} d'acompte
		</AdvancePaymentAmount>
	</ContractContainer>
);

class Toggle extends React.Component {
	state = {
		loading: false,
	};

	handleChange = async () => {
		const { checked, onCheck, onUncheck } = this.props;
		const onChange = checked ? onUncheck : onCheck;
		this.setState({ loading: true });
		try {
			await onChange();
		} catch (error) {
			console.error(error);
		}
	};

	render() {
		const { loading } = this.state;
		if (loading) {
			return (
				<LoadingContainer>
					<Loading size={30} />
				</LoadingContainer>
			);
		}
		const { checked, disabled } = this.props;
		return (
			<Checkbox
				disabled={disabled}
				checked={checked}
				onChange={this.handleChange}
			/>
		);
	}
}

const WorkersWithoutBank = ({ workers }) => (
	<React.Fragment>
		<Typography variant="h4">Workers dont l'IBAN n'est pas validé</Typography>
		<Table
			id="WorkersWithoutBank"
			columns={[
				{
					label: "Identifiant",
					getter: ({ id }) => id,
				},
				{
					label: "Worker",
					getter: ({ name }) => name,
				},
				{
					label: "Nombre de contrats en attente",
					getter: ({ contracts }) => contracts.length,
				},
				{
					label: "Acompte en attente",
					getter({ contracts }) {
						return formatCurrency(
							sum(contracts.map(contract => contract.advancePaymentAmount)),
						);
					},
				},
			]}
			rows={workers}
			orderBy={1}
		/>
	</React.Fragment>
);

const Container = styled.div`
	margin: 20px;
	overflow: auto;
`;

const ContractContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
const WorkerContainer = styled.div``;
const Name = styled.span`
	font-weight: bold;
	font-size: 150%;
	margin-right: 10px;
`;
const Overpayment = styled.span`
	margin-left: 10px;
`;
const Warning = styled.span`
	color: white;
	background-color: ${color("attention")};
	padding: 5px 8px;
	border-radius: 2px;
	margin-left: 10px;
`;
const AdvancePaymentAmount = styled.div``;
const ListItem = styled.div`
	margin: 20px 20px;
`;
const List = styled.div`
	margin-bottom: 50px;
`;
const AdvancePaymentAmountTotal = styled.span`
	color: ${color("valid", "dense")};
	margin-left: 10px;
`;
const Identifier = styled.div`
	width: 100px;
	font-size: 50%;
	color: ${color("black", "light")};
`;
const Business = styled.div`
	width: 300px;
	${ellipsis("300px")};
`;
const Shifts = styled.div`
	width: 200px;
`;
const HourlyRate = styled.div`
	width: 100px;
`;
const TotalHours = styled.div`
	width: 50px;
`;
const LoadingContainer = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const addPayment = contractId => () =>
	Request.mutation(
		/* GraphQL */ `
			mutation($contractId: ID!) {
				addPayment(contractId: $contractId) {
					id
				}
			}
		`,
		{
			contractId,
		},
	);

const removePayment = contractId => () =>
	Request.mutation(
		/* GraphQL */ `
			mutation($contractId: ID!) {
				removePayment(contractId: $contractId) {
					id
				}
			}
		`,
		{
			contractId,
		},
	);

const sendPayments = () =>
	Request.mutation(/* GraphQL */ `
		mutation {
			sendPayments {
				id
			}
		}
	`);
