import React, { useState } from "react";
import styled from "styled-components";
import Button from "components/Button";
import { color } from "common/styles";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";

const contactFields = {
	firstName: { label: "Prénom" },
	lastName: { label: "Nom" },
	phoneNumber: { label: "Téléphone" },
	email: { label: "Email" },
	position: { label: "Fonction" },
};

export default ({ value, updateValue }) => {
	const [open, setOpen] = useState(false);
	const updateContact = (index, field, fieldValue) => {
		const contacts = value.map(contact => ({ ...contact }));
		contacts[index][field] = fieldValue;
		updateValue(contacts);
	};
	const deleteOne = index => {
		const contacts = value.map(contact => ({ ...contact }));
		contacts.splice(index, 1);
		updateValue(contacts);
	};
	const addOne = () => {
		const contacts = value.map(contact => ({ ...contact }));
		contacts.push({});
		updateValue(contacts);
	};

	return (
		<div>
			<Button onClick={() => setOpen(true)}>Contacts</Button>
			<StyledModal open={open} onClose={() => setOpen(false)}>
				<Container>
					{value.map((contact, index) => (
						<SubContainer key={index}>
							<div>
								{Object.keys(contactFields).map((field, key) => (
									<TextField
										key={key}
										fullWidth
										label={contactFields[field].label}
										onChange={event =>
											updateContact(index, field, event.target.value)
										}
										value={contact[field] || ""}
										margin="normal"
									/>
								))}
							</div>
							<DeleteButton
								onClick={() => {
									deleteOne(index);
								}}
							>
								-
							</DeleteButton>
						</SubContainer>
					))}
					<Button onClick={() => addOne()}>+</Button>
				</Container>
			</StyledModal>
		</div>
	);
};

const SubContainer = styled.div`
	border: 1px solid ${color("black", "pale")};
	padding: 10px;
	display: flex;
	flex-direction: column;
	margin: 5px 0;
	border-radius: 5px;
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	background-color: ${color("white")};
`;
const Container = styled.div`
	max-height: 100%;
	overflow: Auto;
	width: 50%;
`;

const DeleteButton = styled(Button)`
	justify-content: right;
`;

const StyledModal = styled(Modal)`
	overflow: Auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;
