import React, { useState } from "react";
import AsyncSearch from "components/AsyncSearch";
import adminWorkers from "components/AsyncSearch/adminWorkers";
import adminBusinesses from "components/AsyncSearch/adminBusinesses";
import SelectJob from "components/SelectJob";
import DateField from "components/DateField";
import reasons from "common/reasons.json";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useMutation } from "@apollo/react-hooks";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "components/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import gql from "graphql-tag";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import PickTime from "components/Fields/PickTime";
import moment from "moment";

const CreateContract = gql`
	mutation($contract: AdminCreateContractInput!, $tracked: Boolean) {
		adminCreateContract(contract: $contract, tracked: $tracked) {
			id
		}
	}
`;

export default ({ dismiss }) => {
	const [contract, setContract] = useState({
		reason: reasons.habit,
		startAt: moment()
			.startOf("day")
			.hour(8)
			.toISOString(),
		endAt: moment()
			.startOf("day")
			.hour(17)
			.toISOString(),
	});
	const [tracked, setTracked] = useState(false);
	const [replacement, setReplacement] = useState({});
	const [saveContract] = useMutation(CreateContract);

	const rateTooLow = contract.rate !== undefined && contract.rate < 951;

	const isValid = () =>
		contract.businessId &&
		contract.workerId &&
		contract.rate &&
		contract.startAt &&
		contract.endAt &&
		contract.job &&
		((contract.reason === reasons.habit && contract.justification) ||
			(contract.reason === reasons.replacement &&
				replacement.firstName &&
				replacement.lastName &&
				replacement.absenceReason));

	return (
		<div class="grid">
			<article class="card four from-five col">
				<h1>Nouveau contrat</h1>

				<section>
					<AsyncSearch
						onSelection={workerId => {
							setContract({ ...contract, workerId });
						}}
						fetchOptions={adminWorkers}
						placeHolder={"Worker"}
					/>
				</section>
				<section>
					<AsyncSearch
						onSelection={businessId => {
							setContract({ ...contract, businessId });
						}}
						fetchOptions={adminBusinesses}
						placeHolder={"Business"}
					/>
				</section>
				<section>
					<SelectJob
						onChange={job => setContract({ ...contract, job })}
						job={contract.job}
						businessId={contract.businessId}
					/>
				</section>
				<FormControl>
					<div class="grid align-bottom">
						<div class="four">
							<TextField
								onBlur={e => {
									setContract({
										...contract,
										rate: e.target.value * 100,
									});
									e.target.value = parseFloat(e.target.value).toFixed(2);
								}}
								fullWidth={true}
								label="Taux horaire net"
								type="number"
								error={rateTooLow}
							/>
							{rateTooLow && (
								<FormHelperText error={true}>
									Le taux horaire minimal est 9,51€
								</FormHelperText>
							)}
						</div>
						<div class="one">
							<strong>€</strong>
						</div>
					</div>
				</FormControl>
				<section>
					<strong>Date de début</strong>
					<DateField
						date={contract.startAt}
						onDateChange={startAt => setContract({ ...contract, startAt })}
					/>
				</section>
				<section>
					<strong>Date de fin</strong>
					<DateField
						date={contract.endAt}
						onDateChange={endAt => setContract({ ...contract, endAt })}
					/>
				</section>
				<h4>Heures de référence</h4>
				<div>
					<TextField
						onBlur={e => {
							setContract({
								...contract,
								breakMinutes: e.target.value,
							});
						}}
						fullWidth={true}
						label="Temps de pause (en minutes)"
						type="number"
					/>
				</div>
				<section>
					<PickTime
						label="Heure de début"
						value={contract.startAt}
						onChange={startAt => setContract({ ...contract, startAt })}
					/>
				</section>
				<section>
					<PickTime
						label="Heure de fin"
						value={contract.endAt}
						onChange={endAt => setContract({ ...contract, endAt })}
					/>
				</section>
				<FormControl fullWidth={true}>
					<InputLabel id="contract-reason-label">
						Motif de la mission
					</InputLabel>
					<Select
						labelId="contract-reason-label"
						value={contract.reason}
						onChange={e => {
							setContract({ ...contract, reason: e.target.value });
						}}
						fullWidth={true}
					>
						<MenuItem value={reasons.habit} key={"habit"}>
							{reasons.habit}
						</MenuItem>
						<MenuItem value={reasons.replacement} key={"replacement"}>
							{reasons.replacement}
						</MenuItem>
					</Select>
				</FormControl>

				{contract.reason === reasons.habit && (
					<section>
						<TextField
							multiline={true}
							fullWidth={true}
							rows={1}
							onChange={e =>
								setContract({ ...contract, justification: e.target.value })
							}
							placeholder="Justificatif (Lié à une variation cyclique d'activité ...)"
						/>
					</section>
				)}
				{contract.reason === reasons.replacement && (
					<section>
						<h4>Remplacement de</h4>
						<section>
							<TextField
								fullWidth={true}
								onChange={e =>
									setReplacement({ ...replacement, firstName: e.target.value })
								}
								placeholder="Prénom"
							/>
						</section>
						<section>
							<TextField
								fullWidth={true}
								onChange={e =>
									setReplacement({ ...replacement, lastName: e.target.value })
								}
								placeholder="Nom"
							/>
						</section>
						<section>
							<TextField
								fullWidth={true}
								onChange={e =>
									setReplacement({
										...replacement,
										absenceReason: e.target.value,
									})
								}
								placeholder="Motif de l'absence"
							/>
						</section>
					</section>
				)}
				<FormControlLabel
					control={<Checkbox onChange={e => setTracked(e.target.checked)} />}
					label="Pointer les horaires"
				/>
				<section class="row">
					<Button secondary className="outline right" onClick={dismiss}>
						Annuler
					</Button>
					<Button
						disabled={!isValid()}
						onClick={async () => {
							await saveContract({
								variables: {
									contract: {
										jobKey: contract.job.key,
										workerId: contract.workerId,
										businessId: contract.businessId,
										rate: contract.rate,
										startAt: contract.startAt,
										endAt: contract.endAt,
										shifts: [],
										btp: true,
										reason: contract.reason,
										breakMinutes: parseInt(contract.breakMinutes),
										justification:
											contract.reason === reasons.replacement
												? `REMPLACEMENT POUR PARTIE DES TACHES DE : ${
														replacement.firstName
												  } ${replacement.lastName}, POUR : ${
														replacement.absenceReason
												  }, QUALIFICATION: ${contract.job.name}`
												: contract.justification,
									},
									tracked,
								},
							});
							dismiss();
						}}
					>
						Enregistrer
					</Button>
				</section>
			</article>
		</div>
	);
};
