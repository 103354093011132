import React from "react";
import Select from "./Select";

const departments = ["75", "77", "78", "91", "92", "93", "94", "95"];

export default ({ value, updateValue }) => (
	<Select
		label="Départements à inclure dans la recherche de workers"
		multiple
		value={value}
		updateValue={updateValue}
		options={departments}
	/>
);
