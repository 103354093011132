import React from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import compare from "utils/compare";
import useJobs from "common/useJobs";
import { useBusinessJobs } from "common/admin/businessHooks";
import { PillsList, Pill } from "components/Pills";

export default ({ job, onChange, jobs, businessId }) => {
	const allJobs = useJobs();
	const { jobs: businessJobs } = useBusinessJobs(businessId);

	return (
		<FormControl fullWidth={true}>
			<InputLabel id="select-job-label">
				{jobs ? "Métiers" : "Métier"}
			</InputLabel>
			<Select
				labelId="select-job-label"
				value={job ? job.key : ""}
				onChange={e => {
					const job = (businessJobs || allJobs).find(
						j => j.key === e.target.value,
					);
					onChange(jobs ? [...jobs, job] : job);
				}}
				fullWidth={true}
			>
				{(businessJobs || allJobs)
					.filter(j => !jobs || !jobs.some(jj => jj.key === j.key))
					.sort(
						compare(j => j.name, {
							ascending: true,
						}),
					)
					.map(j => (
						<MenuItem value={j.key} key={j.key}>
							{j.name}
						</MenuItem>
					))}
			</Select>
			{jobs && jobs.length ? (
				<PillsList>
					{jobs.map(j => (
						<Pill
							onDelete={() => onChange(jobs.filter(job => job.key !== j.key))}
						>
							{j.name}
						</Pill>
					))}
				</PillsList>
			) : null}
		</FormControl>
	);
};
