import React from "react";
import { Card } from "components/Card";
import { SideNav } from "components/Navs";

export default () => (
	<div class="grid">
		<div class="two">
			<SideNav>
				<button className="selected">Lien actif</button>
				<button>Lien inactif</button>
			</SideNav>
		</div>
		<div className="ten scrollable">
			<section>
				<Card>
					<article>
						<h1>Titre 1</h1>
						<h2>Titre 2</h2>
						<h3>Titre 3</h3>
						<h4>Titre 4</h4>

						<p>
							Texte classique avec du <strong>gras</strong> et de l'
							<em>italique</em> Et un <a href="https://gofer.fr">lien</a>.
						</p>
					</article>
				</Card>
			</section>
			<section>
				<Card>
					<article>
						Section représente un bloc de contenu dynamique. Article représente
						un bloc de texte. La classe "scrollable" permet de rendre un contenu
						scrollable.
					</article>
				</Card>
			</section>

			<section>
				<Card>
					<article>
						<p>
							Système de grille : un parent avec la classe grid, douze colonnes
							réparties sur des classes nommées de "one" à "twelve".
						</p>
					</article>
				</Card>
			</section>
		</div>
	</div>
);
