import React from "react";
import reasons from "common/reasons.json";
import { Field } from "redux-form";
import { SelectField, TextField } from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import styled from "styled-components";
import MobileTextField from "components/MobileTextField";
import { FormSection } from "redux-form";
import { isMobile } from "common/styles";

export default ({ reason }) => (
	<React.Fragment>
		<div className="query-reasons-field">
			<Field
				fullWidth
				name="reason"
				component={SelectField}
				floatingLabelText="Motif de la mission"
			>
				{Object.entries(reasons).map(([id, reason]) => (
					<MenuItem key={id} value={reason} primaryText={reason} />
				))}
			</Field>
		</div>

		<Justification reason={reason} />
	</React.Fragment>
);

const Justification = ({ reason }) => {
	if (reason === reasons.replacement) {
		return (
			<FormSection name="replacementJustification">
				<Container>
					<Field
						name="firstName"
						fullWidth={isMobile()}
						component={isMobile() ? MobileTextField : TextField}
						hintText="Prénom"
					/>
					<Field
						name="lastName"
						fullWidth={isMobile()}
						component={isMobile() ? MobileTextField : TextField}
						hintText="Nom"
					/>
					<Field
						name="absenceReason"
						fullWidth={isMobile()}
						component={isMobile() ? MobileTextField : TextField}
						hintText="Raison de l'absence"
					/>
				</Container>
			</FormSection>
		);
	}
	return (
		<Field
			name="justification"
			component={TextField}
			multiLine
			hintText={"Justificatif (Lié à une variation cyclique d'activité ...)"}
			fullWidth
		/>
	);
};

const Container = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;
	flex-direction: ${isMobile() ? "column" : "row"};
	& * {
		flex: 1;
	}
`;
