import React from "react";
import { reduxForm } from "redux-form";
import Button from "components/Button";
import MaxWorkers from "components/MaxWorkers";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const createReduxForm = reduxForm({
	form: "changeMaxWorkers",
});

export default createReduxForm(({ handleSubmit }) => (
	<form onSubmit={handleSubmit}>
		<Container>
			<MaxWorkers />
			<Button submit>Changer le nombre de postes</Button>
		</Container>
	</form>
));
