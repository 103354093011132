import React, { useState } from "react";
import { addModal } from "architecture/Modals";
import { useTrackings } from "./Hooks/useAdminContracts";
import Table from "components/Table";
import moment from "moment";
import Loading from "components/Loading";
import WorkerProfile from "modals/WorkerProfile";
import { SideNav } from "components/Navs";
import { FaCheckCircle, FaClock } from "react-icons/fa";
import { color } from "common/styles";

export default () => {
	const { trackings, trackingsToValidate, loading } = useTrackings();
	const [folder, setFolder] = useState("trackings");

	const cols = [
		{
			label: "Id",
			getter: ({ contractId }) => contractId,
		},
		{
			label: "Worker",
			sortGetter: ({ getWorker: { name } }) => name,
			getter: ({ getJob, getWorker: { name, id }, workerTrackedAt }) => (
				<div class="row vertical-centered">
					<section>
						{workerTrackedAt ? (
							<FaCheckCircle size={20} color={color("accent")} />
						) : (
							<FaClock size={20} color={color("black", "normal", 0.2)} />
						)}
					</section>
					<button
						class="link"
						onClick={() =>
							addModal(() => <WorkerProfile workerId={id} showContact={true} />)
						}
					>
						{name}
						<br />
						{getJob.name}
					</button>
				</div>
			),
		},
		{
			label: "Business",
			sortGetter: ({ getBusiness: { name } }) => name,
			getter: ({ startTimeTracked, getBusiness: { name } }) => (
				<div class="row">
					<section>
						{startTimeTracked ? (
							<FaCheckCircle size={20} color={color("accent")} />
						) : (
							<FaClock size={20} color={color("black", "normal", 0.2)} />
						)}
					</section>
					{name}
				</div>
			),
		},
		{
			label: "Dates",
			sortGetter: ({ startOfWeek }) => moment(startOfWeek),
			getter: ({ startOfWeek, endOfWeek }) => (
				<div>
					{moment(startOfWeek).format("dddd DD/MM")}
					<br />
					{moment(endOfWeek).format("dddd DD/MM")}
				</div>
			),
		},
	];

	if (loading) {
		return <Loading />;
	}

	return (
		<div class="cover row">
			<SideNav>
				<button
					onClick={() => setFolder("trackings")}
					class={folder === "trackings" ? "selected" : ""}
				>
					Pointages en attente
				</button>
				<button
					onClick={() => setFolder("trackingsToValidate")}
					class={folder === "trackingsToValidate" ? "selected" : ""}
				>
					Pointages faits
				</button>
			</SideNav>
			<div class="col expand scrollable">
				<article>
					<Table
						className="section"
						getId={shift => shift.shiftId}
						rows={folder === "trackings" ? trackings : trackingsToValidate}
						columns={cols}
					/>
				</article>
			</div>
		</div>
	);
};
