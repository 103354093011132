import React, { useState, useEffect } from "react";
import client from "common/apolloClient";
import Request from "common/Request";
import styled from "styled-components";
import Loading from "components/Loading";

const ScrollDiv = styled.div`
	height: 100%;
	overflow-y: scroll;
	box-sizing: border-box;
	width: 100%;
	padding: 3px;
`;

const increment = 40;
const scrollPadding = 100;

export default ({ query, variables, extract, children }) => {
	const [loaded, setLoaded] = useState([]);
	const [loading, setLoading] = useState(false);
	const [scrollingDone, setScrollingDone] = useState(false);
	const [index, setIndex] = useState(0);
	const [queryVars, setQueryVars] = useState(variables);

	const load = async () => {
		if (scrollingDone) {
			return;
		}

		setLoading(true);

		const response = await client.query({
			query,
			variables: { ...variables, skip: index, limit: increment },
			fetchPolicy: "no-cache",
		});

		const newElements = extract(response.data);

		if (newElements.length === 0) {
			setScrollingDone(true);
		}

		setLoading(false);
		setLoaded([...loaded, ...newElements]);
	};

	const handleScroll = e => {
		if (
			e.currentTarget.clientHeight + e.currentTarget.scrollTop <
				e.currentTarget.scrollHeight - scrollPadding ||
			loading
		) {
			return;
		}

		setIndex(index + increment);
		load();
	};

	useEffect(() => {
		load();
		setIndex(index + increment);
	}, [queryVars]);

	Request.onRefresh(() => {
		setLoaded([]);
		setIndex(0);
		setQueryVars({ ...variables });
	});

	useEffect(() => {
		if (JSON.stringify(queryVars) === JSON.stringify(variables)) {
			return;
		}
		setLoaded([]);
		setIndex(0);
		setScrollingDone(false);
		setQueryVars(variables);
	}, [variables]);

	return (
		<ScrollDiv onScroll={handleScroll}>
			{children(loaded)}

			{loading && <Loading />}
		</ScrollDiv>
	);
};
