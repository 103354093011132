import React from "react";
import graphql from "common/graphql";
import gql from "graphql-tag";
import ContractsToPayTable from "scenes/Home/Admin/ContractsToPayTable";

export default graphql(
	gql`
		query getAdminContractsToPay {
			admin {
				contractsToPay {
					id
					getWorker {
						locked
						id
						name
						overpayment
						documents {
							bank {
								validatedAt
							}
						}
					}
					getBusiness {
						id
						name
					}
					trackedHours
					shifts {
						startTime
					}
					rate
					advancePaymentAmount
					payment {
						id
					}
				}
			}
		}
	`,
)(({ admin: { contractsToPay } }) => (
	<ContractsToPayTable contracts={contractsToPay} />
));
