import MenuItem from "material-ui/MenuItem";
import React, { useCallback, useMemo, useState } from "react";
import { change, Field, updateSyncWarnings } from "redux-form";
import { SelectField, TextField } from "redux-form-material-ui";
import styled from "styled-components";

import Request from "common/Request";
import { dispatch } from "common/store";
import Button from "components/Button";
import Loading from "components/Loading";

const ButtonsRow = styled.div`
	display: flex;
	padding-top: 20px;
`;

export const DossierField = ({ businessId, dossierList = [], newQuery }) => {
	const dossier = useMemo(() => newQuery.dossier || "", [newQuery]);
	const [isNewDossier, setIsNewDossier] = useState(false);

	const onToggleIsNewDossier = useCallback(() => {
		dispatch(change("NewQuery", "dossier", ""));
		setIsNewDossier(!isNewDossier);
	}, [isNewDossier]);

	const [isNewDosierSaving, setIsNewDosierSaving] = useState(false);

	const onCreateNewDossier = useCallback(async () => {
		try {
			setIsNewDosierSaving(true);
			dispatch(updateSyncWarnings("NewQuery", { dossier: "" }));
			await Request.mutation(
				/* GraphQL */ `
					mutation($businessId: ID!, $dossier: String!) {
						saveDossier(businessId: $businessId, dossier: $dossier) {
							id
							dossier
						}
					}
				`,
				{
					businessId,
					dossier,
				},
			);

			setIsNewDossier(false);
		} catch (err) {
			console.log(err);
			dispatch(updateSyncWarnings("NewQuery", { dossier: err.message }));
		} finally {
			setIsNewDosierSaving(false);
		}
	}, [businessId, dossier]);

	return isNewDossier ? (
		<>
			<Field
				component={TextField}
				floatingLabelText="Dossier"
				fullWidth
				name="dossier"
				inputStyle={{ paddingRight: 42 }}
			/>
			{isNewDosierSaving ? (
				<Loading />
			) : (
				<ButtonsRow>
					<Button className="outline" onClick={onToggleIsNewDossier} secondary>
						Annuler
					</Button>
					<Button
						disabled={dossier.trim().length === 0}
						onClick={onCreateNewDossier}
					>
						Confirmer
					</Button>
				</ButtonsRow>
			)}
		</>
	) : (
		<Field
			component={SelectField}
			floatingLabelText="Dossier"
			fullWidth
			name="dossier"
			style={{ marginTop: -14 }}
		>
			{dossierList.map(dossier => (
				<MenuItem key={dossier} value={dossier} primaryText={dossier} />
			))}
			<div
				key="createDossierButton"
				style={{
					padding: "0 20px 0",
					paddingTop: dossierList.length > 0 ? 10 : 0,
				}}
			>
				<Button className="outline" onClick={onToggleIsNewDossier} secondary>
					Créer un dossier
				</Button>
			</div>
		</Field>
	);
};
