import React, { Component, useEffect } from "react";
import styled from "styled-components";
import { position } from "polished";
import Button from "components/Button";
import { color, acceleration, desceleration, isMobile } from "common/styles";

const modals = [];
let afterUpdate = () => {};

export const hasModal = () => modals.length > 0;

export function addModal(modal, options) {
	modals.push({ component: modal, options });
	afterUpdate();
}

const dismissers = [];

export function dismissFrontModal() {
	const dismiss = dismissers.pop();
	dismiss();
}

export function dismissBackModal() {
	modals.shift();
	afterUpdate();
}

function dismissFrontModalImmediately() {
	modals.pop();
	afterUpdate();
}

export default class Modals extends Component {
	componentDidMount() {
		afterUpdate = this.afterUpdate;
	}

	componentWillUpdate() {
		afterUpdate = this.afterUpdate;
	}

	afterUpdate = () => {
		this.forceUpdate();
	};

	renderModal = (modal, id, isOnTop) => (
		<Modal
			key={id}
			component={modal.component}
			options={modal.options}
			index={id}
			isOnTop={isOnTop}
		/>
	);

	render() {
		const isBtp = window.location.href.indexOf("/btp") !== -1;

		if (modals.length) {
			return (
				<Frame className={`modals ${isBtp ? "btp" : ""}`}>
					{modals.map((modal, id) =>
						this.renderModal(modal, id, id === modals.length - 1),
					)}
				</Frame>
			);
		}
		return null;
	}
}

class Modal extends Component {
	state = {
		mounted: false,
	};

	componentDidMount() {
		dismissers.push(this.dismiss);
		setTimeout(() => this.setState({ mounted: true }), 10);
	}

	dismiss = () => {
		const { options } = this.props;

		setTimeout(dismissFrontModalImmediately, 400);
		this.setState({ mounted: false });

		if (options && options.onDismiss) {
			setTimeout(() => options.onDismiss(), 390);
		}
	};

	backButton = () => {
		const { options } = this.props;

		dismissFrontModal();

		if (options && options.onDismiss) {
			options.onDismiss();
		}
	};

	render() {
		const { mounted } = this.state;
		const { component: Content, index, isOnTop } = this.props;
		return (
			<Container mounted={mounted} index={index} isOnTop={isOnTop}>
				<Header>
					<Button onClick={this.backButton} back>
						{isMobile() ? "" : "Retour"}
					</Button>
				</Header>
				<div>
					<Content dismiss={this.dismiss} dismissBackModal={dismissBackModal} />
				</div>
			</Container>
		);
	}
}

const margin = isMobile() ? 20 : 30;

const Frame = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100%;
	z-index: 2;
	@print {
		display: block;
	}
`;
const Container = styled.div`
  ${position("absolute", "0", "0", "0", "0")} background-color: ${color(
	"appBackground",
)};
  overflow: scroll;
  transition: all 400ms
    ${({ mounted }) => (mounted ? desceleration : acceleration)};
  opacity: ${({ mounted }) => (mounted ? "1" : "0")};
  box-shadow: -1px -1px 3px ${color("black", "normal", 0.25)};
  top: ${({ mounted }) => (mounted ? "0" : "100%")};
  height: 100%;
  z-index: ${({ index }) => index + 1};

  & > * {
    margin: 0 ${margin}px ${margin}px ${margin}px;
    z-index: 1;
  }

  @media print {
    overflow: visible;
    display: ${props => (props.isOnTop ? "block" : "none")};
    & > * {
      margin: 0;
    }
  }
`;
const Header = styled.div`
	margin-top: ${margin}px;
	position: relative;
	z-index: 2;
	pointer-events: none;
	> * {
		pointer-events: all;
	}
	@media print {
		display: none;
	}
`;

const openedModals = {};

export const useModalOnPath = ({ path, modal, history, modals }) => {
	useEffect(() => {
		const id = window.location.pathname.split(path)[1];

		if (!id || id.indexOf("/") !== -1) {
			return;
		}

		if (id !== "home" && openedModals[path] !== id) {
			openedModals[path] = id;

			addModal(modals ? modals[id] : modal(id), {
				onDismiss: () => {
					openedModals[path] = undefined;
					history.push(path);
				},
			});
		}
	});

	const openModal = id => {
		history.push(path + id);
	};

	return { openModal };
};
