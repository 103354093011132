/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { confirm } from "components/Lightbox";
import styled from "styled-components";
import Button from "components/Button";

const AddAnswerContainer = styled.form`
	display: flex;
	margin: auto;
	width: 400px;
	margin-top: 20px;
	margin-bottom: 20px;
	button {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		margin-left: -1px;
	}
	fieldset {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	input {
		padding-top: 7px !important;
		padding-bottom: 7px !important;
	}
`;

const AnswerLine = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 15px;
	padding-bottom: 15px;
	margin: auto;
	padding-left: 15px;
	padding-right: 15px;
	&:nth-child(2n) {
		background: #eee;
	}
`;

export default ({ surveyInput, setSurveyInput, survey }) => {
	const [typedAnswer, setTypedAnswer] = useState("");

	return (
		<React.Fragment>
			<section>
				<strong>Réponses</strong>
				{surveyInput.predefinedAnswers.map(a => (
					<AnswerLine>
						<p>{a}</p>
						<a
							onClick={async () => {
								if (survey.id) {
									const answer = await confirm(
										"Voulez-vous vraiment supprimer cette réponse ? Tous les utilisateurs ayant donné cette réponse verront à nouveau le sondage.",
									);
									if (!answer) {
										return;
									}
								}

								setSurveyInput({
									...surveyInput,
									predefinedAnswers: surveyInput.predefinedAnswers.filter(
										pa => pa !== a,
									),
								});
							}}
						>
							Supprimer
						</a>
					</AnswerLine>
				))}
			</section>
			<AddAnswerContainer
				onSubmit={e => {
					e.preventDefault();
					setSurveyInput({
						...surveyInput,
						predefinedAnswers: [...surveyInput.predefinedAnswers, typedAnswer],
					});
					setTypedAnswer("");
				}}
			>
				<OutlinedInput
					id="survey-answer"
					labelWidth={0}
					value={typedAnswer}
					placeholder="Réponse"
					onChange={e => setTypedAnswer(e.target.value)}
				/>
				<Button
					secondary
					className="outline"
					onClick={() => {
						setSurveyInput({
							...surveyInput,
							predefinedAnswers: [
								...surveyInput.predefinedAnswers,
								typedAnswer,
							],
						});
						setTypedAnswer("");
					}}
				>
					Ajouter
				</Button>
			</AddAnswerContainer>
		</React.Fragment>
	);
};
