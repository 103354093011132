const removeSpaces = input => input.replace(/\s/g, "");
const replaceSeparator = input => input.replace(/,/, ".");
const removeSymbol = input => input.replace(/€/, "");

const compose = (...functions) => input => {
	if (functions.length) {
		const [fun, ...otherFunctions] = functions;
		return compose(...otherFunctions)(fun(input));
	}
	return input;
};

export default currency => {
	if (!currency) {
		return null;
	}
	const hasWeirdCharacters = !/^[0-9.,€ ]*$/.test(currency);
	const hasMultipleSeparators =
		currency.split("").filter(character => [".", ","].includes(character))
			.length > 1;
	if (hasWeirdCharacters || hasMultipleSeparators) {
		return null;
	}

	return compose(
		removeSymbol,
		removeSpaces,
		replaceSeparator,
		parseFloat,
	)(currency);
};
