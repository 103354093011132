import React from "react";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const LightTextField = styled(TextField)`
	filter: invert(100%);
	label {
		filter: invert(100%);
	}
`;

export const LightCheckbox = withStyles({
	root: {
		color: "rgba(255, 255, 255, 0.4)",
	},
})(props => <Checkbox {...props} className={props.classes.root} />);

export const LightRadio = withStyles({
	root: {
		color: "rgba(255, 255, 255, 0.4)",
	},
})(props => <Radio {...props} className={props.classes.root} />);
