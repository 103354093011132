import React from "react";
import Bar from "components/Bar";
import debriefScores from "common/debriefScores";
import compare from "utils/compare";

export default ({
	debriefScoreCounts,
	businessDebriefs,
	medianDebriefScore,
}) => {
	const max = Math.max.apply(null, debriefScoreCounts);

	return businessDebriefs.length === 0 ? null : (
		<section class="col">
			{debriefScores.sort(compare(ds => ds.score)).map(({ score, Emotion }) => (
				<div key={score} class="grid centered">
					<div class="three">
						<div class="grid">
							<div
								class={
									score !== medianDebriefScore
										? "from-two ten greyed-out"
										: "twelve"
								}
							>
								<Emotion />
							</div>
						</div>
					</div>
					<div class="eight from-five">
						<Bar value={debriefScoreCounts[score]} max={max} />
					</div>
				</div>
			))}
		</section>
	);
};
