import LineBrokenText from "components/LineBrokenText";
import SelectDepartments from "./customFields/SelectDepartments";
import DefaultBreakMinutes from "./customFields/DefaultBreakMinutes";
import React from "react";
import Contacts from "./customFields/Contacts";

export default {
	label: "Informations opérationnelles",
	fields: () => ({
		contacts: {
			custom: Contacts,
			optional: true,
		},
		textTips: {
			optional: true,
			custom: () => (
				<LineBrokenText>
					{`Consigne pour les workers lors de leur arrivée:
            - A qui et où se présenter ?
            - Quel est l’accès ? (métro, entrée des artistes, parking pour voiture ou motos, prix du parking)`}
				</LineBrokenText>
			),
		},
		brief: { label: "Briefing général", multiline: true },
		wantSpecifyLocation: {
			label: "Le Business veut pouvoir modifier le lieu de la mission",
			type: "checkBox",
		},
		wantSpecifyReason: {
			label:
				"Le Business veut pouvoir renseigner le motif de recours à l’interim ",
			type: "checkBox",
		},
		managedByGofer: {
			label: "Managed by Gofer",
			type: "checkBox",
		},
		config: {
			locations: {
				custom: SelectDepartments,
				optional: true,
			},
		},
		defaultBreakMinutes: {
			label: "defaultBreakMinutes",
			custom: DefaultBreakMinutes,
		},
		autoValidateTracking: {
			label: "Validation automatique des pointages après 72h",
			type: "checkBox",
		},
	}),
};
