import React from "react";
import Lightbox from "components/Lightbox";
import AsyncSearch from "components/AsyncSearch";
import adminWorkers from "components/AsyncSearch/adminWorkers";
import Button from "components/Button";

export default ({
	visible,
	onDismiss,
	targetedUserIds,
	blacklistedUserIds,
	onChange,
}) => {
	return (
		<Lightbox visible={visible} onDismiss={onDismiss}>
			<article class="col">
				<section>
					<h3>Sondage uniquement pour...</h3>
					<div>
						<AsyncSearch
							onSelection={value => {
								onChange({ targetedUserIds: value ? value : [] });
							}}
							fetchOptions={adminWorkers}
							placeHolder={"Utilisateurs qui recevront le sondage"}
							isMulti={true}
							defaultValue={targetedUserIds}
						/>
					</div>
				</section>
				<section>
					<h3>Sondage pour tous sauf...</h3>
					<div>
						<AsyncSearch
							onSelection={value => {
								onChange({ blacklistedUserIds: value ? value : [] });
							}}
							fetchOptions={adminWorkers}
							placeHolder={"Utilisateurs exclus du sondage"}
							isMulti={true}
							defaultValue={blacklistedUserIds}
						/>
					</div>
				</section>
				<section class="row">
					<Button className="right" onClick={onDismiss}>
						Fermer
					</Button>
				</section>
			</article>
		</Lightbox>
	);
};
