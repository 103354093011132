import styled from "styled-components";
import { color } from "common/styles";
import { GoQuote } from "react-icons/go";

export const LogoBadge = styled.div`
	background: url(/logo.png) ${color("primary")} no-repeat center;
	padding-bottom: 100%;
	width: 100%;
	border-radius: 50%;
	background-size: contain;
	-webkit-print-colors-adjust: exact;
	color-adjust: exact;
`;

export const QuoteIcon = styled(GoQuote)`
	font-size: 40px;
	display: block;
	margin: auto;
`;
