import { useState, useEffect } from "react";
import { confirm } from "components/Lightbox";
import { notify } from "components/Loading";
import useCurrentUser from "common/useCurrentUser";

const adminRequestSlack =
	"https://hooks.slack.com/services/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";

export const useRequestAdminReview = worker => {
	const currentUser = useCurrentUser();
	const [hasRequestedReview, setHasRequestedReview] = useState(true);

	useEffect(() => {
		const storedRequest = localStorage.getItem(`ReviewRequest-${worker.id}`);
		setHasRequestedReview(!!storedRequest);
	}, []);

	const requestReview = async () => {
		const answer = await confirm(
			"Voulez-vous envoyer une demande à Gofer pour ajouter un avis sur ce worker ?",
		);

		if (!answer) {
			return;
		}

		const requestReview = {
			title: `${
				currentUser.business ? currentUser.business.name : currentUser.name
			} souhaite un avis sur ${worker.name}`,
			attachments: [
				{
					fields: [
						{
							title: `${
								currentUser.business
									? currentUser.business.name
									: currentUser.name
							} souhaite un avis sur ${worker.name}`,
							value: `https://web.gofer.fr/workers-to-review/${worker.id}`,
							short: false,
						},
					],
				},
			],
		};

		try {
			console.log(requestReview);
			await fetch(adminRequestSlack, {
				method: "POST",
				body: JSON.stringify(requestReview),
			});

			setHasRequestedReview(true);
			localStorage.setItem(`ReviewRequest-${worker.id}`, true);
			notify("La demande a été envoyée");
		} catch (e) {
			console.log(e);
			notify("La demande échoué. Merci de réessayer.", "error");
		}
	};

	return {
		requestReview,
		hasRequestedReview,
	};
};
