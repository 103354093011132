import styled from "styled-components";
import { color } from "common/styles";

export const marginTop = 20;

export const Document = styled.div`
	margin-top: ${marginTop}px;
	flex: 12;

	& input {
		padding: 10px 0;
		margin-top: ${marginTop}px;
	}

	& p {
		color: ${color("black", "bright")};
	}
`;
export const DocumentTitle = styled.h2`
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
	color: ${color("black", "light")};
`;
