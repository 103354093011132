export const isSet = value =>
	Boolean(
		value !== undefined &&
			value !== null &&
			value !== "" &&
			!Number.isNaN(value),
	);

const isCompleted = (data, fields) => {
	const complete = Object.entries(fields).map(([field, description]) => {
		if (description.optional === true) {
			return true;
		}
		if (description.checkField) {
			return description.checkField(data);
		}
		if (description.label || description.custom) {
			return isSet(data[field]);
		} else if (Array.isArray(description)) {
			if ((data[field] || []).length === 0) {
				return false;
			}

			for (const value of data[field]) {
				if (!isCompleted(value, description[0])) {
					return false;
				}
			}
		}
		if (data[field]) {
			return isCompleted(data[field], description);
		}
		return false;
	});
	return !complete.includes(false);
};

export default isCompleted;
