import "index.css";
import "./log";
import "common/moment";
import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import styled from "styled-components";
import { position } from "polished";
import { IconContext } from "react-icons";
import Home from "scenes/Home";
import WorkerDocuments from "scenes/Worker/Documents";
import PrivateRoute from "scenes/authentication/PrivateRoute";
import NoMatch from "scenes/NoMatch";
import store from "common/store";
import apolloClient from "common/apolloClient";
import Alerts from "architecture/Alerts";
import Modals from "architecture/Modals";
import MaterialThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { color } from "common/styles";
import { fade } from "material-ui/utils/colorManipulator";
import ReactGA from "react-ga";
import { gaTrackingId, auth0 } from "common/constants";
import { Confirm } from "components/Lightbox";
import DevGuide from "scenes/DevGuide";
import { Auth0Provider } from "common/useAuth0";
import { JobsProvider } from "common/useJobs";
import { CurrentUserProvider } from "common/useCurrentUser";
import history from "common/history";
import FrontChat from "components/FrontChat";

import "sass/index.scss";

// after login, we want to redirect the user to the initial route they were trying to access
const onRedirectCallback = appState => {
	history.push(
		appState && appState.targetUrl
			? appState.targetUrl
			: window.location.pathname,
	);
};

const theme = createMuiTheme({
	palette: {
		primary: {
			main: color("primary"),
		},
		secondary: {
			main: color("action"),
		},
	},
	typography: {
		useNextVariants: true,
	},
});

export class Provider extends React.Component {
	render() {
		const { children } = this.props;
		return (
			<MuiThemeProvider theme={theme}>
				<MaterialThemeProvider
					muiTheme={getMuiTheme({
						palette: {
							primary1Color: color("primary"),
							primary2Color: color("primary", "dense"),
							primary3Color: color("black", "pale"),
							accent1Color: color("action"),
							accent2Color: color("black", "translucid"),
							accent3Color: color("black", "bright"),
							textColor: color("black", "normal", 0.87),
							alternateTextColor: color("white"),
							canvasColor: color("white"),
							borderColor: color("black", "bright"),
							disabledColor: fade(color("black", "normal", 0.87), 0.3),
							pickerHeaderColor: color("primary"),
							clockCircleColor: fade(color("black", "normal", 0.87), 0.07),
							shadowColor: color("black", "normal", 1),
						},
					})}
				>
					<IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
						<Auth0Provider
							domain={auth0.domain}
							client_id={auth0.clientId}
							redirect_uri={window.location.origin}
							audience={auth0.audience}
							onRedirectCallback={onRedirectCallback}
						>
							<FrontChat />
							<ApolloHooksProvider client={apolloClient}>
								<ApolloProvider client={apolloClient}>
									<ReduxProvider store={store}>
										<CurrentUserProvider>
											<JobsProvider>
												<Router>
													<Container>
														<Modals />
														{children}
														<Alerts />
													</Container>
												</Router>
											</JobsProvider>
										</CurrentUserProvider>
									</ReduxProvider>
								</ApolloProvider>
							</ApolloHooksProvider>
						</Auth0Provider>
					</IconContext.Provider>
				</MaterialThemeProvider>
			</MuiThemeProvider>
		);
	}
}

class App extends Component {
	componentWillMount() {
		ReactGA.initialize(gaTrackingId);
	}

	render() {
		return (
			<Provider>
				<Switch>
					<Route path="/worker/documents/:token" component={WorkerDocuments} />
					<Route path="/dev-guide" component={DevGuide} />
					<PrivateRoute path="/" component={Home} />
					<Route component={NoMatch} />
				</Switch>
				<Confirm />
			</Provider>
		);
	}
}

const Container = styled.div`
	${position("fixed", "0", "0", "0", "0")};
	display: flex;
	align-items: stretch;

	@media print {
		position: absolute;
		display: block;

		.modals + .split-nav {
			display: none;
		}
	}
`;

export default App;
