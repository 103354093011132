import React, { useState } from "react";
import { FaChevronRight, FaCheck } from "react-icons/fa";

export default ({ label, options, selected, onChange }) => {
	const [opened, setOpened] = useState(false);

	return (
		<React.Fragment>
			<div class={`${opened ? "opened" : ""} drop-down`}>
				<div class="selector" onClick={() => setOpened(!opened)}>
					<FaChevronRight />
					{label(opened ? "..." : ` ${selected.label.toLowerCase()}`)}
				</div>
				<div class="options-container">
					<div class="options-wrapper">
						<div class="options">
							{options.map(opt => (
								<div
									class="option"
									onClick={() => {
										setOpened(false);
										onChange(opt);
									}}
								>
									{opt.label === selected.label ? <FaCheck /> : null}
									{opt.label}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div class="backdrop" onClick={() => setOpened(false)} />
		</React.Fragment>
	);
};
