import { createSelectorCreator, defaultMemoize } from "reselect";

export const tolerantEqual = (current, previous) => {
	if (current === previous) {
		return true;
	} else if (current && current.constructor === Array && previous) {
		return (
			current.length === previous.length &&
			current.reduce(
				(equal, value, index) => equal && tolerantEqual(value, previous[index]),
				true,
			)
		);
	} else {
		return false;
	}
};

export default createSelectorCreator(defaultMemoize, tolerantEqual);
