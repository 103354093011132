import React from "react";
import styled from "styled-components";
import { FormSection } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { Field } from "redux-form";
import { isMobile } from "common/styles";

export default class Location extends React.Component {
	render() {
		return (
			<FormSection name="location">
				<Container>
					<Field
						name="streetName"
						fullWidth={isMobile()}
						component={TextField}
						hintText="Adresse"
					/>
					<Field
						name="postCode"
						fullWidth={isMobile()}
						component={TextField}
						hintText="Code postal"
					/>
					<Field
						name="city"
						fullWidth={isMobile()}
						component={TextField}
						hintText="Ville"
					/>
				</Container>
			</FormSection>
		);
	}
}

const Container = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;
	flex-direction: ${isMobile() ? "column" : "row"};
	& * {
		flex: 1;
	}
`;
