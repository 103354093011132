import React, { useState } from "react";
import styled, { css } from "styled-components";
import { MdArrowBack, MdArrowForward, MdDone } from "react-icons/md";
import { color, disabledLink, link, ripple } from "common/styles";
import { mobileBreakpoint } from "common/styles";
import UnstyledLoading from "components/Loading";

const Loading = ({ darkBackground }) => (
	<UnstyledLoading color={darkBackground ? "white" : color("action")} />
);

export default ({
	className,
	darkBackground,
	submit,
	children,
	loading,
	disabled,
	done,
	onClick,
	icon: Icon,
	leftIcon,
	rightIcon,
	label,
	secondary,
	navigation,
	back,
	asynchronous,
}) => {
	const [isLoading, setLoading] = useState(false);

	const canClick = !disabled && !loading && !done && onClick && !isLoading;
	const props = {
		onClick: canClick
			? async event => {
					event.stopPropagation();
					setLoading(true);
					await onClick(event);
					setLoading(false);
			  }
			: undefined,
		disabled: disabled || (!onClick && !submit),
		darkBackground,
		done,
		secondary,
		navigation: navigation || back,
		submit,
	};
	if (!leftIcon && Icon) {
		leftIcon = <Icon />;
	}
	if (done) {
		leftIcon = <MdDone />;
	}
	if (back) {
		leftIcon = <MdArrowBack />;
	}
	if (navigation) {
		rightIcon = <MdArrowForward />;
	}
	return (
		<Container className={`button-container ${className}`} loading={loading}>
			{(asynchronous && isLoading) || loading ? (
				<Loading darkBackground={darkBackground} />
			) : submit ? (
				<Submit {...props}>{children}</Submit>
			) : (
				<Content {...props} type="button">
					{leftIcon ? <IconContainer>{leftIcon}</IconContainer> : null}
					{children}
					{label}
					{rightIcon ? <IconContainer right>{rightIcon}</IconContainer> : null}
				</Content>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: inline-block;
	${({ loading }) =>
		loading
			? css`
					position: relative;
					height: 40px;
					width: 40px;
			  `
			: ""};
	&.outline button {
		border: 1px solid ${color("action")};
		border-radius: 3px;
	}
	&.error button {
		background: ${color("important")};
	}
	&.delete button {
		background: ${color("important")};
		color: ${color("white")};
	}
	&.success button {
		background: ${color("valid")};
	}
	button {
		width: 100%;
		justify-content: center;
	}
	& + &,
	.button + & {
		margin-left: 12px;
	}
	&.twelve + &.twelve {
		margin-left: 0;
	}
`;
const buttonStyle = css`
	font-size: 0.875rem;
	padding: 8px 14px 7px 14px;
	border-radius: 3px;
	border: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	white-space: nowrap;
	text-align: center;
	text-transform: uppercase;
	font-family: Roboto;
	font-weight: 500;
	letter-spacing: 0.0892857143em;
	transition: all 250ms ease;
	position: relative;
	overflow: hidden;
	@media print {
		display: none;
	}

	${({ disabled, onClick, submit }) =>
		disabled ? disabledLink : onClick || submit ? link : ""}

	${({ disabled, darkBackground, secondary, done, navigation }) =>
		secondary
			? css`
					color: ${disabled
						? darkBackground
							? color("white", "normal", 0.5)
							: color("black", "pale")
						: color("action")};
					${disabled
						? ""
						: css`
								cursor: pointer;
								&:hover {
									opacity: 1;
									background-color: ${darkBackground
										? color("white", "normal", 0.25)
										: color("action", "normal", 0.15)};
								}
						  `};
			  `
			: navigation
			? css`
					color: ${color("black", "light")};
					&:hover {
						opacity: 1;
						background-color: ${color("black", "light", 0.25)};
					}
			  `
			: done
			? css`
					background-color: ${color("primary")};
					color: white;
					&:hover {
						background-color: ${color("primaryDark")};
					}
			  `
			: css`
					background-color: ${disabled
						? darkBackground
							? color("white", "normal", 0.3)
							: color("black", "pale")
						: color("action")};
					color: ${disabled
						? darkBackground
							? color("white", "normal", 0.64)
							: color("black", "bright")
						: "white"};
					${disabled
						? ""
						: css`
								box-shadow: 0 1px 2px 1px ${color("black", "normal", 0.25)};
								cursor: pointer;
						  `};
					margin-bottom: 2px;
					${ripple("active", "light")};
					&:hover {
						background-color: ${color("actionDark")};
						opacity: 1;
					}
			  `};
`;
const Content = styled.button`
	${buttonStyle};
`;
const Submit = styled.button`
	${buttonStyle};
`;

const IconContainer = styled.div`
	margin: -5px;
	font-size: 24px;
	${({ right }) => (right ? `padding-left` : `padding-right`)}: 10px;
	transform: translate(0, -2px);
`;

export const ButtonsRow = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
	align-items: center;
	& > div:last-child:first-child {
		margin: auto;
	}
	@media screen and (max-width: ${mobileBreakpoint}px) {
		flex-direction: column-reverse;
		> *:not(:last-child) {
			margin-top: 20px;
		}
	}
`;

export const ActionsList = ({ actions }) => (
	<div class="actions-list">
		{actions.map(action => (
			<button onClick={action.onClick}>{action.name}</button>
		))}
	</div>
);
