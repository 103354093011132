import React from "react";
import styled from "styled-components";
import { addModal } from "architecture/Modals";
import { BusinessHistory, WorkerHistory } from "./DebriefsHistory/History";
import Button from "components/Button";
import Typography from "@material-ui/core/Typography";
import AsyncSearch from "components/AsyncSearch";
import adminBusinesses from "components/AsyncSearch/adminBusinesses";
import adminWorkers from "components/AsyncSearch/adminWorkers";

export default class DebriefsHistory extends React.Component {
	state = {
		businessId: "",
		workerId: "",
	};

	showBusinessDebriefHistory = () => {
		addModal(() => <BusinessHistory businessId={this.state.businessId} />);
	};

	showWorkerDebriefHistory = () => {
		addModal(() => <WorkerHistory workerId={this.state.workerId} />);
	};

	render() {
		return (
			<Container>
				<Typography variant="h3">Historique des debriefs</Typography>
				<Line>
					<AsyncSearch
						onSelection={value => this.setState({ businessId: value })}
						fetchOptions={adminBusinesses}
						placeHolder={"Business"}
					/>
					<StyledButton onClick={this.showBusinessDebriefHistory}>
						Voir les debriefs du Business
					</StyledButton>
				</Line>
				<Line>
					<AsyncSearch
						onSelection={value => this.setState({ workerId: value })}
						fetchOptions={adminWorkers}
						placeHolder={"Worker"}
					/>
					<StyledButton onClick={this.showWorkerDebriefHistory}>
						Voir les debriefs du Worker
					</StyledButton>
				</Line>
			</Container>
		);
	}
}

const Line = styled.div`
	display: flex;
`;
const StyledButton = styled(Button)`
	margin-left: 10px;
`;
const Container = styled.div`
	margin: 20px;
`;
