import React from "react";
import Select from "./Select";

export default ({ value, updateValue, businessCategories }) => (
	<Select
		label="Catégorie *"
		value={value || ""}
		updateValue={updateValue}
		options={businessCategories.map(({ key, name }) => ({ key, value: name }))}
	/>
);
