import React from "react";
import Button from "components/Button";
import { addModal } from "architecture/Modals";
import NewBTPContract from "modals/Admin/NewBTPContract";
import { useCurrentContracts } from "./Hooks/useAdminContracts";
import Table from "components/Table";
import moment from "moment";
import Loading from "components/Loading";
import WorkerProfile from "modals/WorkerProfile";

export default () => {
	const { contracts, loading } = useCurrentContracts();

	if (loading) {
		return <Loading />;
	}

	return (
		<div>
			<section class="row">
				<Button
					onClick={() =>
						addModal(({ dismiss }) => <NewBTPContract dismiss={dismiss} />)
					}
				>
					Nouveau contrat
				</Button>
			</section>
			<section>
				<Table
					rows={contracts.filter(c => c.btp)}
					columns={[
						{
							label: "Id",
							getter: ({ id }) => id,
						},
						{
							label: "Worker",
							getter: ({ getJob, getWorker: { name, id } }) => (
								<button
									class="link"
									onClick={() =>
										addModal(() => (
											<WorkerProfile workerId={id} showContact={true} />
										))
									}
								>
									{name} ({getJob.name})
								</button>
							),
						},
						{
							label: "Business",
							getter: ({ getBusiness: { name } }) => name,
						},
						{
							label: "Dates",
							getter: ({ startAt, endAt }) =>
								moment(startAt).format("dddd DD/MM") +
								" - " +
								moment(endAt).format("dddd DD/MM"),
						},
					]}
				/>
			</section>
		</div>
	);
};
