import forAll from "utils/forAll";
import moment from "moment";
import compare from "utils/compare";
import serializeShifts from "../serializeShifts";

export default shifts => {
	if (!shifts) {
		return;
	}
	if (
		shifts.length < 0 ||
		forAll(shifts, shift => !shift.date && !shift.timeRange)
	) {
		return "Veuillez compléter au moins un créneau";
	}
	const serializedShifts = serializeShifts(shifts);
	serializedShifts.sort(
		compare(({ startTime }) => startTime, { ascending: true }),
	);
	for (let index = 1; index < serializedShifts.length; index++) {
		if (
			moment(serializedShifts[index].startTime).isBefore(
				serializedShifts[index - 1].endTime,
			)
		) {
			return "Veuillez ne pas avoir de créneaux superposés";
		}
	}
};
