import React from "react";
import Table from "components/Table";
import gql from "graphql-tag";
import { GraphQL } from "common/graphql";
import moment from "moment";
import sum from "utils/sum";
import styled from "styled-components";
import { color } from "common/styles";
import toHours from "utils/humanizeTotalHours";
import formatCurrency from "utils/formatCurrency";
import capitalize from "utils/capitalize";
import { contractsBillingAmount } from "./BudgetGraph";

const BudgetSummary = styled.div`
	background-color: ${color("action")};
	color: #fff;
	display: flex;
	z-index: 1;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
	padding: 8px 15px;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	.total {
		font-size: 20px;
	}
`;

const GetContracts = gql`
	query($startOfMonth: DateTime, $endOfMonth: DateTime) {
		currentUser {
			id
			business {
				id
				contracts(
					signed: true
					canceled: false
					startAfter: $startOfMonth
					startBefore: $endOfMonth
				) {
					id
					startTime
					getJob {
						name
					}
					getWorker {
						id
						name
					}
					totalHours
					trackedHours
					businessTracked
					tracked
					businessTracked
					provisionalBillingAmount
					billingAmount
				}
			}
		}
	}
`;

export default class BudgetTable extends React.Component {
	total(contracts) {
		const { dataType } = this.props;

		if (dataType === "contracts") {
			return <div className="total">{contracts.length}</div>;
		}

		const calcTotal = sum(
			contracts.map(c =>
				dataType === "billing"
					? c.billingAmount
					: c.trackedHours || c.totalHours,
			),
		);

		return (
			<div className="total">
				{dataType === "billing"
					? formatCurrency(calcTotal)
					: toHours(calcTotal, { compact: true })}
			</div>
		);
	}

	summary(contracts) {
		const { dataType } = this.props;
		const { month } = this.props;

		return (
			<BudgetSummary>
				<div>
					<strong>{capitalize(moment(month).format("MMMM YYYY"))}</strong>
					<br />
					{dataType === "billing"
						? "Total HT"
						: dataType === "contracts"
						? "Nombre de vacations"
						: "Nombre d'heures"}
				</div>
				{this.total(contracts)}
			</BudgetSummary>
		);
	}

	render() {
		const { month } = this.props;

		return (
			<GraphQL
				noPoll={true}
				query={GetContracts}
				variables={{
					startOfMonth: moment(month).startOf("month"),
					endOfMonth: moment(month).endOf("month"),
				}}
			>
				{({
					currentUser: {
						business: { contracts },
					},
				}) => {
					contracts = contractsBillingAmount(contracts);
					return (
						<React.Fragment>
							<Table
								columns={[
									{
										label: "Date",
										getter: ({ startTime }) =>
											moment(startTime).format("DD/MM"),
									},
									{
										label: "Mission",
										getter: ({ getJob, getWorker }) =>
											`${getJob.name} - ${getWorker.name}`,
									},
									{
										label: "Durée",
										getter: ({ totalHours, trackedHours }) =>
											toHours(trackedHours || totalHours, {
												compact: true,
												showMinutes: true,
											}),
									},
									{
										label: "Dépense",
										getter: ({ billingAmount }) =>
											formatCurrency(billingAmount),
									},
								]}
								rows={contracts}
								orderBy={1}
							/>
							{this.summary(contracts)}
						</React.Fragment>
					);
				}}
			</GraphQL>
		);
	}
}
