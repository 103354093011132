import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const getWorker = gql`
	query getWorker($workerId: ID!) {
		worker(id: $workerId) {
			id
			name
			firstName
			age
			tagline
			resumeUrl
			jobs {
				name
				key
			}
			skills {
				key
				name
			}
			documents {
				googleFolderId
			}
			verifiedAt
			medianDebriefScore
			pastContractsCount
			belongsToEU
			phoneNumber
			email
			featuredDebrief {
				score
				comment
				matching {
					business {
						name
					}
				}
			}
			businessDebriefs {
				collectedAt
			}
			experiences {
				startDate
				endDate
				businessName
				description
				training
				status
				training
				jobs {
					key
					name
					inferiorJobKeys
				}
			}
			adminReview {
				score
				comment
			}
			mediumAvatarURL
			trackedContractsCount
			createdAt
			experience
			debriefScoreCounts
		}
	}
`;

export const useWorker = workerId => {
	const { data, loading } = useQuery(getWorker, {
		variables: { workerId },
	});

	if (loading || !data || !data.worker) {
		return { loading: true, worker: {} };
	}

	return {
		loading,
		worker: data.worker,
	};
	
};
