import { graphqlURL } from "common/constants";
import { ApolloLink } from "apollo-link";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";

let token;

export const setToken = newToken => {
	token = newToken;
};

export const getToken = () => token;

const additionalHeaders = {};
if (window.location.search.includes("as-business")) {
	additionalHeaders[
		"gofer-admin-impersonates-business-id"
	] = window.location.search.split("as-business=")[1].split("&")[0];
}

export const getHeaders = () => ({
	authorization: token ? `Bearer ${token}` : null,
	...(token ? additionalHeaders : {}),
});

const httpLink = new HttpLink({ uri: graphqlURL });
const authLink = setContext(() => ({
	headers: getHeaders(),
}));
const logErrorsLink = onError(({ graphQLErrors, networkError, operation }) => {
	if (graphQLErrors) {
		graphQLErrors.map(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
					locations,
				)}, Path: ${path}, Operation: ${JSON.stringify(operation)}`,
			),
		);
	}
	if (networkError) {
		console.log(
			`[Network error]: ${networkError}, Operation: ${JSON.stringify(
				operation,
			)}`,
		);
	}
});

export default new ApolloClient({
	link: ApolloLink.from([logErrorsLink, authLink, httpLink]),
	cache: new InMemoryCache(),
});
