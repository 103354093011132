import Request from "common/Request";

export default async name => {
	const {
		createBusiness: { id },
	} = await Request.mutation(
		/* GraphQL */ `
			mutation($name: String!) {
				createBusiness(name: $name) {
					id
				}
			}
		`,
		{
			name,
		},
		{
			successMessage: "Le business a été créé",
			errorMessage: "Une erreur s'est produite. Veuillez réessayer.",
		},
	);
	return id;
};
