import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import apolloClient, { setToken } from "common/apolloClient";

const DEFAULT_REDIRECT_CALLBACK = () =>
	window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();

export default () => useContext(Auth0Context);

export const Auth0Provider = ({
	children,
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
	audience,
	...initOptions
}) => {
	const [isAuthenticated, setIsAuthenticated] = useState();
	const [auth0Client, setAuth0] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const initAuth0 = async () => {
			const auth0FromHook = await createAuth0Client({
				audience,
				...initOptions,
			});
			setAuth0(auth0FromHook);

			if (window.location.search.includes("code=")) {
				const { appState } = await auth0FromHook.handleRedirectCallback();
				onRedirectCallback(appState);
			}

			setLoading(false);
		};
		initAuth0();
	}, []);

	const handleRedirectCallback = async () => {
		setLoading(true);
		await auth0Client.handleRedirectCallback();
		setLoading(false);
		setIsAuthenticated(true);
	};
	return (
		<Auth0Context.Provider
			value={{
				isAuthenticated,
				loading,
				handleRedirectCallback,
				logout: (...p) => {
					apolloClient.resetStore();
					auth0Client.logout({
						...p,
						returnTo: window.location.origin,
					});
				},
				authenticate: async () => {
					try {
						const token = await auth0Client.getTokenSilently({ audience });
						setToken(token);
						setIsAuthenticated(await auth0Client.isAuthenticated());
					} catch (error) {
						console.error(error);
						auth0Client.loginWithRedirect({ audience });
					}
				},
			}}
		>
			{children}
		</Auth0Context.Provider>
	);
};
