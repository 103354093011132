import moment from "moment";

const makeISOString = (date, minutes) =>
	moment(date)
		.startOf("day")
		.add(minutes, "minutes")
		.toISOString();

export const serializeShift = ({
	date,
	timeRange: [start, end],
	breakMinutes,
}) => ({
	startTime: makeISOString(date, start),
	endTime: makeISOString(date, end),
	breakMinutes,
});

export default shifts =>
	shifts.filter(({ date, timeRange }) => date && timeRange).map(serializeShift);
