import React from "react";
import MenuItem from "material-ui/MenuItem";
import { SelectField } from "redux-form-material-ui";
import range from "utils/range";
import { Field } from "redux-form";
import { isMobile } from "common/styles";

const maxBreakMinutes = 90;
const stepMinutes = 5;

export default ({ name }) => (
	<Field
		name={name}
		component={SelectField}
		floatingLabelText="Temps de pause non rémunéré"
		fullWidth={isMobile()}
	>
		{range(maxBreakMinutes / stepMinutes + 1).map(index => (
			<MenuItem
				key={index}
				value={index * stepMinutes}
				primaryText={`${index * stepMinutes} minutes`}
			/>
		))}
	</Field>
);
