import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Loading from "components/Loading";
import { useUpdateSelf } from "models/business";
import Button from "components/Button";

export default ({ dismiss }) => {
	const {
		mutationLoading,
		business: { mediaUrls, description, benefitsDescription },
		setBInfos,
		saveUpdateSelf,
		loading,
	} = useUpdateSelf();
	const [showImage, setShowImage] = useState(0);

	if (loading) {
		return <Loading />;
	}

	return (
		<div class="desktop-grid">
			<div class="eight from-three col">
				<div class="card">
					<article>
						<h1>Mon établissement</h1>
					</article>
					{mediaUrls && (
						<div class="gallery">
							<div
								class="featured-image"
								style={{ backgroundImage: `url(${mediaUrls[showImage]})` }}
							/>
							<div class="all-images">
								{mediaUrls.map((m, i) => (
									<div class="image-item" onClick={() => setShowImage(i)}>
										<img alt="" src={m} />
									</div>
								))}
							</div>
						</div>
					)}
					<article class="col">
						<section>
							<TextField
								multiline={true}
								fullWidth={true}
								variant="outlined"
								rows={4}
								onChange={e => setBInfos({ description: e.target.value })}
								placeholder="Décrivez votre établissement : style, ambiance, culture..."
								defaultValue={description}
							/>
						</section>
						<section>
							<TextField
								multiline={true}
								fullWidth={true}
								variant="outlined"
								rows={4}
								onChange={e =>
									setBInfos({ benefitsDescription: e.target.value })
								}
								placeholder="Quels sont les avantages à travailler chez vous ?"
								defaultValue={benefitsDescription}
							/>
						</section>
					</article>
				</div>
				<section class="row">
					<Button className="right outline" secondary onClick={() => dismiss()}>
						Annuler
					</Button>
					<Button
						loading={mutationLoading}
						onClick={async () => {
							await saveUpdateSelf();
							dismiss();
						}}
					>
						Enregistrer
					</Button>
				</section>
			</div>
		</div>
	);
};
