import React from "react";
import styled from "styled-components";
import { card, mobileBreakpoint, color } from "common/styles";

export const Card = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	${card} padding: 0;
	margin-left: auto;
	margin-right: auto;
`;

export const TwoColsCard = styled(Card)`
	width: 48%;
	margin: 0;
	margin-bottom: 4%;
	@media screen and (max-width: ${mobileBreakpoint}px) {
		width: 100%;
		margin: 0;
		margin-top: 20px;
	}
`;

export const Domino = styled(TwoColsCard)`
	padding: 0;
	flex-direction: row;
	align-items: stretch;
	overflow: hidden;
	.light-half {
		width: 50%;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		@media screen and (max-width: ${mobileBreakpoint}px) {
			width: 130px;
			padding: 15px;
		}
	}
	.dark-half {
		background-color: ${color("primary")};
		color: #fff;
		width: 50%;
		padding: 20px;
		display: flex;
		flex-direction: column;
		position: relative;
		a {
			color: #fff;
		}
		@media screen and (max-width: ${mobileBreakpoint}px) {
			width: auto;
			padding: 15px;
		}
	}
`;

const CardsListContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	& > div:first-child:last-child {
		margin: auto;
	}

	.card {
		margin-right: 25px;
		margin-bottom: 25px;
	}
`;

const CardsListWrapper = styled.div`
	margin-top: 20px;
`;

const CardsListTitle = styled.h2`
	margin-bottom: 20px;
`;

export const CardsList = ({ title, children, className, emptyMessage }) => (
	<CardsListWrapper>
		{title && <CardsListTitle>{title}</CardsListTitle>}

		<CardsListContainer className={className}>
			{children}
			{children.length === 0 && emptyMessage ? (
				<article class="card">{emptyMessage}</article>
			) : null}
		</CardsListContainer>
	</CardsListWrapper>
);

export const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
