import React from "react";
import { GraphQL } from "common/graphql";
import { getFeedback } from "../Feedbacks";
import Badge from "components/Badge";

export default () => (
	<GraphQL query={getFeedback({ badge: true })} hideLoading={true}>
		{({ contractsToDebrief }) => <Badge count={contractsToDebrief.length} />}
	</GraphQL>
);
