import { useState } from "react"
import { useMutation, useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import Request from "common/Request"

const answersProps = `
  id
  createdAt
  motivationalLetter
  matching {
    debrief {
      business {
        comment
        currentScore: score
      }
    }
  }
  worker {
    id
    mediumAvatarURL
    firstName
    lastName
    medianDebriefScore
    pastContractsCount
    verifiedAt
    experiences {
      startDate
      endDate
      training
      status
      jobs {
        key
        inferiorJobKeys
      }
    }
    adminReview {
      score
    }
  }
`

const folders = [
  {
    key: "new",
    label: "Nouvelles candidatures",
    mobileLabel: "Nouveaux",
    emptyText: "Vous n'avez pas de nouvelle candidature",
    query: gql`
  query applicationsWithoutEvaluation($jobPostId: ID!) {
    businessScope {
      jobPost(id: $jobPostId) {
        id
        answers(accept: true, hasBusinessEvaluation: false) {
          ${answersProps}
        }
      }
    }
  }
`,
  },
  {
    key: "favorite",
    label: "Candidatures favorites",
    mobileLabel: "Favoris",
    emptyText: "Vous n'avez pas encore mis de candidature en favori",
    query: gql`
  query favoriteApplications($jobPostId: ID!) {
    businessScope {
      jobPost(id: $jobPostId) {
        id
        answers(accept: true, businessAccept: true) {
          ${answersProps}
        }
      }
    }
  }
`,
  },
  {
    key: "rejected",
    label: "Candidatures rejetées",
    mobileLabel: "Rejetés",
    emptyText: "Vous n'avez pas encore rejeté de candidature",
    query: gql`
  query rejectedApplications($jobPostId: ID!) {
    businessScope {
      jobPost(id: $jobPostId) {
        id
        answers(accept: true, businessAccept: false) {
          ${answersProps}
        }
      }
    }
  }
`,
  },
]

export const useAnswersFolders = () => {
  const [folder, setFolder] = useState("new")
  return {
    folder: folders.find(f => f.key === folder),
    setFolder,
    folders,
  }
}

export const useJobPostAnswers = ({ jobPostId, folder }) => {
  const { loading, data } = useQuery(folder.query, {
    variables: { jobPostId },
    fetchPolicy: "no-cache",
  })

  if (loading || !data || !data.businessScope || !data.businessScope.jobPost) {
    return { loading: true, answers: [] }
  }

  return { loading, answers: data.businessScope.jobPost.answers }
}

const setAnswerStatus = gql`
  mutation evaluateJobPostAnswer(
    $answerId: ID!
    $accept: Boolean!
    $reasonForRefusal: String
  ) {
    business {
      evaluateJobPostAnswer(
        answerId: $answerId
        accept: $accept
        reasonForRefusal: $reasonForRefusal
      ) {
        id
      }
    }
  }
`

const getAnswerStatus = gql`
  query jobPostAnswer($answerId: ID!) {
    businessScope {
      jobPostAnswer(id: $answerId) {
        businessAccept
      }
    }
  }
`

const reasons = [
  "Il n'y a pas assez d'années d'expérience",
  "Les expériences concernent des établissements trop différents du mien",
  "Les avis ne sont pas assez positifs",
  "La lettre de motivation ne correspond pas à ce que je cherche",
  "Il manque une ou plusieurs compétences importantes pour moi",
]

export const useJobPostAnswerStatus = answerId => {
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)
  const { loading, data } = useQuery(getAnswerStatus, {
    variables: {
      answerId,
    },
  })
  const [setStatus] = useMutation(setAnswerStatus)

  if (loading || !data || !data.businessScope) {
    return {
      loading: true,
    }
  }

  const setFavorite = async () => {
    setAcceptLoading(true)
    await setStatus({
      variables: {
        answerId,
        accept: true,
      },
    })
    await Request.refreshAll()
    setAcceptLoading(false)
  }

  const setRejected = async reasonForRefusal => {
    setRejectLoading(true)
    await setStatus({
      variables: {
        answerId,
        accept: false,
        reasonForRefusal,
      },
    })
    await Request.refreshAll()
    setRejectLoading(false)
  }

  return {
    reasons,
    businessAccept: data.businessScope.jobPostAnswer.businessAccept,
    setFavorite,
    setRejected,
    loading,
    acceptLoading,
    rejectLoading,
  }
}
