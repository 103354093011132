import React from "react";
import { GraphQL } from "common/graphql";
import AvatarsToValidateTable from "./AvatarsToValidateTable";
import gql from "graphql-tag";

export default class AvatarsToValidate extends React.Component {
	render() {
		return (
			<GraphQL
				query={gql`
					query avatarsToValidate {
						admin {
							avatarsToValidate {
								jobs {
									name
								}
								name
								id
								mediumAvatarURL
								smallAvatarURL
							}
						}
					}
				`}
			>
				{({ admin: { avatarsToValidate } }) => (
					<AvatarsToValidateTable avatarsToValidate={avatarsToValidate} />
				)}
			</GraphQL>
		);
	}
}
