import makeOneMemoized from "./makeOneMemoized";
import makeDecurryfied from "./makeDecurryfied";
import makeCurryfied from "./makeCurryfied";

const makeMemoized = fun => {
	const makeMemoizedFunOfArity = (fun, arity) => {
		if (arity > 0) {
			return makeOneMemoized(arg =>
				makeMemoizedFunOfArity(fun(arg), arity - 1),
			);
		} else {
			return fun;
		}
	};
	return makeDecurryfied(
		makeMemoizedFunOfArity(makeCurryfied(fun), fun.length),
	);
};

export default makeMemoized;
