const initialState = {
	alerts: [],
	mounted: false,
	idGenerator: 0,
};

export default function alerts(state = initialState, action) {
	switch (action.type) {
		case "addAlert":
			return {
				...state,
				alerts: [
					...state.alerts,
					{ text: action.text, action: action.action, id: state.idGenerator },
				],
				idGenerator: state.idGenerator + 1,
			};
		case "mountAlert":
			return {
				...state,
				mounted: true,
			};
		case "unmountAlert":
			return {
				...state,
				mounted: false,
			};
		case "removeAlert":
			return {
				...state,
				alerts: state.alerts.slice(1),
			};
		default:
			return state;
	}
}
