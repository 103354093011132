import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import store from "common/store";
import { addAlert } from "actions/alerts";
import Request from "common/Request";
import { updateById } from "common/graphql";
import debriefScores from "common/debriefScores";
import client from "common/apolloClient";
import {
	GetContractsToDebrief,
	GetContractsToDebriefVars,
} from "scenes/Home/Admin/ContractsToDebriefQuery";
export default class Form extends React.Component {
	state = {
		score: "",
		comment: "",
	};

	submit = async event => {
		event.preventDefault();
		const params = {
			...this.props.initialValues,
			score: this.state.score,
			comment: this.state.comment,
		};
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation(
						$from: userTypes!
						$workerId: String!
						$businessId: String!
						$jobKey: String!
						$comment: String
						$score: Int!
					) {
						adminDebrief(
							from: $from
							workerId: $workerId
							businessId: $businessId
							jobKey: $jobKey
							comment: $comment
							score: $score
						) {
							id
						}
					}
				`,
				params,
			);
			this.props.dismiss();
			store.dispatch(addAlert("Le debrief a été enregistré."));
			this.updateCache();
		} catch (error) {
			console.log(error);
			store.dispatch(
				addAlert(
					"Une erreur s'est produite et le debrief n'a pas été enregistré.",
				),
			);
		}
	};

	updateCache() {
		const { score } = this.state;
		const { id, from } = this.props.initialValues;

		const { admin } = client.cache.readQuery({
			query: GetContractsToDebrief,
			variables: GetContractsToDebriefVars,
		});

		const newScore = {
			matching: {
				debrief: {
					[from]: { score },
				},
			},
		};

		client.writeQuery({
			query: GetContractsToDebrief,
			data: {
				admin: {
					...admin,
					contractsToDebrief: updateById(
						admin.contractsToDebrief,
						newScore,
						id,
					),
				},
			},
		});
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const { name, phoneNumber } = this.props;
		return (
			<AdminDebrief
				name={name}
				phoneNumber={phoneNumber}
				submit={this.submit}
				score={this.state.score}
				onFieldChange={this.handleChange}
			/>
		);
	}
}

export const AdminDebrief = ({
	name,
	phoneNumber,
	submit,
	score,
	onFieldChange,
}) => (
	<Container>
		<div>
			<Name>{name}</Name>
			<PhoneNumber>{phoneNumber}</PhoneNumber>
			<form onSubmit={submit}>
				<FormControl>
					<InputLabel htmlFor="score">Score</InputLabel>
					<Select
						value={score}
						onChange={onFieldChange}
						name="score"
						id="score"
					>
						{debriefScores.map(({ score, label, Emotion }) => (
							<MenuItem key={score} value={score}>
								<Emotion /> {label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					name="comment"
					label="Commentaire"
					onChange={onFieldChange}
					fullWidth
					multiline
				/>
				<Button submit>Soumettre</Button>
			</form>
		</div>
	</Container>
);

const Container = styled.div`
	display: flex;
	flex-direction: row;

	& input[type="submit"] {
		margin-top: 20px;
	}
`;

const Name = styled.h1`
	font-size: 25px;
	margin-bottom: 10px;
`;

const PhoneNumber = styled.h2`
	font-size: 20px;
	margin-bottom: 10px;
`;
