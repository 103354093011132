import { color, ellipsis, link } from "common/styles";
import QueryAnswerStatistics from "components/QueryAnswerStatistics";
import { statusElements } from "models/query";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import styled, { css } from "styled-components";

import Contracts from "./Contracts";

const formatTime = t => moment(t).format("LT");

const getQueryIdFromContract = contract => ((contract || {}).query || {}).id;

export default function QueryShift(props) {
	const { endTime, query = {}, startTime } = useMemo(() => ({ ...props }), [
		props,
	]);
	const {
		dossier,
		getJob: { name: job },
		groupId,
		hasStarted,
		id,
		maxWorkers,
		selectWorkers,
		status,
	} = useMemo(() => ({ ...query }), [query]);
	const { color } = useMemo(() => statusElements[status], [status]);
	const isTimeout = useMemo(() => status === "timeout", [status]);

	const contracts = useMemo(
		() =>
			groupId
				? (props.queries || [])
						.filter(q => q.groupId === groupId)
						.map(q => q.contracts)
						.flat()
				: (props.query || {}).contracts || [],
		[groupId, props.contracts, props.queries],
	);

	const [redirectPath, setRedirectPath] = useState("");

	const onClick = useCallback(() => {
		let queryId = id;
		if (groupId && contracts.length > 1) {
			queryId = contracts.map(getQueryIdFromContract).join("-");
		}
		setRedirectPath(`/planning/${queryId}`);
	}, [contracts, groupId, id]);

	const onClickContract = useCallback(contract => {
		const queryId = getQueryIdFromContract(contract);
		if (queryId) {
			setRedirectPath(`/planning/${queryId}`);
		}
	}, []);

	return (
		<Container>
			{redirectPath ? <Redirect to={redirectPath} /> : null}
			<Time onClick={onClick}>
				<Start>{formatTime(startTime)}</Start>
				<End>{formatTime(endTime)}</End>
			</Time>
			<Separator color={color} />
			<Side>
				<div onClick={onClick}>
					<Top>
						<Job timeout={isTimeout}>{job}</Job>
						{(query.contracts || []).length < maxWorkers ? (
							<QueryAnswerStatistics {...props} />
						) : null}
					</Top>
					{dossier ? <Dossier>{dossier}</Dossier> : null}
				</div>
				<Contracts
					contracts={contracts}
					extended
					grouped={Boolean(groupId)}
					hasAvailableWorkers={status !== "wait"}
					hasStarted={hasStarted}
					maxWorkers={maxWorkers}
					selectWorkers={selectWorkers}
					onClick={onClick}
					onClickContract={onClickContract}
				/>
			</Side>
		</Container>
	);
}

const Container = styled.div`
	${link} flex: 1;
	display: flex;
	align-items: stretch;
	padding: 8px;
`;
const Dossier = styled.div`
	color: black;
	font-weight: bold;
	font-size: 0.6em;
	margin-bottom: 2px;
`;
const End = styled.div``;
const Job = styled.div`
	font-weight: bold;
	font-size: 0.9em;
	${ellipsis("100%")};
	${({ isTimeout }) =>
		isTimeout &&
		css`
			color: ${color("black", "light")};
			text-decoration: line-through;
		`};
`;
const Separator = styled.div`
	background-color: ${({ color }) => color};
	border-radius: 4px;
	margin: 0 5px;
	width: 4px;
`;
const Side = styled.div`
	align-items: stretch;
	display: flex;
	flex-direction: column;
	flex: 1;
`;
const Start = styled.div`
	padding: 7px 0;
`;
const Time = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	font-size: 0.8em;
	width: 30px;
`;
const Top = styled.div`
	align-items: flex-end;
	display: flex;
	flex-shrink: 0;
	font-size: 0.8em;
	justify-content: space-between;
	position: relative;
`;
