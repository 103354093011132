import React from "react";
import ConfirmDialog from "./customFields/ConfirmDialog";
import SiretField from "./customFields/SiretField";
import isValidSiret from "siret";
import Request from "common/Request";

export default {
	label: "Informations administratives et de facturation",
	fields: () => ({
		company: {
			siret: {
				label: "Siret",
				custom: SiretField,
				checkField: ({ siret }) => isValidSiret(siret || ""),
			},
			name: { label: "Raison sociale" },
			address: {
				streetName: { label: "Rue" },
				postCode: { label: "Code postal" },
				city: { label: "Ville" },
				country: {
					label: "Pays",
					type: "selector",
					selectorType: "countries",
				},
			},
		},
		nafCode: { label: "Code NAF" },
		billingEmailAddress: { label: "Email de facturation" },
		overtimeWeeklyHoursThreshold: {
			label: "Nombre d’heures travaillées par semaine",
			type: "number",
		},
		billingReference: {
			label: "Référence de facturation",
			type: "selector",
			selectorType: "billingReferences",
		},
		guarantee: { label: "Garantie (€)", type: "rate", optional: true },
		businessAlreadyExists: {
			optional: true,
			custom: ({ openConfirmDialog, setOpenConfirmDialog, saveBusiness }) => (
				<ConfirmDialog
					openConfirmDialog={openConfirmDialog}
					setOpenConfirmDialog={setOpenConfirmDialog}
					saveBusiness={saveBusiness}
				/>
			),
		},
	}),
	beforeNext: async (initialBusiness, business) => {
		const siren = business.company.siret.substring(0, 9);
		const billingReference = business.billingReference;
		if (
			billingReference !== initialBusiness.billingReference ||
			business.company.siret !== initialBusiness.company.siret
		) {
			const data = await Request.query(
				/* GraphQL */ `
					query businessesWithSirenAndBillingRef(
						$siren: String
						$billingReference: String
					) {
						admin {
							businesses(siren: $siren, billingReference: $billingReference) {
								id
							}
						}
					}
				`,
				{
					variables: {
						siren,
						billingReference,
					},
				},
			);
			if (!data) {
				throw new Error("Une erreur s'est produite, veuillez réessayer");
			}
			const {
				admin: { businesses },
			} = data;
			if (businesses.length > 0) {
				throw new Error("businessAlreadyExists");
			}
		}
	},
};
