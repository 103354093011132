import { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Request from "common/Request";

const ListSkills = gql`
	{
		skills {
			key
			name
			category
			jobs {
				key
				name
			}
		}
	}
`;

export const categories = {
	PMS: "PMS",
	language: "Langue",
	diploma: "Diplôme",
};

export const useSkills = () => {
	const { data, loading } = useQuery(ListSkills);

	if (!data || loading || !data.skills) {
		return { loading: true, skills: [] };
	}

	return { loading, skills: data.skills };
};

const CreateSkill = gql`
	mutation($name: String!, $jobKeys: [String!]!, $category: SkillCategory) {
		admin {
			createSkill(name: $name, jobKeys: $jobKeys, category: $category) {
				key
			}
		}
	}
`;

export const useCreateSkill = () => {
	const [skill, setSkill] = useState({ name: "", category: "", jobs: [] });
	const [save, { loading }] = useMutation(CreateSkill);

	const saveSkill = async () => {
		await save({
			variables: {
				...(skill.category ? { category: skill.category } : {}),
				...{
					name: skill.name,
					jobKeys: skill.jobs.map(j => j.key),
				},
			},
		});

		Request.refreshAll();
	};

	return {
		skill,
		setSkill: val => setSkill({ ...skill, ...val }),
		saveSkill,
		loading,
	};
};
