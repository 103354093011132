import React from "react";
import gql from "graphql-tag";
import WorkersToReviewTable from "./WorkersToReviewTable";
import { useQuery } from "@apollo/react-hooks";
import Loading from "components/Loading";
import { useModalOnPath } from "architecture/Modals";
import ReviewWorker from "./ReviewWorker";

const WorkersToReview = gql`
	query workersToReview {
		admin {
			workersToReview {
				id
				name
				pastContractsCount
				uniqueBusinessDebriefsCount
			}
		}
	}
`;

export default ({ history }) => {
	const { loading, data } = useQuery(WorkersToReview);
	const { openModal } = useModalOnPath({
		history,
		path: "/workers-to-review/",
		modal: id => ({ dismiss }) => (
			<ReviewWorker workerId={id} dismiss={dismiss} />
		),
	});

	if (loading || !data || !data.admin) {
		return <Loading />;
	}

	return (
		<WorkersToReviewTable
			openModal={openModal}
			workersToReview={data.admin.workersToReview}
		/>
	);
};
