import React from "react";
import { Route, Switch } from "react-router";
import Navbar from "architecture/Navbar";
import Contracts from "./BTP/Contracts";
import Trackings from "./BTP/Trackings";

const btpRoutes = [
	{
		label: "Contrats",
		route: "/btp",
		component: Contracts,
	},
	{
		label: "Pointages",
		route: "/btp/trackings",
		component: Trackings,
	},
];

export default () => (
	<Navbar routes={btpRoutes} className="btp">
		<Switch>
			{btpRoutes.map(m => (
				<Route key={m.route} exact path={m.route} component={m.component} />
			))}
		</Switch>
	</Navbar>
);
