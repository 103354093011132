const pluralizeWord = word => {
	switch (word) {
		case "un":
		case "une":
			return "des";
		case "la":
			return "les";
		case "votre":
			return "vos";
		default:
			return `${word}s`;
	}
};

const pluralize = (count, singularVersion, pluralizedVersion) => {
	if (!singularVersion && !pluralizedVersion) {
		// a single word has been passed as the first argument
		return pluralize(2, count);
	}
	if (Array.isArray(count)) {
		count = count.length;
	}
	if (count < 2) {
		return singularVersion;
	}
	return (
		pluralizedVersion ||
		singularVersion
			.split(" ")
			.map(pluralizeWord)
			.join(" ")
	);
};

export default pluralize;
