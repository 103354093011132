import React from "react";
import Form from "./CustomNotification/Form";
import Request from "common/Request";
import { DEV } from "common/constants";

export default class CustomNotification extends React.Component {
	state = {
		result: undefined,
	};

	submit = async (event, { title, message, userIds }) => {
		this.setState({ result: undefined });
		event.preventDefault();
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation($title: String!, $message: String!, $userIds: [ID!]) {
						sendCustomNotification(
							title: $title
							message: $message
							userIds: $userIds
						) {
							success
						}
					}
				`,
				{
					title,
					message,
					userIds,
				},
			);
			this.setState({ result: "success" });
		} catch (error) {
			DEV && console.error(error);
			this.setState({
				result: "error",
			});
		}
	};

	render() {
		const { result } = this.state;

		return <Form onSubmit={this.submit} result={result} />;
	}
}
