import React from "react";
import Select from "./Select";
import range from "utils/range";

const maxBreakMinutes = 90;
const stepMinutes = 5;

export default ({ value, updateValue }) => (
	<Select
		label="Temps de pause non rémunéré (minutes)"
		value={value || (value === 0 ? 0 : "")}
		updateValue={updateValue}
		options={range(maxBreakMinutes / stepMinutes + 1)
			.map(index => index * stepMinutes)
			.map(minutes => ({ key: minutes, value: `${minutes} minutes` }))}
	/>
);
