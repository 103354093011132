import React from "react";
import styled from "styled-components";
import { TextField } from "redux-form-material-ui";
import Button from "components/Button";
import AsyncSearch from "components/AsyncSearch";
import adminWorkers from "components/AsyncSearch/adminWorkers";

const StyledForm = styled.form`
	overflow: auto;
	min-height: 100%;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	min-width: 400px;
`;

const StyledButton = styled(Button)`
	margin-top: 20px;
	margin-bottom: 20px;
`;

const validate = ({ title, message, userIds }) => {
	const errors = {};
	if (!title) {
		errors.title = "Titre ne peut être vide";
	}
	if (!message) {
		errors.message = "Message ne peut être vide";
	}
	if (!userIds) {
		errors.userIds = "Les identifiants ne peuvent être vide";
	}
	return errors;
};

export default class Form extends React.Component {
	state = { title: "Gofer", message: "", userIds: "" };

	render() {
		const { onSubmit, result } = this.props;
		const { title, message, userIds } = this.state;
		const errors = validate({ title, message, userIds });

		return (
			<StyledForm onSubmit={e => onSubmit(e, { title, message, userIds })}>
				<Container>
					<Content>
						<TextField
							name="title"
							floatingLabelText="Titre"
							fullWidth={true}
							type="text"
							value={title}
							onChange={event => {
								this.setState({ title: event.target.value });
							}}
							errorText={errors.title}
						/>
						<TextField
							name="message"
							floatingLabelText="Message"
							multiLine={true}
							fullWidth={true}
							type="text"
							value={message}
							onChange={event => {
								this.setState({ message: event.target.value });
							}}
							errorText={errors.message}
						/>
						<AsyncSearch
							onSelection={value => {
								this.setState({ userIds: value });
							}}
							fetchOptions={adminWorkers}
							placeHolder={"Utilisateurs"}
							isMulti={true}
						/>
					</Content>
					<StyledButton
						disabled={errors.message || errors.title || errors.userIds}
						submit
					>
						Envoyer
					</StyledButton>
					{result &&
						(result === "success" ? (
							<div className="success message">
								La notification a été envoyée avec succès
							</div>
						) : (
							<div className="error message">
								Une erreur s'est produite et la notification n'a pas été envoyée
								!
							</div>
						))}
				</Container>
			</StyledForm>
		);
	}
}
