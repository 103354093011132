const makeCurryfied = fun => {
	const makeCurryfiedFunOfArity = (fun, arity) => {
		if (arity > 1) {
			return arg =>
				makeCurryfiedFunOfArity((...args) => fun(arg, ...args), arity - 1);
		} else {
			return fun;
		}
	};
	return makeCurryfiedFunOfArity(fun, fun.length);
};

export default makeCurryfied;
