import * as React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import { color } from "common/styles";

const Loader = styled(CircularProgress)`
	color: ${color("action")};
	opacity: ${props => (props.loading === "true" ? 1 : 0)};
	margin-left: 7px;
	vertical-align: middle;
`;

const statusList = [
	{ key: "notYetContacted", name: "Pas encore contacté" },
	{ key: "contacted", name: "En cours de validation" },
	{ key: "unreachable", name: "Injoignable" },
	{ key: "validated", name: "Expérience confirmée" },
	{ key: "insufficient", name: "Non pertinent" },
	{ key: "rejected", name: "Rejeté" },
];

export default class ExperienceContact extends React.Component {
	state = {
		loading: false,
	};

	async updateStatus(event) {
		const { onContactChange } = this.props;
		this.setState({ loading: true, newStatus: event.target.value });
		onContactChange &&
			(await onContactChange({
				...this.props,
				status: event.target.value,
			}));

		//allow rendering engine to update view before removing loading sign
		setTimeout(() => this.setState({ loading: false }), 200);
	}

	render() {
		const { name, phoneNumber, status } = this.props;
		const { loading, newStatus } = this.state;

		return (
			<tr>
				<td>{name}</td>
				<td>{phoneNumber}</td>
				<td>
					<Select
						style={{ width: "250px" }}
						value={newStatus || status}
						onChange={event => this.updateStatus(event)}
					>
						{statusList.map(st => (
							<MenuItem key={st.key} value={st.key}>
								{st.name}
							</MenuItem>
						))}
					</Select>
					<Loader size={24} loading={loading.toString()} />
				</td>
			</tr>
		);
	}
}
