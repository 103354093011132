import React from "react";
import { AvatarsFlower } from "components/Avatar";
import useCurrentUser from "common/useCurrentUser";

export default ({
	title,
	onClick,
	favoriteAnswers,
	newAnswers,
	grossMonthlyRate,
	closed,
}) => {
	const currentUser = useCurrentUser();

	return (
		<div
			class={`card highlight success clickable six row mobile-section ${
				closed ? "error" : ""
			}`}
			onClick={onClick}
		>
			<article>
				<AvatarsFlower
					avatars={[...favoriteAnswers, ...newAnswers].map(
						a => a.worker.mediumAvatarURL,
					)}
				/>
			</article>
			<section class="col vertical-centered">
				<h3>{title}</h3>
				{grossMonthlyRate && (
					<p>
						<strong class="price">{grossMonthlyRate / 100}€</strong> brut/mois
					</p>
				)}
				{!!currentUser.business.benefitsAmount && (
					<p>
						<strong class="price">
							+{currentUser.business.benefitsAmount / 100}€
						</strong>{" "}
						en avantages
					</p>
				)}
			</section>
		</div>
	);
};
