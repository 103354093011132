import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React, { Component } from "react";
import styled, { css } from "styled-components";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import moment from "moment";
import { color, link, isMobile } from "common/styles";

const allDossier = "Tous";

export default class Navigation extends Component {
	translate = by => {
		this.navigateTo(
			moment(this.props.week)
				.startOf("week")
				.add(by, "week"),
		);
	};

	showPrevious = () => {
		this.translate(-1);
	};

	showNext = () => {
		this.translate(1);
	};

	showToday = () => {
		this.navigateTo(moment());
	};

	getTime = () => {
		const week = this.props.week || moment();
		const start = moment(week).startOf("week");

		const end = moment(week).endOf("week");
		return `${start.format("D MMM")} - ${end.format("D MMM")}`;
	};

	navigateTo = day => {
		this.props.setWeek(moment(day));
	};

	dossierList = () => [allDossier, ...(this.props.dossierList || [])];

	render() {
		return (
			<NavigationContainer>
				<Arrow onClick={this.showPrevious}>
					<MdKeyboardArrowLeft />
				</Arrow>
				<Time>{this.getTime()}</Time>
				<Arrow onClick={this.showNext}>
					<MdKeyboardArrowRight />
				</Arrow>
				{!isMobile() ? (
					<Action onClick={this.showToday}>Aujourd'hui</Action>
				) : null}
				<Select
					disableUnderline
					onChange={e => {
						const val = e.target.value;
						if (typeof this.props.setDossier === "function") {
							this.props.setDossier(val === allDossier ? null : val);
						}
					}}
					style={{ color: "#7f7f7f", marginLeft: 10, marginRight: 10 }}
					value={this.props.dossier || allDossier}
				>
					{this.dossierList().map(i => (
						<MenuItem key={i} value={i}>
							{i}
						</MenuItem>
					))}
				</Select>
			</NavigationContainer>
		);
	}
}

const Action = styled.div`
	${link} margin: 0 20px;
	${({ selected }) =>
		selected
			? css`
					color: ${color("action")};
			  `
			: ""};
`;

const Arrow = styled.div`
	${link} margin: 0 10px;
	font-size: 20px;
`;

const Time = styled.div`
	width: 120px;
	display: flex;
	justify-content: center;
	font-size: 14px;
`;

const NavigationContainer = styled.div`
	display: flex;
	align-items: center;
	user-select: none;
`;
