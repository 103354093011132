import React from "react";
import styled from "styled-components";

const BlueBox = styled.div`
	align-items: center;
	flex: 1;
	width: 100%;
	justify-content: flex-end;
	position: relative;

	&:last-child {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	&:first-child {
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}

	&.image-on-top {
		margin-top: 5.5%;
		padding-top: 5.5%;
		&:first-child {
			margin-top: 11%;
		}
		.illustration {
			margin-top: -11%;
			top: 0;
		}
	}

	&.image-on-bottom {
		margin-bottom: 5.5%;
		padding-bottom: 5.5%;
		&:first-child {
			margin-bottom: 11%;
		}
		.illustration {
			bottom: 0;
			margin-bottom: -11%;
		}
	}

	.illustration {
		position: absolute;
		left: 38%;
		width: 22%;
		position: absolute;
		img {
			max-height: 22%;
		}
	}
`;

export default ({
	illustration,
	children,
	illustrationPosition,
	className,
}) => (
	<BlueBox className={`dark-box image-on-${illustrationPosition} ${className}`}>
		<div class="illustration">{illustration ? illustration() : null}</div>
		{children}
	</BlueBox>
);
