import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import compare from "utils/compare";

const GetCurrentContracts = gql`
	query getCurrentContracts($endAfter: DateTime) {
		admin {
			contracts(
				endAfter: $endAfter
				signed: true
				canceled: false
				orderBy: startTime
				isGofer: false
			) {
				id
				btp
				getWorker {
					id
					name
				}
				getBusiness {
					id
					name
					owner {
						email
					}
				}
				getJob {
					key
					name
				}
				endAt
				startAt
			}
		}
	}
`;

export const useCurrentContracts = () => {
	const { loading, data } = useQuery(GetCurrentContracts, {
		variables: {
			endAfter: moment()
				.startOf("hour")
				.toISOString(),
		},
	});

	if (!data || !data.admin) {
		return { loading: true, contracts: [] };
	}

	return {
		loading,
		contracts: data.admin.contracts.filter(
			c => c.btp && c.getBusiness.owner.email.indexOf("gofer.fr") === -1,
		),
	};
};

const GetAdminTrackings = gql`
	query GetAdminTrackings($endBefore: DateTime) {
		admin {
			contracts(
				trackingValidated: false
				canceled: false
				signed: true
				orderBy: tracking
				isGofer: false
				endBefore: $endBefore
			) {
				id
				btp
				endAt
				startAt
				getWorker {
					id
					name
					locked
				}
				getBusiness {
					id
					name
					owner {
						email
					}
				}
				getJob {
					key
					name
				}
				shifts {
					id
					startTime
					endTime
					startTimeTracked
					endTimeTracked
					breakMinutesTracked
					workerTrackedAt
				}
			}
		}
	}
`;

export const startOfWeek = value =>
	moment(value)
		.utc()
		.startOf("week")
		.toISOString();

const contractsToWeeks = (contracts, shiftsFilter) => {
	let shifts = [];
	for (let contract of contracts) {
		shifts = [
			...shifts,
			...contract.shifts.filter(shiftsFilter).map(s => ({
				getBusiness: contract.getBusiness,
				getWorker: contract.getWorker,
				contractId: contract.id,
				shiftId: s.id,
				startTimeTracked: s.startTimeTracked,
				workerTrackedAt: !!s.workerTrackedAt,
				getJob: contract.getJob,
				startTime: s.startTime,
				endTime: s.endTime,
			})),
		];
	}

	const weeks = [];

	for (let shift of shifts) {
		const week = weeks.find(
			w =>
				w.weekDate === startOfWeek(shift.startTime) &&
				w.contractId === shift.contractId,
		);

		if (!week) {
			weeks.push({
				weekDate: startOfWeek(shift.startTime),
				endOfWeek: shift.endTime,
				startOfWeek: shift.startTime,
				shifts: [shift],
				...shift,
			});
		} else {
			week.shifts.push(shift);
			week.endOfWeek =
				moment(shift.endTime) > moment(week.endOfWeek)
					? shift.endTime
					: week.endOfWeek;
			week.startOfWeek =
				moment(shift.startTime) < moment(week.startOfWeek)
					? shift.starttime
					: week.startOfWeek;
			week.workerTrackedAt = !!(week.workerTrackedAt && shift.workerTrackedAt);
			week.startTimeTracked = !!(
				week.startTimeTracked && shift.startTimeTracked
			);
		}
	}

	return weeks.sort(compare(w => moment(w.startOfWeek)));
};

export const useTrackings = () => {
	const { loading, data } = useQuery(GetAdminTrackings, {
		variables: {
			endBefore: moment()
				.startOf("hour")
				.toISOString(),
		},
	});

	if (!data || !data.admin) {
		return { loading: true, contracts: [] };
	}

	const contracts = data.admin.contracts.filter(
		c => c.btp && c.getBusiness.owner.email.indexOf("gofer.fr") === -1,
	);

	return {
		loading,
		trackings: contractsToWeeks(
			contracts,
			c => !c.workerTrackedAt || !c.startTimeTracked,
		),
		trackingsToValidate: contractsToWeeks(
			contracts,
			c => c.workerTrackedAt && c.startTimeTracked,
		),
	};
};
