import React from "react";
import moment from "moment";
import styled from "styled-components";
import sum from "utils/sum";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";
import { color, mobileBreakpoint, isMobile } from "common/styles";
import toHours from "utils/humanizeTotalHours";
import formatCurrency from "utils/formatCurrency";

const GetMonth = gql`
	query($startOfMonth: DateTime, $endOfMonth: DateTime) {
		currentUser {
			id
			business {
				id
				contracts(
					signed: true
					canceled: false
					startAfter: $startOfMonth
					startBefore: $endOfMonth
				) {
					id
					billingAmount
					startTime
					totalHours
					trackedHours
					tracked
					businessTracked
					provisionalBillingAmount
				}
			}
		}
	}
`;
export const contractsBillingAmount = contracts =>
	contracts.map(contract => ({
		...contract,
		billingAmount:
			contract.tracked || contract.businessTracked
				? contract.billingAmount
				: contract.provisionalBillingAmount,
	}));

const MonthContainer = styled.div`
	height: 150px;
	width: 30px;
	position: relative;
	margin: 10px;
	margin-top: 20px;
	margin-bottom: 55px;
	display: flex;
	flex-direction: column-reverse;
	text-align: center;
	font-size: 12px;
	cursor: pointer;

	p {
		position: absolute;
		bottom: -45px;
		width: 100%;
		line-height: 18px;
	}
	&:hover,
	&.selected {
		&::before {
			background-color: ${color("action")};
		}
		.small-bubble {
			transform: scaleX(1);
			opacity: 1;
		}
	}
	&::before {
		height: ${props => props.percent}%;
		background-color: ${color("primaryLight")};
		transition: background-color 500ms ease;
		content: " ";
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	.small-bubble {
		z-index: 1;
		position: absolute;
		bottom: calc(${props => props.percent}% - 15px);
		left: 100%;
		height: 28px;
		padding-left: 16px;
		transform: scaleX(0);
		transform-origin: left;
		transition: all 250ms ease;
		opacity: 0;
		pointer-events: none;
		.bubble-text {
			height: 100%;
			width: 100%;
			background-color: ${color("primary")};
			color: #fff;
			font-weight: bold;
			align-items: center;
			display: flex;
			justify-content: center;
			padding: 0 10px;
			padding-left: 0;
			z-index: 1;
			position: relative;
		}
		&::before {
			content: " ";
			background-color: ${color("primary")};
			left: -7px;
			height: 19px;
			width: 19px;
			transform: rotate(45deg);
			transform-origin: bottom;
			position: absolute;
			left: 0;
			top: 2px;
		}
	}

	@media screen and (max-width: ${mobileBreakpoint}px) {
		height: 100px;
		margin-top: 0;
	}
`;

const Graph = styled.div`
	display: flex;
	justify-content: center;
	height: 235px;
	flex-shrink: 0;

	@media print {
		display: none;
	}

	@media screen and (max-width: ${mobileBreakpoint}px) {
		height: 150px;
	}
`;

const Month = ({ month, onClick, label, value, max, selected }) => (
	<MonthContainer
		onClick={onClick}
		percent={(value * 100) / max}
		className={selected ? "selected" : ""}
	>
		<div percent={(value * 100) / max} className="small-bubble">
			<div className="bubble-text">{label}</div>
		</div>
		<p>
			<strong>
				{month.format("MMMM").toUpperCase()[0]}
				{month.format("MMMM").toLowerCase()[1]}
			</strong>
			<br />
			{month.format("YYYY")}
		</p>
	</MonthContainer>
);

export default class BudgetGraph extends React.Component {
	monthValue(month, contracts) {
		const { dataType } = this.props;

		const filteredContracts = contracts.filter(
			c => moment(c.startTime).format("MM/YYYY") === month.format("MM/YYYY"),
		);

		if (dataType === "contracts") {
			return filteredContracts.length;
		}

		return sum(
			filteredContracts.map(c =>
				dataType === "billing"
					? c.billingAmount
					: c.trackedHours || c.totalHours,
			),
		);
	}

	monthsValues(contracts) {
		return this.months.map(m => this.monthValue(m, contracts));
	}

	max(contracts) {
		return Math.max.apply(null, this.monthsValues(contracts));
	}

	get months() {
		const nbMonths = isMobile() ? 3 : 12;
		const months = [];
		const initial = moment().subtract(nbMonths, "months");
		for (let i = 0; i < nbMonths; i++) {
			months.push(moment(initial.add(1, "months")));
		}
		return months;
	}

	render() {
		const { onPickMonth, selectedMonth, dataType } = this.props;

		return (
			<GraphQL
				query={GetMonth}
				variables={{
					startOfMonth: moment()
						.startOf("year")
						.subtract(1, "years"),
					endOfMonth: moment().endOf("year"),
				}}
			>
				{({
					currentUser: {
						business: { contracts },
					},
				}) => {
					contracts = contractsBillingAmount(contracts);
					return (
						<Graph>
							{this.months.map((month, i) => {
								const value = this.monthValue(month, contracts);
								const label =
									dataType === "billing"
										? formatCurrency(value)
										: dataType === "contracts"
										? value
										: toHours(value, { compact: true });
								return (
									<Month
										key={i}
										month={month}
										max={this.max(contracts)}
										label={label}
										value={value}
										selected={
											month.format("MM/YYYY") ===
											moment(selectedMonth).format("MM/YYYY")
										}
										onClick={() => onPickMonth(month.toISOString())}
									/>
								);
							})}
						</Graph>
					);
				}}
			</GraphQL>
		);
	}
}
