import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import Request from "common/Request";
import FormWorker from "./Zoho/FormWorker";

export default class Zoho extends React.Component {
	state = {
		syncingWorkers: false,
		syncingWorker: false,
	};

	syncWorkers = async ({ recent } = {}) => {
		this.setState({ syncingWorkers: true });
		await Request.mutation(
			/* GraphQL */ `
				mutation($recent: Boolean) {
					zohoSyncWorkers(recent: $recent) {
						success
					}
				}
			`,
			{
				recent,
			},
		);
	};

	syncWorker = async ({ workerId } = {}) => {
		this.setState({ syncingWorker: true });
		await Request.mutation(
			/* GraphQL */ `
				mutation($workerId: ID) {
					zohoSyncWorker(workerId: $workerId) {
						success
					}
				}
			`,
			{ workerId },
		);
	};

	render() {
		const { syncingWorkers, syncingWorker } = this.state;
		return (
			<div>
				<Container>
					<Button onClick={() => this.syncWorkers()} loading={syncingWorkers}>
						Synchroniser Zoho Recruit
					</Button>
					<Button
						onClick={() => this.syncWorkers({ recent: true })}
						loading={syncingWorkers}
					>
						Synchroniser Zoho Recruit (Workers récents uniquement)
					</Button>
				</Container>
				<Container>
					<FormWorker onSubmit={this.syncWorker} loading={syncingWorker} />
				</Container>
			</div>
		);
	}
}

const Container = styled.div`
	display: flex;
	align-items: flex-start;

	& > * {
		margin: 40px;
	}
`;
