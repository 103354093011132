import React, { Component } from "react";
import styled, { css } from "styled-components";
import Periods from "./Periods";
import getWeekPeriods from "./getWeekPeriods";
import moment from "moment";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import { color } from "common/styles";
import { addModal } from "architecture/Modals";
import NewQuery from "modals/NewQuery";
import { isMobile, isTablet } from "common/styles";

export default class Week extends Component {
	start = (week = this.props.week) => moment(week).startOf("week");

	getDays = () => {
		const start = this.start();
		return getWeekPeriods(start)(this.props).map((periods, index) => ({
			periods,
			date: moment(start).add(index, "day"),
		}));
	};

	renderDay = (day, index) => {
		const { allowNewQueries } = this.props;

		return <Day key={index} {...day} allowNewQueries={allowNewQueries} />;
	};

	render() {
		if (isTablet()) {
			return <div>{this.getDays().map(this.renderDay)}</div>;
		}
		return <Container>{this.getDays().map(this.renderDay)}</Container>;
	}
}

class Day extends Component {
	render() {
		const { date, periods, allowNewQueries } = this.props;

		return isMobile() && periods.length === 0 ? null : (
			<DayContainer>
				<Header>
					<WeekDay>{capitalizeFirstLetter(date.format("dddd"))}</WeekDay>
					<FullDate>{date.format("D/M")}</FullDate>
				</Header>
				<Content>
					<Periods periods={periods} extended />
					{moment(date).hour(23) > moment() &&
					!isMobile() &&
					allowNewQueries ? (
						<AddQuery
							onClick={() =>
								addModal(({ dismiss }) => (
									<NewQuery initialDate={date.toDate()} dismiss={dismiss} />
								))
							}
						>
							Les nouvelles demandes ne seront pas traitées
						</AddQuery>
					) : null}
				</Content>
			</DayContainer>
		);
	}
}

const border = css`solid 1px rgb(230, 230, 230)`;

const AddQuery = styled.div`
	display: flex;
	flex: 1;
	min-height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	color: transparent;
	font-size: 16px;
	text-align: center;
	cursor: pointer;
	background: transparent;
	transition: all 500ms ease;
	&:hover {
		color: ${color("primary")};
		background: #ff0000;
	}
`;

const Container = styled.div`
	flex: 1;
	display: flex;
	align-items: stretch;
	margin-top: 20px;
`;

const DayContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	&:hover {
		z-index: 1;
	}
	&:last-child {
		> div:last-child {
			border-right: ${border};
		}
	}
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-bottom: ${isMobile() ? "none" : border};
	padding-bottom: 10px;
`;

const WeekDay = styled.div`
	font-weight: 500;
`;

const FullDate = styled.div`
	font-size: 12px;
`;

const Content = styled.div`
	border-left: ${isMobile() ? "none" : border};
	background-color: ${isMobile() ? "transparent" : "rgb(248, 248, 248)"};
	flex: 1;
	display: flex;
	flex-direction: column;
	border-right: 1px solid #fff;
	transition: all 500ms ease;
	padding-bottom: ${isMobile ? 20 : 0}px;
`;
