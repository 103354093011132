import React, { Component } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { Radio } from "@material-ui/core";
import PropTypes from "prop-types";

const Checkbox = ({ name, checked, onChange }) => (
	<div class="checkbox-input-label">
		<Radio color="default" checked={checked} onChange={onChange} />
		{name}
	</div>
);

Checkbox.propTypes = {
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default class extends Component {
	state = {
		value: null,
		freeText: "",
		isFreeText: false,
	};

	setValue(value, { isFreeText = false }) {
		const { input, onChange } = this.props;

		if (isFreeText) {
			this.setState({ freeText: value });
		}
		this.setState({ value, isFreeText });
		input ? input.onChange(value) : onChange(value);
	}

	render() {
		const { labels, textFieldLabel, title } = this.props;
		const { isFreeText } = this.state;
		return (
			<CheckboxForm>
				{title ? <strong>{title}</strong> : null}
				{labels.map(label => {
					return (
						<Checkbox
							name={label}
							checked={label === this.state.value && !isFreeText}
							onChange={() => {
								this.setValue(label, { isFreeText: false });
							}}
						/>
					);
				})}
				<div class="checkbox-input-label">
					<Radio color="default" checked={isFreeText} />
					<TextField
						onFocus={() => {
							this.setValue(this.state.freeText, { isFreeText: true });
						}}
						onChange={event => {
							this.setValue(event.target.value, { isFreeText: true });
						}}
						placeholder={textFieldLabel || "Autre"}
						value={this.freeText}
					/>
				</div>
			</CheckboxForm>
		);
	}
}

const CheckboxForm = styled.div`
	strong {
		margin-top: 5px;
		display: block;
	}

	.checkbox-input-label {
		align-items: center;
		display: flex;
	}
`;
