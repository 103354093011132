import React from "react";
import JobPostsView from "./JobPosts/JobPostsView";
import JobPostsOnboarding from "./JobPosts/JobPostsOnboarding";
import InfiniteScroll from "components/InfiniteScroll";
import { ClosedJobPosts } from "models/jobPost";
import Loading from "components/Loading";
import { useOpenJobPosts } from "models/jobPost";
import { useModalOnPath } from "architecture/Modals";
import EditBDescription from "modals/EditBDescription";
import EditJobPost from "modals/JobPosts/EditJobPost";

export default ({ history }) => {
	const { loading, jobPosts: openJobPosts } = useOpenJobPosts();

	useModalOnPath({
		history,
		path: "/job-posts/",
		modals: {
			"edit-business": ({ dismiss }) => <EditBDescription dismiss={dismiss} />,
			new: ({ dismiss }) => <EditJobPost dismiss={dismiss} />,
		},
	});

	if (loading) {
		return <Loading />;
	}

	return (
		<InfiniteScroll
			query={ClosedJobPosts}
			extract={data => data.businessScope.jobPosts}
		>
			{closedJobPosts =>
				closedJobPosts.length === 0 && openJobPosts.length === 0 ? (
					<JobPostsOnboarding />
				) : (
					<JobPostsView
						openJobPosts={openJobPosts}
						closedJobPosts={closedJobPosts}
						history={history}
					/>
				)
			}
		</InfiniteScroll>
	);
};
