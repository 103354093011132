import React from "react";
import { Route, Switch } from "react-router";
import CurrentContracts from "./Admin/CurrentContracts";
import PendingQueries from "./Admin/PendingQueries";
import TrackingsToValidate from "./Admin/TrackingsToValidate";
import Operations from "./Admin/Operations";
import Zoho from "./Admin/Zoho";
import CustomNotification from "./Admin/CustomNotification";
import WorkerDocuments from "./Admin/WorkerDocuments";
import ContractsToDebrief from "./Admin/ContractsToDebrief";
import ContractsToBrief from "./Admin/ContractsToBrief";
import ContractsToPay from "./Admin/ContractsToPay";
import DebriefsHistory from "./Admin/DebriefsHistory";
import PaymentsToValidate from "./Admin/PaymentsToValidate";
import AvatarsToValidate from "./Admin/AvatarsToValidate";
import WorkersToReview from "./Admin/WorkersToReview";
import LogAsB from "./Admin/LogAsB";
import Surveys from "./Admin/Surveys";
import AdminSkills from "./Admin/AdminSkills";
import TrackingToValidateBadge from "components/Badge/TrackingToValidate";
import WorkerDocumentsBadge from "components/Badge/WorkerDocuments";
import ContractsToDebriefBadge from "components/Badge/ContractsToDebrief";
import ContractsToBriefBadge from "components/Badge/ContractsToBrief";
import Navbar from "architecture/Navbar";

const chrMatch = [
	{
		label: "En ce moment",
		route: "",
		component: CurrentContracts,
	},
	{
		label: "Pas encore remplies",
		route: "pending",
		component: PendingQueries,
	},
	{
		label: "Pointages",
		route: "trackings-to-validate",
		component: TrackingsToValidate,
		badge: TrackingToValidateBadge,
	},
	{
		label: "Operations",
		route: "operations",
		component: Operations,
	},
	{
		label: "Documents",
		route: "worker-documents",
		component: WorkerDocuments,
		badge: WorkerDocumentsBadge,
	},
	{
		label: "À débriefer",
		route: "contracts-debrief",
		component: ContractsToDebrief,
		badge: ContractsToDebriefBadge,
	},
	{
		label: "À briefer",
		route: "contracts-to-brief",
		component: ContractsToBrief,
		badge: ContractsToBriefBadge,
	},
	{ label: "Zoho", route: "zoho", component: Zoho, drawer: true },
	{
		label: "Connexion à un compte B",
		route: "log-as-b",
		component: LogAsB,
		drawer: true,
	},
	{
		label: "Sondages",
		route: "surveys",
		component: Surveys,
		drawer: true,
	},
	{
		label: "Custom Notification",
		route: "custom-notification",
		component: CustomNotification,
		drawer: true,
	},
	{
		label: "Historique des debriefs",
		route: "debriefs-history",
		component: DebriefsHistory,
		drawer: true,
	},

	{
		label: "Envoi d'acomptes",
		route: "contracts-to-pay",
		component: ContractsToPay,
		drawer: true,
	},
	{
		label: "Validation d'acomptes",
		route: "payments-to-validate",
		component: PaymentsToValidate,
		drawer: true,
	},
	{
		label: "Validation des avatars",
		route: "avatars-to-validate",
		component: AvatarsToValidate,
		drawer: true,
	},
	{
		label: "Evaluation des workers",
		route: "workers-to-review",
		component: WorkersToReview,
		drawer: true,
	},
	{
		label: "Compétences",
		route: "skills",
		component: AdminSkills,
		drawer: true,
	},
];

const chrRoutes = chrMatch.map(m => ({ ...m, route: `/${m.route}` }));

export default () => (
	<Navbar routes={chrRoutes}>
		<Switch>
			<Route exact path="/operations/:type/:id" component={Operations} />
			<Route exact path="/workers-to-review/:id" component={WorkersToReview} />
			{chrRoutes.map(m => (
				<Route key={m.route} exact path={m.route} component={m.component} />
			))}
		</Switch>
	</Navbar>
);
