import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import { color } from "common/styles";
import DefaultQuery from "./DefaultQueries/DefaultQuery";
import { maxRate } from "common/constants";

const copyArray = array => array.map(field => ({ ...field }));

export default {
	label: "Métiers",
	fields: ({ specifiesGrossRate }) => ({
		defaultQueries: [
			{
				jobKey: { label: "Métier" },
				comment: { label: "Brief" },
				...(specifiesGrossRate
					? {
							conditions: [
								{
									grossRate: {
										label: "grossRate",
										checkField: ({ grossRate }) =>
											grossRate && grossRate <= maxRate,
									},
									billingRate: { label: "billingRAte" },
								},
							],
					  }
					: {
							rate: { label: "rate" },
					  }),
			},
		],
	}),
	Component: ({ updateValue, value, jobs }) => {
		const {
			defaultQueries,
			specifiesGrossRate,
			foodCompensatoryAllowance,
			defaultBreakMinutes,
		} = value;
		const updateFieldValue = (fieldValue, field) =>
			updateValue({ ...value, [field]: fieldValue });
		const deleteOne = index => {
			const defaultQueriesCopy = copyArray(defaultQueries);
			defaultQueriesCopy.splice(index, 1);
			updateFieldValue(defaultQueriesCopy, "defaultQueries");
		};

		const addOne = () => {
			const defaultQueriesCopy = copyArray(defaultQueries);
			defaultQueriesCopy.push({ conditions: [] });
			updateFieldValue(defaultQueriesCopy, "defaultQueries");
		};
		if (defaultQueries.length === 0) {
			addOne();
		}
		if (specifiesGrossRate === null) {
			return (
				<SubContainer>
					<ButtonContainer>
						<StyledButton
							onClick={() => updateFieldValue(false, "specifiesGrossRate")}
						>
							Utiliser des taux horaires NET
						</StyledButton>
						<StyledButton
							onClick={() => updateFieldValue(true, "specifiesGrossRate")}
						>
							Utiliser des taux horaires BRUT
						</StyledButton>
					</ButtonContainer>
				</SubContainer>
			);
		}
		return (
			<>
				{defaultQueries.map((defaultQuery, index) => (
					<React.Fragment key={index}>
						<DefaultQuery
							updateDefaultQuery={(fieldValue, field) => {
								const defaultQueriesCopy = copyArray(defaultQueries);
								defaultQueriesCopy[index][field] = fieldValue;
								updateFieldValue(defaultQueriesCopy, "defaultQueries");
							}}
							defaultQuery={defaultQuery}
							specifiesGrossRate={specifiesGrossRate}
							foodCompensatoryAllowance={foodCompensatoryAllowance}
							jobs={jobs}
							defaultBreakMinutes={defaultBreakMinutes}
						/>
						{defaultQueries.length > 1 ? (
							<DeleteButton
								onClick={() => {
									deleteOne(index);
								}}
							>
								Suprimer métier
							</DeleteButton>
						) : null}
					</React.Fragment>
				))}
				<Button onClick={() => addOne()}>Ajouter un métier</Button>
			</>
		);
	},
};

const SubContainer = styled.div`
	border: 1px solid ${color("black", "pale")};
	padding: 10px;
	display: flex;
	flex-direction: column;
	margin: 5px 0;
	background-color: ${color("white")};
`;

const DeleteButton = styled(Button)`
	justify-content: right;
`;

const ButtonContainer = styled.div`
	display: flex;
`;
const StyledButton = styled(Button)`
	margin: 10px;
`;
