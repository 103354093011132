import * as React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { color, mobileBreakpoint } from "common/styles";

const NavbarLink = styled(NavLink)`
	color: #fff;
	font-weight: bold;
	font-size: 16px;
	text-decoration: none;
	padding-right: 10px;
	padding-left: 10px;
	height: 53px;
	display: inline-block;
	position: relative;
	line-height: 53px;
	transition: opacity 500ms ease;
	opacity: ${props => (props.active ? 1 : 0.7)};
	&::after {
		content: " ";
		display: block;
		position: absolute;
		background: ${color("action")};
		height: 2px;
		width: 100%;
		bottom: 0;
		left: 0;
		transform: scaleX(0);
		opacity: 0;
		transition: all 500ms ease;
	}
	&:hover,
	&.selected {
		color: #fff;
		opacity: 1;
		&::after {
			opacity: 1;
			transform: scaleX(1);
		}
	}
	@media screen and (max-width: ${mobileBreakpoint}px) {
		display: none;
	}
`;

export default ({ routes }) => (
	<div>
		{routes
			.filter(r => !r.drawer)
			.map(route => (
				<NavbarLink
					key={route.route}
					exact
					activeClassName="selected"
					to={route.route}
				>
					{route.badge ? <route.badge /> : ""}
					{route.label}
				</NavbarLink>
			))}
	</div>
);
