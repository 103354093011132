export const getAlert = state => {
	const {
		alerts: [alert],
		mounted,
	} = state.alerts;
	if (alert) {
		return {
			...alert,
			mounted,
		};
	}
	return null;
};
