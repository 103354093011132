import axios from "axios";

const apiURL = "https://entreprise.data.gouv.fr/api/sirene/v1/siret/";

export default async ({ siret, updateBusiness, business }) => {
	const data = (await axios.get(`${apiURL}${siret.trim()}`)).data.etablissement;
	updateBusiness({
		...business,
		location: {
			...business.location,
			streetName: data.l4_normalisee,
			postCode: data.code_postal,
			city: data.libelle_commune,
			country: "France",
		},
		nafCode: data.activite_principale,
		company: {
			...business.company,
			siret,
			name: data.nom_raison_sociale,
			address: {
				...business.company.address,
				streetName: data.l4_normalisee,
				postCode: data.code_postal,
				city: data.libelle_commune,
				country: "France",
			},
		},
	});
};
