import axios from "axios";
const url = "https://api.pipedrive.com/v1/";

const typeStages = [
	{ stageName: "Client Gofer", stages: [5, 6, 8, 19, 21, 25, 24, 23, 26, 27] },
	{
		stageName: "Action commerciale en cours",
		stages: [7, 3, 18],
	},
	{
		stageName: "Pas d'action commerciale en cours",
		stages: [17, 2, 28, 31, 29, 30],
	},
];

export default async (search, pipedriveApiToken) => {
	let organizationsRaw = await axios.get(`${url}organizations/find`, {
		params: {
			term: search,
			limit: "20",
			start: "0",
			api_token: pipedriveApiToken,
		},
	});
	organizationsRaw = organizationsRaw.data.data;
	if (!organizationsRaw) {
		return [];
	}

	const organizations = [];
	for (const organizationRaw of organizationsRaw) {
		let deals = await axios.get(
			`${url}organizations/${organizationRaw.id}/deals`,
			{
				params: {
					start: "0",
					api_token: pipedriveApiToken,
				},
			},
		);
		deals = deals.data.data;
		organizations.push({
			name: organizationRaw.name,
			organizationId: organizationRaw.id,
			contacts: [],
			stages: [],
			owners: deals ? deals.map(deal => deal.owner_name) : null,
		});
		const organization =
			organizations[organizationsRaw.indexOf(organizationRaw)];
		let persons = await axios.get(
			`${url}organizations/${organizationRaw.id}/persons`,
			{
				params: {
					start: "0",
					api_token: pipedriveApiToken,
				},
			},
		);
		persons = persons.data.data;
		const fonctionKey = "4db2426384ccfa8eee37d44838f1840b8bcdf4d6";
		if (persons) {
			persons.map(person =>
				organization.contacts.push({
					name: person.name,
					mail: person.email.map(email => email.value).join(" ou "),
					phone: person.phone.map(phone => phone.value).join(" ou "),
					fonction: person[fonctionKey],
				}),
			);
		}
		if (deals) {
			deals.forEach(deal => {
				typeStages.forEach(typeStage => {
					if (typeStage.stages.includes(deal.stage_id)) {
						organization.stages.push(typeStage.stageName);
					}
				});
			});
		}
	}

	return organizations;
};
