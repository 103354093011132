import React from "react";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";
import moment from "moment";

export default ({ isBadge, children, noPoll, hideLoading }) => (
	<GraphQL
		hideLoading={hideLoading}
		noPoll={noPoll}
		query={GetContractsToDebrief({ isBadge })}
		variables={GetContractsToDebriefVars}
	>
		{({ admin: { contractsToDebrief } }) => children(contractsToDebrief)}
	</GraphQL>
);

export const GetContractsToDebrief = ({ isBadge } = {}) => gql`
  query contractsToDebrief($ignoreBefore: DateTime, $now: DateTime) {
    admin {
      contractsToDebrief(
        startAfter: $ignoreBefore
        endBefore: $now
        isGofer: false
      ) {
        id
        ${
					isBadge
						? ""
						: `
        getWorker {
          id
          name
          phoneNumber
        }
        getBusiness {
          id
          name
          owner {
            id
            phoneNumber
          }
          salesPerson {
            id
            name
          }
        }
        startTime
        shiftsDescription
        getJob {
          name
          key
        }
        matching {
          id
          debrief {
            worker {
              score
            }
            business {
              score
            }
          }
          businessContactedForDebrief
          businessContactedForDebriefBy {
            name
          }
          businessDebriefContactedAt
        }`
				}
      }
    }
  }
`;

export const GetContractsToDebriefVars = {
	ignoreBefore: moment()
		.startOf("day")
		.subtract(14, "days"),
	now: moment().startOf("hour"),
};
