import React from "react";
import Calendar from "./Business/Calendar";
import Trackings from "./Business/Trackings";
import Feedbacks from "./Business/Feedbacks";
import Budget from "./Business/Budget";
import Survey from "./Business/Prompts/Survey";
import Weathercast from "./Business/Prompts/Weathercast";
import LateTrackings from "./Business/Prompts/LateTrackings";
import { Switch, Route, Redirect } from "react-router";
import { withTracker } from "utils/withTracker";
import FeedbacksBadge from "./Business/Badges/FeedbacksBadge";
import TrackingsBadge from "./Business/Badges/TrackingsBadge";
import JobPosts from "./Business/JobPosts";
import useCurrentUser from "common/useCurrentUser";
import Navbar from "architecture/Navbar";
import SplitNav from "./Business/SplitNav";
import { isMobile } from "common/styles";
import Loading from "components/Loading";

const TrackedCalendar = withTracker(Calendar);
const TrackedTrackings = withTracker(Trackings);

const Routes = [
	{
		label: "Planning",
		route: "/planning/home",
	},
	{
		label: "Pointages",
		route: "/trackings",
		component: TrackedTrackings,
		badge: TrackingsBadge,
	},
	{
		label: "Feedbacks",
		route: "/feedbacks",
		component: withTracker(Feedbacks),
		badge: FeedbacksBadge,
	},
	{
		label: "🔒 Votre application change. Rendez-vous sur webapp.gofer.fr 🔒",
		route: "",
	},
];

const Prompts = () => (
	<React.Fragment>
		<Survey />
		<Weathercast />
		<LateTrackings />
	</React.Fragment>
);

const BusinessRoutes = () => {
	const currentUser = useCurrentUser();

	return (
		<Switch>
			<Route exact path="/" render={() => <Redirect to="planning/home" />} />
			<Route exact path="/planning/:queryId" component={TrackedCalendar} />
			<Route exact path="/trackings/:queryId" component={TrackedTrackings} />
			{Routes.map(m => (
				<Route
					key={m.route}
					exact
					path={m.route}
					component={m.component}
					render={m.render}
				/>
			))}

			{currentUser.business.canCreateJobPosts ? (
				<React.Fragment>
					<Route exact path="/job-posts/job-post/:id" component={JobPosts} />
					<Route exact path="/job-posts/:modal" component={JobPosts} />
					<Route exact path="/job-posts" component={JobPosts} />
				</React.Fragment>
			) : null}
		</Switch>
	);
};

const Component = () => {
	const currentUser = useCurrentUser();

	if (!currentUser.business) {
		console.error(`User ${currentUser.id} has no business`);
		return <Loading />;
	}

	if (currentUser.business.canCreateJobPosts) {
		return (
			<SplitNav
				left={{
					label: "Intérim",
					route: "/planning/home",
					links: Routes,
				}}
				right={{
					label: isMobile() ? "Recruter" : "Recrutement",
					route: "/job-posts",
					links: [
						{
							label: "Toutes les offres",
							route: "/job-posts",
						},
						{
							label: "Mon établissement",
							route: "/job-posts/edit-business",
						},
						...(currentUser.business.description
							? [
									{
										label: "Nouvelle offre",
										route: "/job-posts/new",
									},
							  ]
							: []),
					],
				}}
			>
				<BusinessRoutes />
				<Prompts />
			</SplitNav>
		);
	}

	return (
		<React.Fragment>
			<Navbar routes={Routes}>
				<BusinessRoutes />
			</Navbar>
			<Prompts />
		</React.Fragment>
	);
};

export const business = { Component, Routes };
