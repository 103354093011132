import React from "react";
import { Pill, PillsList } from "components/Pills";
import { categories } from "models/skills";

export default ({ name, category, jobs }) => (
	<div class="grid">
		<article class="eight from-three card col">
			<h1>{name}</h1>
			<div>
				<strong>{categories[category]}</strong>
			</div>
			<PillsList>
				{jobs.map(j => (
					<Pill>{j.name}</Pill>
				))}
			</PillsList>
		</article>
	</div>
);
