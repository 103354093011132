import moment from "moment";
import gql from "graphql-tag";
import formatCurrency from "utils/formatCurrency";
import Request from "common/Request";

const LEGAL_CONDITIONS_AT = gql`
	query legalConditionsAt($date: DateTime!) {
		legalConditions(at: $date) {
			minGrossRate
		}
	}
`;

const Legal = {
	async at(date) {
		const key = moment(date).toISOString();
		const legalConditions = Legal.legalConditions.get(key);
		if (legalConditions) {
			return legalConditions;
		}
		const data = await Request.query(LEGAL_CONDITIONS_AT, {
			variables: { date },
		});
		Legal.legalConditions.set(key, data.legalConditions);
		return data.legalConditions;
	},
	legalConditions: new Map(),
};

export default async ({ validFrom, grossRate }) => {
	const { minGrossRate } = await Legal.at(validFrom);
	if (grossRate < minGrossRate) {
		throw new Error(
			`GoferDisplayableError: Quelque chose est mal configuré : le taux horaire brut ${formatCurrency(
				grossRate,
			)} est en dessous du seuil légal de ${formatCurrency(
				minGrossRate,
			)} au ${moment(validFrom).format("ll")}`,
		);
	}
};
