import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const BusinessQuery = gql`
	query BusinessJobs($businessId: ID!) {
		business(id: $businessId) {
			id
			name
			owner {
				defaultQueries {
					job {
						key
						name
					}
					rate
				}
			}
		}
	}
`;

export const useBusinessJobs = businessId => {
	const { loading, data } = useQuery(BusinessQuery, {
		variables: { businessId },
		skip: !businessId,
	});

	if (!businessId) {
		return { jobs: undefined };
	}

	if (loading || !data) {
		return { loading, jobs: [] };
	}

	return {
		loading,
		jobs: data.business.owner.defaultQueries.map(dq => dq.job),
	};
};
