import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import { CardsList, Domino } from "components/Card";
import Trackings from "./Trackings";
import Debrief from "./Debrief";
import { mobileBreakpoint } from "common/styles";
import { statusElements } from "models/contract";
import Avatar from "components/Avatar";
import formatPhoneNumber from "utils/formatPhoneNumber";

export default ({ contracts = [] }) => (
	<CardsList>
		{contracts.map(contract => (
			<Contract key={contract.id} {...contract} />
		))}
	</CardsList>
);

const Contract = contract => {
	const {
		getWorker: { name, firstName, phoneNumber, mediumAvatarURL } = {},
		onCancel,
		status,
	} = contract;
	const { color, label } = statusElements[status];
	return (
		<ContractContainer>
			<div className="light-half">
				<Name status={status}>{name}</Name>
				<Avatar image={mediumAvatarURL} />
				{phoneNumber ? (
					<PhoneNumber href={`tel:${phoneNumber}`}>
						{formatPhoneNumber(phoneNumber)}
					</PhoneNumber>
				) : null}

				{onCancel ? (
					<Button secondary className="outline" onClick={onCancel}>
						Annuler
					</Button>
				) : null}
			</div>
			<StatusBar background={color} />
			<ContractActions className="dark-half">
				<Debrief {...contract} />
				<h3>
					{label}
					{["pending worker tracking", "tracked"].includes(status)
						? ` par ${firstName}`
						: ""}
				</h3>
				<Trackings {...contract} />
			</ContractActions>
		</ContractContainer>
	);
};

const ContractContainer = styled(Domino)`
	width: 100%;
	.light-half {
		width: 175px;
	}
	.dark-half {
		width: 100%;
	}
	@media screen and (max-width: ${mobileBreakpoint}px) {
		flex-direction: column;
		.light-half,
		.dark-half {
			width: 100%;
		}
	}
`;

const ContractActions = styled.div`
	label {
		color: rgba(255, 255, 255, 0.75);
	}
	h3 {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		padding-top: 35px;
		text-align: center;
		padding-bottom: 35px;
		margin-top: 15px;
	}
`;

const Name = styled.h3`
	margin-bottom: 10px;
	text-align: center;
`;

const PhoneNumber = styled.a`
	user-select: all;
	text-decoration: none;
	font-weight: bold;
	display: block;
	margin-top: 10px;
`;

const StatusBar = styled.div`
	width: 18px;
	background-color: ${({ background }) => background};
	@media screen and (max-width: ${mobileBreakpoint}px) {
		height: 18px;
		width: 100%;
	}
`;
