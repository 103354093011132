import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "../steps/customFields/Select";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Rate from "components/Rate";
import Percentage from "components/Percentage";
import Int from "components/Int";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

const numberComponents = {
	rate: Rate,
	percentage: Percentage,
	int: Int,
};

const AutoFields = ({ value, updateValue, fields, ...props }) => (
	<Container>
		{Object.entries(fields).map(
			([
				field,
				{ label, custom: Custom, type, multiline, optional, selectorType },
			]) => {
				const updateFieldValue = fieldValue =>
					updateValue({ ...value, [field]: fieldValue });
				if (Custom) {
					return (
						<Line key={field}>
							<Custom
								parent={value}
								updateParent={updateValue}
								value={value[field]}
								updateValue={updateFieldValue}
								{...props}
							/>
						</Line>
					);
				}
				if (label) {
					const formatedLabel = label + (optional ? "" : " *");
					if (type === "checkBox") {
						return (
							<Line key={field}>
								<FormControl>
									<FormLabel component="legend">{formatedLabel}</FormLabel>
									<FormGroup row>
										<FormControlLabel
											key={`${field}yes`}
											control={
												<Checkbox
													checked={value[field] === true || false}
													onChange={() => updateFieldValue(true)}
												/>
											}
											label="Oui"
										/>
										<FormControlLabel
											key={`${field}no`}
											control={
												<Checkbox
													checked={value[field] === false || false}
													onChange={() => updateFieldValue(false)}
												/>
											}
											label="Non"
										/>
									</FormGroup>
								</FormControl>
							</Line>
						);
					}

					if (numberComponents[type]) {
						const Component = numberComponents[type];
						return (
							<Line key={field}>
								<Component
									fullWidth
									input={{
										value: value[field] || "",
										name: field,
										onChange: amount => updateFieldValue(amount),
									}}
									meta={{ error: false, dirty: false }}
									label={formatedLabel}
								/>
							</Line>
						);
					}
					if (type === "selector") {
						const options = props[selectorType] || [];
						return (
							<Select
								key={field}
								value={value[field] || ""}
								updateValue={updateFieldValue}
								label={formatedLabel}
								options={options.map(({ name }) => name)}
							/>
						);
					}
					return (
						<Line key={field}>
							<TextField
								fullWidth
								type={type || "text"}
								label={formatedLabel}
								multiline={multiline}
								rows={multiline ? 4 : 1}
								onChange={event => updateFieldValue(event.target.value)}
								value={value[field] || (value[field] === 0 ? 0 : "")}
								variant={multiline ? "outlined" : "standard"}
								margin="normal"
							/>
						</Line>
					);
				}
				return (
					<Line key={field}>
						<AutoFields
							value={value[field]}
							updateValue={updateFieldValue}
							{...props}
							fields={fields[field]}
						/>
					</Line>
				);
			},
		)}
	</Container>
);

export default AutoFields;

const Line = styled.div`
	display: flex;
	flex: 1;
`;

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
