import React from "react";
import { PillsList, Pill } from "components/Pills";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const jobsList = gql`
	{
		jobs {
			key
			name
		}
	}
`;

export default ({ onAdd, onRemove, jobs }) => (
	<PillsList>
		{jobs.map(job => (
			<Pill key={job.key} onDelete={() => onRemove(job)}>
				{job.name}
			</Pill>
		))}
		<GraphQL query={jobsList}>
			{({ jobs }) => (
				<Select
					value="default"
					onChange={e => onAdd(jobs.find(j => j.key === e.target.value))}
				>
					<MenuItem value="default" disabled>
						Ajouter un métier
					</MenuItem>
					{jobs
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(job => (
							<MenuItem value={job.key} key={job.key}>
								{job.name}
							</MenuItem>
						))}
				</Select>
			)}
		</GraphQL>
	</PillsList>
);
