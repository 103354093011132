import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import { FaPen } from "react-icons/fa";
import { addModal } from "architecture/Modals";
import ExperienceContact from "./ExperienceContact";
import { PillsList, Pill } from "components/Pills";
import Button from "components/Button";
import EditWorkerExperience from "modals/ReferenceValidator/EditWorkerExperience";
import { color } from "common/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { experienceStatusList, removeXp } from "models/worker/resume";
import { confirm } from "components/Lightbox";

const ContactsList = styled.table`
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	background-color: #f8f8f8;
	td {
		padding: 10px;
	}
`;

const EditIcon = styled(FaPen)`
	transition: color 500ms ease;
	color: ${color("action")};
	margin-right: 5px;
	height: 0.8em;
	width: 0.8em;
`;

const Loader = styled(CircularProgress)`
	color: ${color("action")};
	opacity: ${props => (props.loading === "true" ? 1 : 0)};
	margin-left: 7px;
	vertical-align: middle;
`;

const formatDate = date => moment(date).format("MMMM YYYY");

export default class Experience extends React.Component {
	state = {
		loading: false,
	};

	async updateStatus(e) {
		const { onChange } = this.props;

		this.setState({ loading: true });
		onChange &&
			(await onChange({
				...this.props,
				status: e.target.value,
			}));
		this.setState({ loading: false });
	}

	async saveContact(newContact) {
		const { onChange, referrals } = this.props;

		onChange &&
			(await onChange({
				...this.props,
				referrals: referrals.map(r =>
					r.id === newContact.id ? newContact : r,
				),
			}));
	}

	async removeXp() {
		const { id, dismiss, currentWorker } = this.props;

		const answer = await confirm(
			"Voulez-vous vraiment supprimer cette expérience ?",
		);
		if (!answer) {
			return;
		}

		this.setState({ loading: true });
		await removeXp(currentWorker, id);
		this.setState({ loading: false });
		dismiss && dismiss();
	}

	render() {
		const {
			businessName,
			startDate,
			endDate,
			jobs,
			referrals,
			description,
			status,
		} = this.props;

		const { loading } = this.state;

		if (!businessName && !jobs.length) {
			return null;
		}

		return (
			<div class="col">
				<div class="row">
					<div>
						<div>
							<strong>{businessName}</strong> de {formatDate(startDate)} à{" "}
							{!endDate ? "aujourd'hui" : formatDate(endDate)}
						</div>
						<div>{description}</div>
						<div>
							<Select value={status} onChange={e => this.updateStatus(e)}>
								{experienceStatusList.map(status => (
									<MenuItem key={status.key} value={status.key}>
										{status.name}
									</MenuItem>
								))}
							</Select>
							<Loader loading={loading.toString()} />
						</div>
						<PillsList>
							{jobs.map(job => (
								<Pill key={job.key}>{job.name}</Pill>
							))}
						</PillsList>
					</div>
					<div class="right">
						<Button
							className="outline right"
							secondary
							onClick={() =>
								addModal(({ dismiss }) => (
									<EditWorkerExperience dismiss={dismiss} {...this.props} />
								))
							}
						>
							<EditIcon />
							Modifier
						</Button>
						<Button className="outline right" onClick={() => this.removeXp()}>
							Supprimer
						</Button>
					</div>
				</div>

				<section>
					<ContactsList>
						<tbody>
							{referrals.map((contact, i) => (
								<ExperienceContact
									key={i}
									{...contact}
									onContactChange={async newContact =>
										await this.saveContact(newContact)
									}
								/>
							))}
						</tbody>
					</ContactsList>
				</section>
			</div>
		);
	}
}
