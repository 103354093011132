import Request from "common/Request";
import gql from "graphql-tag";

const businessesMatchingRegex = gql`
	query businesses($limit: Int, $orderBy: BusinessesOrderBy, $regex: String) {
		admin {
			businesses(orderBy: $orderBy, limit: $limit, regex: $regex) {
				id
				name
			}
		}
	}
`;

export default async regex => {
	const {
		admin: { businesses },
	} = await Request.query(businessesMatchingRegex, {
		variables: { limit: 10, regex, orderBy: "name" },
	});
	return businesses;
};
