import React, { useState, useMemo } from "react";
import { uniqueId } from "lodash";
import { notify } from "components/Loading";
import ErrorMessage from "./NewQuery/ErrorMessage";
import LegacyNewQueryForm from "./NewQuery/Form";
import NewQueryForm from "./NewQuery/NewQueryForm";
import Request from "common/Request";
import { DEV } from "common/constants";
import reasons from "common/reasons";
import { defaultShift } from "./NewQuery/Shifts";
import { addQuery, queryProperties } from "models/queries/Planning";
import { useDirectContractDraft } from "models/contract";
import useJobs from "common/useJobs";

export const createQuery = (
	{
		rate,
		location,
		shifts,
		groupId,
		reason,
		justification,
		replacementJustification,
		network,
		jobKey,
		comment,
		maxWorkers,
		workerId,
		businessDelegatesSelectionToGofer,
		dossier,
		reference,
	},
	{ jobs, directContract },
) => {
	if (reason === reasons.replacement && replacementJustification) {
		const { firstName, lastName, absenceReason } = replacementJustification;
		const jobName = jobs.find(job => job.key === jobKey).name;
		justification = `REMPLACEMENT POUR PARTIE DES TACHES DE : ${firstName} ${lastName}, POUR : ${absenceReason}, QUALIFICATION: ${jobName}`;
	}
	return {
		jobKey,
		rate,
		groupId,
		location,
		shifts: shifts.map(({ breakMinutes, startTime, endTime }) => ({
			breakMinutes,
			startTime,
			endTime,
		})),
		reason,
		justification,
		comment,
		dossier,
		reference,
		...(directContract
			? { workerId }
			: {
					maxWorkers,
					network,
					businessDelegatesSelectionToGofer,
			  }),
	};
};

export default props => {
	const [error, setError] = useState("");
	const { contract, setQuery } = useDirectContractDraft();
	const jobs = useJobs();
	const { initialDate, informations } = props;

	const submit = async queryParams => {
		const { workerId } = queryParams;

		const workerIds = Array.isArray(workerId) ? workerId : [workerId];
		const groupId = workerIds.length > 1 ? uniqueId() : undefined;

		try {
			await Promise.all(
				workerIds.map(workerId => {
					return Request.mutation(
						/* GraphQL */ `
						mutation businessNewQuery($query: QueryInput!, $directContract: Boolean) {
							newQuery(query: $query, directContract: $directContract) {
								${queryProperties}
							}
						}
					`,
						{
							query: createQuery({ ...queryParams, workerId, groupId }, props),
							directContract: props.directContract,
						},
					).then(({ newQuery }) => {
						addQuery(newQuery);
					});
				}),
			);
			props.dismiss();
			console.log("User created query");
			notify("La demande a été envoyée avec succès !");
		} catch (error) {
			console.log(error);
			DEV && console.error(error);

			const { message } = error;
			const result = /GoferDisplayableError: (.+)$/.exec(message);
			setError(
				result ? result[1] : "Une erreur s'est produite. Veuillez réessayer.",
			);
		}
	};

	const Form = useMemo(
		() => (props.adminNewContract ? LegacyNewQueryForm : NewQueryForm),
		[props.adminNewContract],
	);

	return (
		<div class="desktop-grid">
			<article class="card six from-four col">
				{informations ? informations() : null}
				<ErrorMessage error={error} />
				<Form
					contractPreview={props.showPreview ? contract : undefined}
					jobs={jobs}
					onChange={newQuery => setQuery(createQuery(newQuery, props))}
					onSubmit={submit}
					shifts={[defaultShift(initialDate)]}
					{...props}
				/>
			</article>
		</div>
	);
};
