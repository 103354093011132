import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import SelectJob from "components/SelectJob";
import SelectSkills from "components/SelectSkills";
import { useEditJobPost } from "models/jobPost";
import Button from "components/Button";
import Loading from "components/Loading";

export default ({ dismiss, jobPost }) => {
	const {
		jobPost: job,
		setJobPost,
		saveJobPost,
		loading,
		isValid,
	} = useEditJobPost(jobPost);

	if (loading) {
		return <Loading />;
	}

	return (
		<div class="desktop-grid">
			<div class="col eight from-three">
				<article class="card">
					<section>
						<h1>{job.id ? job.title : "Nouvelle offre fixe"}</h1>
					</section>
					<section>
						<FormControl fullWidth={true}>
							<TextField
								defaultValue={job.title}
								onChange={event => setJobPost({ title: event.target.value })}
								fullWidth={true}
								label="Intitulé de l'offre"
								required={true}
							/>
						</FormControl>
						<SelectJob onChange={job => setJobPost({ job })} job={job.job} />
					</section>
					<section>
						<div class="row">
							<TextField
								multiline={true}
								fullWidth={true}
								variant="outlined"
								rows={8}
								required={true}
								defaultValue={job.description}
								onChange={e => setJobPost({ description: e.target.value })}
								placeholder="Décrivez le poste : responsabilités, le style de votre établissement, tout ce que vous recherchez chez le candidat parfait !"
							/>
						</div>
					</section>
					<section>
						<SelectSkills
							jobKey={job.job ? job.job.key : ""}
							skills={job.skills}
							onChange={skills => setJobPost({ skills })}
						/>
					</section>
					<section>
						<h4>Horaires</h4>
						<div class="row">
							<TextField
								multiline={true}
								fullWidth={true}
								variant="outlined"
								rows={8}
								required={true}
								defaultValue={job.timesheet}
								onChange={e => setJobPost({ timesheet: e.target.value })}
								placeholder="Description des horaires : indiquez les coupures, jours de repos..."
							/>
						</div>
					</section>
					<section>
						<h4>Pré-requis pour postuler</h4>
						<TextField
							onChange={e =>
								setJobPost({
									prerequisites: [e.target.value, job.prerequisites[1]],
								})
							}
							fullWidth={true}
							label="Premier pré-requis"
							defaultValue={job.prerequisites[0]}
						/>
						<TextField
							onChange={e =>
								setJobPost({
									prerequisites: [job.prerequisites[0], e.target.value],
								})
							}
							fullWidth={true}
							label="Second pré-requis"
							defaultValue={job.prerequisites[1]}
						/>
					</section>
					<section>
						<FormControl>
							<h4>Salaire brut mensuel</h4>
							<div class="grid align-bottom">
								<div class="four">
									<TextField
										onChange={e =>
											setJobPost({
												grossMonthlyRate: e.target.value * 100,
											})
										}
										fullWidth={true}
										label="Salaire"
										type="number"
										defaultValue={job.grossMonthlyRate / 100}
									/>
								</div>
								<div class="one">
									<strong>€</strong>
								</div>
							</div>
						</FormControl>
					</section>
				</article>
				<section class="row">
					<Button secondary className="outline right" onClick={dismiss}>
						Annuler
					</Button>
					<Button
						disabled={!isValid()}
						onClick={async () => {
							await saveJobPost();
							dismiss();
						}}
					>
						{job.id ? "Enregistrer" : "Poster"} l'offre
					</Button>
				</section>
			</div>
		</div>
	);
};
