import moment from "moment";
import reasons from "common/reasons";
import formatCurrency from "utils/formatCurrency";
import { minRate, maxRate } from "common/constants.js";

const ifMissing = errorMessage => value => {
	if (value === undefined) {
		return errorMessage;
	}
};

const combine = validators => (values = {}, props = {}) => {
	const results = {};
	for (const key of Object.keys(validators)) {
		results[key] = validators[key](values[key], { ...props, values });
	}
	return results;
};

export default combine({
	workerId(workerId, { directContract }) {
		if (directContract) {
			if ((workerId || []).length === 0) {
				return "Veuillez choisir l'un des workers de votre réseau";
			}
		}
	},
	jobKey(jobKey, { jobs = [] }) {
		if (!jobKey) {
			return "Veuillez renseigner un métier";
		}
		if (!jobs.find(({ key }) => jobKey === key)) {
			return "Ce métier n'est pas dans la liste";
		}
	},
	rate(rate) {
		if (!rate) {
			return "Veuillez renseigner un taux horaire";
		}
		if (rate < minRate) {
			return `Le taux horaire minimal est ${formatCurrency(minRate)}`;
		}
		if (rate > maxRate) {
			return `Le taux horaire maximal est ${formatCurrency(maxRate)}`;
		}
	},
	reason(reason, { specifiesReason }) {
		if (specifiesReason) {
			if (!reason) {
				return "Veuillez renseigner un motif de recours à l'interim";
			}
		}
	},
	justification(justification, { specifiesReason, values: { reason } }) {
		if (specifiesReason) {
			if (!justification && reason !== reasons.replacement) {
				return "Veuillez renseigner une justification de recours à l'interim";
			}
		}
	},
	replacementJustification(
		replacementJustification,
		{ specifiesReason, values: { reason } },
	) {
		if (specifiesReason) {
			if (reason === reasons.replacement) {
				return combine({
					firstName: ifMissing("Veuillez renseigner un prénom"),
					lastName: ifMissing("Veuillez renseigner un nom"),
					absenceReason: ifMissing(
						"Veuillez renseigner une raison de l'absence",
					),
				})(replacementJustification);
			}
		}
	},
	location(location, { specifiesLocation }) {
		if (specifiesLocation) {
			return combine({
				streetName: ifMissing("Veuillez renseigner une adresse"),
				postCode: value =>
					ifMissing("Veuillez renseigner un code postal")(value) ||
					(!RegExp(/^[0-9]{5}$/).test(value)
						? "Veuillez renseigner un code postal valide"
						: undefined),
				city: ifMissing("Veuillez renseigner une ville"),
			})(location);
		}
	},
	maxWorkers(maxWorkers, { directContract }) {
		if (!directContract) {
			if (maxWorkers !== undefined && maxWorkers < 1) {
				return "Vous devez demander au moins un poste";
			}
		}
	},
	shifts(shifts = [], { adminNewContract }) {
		const makeError = (errors = {}) => ({
			date: undefined,
			timeRange: undefined,
			...errors,
		});

		return shifts.map(shift => {
			const { startTime, endTime } = shift;

			if (moment(endTime) < moment(startTime)) {
				return makeError({
					timeRange: "La fin du créneau doit être après le début du créneau",
				});
			}
			if (!adminNewContract && moment() > moment(startTime)) {
				return makeError({
					timeRange: "Un créneau ne peut pas démarrer dans le passé",
				});
			}
			return makeError();
		});
	},
});
