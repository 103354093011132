import graphql from "common/graphql";
import gql from "graphql-tag";
import Form from "scenes/Worker/Documents/Form";
import { compose, mapProps } from "recompose";

export default compose(
	mapProps(({ match }) => ({
		token: match.params.token,
	})),
	graphql(
		gql`
			query getWorkerDocuments($token: String!) {
				workerFromToken(token: $token) {
					name
					belongsToEU
					obfuscatedIBAN
					documents {
						lastReviewedAt
						files {
							name
							validatedAs
						}
						identity {
							validatedAt
							expiresAt
							number
						}
						socialSecurity {
							validatedAt
							expiresAt
						}
						bank {
							validatedAt
							bic
							accountHolder
						}
					}
				}
			}
		`,
		({ token }) => ({ variables: { token } }),
		{ requireAuthentication: false },
	),
)(Form);
