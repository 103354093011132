import React, { useState } from "react";
import AsyncSearch from "components/AsyncSearch";
import adminBusinesses from "components/AsyncSearch/adminBusinesses";

export default () => {
	const [business, setBusiness] = useState(undefined);

	const { host, protocol } = window.location;

	return (
		<article class="col expand">
			<section class="row">
				<AsyncSearch
					onSelection={business => {
						setBusiness(business);
					}}
					fetchOptions={adminBusinesses}
					placeHolder={"Se connecter au compte de..."}
				/>
			</section>
			{business && (
				<section class="expand">
					<iframe
						title="logAsBiFrame"
						src={`${protocol}//${host}?as-business=${business}`}
					/>
				</section>
			)}
		</article>
	);
};
