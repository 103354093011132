import React, { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "components/Button";
import styled from "styled-components";
import { color } from "common/styles";

export default ({ steps }) => {
	const [activeStep, setActiveStep] = useState(0);
	const { render: renderStep, beforeNext, completed } = steps[activeStep];
	const isLastStep = activeStep === steps.length - 1;
	const next = () => !isLastStep && setActiveStep(activeStep + 1);
	return (
		<div>
			<Stepper alternativeLabel nonLinear activeStep={activeStep}>
				{steps.map(({ label, completed }, index) => {
					const previousStep = steps[index - 1];
					return (
						<Step key={label}>
							<StepButton
								disabled={
									!completed &&
									index !== activeStep &&
									(!previousStep || !previousStep.completed)
								}
								onClick={() => {
									setActiveStep(index);
								}}
								completed={completed}
							>
								{label}
							</StepButton>
						</Step>
					);
				})}
			</Stepper>
			<div>
				<SubContainer>{renderStep()}</SubContainer>
				<ButtonContainer>
					<Button
						disabled={activeStep === 0}
						onClick={() => {
							setActiveStep(activeStep - 1);
						}}
					>
						Retour
					</Button>
					<Button
						asynchronous
						disabled={!completed}
						onClick={() => (beforeNext ? beforeNext(next) : next())}
					>
						{isLastStep ? "Terminer" : "Suivant"}
					</Button>
				</ButtonContainer>
			</div>
		</div>
	);
};

const SubContainer = styled.div`
	background-color: #fcfcfc;
	border: 1px solid ${color("black", "pale")};
	padding: 10px;
	display: flex;
	flex-direction: column;
	margin: 5px 0;
	border-radius: 5px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
