import React from "react";
import Table from "components/Table";
import moment from "moment";
import styled from "styled-components";
import { color } from "common/styles";
import { addAlert } from "actions/alerts";
import Request from "common/Request";
import Chip from "@material-ui/core/Chip";
import { dispatch } from "common/store";
import RequestButton from "./utils/RequestButton";

export default ({ contracts }) => {
	const columns = [
		{
			label: "Identifiant",
			getter: ({ id }) => id,
		},
		{
			label: "Business",
			getter: ({ getBusiness }) => (getBusiness ? getBusiness.name : null),
		},
		{
			label: "Worker",
			getter: ({ getWorker }) => (getWorker ? getWorker.name : null),
		},
		{
			label: "Téléphone Worker",
			getter: ({ getWorker }) => (getWorker ? getWorker.phoneNumber : null),
		},
		{
			label: "Début du contrat",
			sortGetter: ({ startTime }) => startTime,
			getter: ({ startTime, priorityColor }) => (
				<SpanBackground color={priorityColor}>
					{moment(startTime).format("Do MMM YYYY HH:mm")}
				</SpanBackground>
			),
		},
		{
			label: "Job du contrat",
			getter: ({ getJob }) => getJob.name,
		},
		{
			label: "",
			getter: ({ isFirst, getBusiness: { farmingStartedAt } }) => (
				<div>
					{!farmingStartedAt ? (
						<Chip label="Business en cours d'activation" />
					) : null}
					{isFirst ? <Chip label="Première mission" /> : null}
				</div>
			),
		},
		{
			label: "Actions",
			getter: ({
				getBusiness,
				getWorker,
				getJob,
				matching: {
					workerContactedForBrief,
					briefContactedAt,
					briefContactedBy,
				},
			}) => (
				<React.Fragment>
					{workerContactedForBrief ? (
						`${briefContactedBy.name} a pris contact ${moment(
							briefContactedAt,
						).fromNow()}`
					) : (
						<RequestButton
							label="Contacté"
							onClick={() =>
								Request.mutation(
									/* GraphQL */ `
										mutation(
											$workerId: ID!
											$businessId: ID!
											$jobKey: String!
										) {
											adminContactForBriefWorker(
												workerId: $workerId
												businessId: $businessId
												jobKey: $jobKey
											) {
												id
											}
										}
									`,
									{
										workerId: getWorker.id,
										businessId: getBusiness.id,
										jobKey: getJob.key,
									},
								)
							}
						/>
					)}
					<RequestButton
						label="Briefé"
						onClick={async () => {
							try {
								await Request.mutation(
									/* GraphQL */ `
										mutation(
											$workerId: ID!
											$businessId: ID!
											$jobKey: String!
										) {
											adminBriefWorker(
												workerId: $workerId
												businessId: $businessId
												jobKey: $jobKey
											) {
												id
											}
										}
									`,
									{
										workerId: getWorker.id,
										businessId: getBusiness.id,
										jobKey: getJob.key,
									},
								);
								dispatch(
									addAlert(
										`Le brief de ${getWorker.name} a bien été enregistré.`,
										{
											label: "Annuler",
											onClick: cancelBrief({
												worker: getWorker,
												businessId: getBusiness.id,
												jobKey: getJob.key,
											}),
										},
									),
								);
							} catch (error) {
								dispatch(
									addAlert(
										`Une erreur s'est produite et le brief de ${
											getWorker.name
										} n'a pas été enregistré`,
									),
								);
							}
						}}
					/>
				</React.Fragment>
			),
		},
	];

	return (
		<Table
			id="ContractsToBriefTable"
			columns={columns}
			rows={contracts}
			orderBy={4}
			getId={contract => contract.id}
		/>
	);
};

const cancelBrief = ({ worker, businessId, jobKey }) => async () => {
	try {
		await Request.mutation(
			/* GraphQL */ `
				mutation($workerId: ID!, $businessId: ID!, $jobKey: String!) {
					adminCancelBriefWorker(
						workerId: $workerId
						businessId: $businessId
						jobKey: $jobKey
					) {
						id
					}
				}
			`,
			{
				workerId: worker.id,
				businessId,
				jobKey,
			},
		);
		dispatch(addAlert(`Le brief de ${worker.name} a bien été annulé.`));
	} catch (error) {
		dispatch(
			addAlert(
				`Une erreur s'est produite et le brief de ${
					worker.name
				} n'a pas pu être annulé`,
			),
		);
	}
};

const SpanBackground = styled.span`
	color: ${props => (props.color ? color(props.color) : color("black"))};
`;
