const groupByBatch = (elements, length) => {
	const container = [];
	let array = [];

	for (let i = 0; i < elements.length; i++) {
		array.push(elements[i]);
		if ((i + 1) % length === 0) {
			container.push(array);
			array = [];
		}
	}
	if (array.length > 0) {
		container.push(array);
	}
	return container;
};

export default groupByBatch;
