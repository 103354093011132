import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { property } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import ReactGA from "react-ga";
import { MdClose, MdDone, MdEdit } from "react-icons/md";
import styled from "styled-components";

import Request from "common/Request";
import useCurrentUser from "common/useCurrentUser";
import Button from "components/Button";
import { Row } from "components/Card";
import Loading from "components/Loading";
import QueryAnswerStatistics from "components/QueryAnswerStatistics";
import Status from "./Status";

const DossierField = ({ query }) => {
	const { dossier } = query;

	const user = useCurrentUser();
	const businessId = useMemo(() => property("business.id")(user), [user]);
	const dossierList = useMemo(() => property("business.dossier")(user) || [], [
		user,
	]);

	const [dossierValue, setDossierValue] = useState("");
	const [isDossierEditing, setDossierEditing] = useState(false);
	const [isDossierUpdating, setDossierUpdating] = useState(false);
	const [isNewDossier, setNewDossier] = useState(false);

	const onToggleDossierEditing = useCallback(() => {
		setDossierValue("");
		setNewDossier(false);
		setDossierEditing(!isDossierEditing);
	}, [isDossierEditing]);

	const onToggleNewDossier = useCallback(() => {
		setDossierValue("");
		setNewDossier(!isNewDossier);
	}, [isNewDossier]);

	const onUpdateDossier = useCallback(async () => {
		if (!dossierValue) {
			return;
		}

		if (dossierValue === dossier) {
			onToggleDossierEditing();
			return;
		}

		try {
			setDossierUpdating(true);

			if (isNewDossier) {
				await Request.mutation(
					/* GraphQL */ `
						mutation($businessId: ID!, $dossier: String!) {
							saveDossier(businessId: $businessId, dossier: $dossier) {
								id
								dossier
							}
						}
					`,
					{
						businessId,
						dossier: dossierValue,
					},
				);
			}

			await Request.mutation(
				/* GraphQL */ `
					mutation($query: EditQueryInput!) {
						editQuery(query: $query) {
							id
							dossier
						}
					}
				`,
				{
					query: {
						id: query.id,
						dossier: dossierValue,
					},
				},
			);

			onToggleDossierEditing();
		} catch (err) {
			console.log("onUpdateDossier failure", err);
		} finally {
			setDossierUpdating(false);
		}
	}, [businessId, dossier, dossierValue, isNewDossier, onToggleDossierEditing]);

	return isDossierEditing ? (
		<FieldRow>
			<>
				{isNewDossier ? (
					<TextField
						disabled={isDossierUpdating}
						onChange={event => {
							setDossierValue(event.target.value);
						}}
						placeholder="Dossier"
						value={dossierValue}
					/>
				) : (
					<DossierSelect
						disabled={isDossierUpdating}
						onChange={e => {
							setDossierValue(e.target.value);
						}}
						value={dossierValue || dossier}
					>
						{dossierList.map(d => (
							<MenuItem key={d} value={d}>
								{d}
							</MenuItem>
						))}
						<CreateDossierButton>
							<Button
								className="outline"
								onClick={onToggleNewDossier}
								secondary
							>
								Créer un dossier
							</Button>
						</CreateDossierButton>
					</DossierSelect>
				)}
				{isDossierUpdating ? (
					<LoadingContainer>
						<Loading size={18} />
					</LoadingContainer>
				) : (
					<>
						<CloseIcon onClick={onToggleDossierEditing} />
						<DoneIcon onClick={onUpdateDossier} />
					</>
				)}
			</>
		</FieldRow>
	) : (
		<FieldRow>
			<strong>{dossier}</strong>
			<EditIcon onClick={onToggleDossierEditing} />
		</FieldRow>
	);
};

const ReferenceField = ({ query }) => {
	const { reference } = query;

	const [referenceValue, setReferenceValue] = useState("");
	const [isReferenceEditing, setReferenceEditing] = useState(false);
	const [isReferenceUpdating, setReferenceUpdating] = useState(false);

	const onToggleReferenceEditing = useCallback(() => {
		setReferenceValue("");
		setReferenceEditing(!isReferenceEditing);
	}, [isReferenceEditing]);

	const onUpdateReference = useCallback(async () => {
		if (!referenceValue) {
			return;
		}

		if (referenceValue === reference) {
			onToggleReferenceEditing();
			return;
		}

		try {
			setReferenceUpdating(true);
			await Request.mutation(
				/* GraphQL */ `
					mutation($query: EditQueryInput!) {
						editQuery(query: $query) {
							id
							reference
						}
					}
				`,
				{
					query: {
						id: query.id,
						reference: referenceValue,
					},
				},
			);
			onToggleReferenceEditing();
		} catch (err) {
			console.log("onUpdateReference failure", err);
		} finally {
			setReferenceUpdating(false);
		}
	}, [onToggleReferenceEditing, reference, referenceValue]);

	return (
		<FieldRow>
			{isReferenceEditing ? (
				<>
					<TextField
						disabled={isReferenceUpdating}
						onChange={event => {
							setReferenceValue(event.target.value);
						}}
						placeholder="Réference"
						value={referenceValue}
					/>
					{isReferenceUpdating ? (
						<LoadingContainer>
							<Loading size={18} />
						</LoadingContainer>
					) : (
						<>
							<CloseIcon onClick={onToggleReferenceEditing} />
							<DoneIcon onClick={onUpdateReference} />
						</>
					)}
				</>
			) : (
				<>
					<strong>{reference}</strong>
					<EditIcon onClick={onToggleReferenceEditing} />
				</>
			)}
		</FieldRow>
	);
};

export default ({ dismiss, nbContracts, query }) => {
	const {
		acceptCount,
		comment,
		createdAt,
		location,
		getJob: { name: job } = {},
		hasStarted,
		maxWorkers,
		onCancel,
		onEdit,
		rejectCount,
		rejectedDirectContract,
		shifts,
		status,
	} = query;

	const nbRemainingSpots = useMemo(
		() => Math.max(maxWorkers - nbContracts, 0),
		[maxWorkers, nbContracts],
	);

	const plural = useMemo(() => (nbRemainingSpots > 1 ? "s" : ""), [
		nbRemainingSpots,
	]);

	return (
		<Container>
			<Head>
				<h1>{job}</h1>
				<Actions>
					{onCancel ? (
						<Button
							asynchronous
							secondary
							className="outline"
							onClick={async () => {
								const onSuccess = () => {
									ReactGA.event({
										category: "Queries",
										action: "Query canceled",
									});
									dismiss();
								};
								await onCancel({ onSuccess });
							}}
						>
							Annuler
						</Button>
					) : null}
					{onEdit ? (
						<Button secondary className="outline" onClick={onEdit}>
							Modifier
						</Button>
					) : null}
				</Actions>
			</Head>
			{rejectedDirectContract ? (
				<div className="error message">{`Puisque ${rejectedDirectContract.getWorker.name}
            a refusé le contrat direct que vous aviez proposé,
            nous avons automatiquement relancé une recherche.`}</div>
			) : null}
			<Status
				status={status}
				createdAt={createdAt}
				acceptCount={acceptCount}
				rejectCount={rejectCount}
			/>
			{location && (
				<InfoRow>
					<LabeledRow>
						<strong>Lieu:</strong>
						<strong>
							{[location.streetName, location.city, location.postCode]
								.filter(v => !!v)
								.join(", ")}
						</strong>
					</LabeledRow>
				</InfoRow>
			)}
			<InfoRow>
				<LabeledRow>
					<strong>Date:</strong>
					<strong>
						{shifts.map(
							shift =>
								`${moment(shift.startTime).format(
									"[Le] DD MMMM [de] HH[h]mm",
								)} à ${moment(shift.endTime).format("HH[h]mm")}`,
						)}
					</strong>
				</LabeledRow>
				<QueryAnswerStatistics
					acceptCount={acceptCount}
					rejectCount={rejectCount}
				/>
			</InfoRow>
			<LabeledRow>
				<strong>Dossier:</strong>
				<DossierField query={query} />
			</LabeledRow>
			<LabeledRow>
				<strong>Dossier:</strong>
				<ReferenceField query={query} />
			</LabeledRow>
			{comment && (
				<InfoRow>
					<LabeledRow>
						<strong>Details:</strong>
						<div>{comment}</div>
					</LabeledRow>
				</InfoRow>
			)}
			{nbRemainingSpots > 0 && maxWorkers > 1 ? (
				<p>
					{`${nbRemainingSpots} poste${plural} restant${plural}`}{" "}
					{hasStarted ? `non pourvu${plural}` : "à pourvoir"}
				</p>
			) : null}
		</Container>
	);
};

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
`;

const CloseIcon = styled(MdClose)`
	color: #7f7f7f;
	cursor: pointer;
	font-size: 21px;
	margin-left: 32px;
`;

const LabeledRow = styled(Row)`
	width: 100%;
	gap: 8px;
`;

const Container = styled.div`
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-items: stretch;
`;

const CreateDossierButton = styled.div`
	padding: 5px 20px 5px;
`;

const DoneIcon = styled(MdDone)`
	color: #7f7f7f;
	cursor: pointer;
	font-size: 21px;
	margin-left: 12px;
`;

const DossierSelect = styled(Select)`
	max-width: 300px;
	width: 300px;
`;

const EditIcon = styled(MdEdit)`
	color: #7f7f7f;
	cursor: pointer;
	margin-left: 32px;
`;

const FieldRow = styled(Row)`
	align-items: center;
	margin-bottom: 14px;
	width: 100%;
`;

const LoadingContainer = styled.div`
	margin-left: 32px;
`;

const InfoRow = styled(Row)`
	justify-content: space-between;
	margin-bottom: 14px;
	width: 100%;
`;

const Head = styled.div`
	margin-bottom: 14px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
