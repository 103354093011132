import moment from "moment";

import compare from "utils/compare";
import tolerantSelector from "./common/tolerantSelector";
import findOptimumInSortedArray from "./utils/findOptimumInSortedArray";
import flattenArray from "./utils/flattenArray";
import makeMemoized from "./utils/makeMemoized";
import makePeriods from "./utils/makePeriods";
import makeSlots from "./utils/makeSlots";
import propertyGetter from "./utils/propertyGetter";
import range from "./utils/range";
import splitArray from "./utils/splitArray";

export const getPeriods = tolerantSelector(
	[propertyGetter("children"), propertyGetter("renderShift")],
	(queries, renderShift) =>
		flattenArray(
			queries
				.reduce(
					(acc, val) =>
						!val.groupId
							? { ...acc, list: [...acc.list, val] }
							: !acc.byGroup[val.groupId]
							? {
									byGroup: { ...acc.byGroup, [val.groupId]: val },
									list: [...acc.list, val],
							  }
							: acc,
					{
						byGroup: {},
						list: [],
					},
				)
				.list.map(query => {
					const { shifts } = query;
					return makeSlots(makePeriods(shifts)).map(
						({ startTime, endTime }) => ({
							...query,
							startTime,
							endTime,
							render() {
								return renderShift({
									endTime,
									queries,
									query,
									startTime,
								});
							},
						}),
					);
				}),
		).sort(compare(query => query.startTime, { ascending: true })),
);

const getWeekPeriods = makeMemoized(weekValue => {
	console.log("foo:weekValue", weekValue);
	const days = range(8).map(index => moment(weekValue).add(index, "day"));
	const dayValues = days.map(day => day.valueOf());
	return tolerantSelector([getPeriods], periods => {
		const object = splitArray(
			periods,
			({ startTime }) =>
				findOptimumInSortedArray(dayValues, date =>
					moment(date).isAfter(startTime),
				),
			range(7),
		);
		return range(7).map(index => object[index]);
	});
});

export default week => getWeekPeriods(week.valueOf());
