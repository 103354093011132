import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "components/Button";
import styled from "styled-components";
import Request from "common/Request";
import { color } from "common/styles";
import Modal from "@material-ui/core/Modal";

export default ({ parent: { id: ownerId } }) => {
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(false);
	const [password, updatePassword] = useState("");

	return (
		<div>
			<Button
				onClick={() => {
					setOpen(true);
				}}
			>
				Réinitialiser mot de passe
			</Button>
			<StyledModal open={open} onClose={() => setOpen(false)}>
				<Container>
					<TextField
						label="Mot de passe"
						error={error}
						onChange={event => updatePassword(event.target.value)}
						margin="normal"
						variant="outlined"
					/>
					<StyledButton>
						<Button
							onClick={() => {
								if (password && password.length > 3) {
									Request.mutation(
										/* GraphQL */ `
											mutation($password: String!, $ownerId: ID!) {
												reinitializePassword(
													password: $password
													ownerId: $ownerId
												) {
													id
												}
											}
										`,
										{
											password,
											ownerId,
										},
										{
											successMessage: "Mot de passe réinitialisé avec succès",
											errorMessage:
												"Une erreur s'est produite. Veuillez réessayer.",
										},
									);
									setOpen(false);
								} else {
									setError(true);
								}
							}}
						>
							Réinitialiser mot de passe
						</Button>
					</StyledButton>
				</Container>
			</StyledModal>
		</div>
	);
};

const Container = styled.div`
	display: flex;
	width: 50%;
	padding: 10px;
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	background-color: ${color("white")};
`;

const StyledModal = styled(Modal)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledButton = styled.div`
	margin: auto;
`;
