import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormGroup from "@material-ui/core/FormGroup";
import PolitePrompt from "components/PolitePrompt";
import gql from "graphql-tag";
import Request from "common/Request";
import styled from "styled-components";
import Button from "components/Button";
import { shuffle } from "lodash";
import { LightTextField, LightCheckbox, LightRadio } from "components/Inputs";

const answerSurvey = gql`
	mutation($surveyId: ID!, $custom: String, $predefinedAnswerIndices: [Int]) {
		answerSurvey(
			surveyId: $surveyId
			ignore: false
			custom: $custom
			predefinedAnswerIndices: $predefinedAnswerIndices
		) {
			id
			question
			customResponse
			multipleResponse
		}
	}
`;

const getNextSurvey = gql`
	{
		currentUser {
			id
			availableSurvey {
				id
				question
				customResponse
				multipleResponse
				predefinedAnswers
				randomizePredefinedAnswers
			}
		}
	}
`;

const SurveyLine = styled.label`
	display: flex;
	width: 100%;
	align-items: center;
	text-align: left;
	margin-bottom: 10px;
`;

const SendContainer = styled.div`
	flex-direction: row-reverse;
	display: flex;
	button {
		margin-right: 10px;
	}
	> *:first-child button {
		margin-right: 0;
	}
`;

export default class Survey extends React.Component {
	state = {
		dismissed: false,
		checkedAnswers: [],
		pickedCustom: false,
		availableSurvey: undefined,
		answered: false,
	};

	thanks =
		"Merci ! Vos réponses nous sont précieuses pour nous aider à améliorer votre expérience.";

	async componentDidMount() {
		const {
			currentUser: { availableSurvey },
		} = await Request.query(getNextSurvey);

		const answers =
			availableSurvey && availableSurvey.predefinedAnswers
				? availableSurvey.randomizePredefinedAnswers
					? shuffle(availableSurvey.predefinedAnswers)
					: availableSurvey.predefinedAnswers
				: [];

		this.setState({
			availableSurvey,
			answers,
		});
	}

	async answer(e, { id }) {
		const {
			checkedAnswers,
			customAnswer,
			pickedCustom,
			availableSurvey,
		} = this.state;
		const { predefinedAnswers } = availableSurvey;

		e && e.preventDefault();
		this.setState({ answered: true, dismissed: true });

		const variables = {
			surveyId: id,
			custom: pickedCustom ? customAnswer : null,
			predefinedAnswerIndices: predefinedAnswers
				.map((answer, i) => ({ answer, i }))
				.filter(a => checkedAnswers.includes(a.answer))
				.map(el => el.i),
		};

		await Request.mutation(answerSurvey, variables);

		this.setState({ dismissed: false });

		//auto close after some time
		setTimeout(() => this.setState({ dismissed: true }), 5000);
	}

	pick(answer, multipleResponse) {
		if (!multipleResponse) {
			this.setState({
				checkedAnswers: [answer],
				pickedCustom: false,
			});
		} else {
			this.setState({
				checkedAnswers: [...this.state.checkedAnswers, answer],
			});
		}
	}

	pickCustom(multipleResponse, pickedCustom) {
		const { checkedAnswers } = this.state;
		this.setState({
			pickedCustom,
			checkedAnswers: !multipleResponse && pickedCustom ? [] : checkedAnswers,
		});
	}

	unpick(answer) {
		this.setState({
			checkedAnswers: this.state.checkedAnswers.filter(a => a !== answer),
		});
	}

	survey({ customResponse, multipleResponse }) {
		const { checkedAnswers, pickedCustom, answers } = this.state;

		const Group = multipleResponse ? FormGroup : RadioGroup;
		const Control = multipleResponse ? LightCheckbox : LightRadio;

		return (
			<Group name="survey">
				{answers.map((answer, i) => (
					<SurveyLine key={i}>
						<Control
							checked={checkedAnswers.includes(answer)}
							onChange={(e, value) =>
								value
									? this.pick(answer, multipleResponse)
									: this.unpick(answer)
							}
						/>
						<div>{answer}</div>
					</SurveyLine>
				))}
				{customResponse && (
					<SurveyLine>
						<SurveyLine>
							<Control
								checked={pickedCustom}
								onChange={(e, pickedCustom) =>
									this.pickCustom(multipleResponse, pickedCustom)
								}
							/>
							<div>
								<LightTextField
									type="text"
									placeholder="Autre réponse"
									onFocus={() => this.pickCustom(multipleResponse, true)}
									onChange={e =>
										this.setState({ customAnswer: e.target.value })
									}
								/>
							</div>
						</SurveyLine>
					</SurveyLine>
				)}
			</Group>
		);
	}

	render() {
		const {
			dismissed,
			availableSurvey,
			answered,
			checkedAnswers,
			pickedCustom,
		} = this.state;

		if (!availableSurvey) {
			return null;
		}

		return (
			<PolitePrompt hide={dismissed} canClose={false}>
				{answered ? (
					<article>
						<h4>{this.thanks}</h4>
					</article>
				) : (
					<article>
						<h4>{availableSurvey.question}</h4>
						<form onSubmit={e => this.answer(e, availableSurvey)}>
							{this.survey(availableSurvey)}
							<SendContainer>
								<Button
									submit
									disabled={checkedAnswers.length === 0 && !pickedCustom}
								>
									Envoyer
								</Button>
							</SendContainer>
						</form>
					</article>
				)}
			</PolitePrompt>
		);
	}
}
