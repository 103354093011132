import React from "react";
import Profile from "../Query/Profile";
import WorkerProfile from "modals/WorkerProfile";
import { addModal } from "architecture/Modals";
import Request from "common/Request";

export default ({ answers, sort, jobPost }) => {
	return answers.length > 0 ? (
		<article class="cards-list">
			{sort.value(answers).map(answer => (
				<Profile
					key={answer.worker.id}
					{...answer}
					job={jobPost.job}
					actions={[
						{
							name: "Voir",
							onClick: () =>
								addModal(() => (
									<WorkerProfile
										answer={answer}
										jobPost={jobPost}
										workerId={answer.worker.id}
										showContact={true}
										onRevealPhoneNumber={() => {
											Request.mutation(
												/* GraphQL */ `
													mutation($answerId: ID!) {
														business {
															didRevealWorkerPhoneNumber(answerId: $answerId) {
																id
															}
														}
													}
												`,
												{ answerId: answer.id },
											);
										}}
									/>
								)),
						},
					]}
				/>
			))}
		</article>
	) : null;
};
