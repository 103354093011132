import React from "react";
import PolitePrompt from "components/PolitePrompt";
import { useTrackings } from "models/contract";
import { addModal } from "architecture/Modals";
import Query from "modals/Query";
import moment from "moment";

export default () => {
	const { lateTrackings, loading, isLockedOut } = useTrackings();

	if (loading || lateTrackings.length === 0) {
		return null;
	}

	return (
		<PolitePrompt
			isLockedOut={isLockedOut}
			title="Pointages urgents"
			hide={false}
			canClose={false}
			highlight="error"
		>
			<ul>
				{lateTrackings.slice(0, 6).map(t => (
					<li
						class="row link"
						onClick={() =>
							addModal(({ dismiss }) => (
								<Query
									dismiss={() => {
										dismiss();
									}}
									queryId={t.query.id}
								/>
							))
						}
					>
						<strong>{t.getWorker.name}</strong>
						<p class="right">
							{moment(t.startTime).format("DD MMMM")}
							<br />
							{moment(t.startTime).format("HH[h]mm")}
						</p>
					</li>
				))}
				{lateTrackings.length > 6 ? (
					<li>Et {lateTrackings.length - 6} autres pointages</li>
				) : null}
			</ul>
		</PolitePrompt>
	);
};
