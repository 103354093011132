import React, { useState } from "react";
import Loading from "components/Loading";
import Autocomplete from "react-select";
import { PillsList, Pill } from "components/Pills";
import { useSkills } from "models/skills";

export default ({ skills, onChange, jobKey }) => {
	const [searchValue, setSearchValue] = useState("");
	const { loading, skills: allSkills } = useSkills();

	if (loading) {
		return <Loading />;
	}

	return (
		<React.Fragment>
			<h4>Compétences demandées</h4>
			<Autocomplete
				value={searchValue}
				options={allSkills.filter(
					s =>
						!skills.some(skill => skill.key === s.key) &&
						(!jobKey || s.jobs.some(j => j.key === jobKey)),
				)}
				getOptionLabel={skill => skill.name}
				placeholder={"Rechercher une compétence"}
				onChange={skill => {
					onChange([...skills, skill]);
					setSearchValue("");
				}}
			/>
			{skills ? (
				<PillsList>
					{skills.map(s => (
						<Pill
							onDelete={() =>
								onChange(skills.filter(skill => skill.key !== s.key))
							}
						>
							{s.name}
						</Pill>
					))}
				</PillsList>
			) : null}
		</React.Fragment>
	);
};
