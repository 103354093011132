import React, { useState } from "react";
import { useJobPostAnswerStatus } from "models/jobPostAnswers";
import Lightbox from "components/Lightbox";
import Button from "components/Button";
import CheckboxesAndTextField from "components/CheckboxesAndTextField";
import Loading from "components/Loading";

export default ({ answer }) => {
	const { id } = answer;
	const {
		businessAccept,
		setFavorite,
		setRejected,
		reasons,
		loading,
		acceptLoading,
		rejectLoading,
	} = useJobPostAnswerStatus(id);
	const [pickReason, setPickReason] = useState(false);
	const [reason, setReason] = useState("");

	if (loading) {
		return <Loading />;
	}

	return (
		<article class="dark-box card">
			<div class="grid">
				<div class="col twelve">
					{businessAccept === true ? (
						<section class="success message twelve">
							Vous avez mis cette candidature en favori
						</section>
					) : businessAccept === false ? (
						<section class="error message twelve">
							Vous avez écarté cette candidature
						</section>
					) : null}

					{businessAccept !== true && (
						<Button
							secondary={businessAccept === false}
							loading={acceptLoading}
							className={
								businessAccept === null
									? "twelve success section"
									: "twelve outline"
							}
							onClick={() => setFavorite()}
						>
							Mettre en favori
						</Button>
					)}
					{businessAccept !== false && (
						<Button
							secondary={businessAccept === true}
							loading={rejectLoading}
							className={
								businessAccept === null
									? "twelve error section"
									: "twelve outline"
							}
							onClick={() => setPickReason(true)}
						>
							écarter
						</Button>
					)}
				</div>
			</div>
			<Lightbox visible={pickReason} onDismiss={() => setPickReason(false)}>
				<article>
					<h2>Vous écartez cette candidature parce que...</h2>
					<CheckboxesAndTextField
						labels={reasons}
						onChange={reason => setReason(reason)}
					/>
					<div class="row">
						<Button
							secondary
							className="right"
							onClick={() => setPickReason(false)}
						>
							Annuler
						</Button>
						<Button
							onClick={() => {
								setRejected(reason);
								setPickReason(false);
							}}
						>
							Enregistrer
						</Button>
					</div>
				</article>
			</Lightbox>
		</article>
	);
};
