import React from "react";
import graphql from "common/graphql";
import gql from "graphql-tag";
import moment from "moment";
import ContractsTable from "scenes/Home/Admin/ContractsTable";

const endAfter = moment().toISOString();

export default graphql(
	gql`
		query getCurrentContracts($endAfter: DateTime) {
			admin {
				contracts(
					endAfter: $endAfter
					signed: true
					canceled: false
					orderBy: startTime
					isGofer: false
				) {
					id
					btp
					getWorker {
						id
						name
						documents {
							identity {
								expiresAt
							}
						}
					}
					getBusiness {
						id
						name
					}
					getJob {
						name
					}
					startTime
					shiftsDescription
				}
			}
		}
	`,
	() => ({ variables: { endAfter } }),
)(({ admin: { contracts } }) => (
	<ContractsTable contracts={contracts.filter(c => !c.btp)} />
));
