import React from "react";
import { LogoBadge } from "components/Decorations";
import { cvDates } from "models/worker/resume";

export default ({
	startDate,
	endDate,
	businessName,
	description,
	jobs,
	status,
}) => {
	if (!jobs.length && !businessName && !startDate) {
		return null;
	}

	return (
		<section class={`${status === "validated" ? "highlight" : ""} card`}>
			<article class="grid">
				<div class="eleven">
					{status === "validated" ? <h4>Référence vérifiée</h4> : null}
					<strong>{jobs.map(j => j.name).join(", ")}</strong>
					<br />
					{cvDates(startDate, endDate)}
					<br />
					{businessName}

					{description ? <paragraph>{description}</paragraph> : null}
				</div>
				<div class="one">{status === "validated" ? <LogoBadge /> : null}</div>
			</article>
		</section>
	);
};
