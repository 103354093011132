import React from "react";
import { Document, DocumentTitle } from "./shared";
import styled from "styled-components";
import moment from "moment";
import { formatBoolean } from "common/utilities";

export default props => {
	const { initialValues } = props;
	return (
		<Document>
			<DocumentTitle>Informations</DocumentTitle>
			<Block>EEE : {formatBoolean(initialValues.belongsToEU)}</Block>
			<Block>Profil vérifié : {formatBoolean(initialValues.verfied)}</Block>
			<Block>
				Date de dernier upload :{" "}
				{moment(initialValues.documentsLastUploadedAt).format("LLLL")}
			</Block>
		</Document>
	);
};

const Block = styled.div`
	display: flex;
	flex-direction: row;
`;
