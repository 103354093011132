import forAll from "utils/forAll";
import moment from "moment";

export function hasStarted({ shifts = [] } = {}) {
	return Boolean(shifts.find(({ startTime }) => moment().isAfter(startTime)));
}

export function hasEnded({ shifts = [] } = {}) {
	return forAll(shifts, ({ endTime }) => moment().isAfter(endTime));
}

export const makeShift = ({ startTime, endTime, ...shift }) => ({
	startTime: moment(startTime),
	endTime: moment(endTime),
	...shift,
});
