import React, { useState, useEffect } from "react";
import Input from "@material-ui/core/Input";
import isValidSiret from "siret";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

export default ({ updateValue, value, getCompanyInformations }) => {
	const siret = value || "";
	const [errorMessage, setErrorMessage] = useState(null);
	const [didBlurOnce, setDidBlurOnce] = useState(false);
	const [focused, setFocused] = useState(false);
	useEffect(() => {
		const effect = async () => {
			if (isValidSiret(siret)) {
				try {
					setErrorMessage(null);
					await getCompanyInformations(siret);
				} catch (error) {
					if (error.message.includes("code 404")) {
						setErrorMessage("Siret introuvable");
						return;
					}
					setErrorMessage(error.message);
				}
			} else {
				setErrorMessage("Il y a une erreur dans le Siret");
			}
		};
		effect();
	}, [siret]);
	const shouldDisplayError = Boolean(
		errorMessage &&
			(didBlurOnce || siret.length) &&
			(!focused || siret.length >= 14),
	);
	return (
		<FormControl fullWidth error={shouldDisplayError}>
			<InputLabel>Siret *</InputLabel>
			<Input
				value={siret}
				onChange={event => {
					const siret = event.target.value;
					updateValue(siret);
				}}
				onFocus={() => setFocused(true)}
				onBlur={() => {
					setFocused(false);
					setDidBlurOnce(true);
				}}
			/>
			{shouldDisplayError ? (
				<FormHelperText>{errorMessage}</FormHelperText>
			) : null}
		</FormControl>
	);
};
