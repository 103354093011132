import { color } from "common/styles";
import {
	MdClose,
	MdDoneAll,
	MdDone,
	MdGroup,
	MdSchedule,
	MdPlayArrow,
	MdAssignment,
	MdWarning,
	MdFeedback,
} from "react-icons/md";
import { makeTarget } from "./target";
import { makeShift, hasEnded, hasStarted } from "./shift";
import { makeContract } from "./contract";
import exists from "utils/exists";
import Request from "common/Request";
import store from "common/store";
import { addAlert } from "actions/alerts";
import compare from "utils/compare";

export const statusElements = {
	timeout: {
		color: color("black", "bright"),
		Icon: MdClose,
		label: "Expirée",
	},
	"all contracts signed": {
		color: color("valid"),
		Icon: MdDoneAll,
		label: "Mission attribuée",
	},
	"all workers found": {
		color: color("almostValid"),
		Icon: MdDone,
		label: "En attente de validation",
	},
	"at least one accept": {
		color: color("action"),
		Icon: MdGroup,
		label: "En cours de sélection",
	},
	wait: {
		color: color("black", "bright"),
		Icon: MdSchedule,
		label: "En attente de réponse",
	},
	"in progress": {
		color: color("valid"),
		Icon: MdPlayArrow,
		label: "En cours",
	},
	"pending business tracking": {
		color: color("action"),
		Icon: MdAssignment,
		label: "En attente de pointage",
	},
	"pending worker tracking": {
		color: color("almostValid"),
		Icon: MdDone,
		label: "En attente de validation de pointage",
	},
	finished: {
		color: color("valid"),
		Icon: MdDoneAll,
		label: "Mission terminée",
	},
	"pending auto tracking": {
		color: color("action"),
		Icon: MdWarning,
		label: "Pointage automatique non validé",
	},
	"rejected auto tracking": {
		color: color("important"),
		Icon: MdClose,
		label: "Pointage automatique contesté",
	},
	"pending debrief": {
		color: color("action"),
		Icon: MdFeedback,
		label: "En attente d'évaluation",
	},
};

export function getQueryStatus({
	acceptCount,
	rejectCount,
	maxWorkers,
	contracts = [],
	shifts = [],
} = {}) {
	if (hasStarted({ shifts })) {
		const signedContracts = getSignedContracts(contracts);
		if (signedContracts.length === 0) {
			return "timeout";
		}
		if (hasEnded({ shifts })) {
			const status = [
				"pending business tracking",
				"pending auto tracking",
				"rejected auto tracking",
			].reduce(
				(foundStatus, status) =>
					foundStatus ||
					(exists(contracts, contract => contract.status === status)
						? status
						: ""),
				"",
			);
			if (status) {
				return status;
			}
			if (
				exists(
					contracts,
					contract => contract.matching.debrief.business.currentScore === null,
				)
			) {
				return "pending debrief";
			}
			if (
				exists(
					contracts,
					contract => contract.status === "pending worker tracking",
				)
			) {
				return "pending worker tracking";
			}
			return "finished";
		}
		return "in progress";
	}
	if (contracts.length >= maxWorkers) {
		if (getSignedContracts(contracts).length === maxWorkers) {
			return "all contracts signed";
		}
		return "all workers found";
	}
	if (acceptCount > 0) {
		return "at least one accept";
	}
	return "wait";
}

function getSignedContracts(contracts) {
	return contracts.filter(({ signed }) => signed);
}

export const makeQuery = (query = {}) => {
	const cancel = async ({ onSuccess }) => {
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation($queryId: ID) {
						cancelQuery(queryId: $queryId) {
							id
						}
					}
				`,
				{
					queryId: query.id,
				},
			);
			store.dispatch(addAlert("La demande a été annulée."));
			onSuccess();
		} catch (error) {
			store.dispatch(addAlert("La demande n'a pas pu être annulée."));
		}
	};

	const contracts = (query.contracts || []).map(contract =>
		makeContract({ query, ...contract }),
	);

	return {
		...query,
		shifts: (query.shifts || []).map(makeShift),
		contracts,
		status: getQueryStatus({ ...query, contracts }),
		onCancel: !(query.contracts || []).length && cancel,
		hasStarted: hasStarted(query),
		getTargets: (query.getTargets || []).map(makeTarget(query)),
	};
};

export const sortByDebrief = targets =>
	targets.sort(
		compare(t =>
			t.matching &&
			t.matching.debrief &&
			t.matching.debrief.business &&
			t.matching.debrief.business.currentScore
				? t.matching.debrief.business.currentScore
				: t.worker.medianDebriefScore !== null
				? t.worker.medianDebriefScore
				: 1.5,
		),
	);
