const forAll = (array, condition) => {
	let i = 0;
	while (i < array.length) {
		if (!condition(array[i])) {
			return false;
		}
		i++;
	}
	return true;
};

export default forAll;
