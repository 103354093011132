import React from "react";
import { Field, FormSection } from "redux-form";
import MenuItem from "material-ui/MenuItem";
import { SelectField } from "redux-form-material-ui";
import styled from "styled-components";
import moment from "moment";
import range from "utils/range";
import rangeText from "modals/NewQuery/Shifts/rangeText";
import BreakMinutesField from "modals/NewQuery/Shifts/BreakMinutesField";
import { isMobile, mobileBreakpoint } from "common/styles";
import Request from "common/Request";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";

export const getStartEndFromValue = (
	{ startTime, endTime, breakMinutes },
	date,
) => {
	if (!startTime || !endTime) {
		return { breakMinutes };
	}
	const getMinutes = ({ hours, minutes }) => hours * 60 + minutes;
	const startMinutes = getMinutes(startTime);
	const endMinutes = getMinutes(endTime);
	return {
		startTime: moment(date)
			.startOf("day")
			.add(startMinutes, "minutes"),
		endTime: moment(date)
			.startOf("day")
			.add(endMinutes, "minutes")
			.add(endMinutes < startMinutes ? 1 : 0, "day"),
		breakMinutes,
	};
};

const convertToRange = ({ startTime, endTime }) => {
	const date = moment(startTime).startOf("day");
	return {
		date,
		timeRange: [
			moment(startTime).diff(date, "minutes"),
			moment(endTime).diff(date, "minutes"),
		],
	};
};

export const formatTracking = ({ startTime, endTime, breakMinutes }) => {
	const { date, timeRange } = convertToRange({ startTime, endTime });
	return `${moment(date).format("D MMMM")} : ${rangeText(timeRange, {
		breakMinutes,
	})}`;
};

const TimeField = ({ name, label }) => (
	<FormSection name={name}>
		<Time>
			<Field
				fullWidth
				name={`hours`}
				component={SelectField}
				floatingLabelText={label}
			>
				{range(24).map(hour => (
					<MenuItem
						key={hour}
						value={hour}
						primaryText={String(hour).padStart(2, "0")}
					/>
				))}
			</Field>
			<Field
				fullWidth
				name={`minutes`}
				component={SelectField}
				floatingLabelText=" "
			>
				{range(60 / 5)
					.map(m => m * 5)
					.map(minutes => (
						<MenuItem
							key={minutes}
							value={minutes}
							primaryText={String(minutes).padStart(2, "0")}
						/>
					))}
			</Field>
		</Time>
	</FormSection>
);

export default class Shift extends React.Component {
	getStartEnd = () => {
		const {
			startTime,
			endTime,
			breakMinutes,
			startTimeTracked,
			endTimeTracked,
			breakMinutesTracked,
			value = {},
		} = this.props;
		const {
			startTime: valueStartTime,
			endTime: valueEndTime,
			breakMinutes: valueBreakMinutes,
		} = getStartEndFromValue(value, startTime);
		const or = (a, b) => (a !== undefined ? a : b);
		return {
			startTime: valueStartTime || startTimeTracked || startTime,
			endTime: valueEndTime || endTimeTracked || endTime,
			breakMinutes: or(
				valueBreakMinutes,
				or(breakMinutesTracked, breakMinutes),
			),
		};
	};

	generateContract = async (contractId, shiftId) => {
		try {
			const {
				data: {
					generateOwnerContractFile: { url },
				},
			} = await Request.mutation(
				/* GraphQL */ `
					mutation($contractId: ID!, $shiftId: ID!) {
						generateOwnerContractFile(
							contractId: $contractId
							shiftId: $shiftId
						) {
							url
						}
					}
				`,
				{
					contractId,
					shiftId,
				},
			);
			setTimeout(() => {
				window.open(url);
			}, 100);
			this.handleClose();
		} catch (error) {
			dispatch(
				addAlert(
					"Une erreur s'est produite, nous ne pouvons vous fournir le contrat pour le moment.",
				),
			);
		}
	};

	render() {
		const { startTime, endTime, breakMinutes } = this.getStartEnd();
		const { input, member } = this.props;

		return (
			<Container>
				<Title>{formatTracking({ startTime, endTime, breakMinutes })}</Title>
				{input ? (
					<TimeFields>
						<TimeField name={`${member}.startTime`} label="Début" />
						<TimeField name={`${member}.endTime`} label="Fin" />
						<BreakMinutesField name={`${member}.breakMinutes`} />
					</TimeFields>
				) : null}
			</Container>
		);
	}
}

const Container = styled.div`
	label {
		filter: invert(100%);
	}
	label + div {
		filter: invert(100%);
	}
	button {
		filter: invert(100%);
	}
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const TimeFields = styled.div`
	display: flex;
	flex-direction: ${isMobile() ? "column" : "row"};
	> div {
		margin: 10px;
	}
	> div:first-child {
		margin-left: 0;
	}
	@media screen and (max-width: ${mobileBreakpoint}px) {
		> div {
			margin: 0;
		}
	}
`;
const Time = styled.div`
	display: flex;
`;
