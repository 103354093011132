import React, { useEffect } from "react";
import { FieldArray, Field, change } from "redux-form";
import BreakMinutesField from "./Shifts/BreakMinutesField";
import Button from "components/Button";
import DateField from "components/DateField";
import validate from "./Shifts/validate";
import ErrorMessage from "./ErrorMessage";
import styled from "styled-components";
import { dispatch } from "common/store";
import moment from "moment";
import { isMobile } from "common/styles";
import TimeField from "components/TimeField";

export const defaultShift = (
	initialDate = moment() > moment({ hours: 11, minutes: 30 })
		? moment().add(1, "day")
		: moment(),
) => {
	const date = moment(initialDate)
		.startOf("day")
		.toDate();
	return {
		date,
		startTime: moment(date)
			.hours(11)
			.minutes(30)
			.toISOString(),
		endTime: moment(date)
			.hours(15)
			.minutes(30)
			.toISOString(),
		breakMinutes: 0,
	};
};

class Shifts extends React.Component {
	componentDidMount() {
		const { fields } = this.props;
		if (fields.length < 1) {
			this.addShift();
		}
	}

	addShift = () => {
		const { fields } = this.props;
		const lastShift = fields.get(fields.length - 1);
		if (lastShift) {
			const { startTime, endTime, breakMinutes, date } = lastShift;
			fields.push({
				date: moment(date)
					.add(1, "day")
					.toDate(),
				startTime: moment(startTime).add(1, "day"),
				endTime: moment(endTime).add(1, "day"),
				breakMinutes,
			});
		} else {
			fields.push(defaultShift());
		}
	};

	render() {
		const {
			fields,
			meta: { submitFailed, invalid, error },
			allowPastDate,
		} = this.props;

		return (
			<div class="col">
				<ErrorMessage error={invalid && submitFailed ? error : ""} />
				{fields.map((member, index) => (
					<Shift
						member={member}
						index={index}
						value={fields.get(index)}
						allowPastDate={allowPastDate}
						fields={fields}
					/>
				))}
				<section>
					<Button onClick={this.addShift} secondary className="outline">
						Ajouter un créneau
					</Button>
				</section>
			</div>
		);
	}
}

const Shift = ({
	member,
	index,
	value: { startTime, endTime, date },
	allowPastDate,
	fields,
}) => {
	useEffect(() => {
		const newStartTime = moment(date).set({
			hours: moment(startTime).hours(),
			minutes: moment(startTime).minutes(),
		});
		const newEndTime = moment(date).set({
			hours: moment(endTime).hours(),
			minutes: moment(endTime).minutes(),
		});
		if (newEndTime <= newStartTime) {
			newEndTime.add(1, "day");
		}
		dispatch(change("NewQuery", `${member}.startTime`, newStartTime));
		dispatch(change("NewQuery", `${member}.endTime`, newEndTime));
		dispatch(change("NewContract", `${member}.startTime`, newStartTime));
		dispatch(change("NewContract", `${member}.endTime`, newEndTime));
	}, [date, startTime, endTime]);

	return (
		<div key={index}>
			<Line>
				<Field
					name={`${member}.date`}
					component={DateField}
					hintText="Date"
					DateTimeFormat={Intl.DateTimeFormat}
					locale="fr-FR"
					minDate={allowPastDate ? undefined : new Date()}
					format={date => (date ? moment(date).toDate() : null)}
					defaultValue={moment(startTime)
						.startOf("day")
						.toDate()}
					fullWidth={true}
				/>
			</Line>
			<Line>
				<BreakMinutesField name={`${member}.breakMinutes`} />
			</Line>
			<Line>
				<Field
					label="Début"
					name={`${member}.startTime`}
					component={TimeField}
				/>
				<Field label="Fin" name={`${member}.endTime`} component={TimeField} />
				<Field name={`${member}.timeRange`} component={TimeRangeError} />
			</Line>
			{fields.length > 1 ? (
				<Button onClick={() => fields.remove(index)} secondary>
					Supprimer
				</Button>
			) : null}
		</div>
	);
};

export default ({ allowPastDate }) => (
	<FieldArray
		name="shifts"
		component={Shifts}
		validate={validate}
		allowPastDate={allowPastDate}
	/>
);

const Line = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	padding-bottom: 5px;
	overflow: visible;
	flex-direction: ${isMobile() ? "column" : "row"};
	& > * {
		flex: 1;
	}
	> div {
		${isMobile() ? "width: 100%" : ""};
	}
`;

const TimeRangeError = ({ meta: { error } }) => <ErrorMessage error={error} />;
