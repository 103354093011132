import React from "react";

export default ({ children, className }) => {
	const lines = (children || "").split("\n");
	return (
		<div className={className}>
			{[...Array(2 * lines.length - 1).keys()].map(index => {
				if (index % 2 === 0) {
					return <span key={index}>{lines[index / 2]}</span>;
				} else {
					return <br key={index} />;
				}
			})}
		</div>
	);
};
