import React from "react";
import NumberField from "./NumberField";
import round from "utils/round";

export default props => (
	<NumberField
		format={value => (value ? `${value} %` : "")}
		convertToUserInput={value => (value ? round(value * 100, 2) : "")}
		parseUserInput={percentage =>
			parseFloat(percentage.toString().replace(",", ".")) / 100
		}
		{...props}
	/>
);
