import React from "react";
import Button from "components/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default ({ openConfirmDialog, setOpenConfirmDialog, saveBusiness }) => (
	<Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
		<DialogTitle>Le business existe déjà</DialogTitle>
		<DialogContent>
			<DialogContentText>
				Un compte avec ce SIREN a déjà cette référence de facturation .
				Êtes-vous sure de vouloir valider ?
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => setOpenConfirmDialog(false)}>Non</Button>
			<Button
				asynchronous
				onClick={async () => {
					await saveBusiness();
					setOpenConfirmDialog(false);
				}}
				autoFocus
			>
				oui
			</Button>
		</DialogActions>
	</Dialog>
);
