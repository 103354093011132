import { useState } from "react";
import { computeJobsExperience } from "models/worker/resume";
import { sortByDebrief } from "models/query";
import moment from "moment";
import compare from "utils/compare";

export default job => {
	const sortOptions = [
		{ label: "Avis des établissements", value: sortByDebrief },
		{
			jobPostLabel: "Date de candidature",
			label: "Date à laquelle les workers se sont déclarés disponibles",
			value: answers =>
				answers.sort(compare(a => moment(a.createdAt || a.answeredAt))),
		},
		{
			label: "Années d'expérience",
			value: answers =>
				answers.sort(
					compare(a => {
						const xpJob = computeJobsExperience(
							a.worker.experiences,
							true,
						).find(xpJob => xpJob.key === job.key) || { nbMonths: 0 };

						return xpJob.nbMonths;
					}),
				),
		},
		{
			label: "Nombre de missions",
			value: answers => answers.sort(compare(a => a.worker.pastContractsCount)),
		},
		{
			label: "Prénom",
			value: answers =>
				answers.sort(compare(a => a.worker.firstName, { ascending: true })),
		},
	];

	const [sort, setSort] = useState(sortOptions[0]);

	return {
		sort,
		setSort,
		sortOptions,
	};
};
