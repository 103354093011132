import React, { useState } from "react";
import MainPanel from "./WorkerProfile/MainPanel";
import GoferPanel from "./WorkerProfile/GoferPanel";
import Lightbox from "components/Lightbox";
import Button from "components/Button";
import formatPhoneNumber from "utils/formatPhoneNumber";
import { useWorker } from "models/worker/profile";
import Loading from "components/Loading";
import NewQuery from "modals/NewQuery";
import { addModal } from "architecture/Modals";
import reasons from "common/reasons.json";
import { notify } from "components/Loading";
import useCurrentUser from "common/useCurrentUser";
import { MdFolder } from "react-icons/md";

// import * as googleFolderId from "models/admin/workerDocuments";

const QueryInfos = ({ title }) => (
	<section class="row">
		<article class="dark-box card">
			<p>
				Cette mission a comme justification :{" "}
				<strong>
					Couvrir provisoirement le poste de "{title}" qui est en instance de
					recrutement dans votre établissement
				</strong>
				. Si le worker Gofer est embauché(e), la durée de cette mission devra
				être déduite de la période d'essai du nouveau contrat. Nous informerons
				le Worker que cette mission est proposée dans le cadre de sa candidature
				à ce poste
			</p>
		</article>
	</section>
);



export default props => {
	const {
		workerId,
		answer = {},
		jobPost,
		onRevealPhoneNumber = () => {},
	} = props;
	const { worker, loading } = useWorker(workerId);
	const [showPhoneNumber, setShowPhoneNumber] = useState(false);
	const user = useCurrentUser();
	

	if (loading) {
		return <Loading />;
	}

	const googleFolder = () => {


		  window.open(
			`https://drive.google.com/drive/folders/${worker.documents.googleFolderId}`,
			"worker-documents-folder"
		  );
	
	  };



	return (
		<div class="desktop-grid print-grid with-gap">
			<div class="twelve">
				<div class="row">
					<Button
						secondary
						onClick={() => {
							window.print();
						}}
						className="outline right"
					>
						Imprimer
					</Button>
		
					<Button onClick={googleFolder}>
            <MdFolder />
            <span>Google Drive</span>
						</Button>
					{answer.id &&
					!user.business.locked &&
					user.business.verified &&
					user.defaultQueries.some(q => q.job.key === jobPost.job.key) ? (
						<Button
							secondary
							onClick={() =>
								addModal(({ dismiss }) => (
									<NewQuery
										worker={worker}
										directContract
										dismiss={() => {
											notify("La mission d'essai a bien été envoyée");
											dismiss();
										}}
										reason={reasons.replacement}
										justification="Remplacement sur un poste en instance de recrutement"
										jobKey={jobPost.job.key}
										showPreview={true}
										buttonTitle="Proposer une mission d'essai"
										informations={() => <QueryInfos {...jobPost} />}
									/>
								))
							}
							className="right outline"
						>
							Mission d'essai
						</Button>
					) : null}
					{props.showContact && (
						<Button
							onClick={() => {
								setShowPhoneNumber(true);
								onRevealPhoneNumber();
							}}
							className="right"
						>
							Contacter
						</Button>
					)}
					<Lightbox
						visible={showPhoneNumber}
						onDismiss={() => setShowPhoneNumber(false)}
					>
						<article>
							<h2>Contacter {worker.name}</h2>
							<paragraph class="centered">
								Vous pouvez appeler {worker.name} au{" "}
								<strong>
									<a href={`tel:${worker.phoneNumber}`}>
										{formatPhoneNumber(worker.phoneNumber)}
									</a>
								</strong>{" "}
								ou lui envoyer un e-mail à{" "}
								<strong>
									<a href={`mailto:${worker.email}`}>{worker.email}</a>
								</strong>
							</paragraph>
						</article>
					</Lightbox>
				</div>
			</div>
			<div class="four print-from-one print-three">
				<GoferPanel
					answer={answer}
					worker={worker}
					anonymizeFeedbacks={props.anonymizeFeedbacks}
				/>
			</div>

			<div class="eight print-eight print-from-two">
				<MainPanel worker={worker} answer={answer} />
			</div>
		</div>
	);
};
