import moment from "moment";

const formatTime = time => {
	time = moment(time);
	return `${time.format("H")}h${time.minutes() ? time.format("mm") : ""}`;
};

const formatDay = (day, { includeMonth = true } = {}) => {
	day = moment(day);
	return `${day.format("D")}${day.date() === 1 ? "er" : ""}${
		includeMonth ? ` ${day.format("MMMM")}` : ""
	}`;
};

export default (shifts, { skipHours } = {}) => {
	let start, end;
	switch (shifts.length) {
		case 1:
			const [{ startTime, endTime }] = shifts;
			return `le ${formatDay(startTime)}${
				skipHours ? "" : ` de ${formatTime(startTime)} à ${formatTime(endTime)}`
			}`;
		case 2:
			start = moment(shifts[0].startTime);
			end = moment(shifts[1].startTime);
			return `les ${formatDay(start, {
				includeMonth: !start.isSame(end, "month"),
			})} et ${formatDay(end)}`;
		default:
			const shiftStarts = shifts.map(shift => moment(shift.startTime));
			start = moment.min(shiftStarts);
			end = moment.max(shiftStarts);
			return `du ${formatDay(start, {
				includeMonth: !start.isSame(end, "month"),
			})} au ${formatDay(end)}`;
	}
};
