import React from "react";
import SimpleTextField from "material-ui/TextField";
import Button from "components/Button";
import styled from "styled-components";
import searchOrganizationsPipedriveRequest from "./SearchOrganizationsPipedrive/SearchOrganizationsPipedriveRequest";
import SearchOrganizationsTable from "./SearchOrganizationsTable";

export default class SearchOrganizationsPipedrive extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			organizations: [],
		};
	}

	render() {
		return (
			<Container>
				<Line>
					<SimpleTextField
						name="search"
						value={this.state.search}
						onChange={event => this.setState({ search: event.target.value })}
					/>
					<StyledButton
						submit
						onClick={async () =>
							this.setState({
								organizations: await searchOrganizationsPipedriveRequest(
									this.state.search,
									this.props.pipedriveApiToken,
								),
							})
						}
					>
						Search
					</StyledButton>
				</Line>
				<SearchOrganizationsTable
					organizations={this.state.organizations}
					token={this.props.pipedriveApiToken}
				/>
			</Container>
		);
	}
}

const StyledButton = styled(Button)`
	margin-left: 10px;
`;
const Line = styled.div`
	display: flex;
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;
