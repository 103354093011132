import React from "react";
import { Card } from "components/Card";

export default () => (
	<Card>
		<p>
			Allez sur <a href="https://www.zoho.com/recruit/">Zoho</a> pour consulter
			la liste des workers à valider et accéder à leurs références.
		</p>
	</Card>
);
