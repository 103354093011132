import React from "react";
import styled from "styled-components";
import Table from "components/Table";
import LineBrokenText from "components/LineBrokenText";
import Button from "components/Button";
import store from "common/store";
import { addAlert } from "actions/alerts";
import { DEV } from "common/constants";
import Request from "common/Request";
import { color } from "common/styles";
import Chip from "@material-ui/core/Chip";

export default ({ contracts }) => (
	<Table
		id="TrackingsTable"
		columns={[
			{
				label: "Identifiant",
				getter: ({ id }) => id,
			},
			{
				label: "Worker",
				getter: ({ getWorker: { name } }) => name,
			},
			{
				label: "Poste",
				getter: ({ getJob: { name } }) => name,
			},
			{
				label: "Business",
				getter: ({ getBusiness: { name } }) => name,
			},
			{
				label: "Shifts",
				sortGetter: ({ startTime }) => startTime,
				getter: ({
					trackedShiftsDescription,
					shiftsDescription,
					businessTracked,
				}) => (
					<LineBrokenText>
						{businessTracked ? trackedShiftsDescription : shiftsDescription}
					</LineBrokenText>
				),
			},
			{
				label: "Heures pointées",
				getter: ({ trackedHours, totalHours, businessTracked }) =>
					businessTracked ? trackedHours : totalHours,
			},
			{
				label: "En attente",
				getter: ({ businessTracked, tracked }) => (
					<LineBrokenText>
						{[!businessTracked && "Du business", !tracked && "Du worker"]
							.filter(Boolean)
							.join("\n")}
					</LineBrokenText>
				),
			},
			{
				label: "Pointage auto",
				getter: ({
					businessAutoTracked,
					workerAutoTracked,
					businessRejectedAutoTracking,
					autoTrackingRejectionBusinessComment,
				}) =>
					businessAutoTracked && businessRejectedAutoTracking ? (
						<RejectionComment>
							{autoTrackingRejectionBusinessComment}
						</RejectionComment>
					) : (
						[businessAutoTracked && "business", workerAutoTracked && "worker"]
							.filter(Boolean)
							.join(",")
					),
			},
			{
				label: "",
				getter: ({ getWorker: { locked } }) =>
					locked ? <Chip label="Worker Rejeté" /> : null,
			},
			{
				label: "",
				getter: ({
					id,
					businessAutoTracked,
					businessRejectedAutoTracking,
					tracked,
				}) =>
					!(businessAutoTracked && businessRejectedAutoTracking) && tracked ? (
						<ValidateTrackingButton contractId={id} />
					) : null,
			},
		]}
		rows={contracts}
		orderBy={4}
		getId={({ id }) => id}
	/>
);

class ValidateTrackingButton extends React.Component {
	state = {
		validating: false,
	};

	validateTracking = async () => {
		this.setState({ validating: true });
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation($id: ID!) {
						validateTracking(contractId: $id) {
							id
						}
					}
				`,
				{
					id: this.props.contractId,
				},
			);
		} catch (error) {
			DEV && console.error(error);
			this.setState({ validating: false });
			store.dispatch(addAlert("Le pointage n'a pas pu être validé."));
		}
	};

	render() {
		const { validating } = this.state;
		return (
			<Button onClick={this.validateTracking} loading={validating}>
				Valider
			</Button>
		);
	}
}

const RejectionComment = styled.div`
	color: ${color("important")};
`;
