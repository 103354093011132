const splitArray = (elements, getCategory, categories = []) => {
	const categoryList = {};

	for (const category of categories) {
		categoryList[category] = [];
	}

	elements.forEach(element => {
		const category = getCategory(element);
		if (!categoryList[category]) {
			categoryList[category] = [];
		}
		categoryList[category].push(element);
	});

	return categoryList;
};

export default splitArray;
