import { useEffect } from "react";

const FrontChat = () => {
	useEffect(() => {
		const initFrontChat = () => {
			window.FrontChat("init", {
				chatId: "e237131521217525cd74eed2a2a606a0", // Replace with your actual chatId
				useDefaultLauncher: true,
			});
		};

		if (window.FrontChat) {
			initFrontChat();
		} else {
			const script = document.createElement("script");
			script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
			script.async = true;
			script.onload = initFrontChat;
			document.body.appendChild(script);
		}
	}, []);

	return null;
};

export default FrontChat;
