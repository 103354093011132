import React from "react";
import Twemoji from "react-twemoji";
import styled from "styled-components";

export const feedbacks = [
	{
		score: 0,
		label: "Plus jamais",
		emoji: "☹️",
		ariaLabel: "très-triste",
		sentence: ({ firstName }) => `Vous ne souhaitez plus revoir ${firstName}.`,
	},
	{
		score: 1,
		label: "Si vraiment je n'ai personne d'autre",
		emoji: "😕",
		ariaLabel: "perplexe",
		sentence: ({ firstName }) =>
			`Vous préférez ne plus avoir à travailler avec ${firstName}.`,
	},
	{
		score: 2,
		label: "Volontiers !",
		emoji: "😀",
		ariaLabel: "grand-sourire",
		sentence: ({ firstName }) =>
			`Vous souhaitez travailler à nouveau avec ${firstName}.`,
	},
	{
		score: 3,
		label: "Si seulement tous pouvaient être comme lui / elle !",
		emoji: "😍",
		ariaLabel: "yeux-en-coeur",
		sentence: ({ firstName }) => `Vous adorez travailler avec ${firstName} !`,
	},
];

const EmojiContainer = styled.span`
	img {
		max-width: 100%;
		max-height: 100%;
		width: 100px;
	}
`;

export const EmotionScore = ({ option, score }) => (
	<Twemoji options={{ folder: "svg", ext: ".svg" }}>
		<EmojiContainer
			role="img"
			aria-label={
				option
					? option.ariaLabel
					: score !== null
					? feedbacks[score].ariaLabel
					: "sourire"
			}
			style={{ position: "relative" }}
		>
			{option ? option.emoji : score !== null ? feedbacks[score].emoji : "🙂"}
		</EmojiContainer>
	</Twemoji>
);

export default feedbacks.map(option => ({
	...option,
	Emotion: () => <EmotionScore option={option} />,
}));
