import React from "react";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default ({ date, onChange }) => (
	<Select
		value={moment(date).format("MMMM")}
		style={{ marginRight: 20 }}
		onChange={e =>
			onChange &&
			onChange(
				moment(date)
					.month(e.target.value)
					.toISOString(),
			)
		}
	>
		{moment.months().map(month => (
			<MenuItem value={month} key={month}>
				{month}
			</MenuItem>
		))}
	</Select>
);
