import React from "react";
import styled from "styled-components";
import { MdError } from "react-icons/md";
import { Form } from "./NewQuery/Form";
import Request from "common/Request";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import { color } from "common/styles";
import { DEV } from "common/constants";
import { connect } from "react-redux";
import { createQuery } from "./NewQuery";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import reasons from "common/reasons.json";
import validate from "./NewQuery/validate";
import useJobs from "common/useJobs";

const EnhancedForm = compose(
	connect(state => {
		const { reason, shifts } = getFormValues(state.form);
		return {
			specifiesReason: true,
			initialValues: {
				reason: reasons.habit,
				justification: "LIE A UNE VARIATION CYCLIQUE D'ACTIVITE",
			},
			reason,
			shifts,
			pickJob: true,
		};
	}),
	reduxForm({
		form: "NewContract",
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		validate,
	}),
)(Form);

const getFormValues = ({ NewContract = {} }) => {
	const { values } = NewContract;
	return values || {};
};

class NewContract extends React.Component {
	state = {};

	submit = async ({ tracked, replacementJustification, ...contractParams }) => {
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation($contract: AdminCreateContractInput!, $tracked: Boolean) {
						adminCreateContract(contract: $contract, tracked: $tracked) {
							id
						}
					}
				`,
				{
					contract: {
						...contractParams,
						...createQuery(
							{ ...contractParams, replacementJustification },
							this.props,
						),
					},
					tracked,
				},
			);
			this.props.dismiss();
			dispatch(addAlert("Le contrat a été créé"));
		} catch (error) {
			DEV && console.error(error);
			this.setState({ error });
		}
	};

	render() {
		const { error } = this.state;
		return (
			<Container>
				{error && (
					<SubmitError>
						<MdError /> Une erreur s'est produite. Veuillez réessayer.
					</SubmitError>
				)}
				<EnhancedForm
					onSubmit={this.submit}
					jobs={this.props.jobs}
					adminNewContract
				/>
			</Container>
		);
	}
}

export default props => {
	const jobs = useJobs();
	return <NewContract jobs={jobs} {...props} />;
};

const Container = styled.div``;
const SubmitError = styled.div`
	color: ${color("important")};
	padding: 10px 0;
`;
