import * as React from "react";
import styled from "styled-components";
import { color } from "common/styles";

const PillText = styled.div`
	margin-right: 10px;
	border-radius: 20px;
	padding: 5px;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 6px;
	display: flex;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
	background-color: ${color("primary")};
	align-items: center;
	line-height: 1.5em;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	@media print {
		background: transparent;
		border: 1px solid ${color("primary")};
		color: ${color("primary")};
	}
`;

export const PillsList = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
export const DeleteLink = styled.a`
	margin-right: 7px;
	color: #fff;
`;

export const Pill = ({ children, onDelete }) => (
	<PillText
		onClick={() => onDelete && onDelete()}
		style={{ cursor: onDelete ? "pointer" : "default" }}
	>
		{onDelete ? <DeleteLink>x</DeleteLink> : null}
		<div>{children}</div>
	</PillText>
);
