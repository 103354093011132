import React, { useState } from "react";
import Request from "common/Request";
import { TextField } from "material-ui";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/Button";
import DateField from "components/DateField";
import { Pill, PillsList } from "components/Pills";
import moment from "moment";
import gql from "graphql-tag";
import styled from "styled-components";
import SurveyUsersBox from "./SurveyUsersBox";
import SurveyJobsBox from "./SurveyJobsBox";
import SurveyAnswers from "./SurveyAnswers";
import { useJobs } from "models/jobs";

const Dates = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	label {
		margin-right: 10px;
		&:nth-of-type(2) {
			margin-left: 25px;
		}
	}
`;

const UpdateSurvey = gql`
	mutation($id: ID!, $surveyInput: Admin_SurveyInput!) {
		admin {
			updateSurvey(id: $id, surveyInput: $surveyInput) {
				id
			}
		}
	}
`;

const CreateSurvey = gql`
	mutation($surveyInput: Admin_SurveyInput!) {
		admin {
			createSurvey(surveyInput: $surveyInput) {
				id
			}
		}
	}
`;

export default ({ survey, dismiss }) => {
	const { jobs } = useJobs();
	const [loading, setLoading] = useState(false);
	const [showJobs, setShowJobs] = useState(false);
	const [showUsers, setShowUsers] = useState(false);

	const [surveyInput, setSurveyInput] = useState({
		question: survey.question,
		startAt: moment(survey.startAt).toISOString(),
		endAt: moment(survey.endAt).toISOString(),
		workerVisibility: survey.workerVisibility,
		businessVisibility: survey.businessVisibility,
		multipleResponse: !!survey.multipleResponse,
		customResponse: !!survey.customResponse,
		predefinedAnswers: survey.predefinedAnswers,
		randomizePredefinedAnswers:
			survey.question === undefined
				? true
				: !!survey.randomizePredefinedAnswers,
		...(survey.targetedUsers
			? { targetedUserIds: survey.targetedUsers.map(u => u.id) }
			: {}),
		...(survey.blacklistedUsers
			? { blacklistedUserIds: survey.blacklistedUsers.map(u => u.id) }
			: {}),
		...(survey.targetedJobs
			? { targetedJobKeys: survey.targetedJobs.map(u => u.key) }
			: {}),
	});

	return (
		<div class="grid">
			<article class="card six from-four col">
				<h1>{survey.id ? "Modifier le " : "Nouveau"} sondage</h1>
				<TextField
					id="question"
					fullWidth={true}
					placeholder="Question"
					defaultValue={surveyInput.question}
					onChange={e =>
						setSurveyInput({ ...surveyInput, question: e.target.value })
					}
				/>
				<Dates>
					<label>Date de début</label>
					<DateField
						date={surveyInput.startAt}
						onDateChange={startAt =>
							setSurveyInput({ ...surveyInput, startAt })
						}
						name="Date de début"
					/>
					<label>Date de fin</label>
					<DateField
						date={surveyInput.endAt}
						onDateChange={endAt => setSurveyInput({ ...surveyInput, endAt })}
						name="Date de fin"
					/>
				</Dates>
				<SurveyAnswers
					survey={survey}
					surveyInput={surveyInput}
					setSurveyInput={setSurveyInput}
				/>
				<div>
					<FormControlLabel
						control={
							<Checkbox
								checked={surveyInput.multipleResponse}
								onChange={e =>
									setSurveyInput({
										...surveyInput,
										multipleResponse: e.target.checked,
									})
								}
							/>
						}
						label="Autoriser les réponses multiples"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={surveyInput.customResponse}
								onChange={e =>
									setSurveyInput({
										...surveyInput,
										customResponse: e.target.checked,
									})
								}
							/>
						}
						label="Ajouter un champ libre"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={surveyInput.randomizePredefinedAnswers}
								onChange={e =>
									setSurveyInput({
										...surveyInput,
										randomizePredefinedAnswers: e.target.checked,
									})
								}
							/>
						}
						label="Ordre des réponses aléatoire"
					/>
				</div>
				<section class="col">
					<h3>Définir les utilisateurs</h3>
					<div class="centered row">
						{survey.workerVisibility ? (
							<Button
								secondary
								className="outline"
								onClick={() => setShowJobs(true)}
							>
								Par métier
							</Button>
						) : null}
						<Button
							secondary
							className="outline"
							onClick={() => setShowUsers(true)}
						>
							Par identifiant
						</Button>
					</div>
				</section>
				{surveyInput.targetedJobKeys ? (
					<section>
						<PillsList>
							{surveyInput.targetedJobKeys.map(k => (
								<Pill key={k}>{jobs.find(j => j.key === k).name}</Pill>
							))}
						</PillsList>
					</section>
				) : null}
				<SurveyUsersBox
					visible={showUsers}
					onDismiss={() => setShowUsers(false)}
					onChange={survey => setSurveyInput({ ...surveyInput, ...survey })}
					{...surveyInput}
				/>
				<SurveyJobsBox
					visible={showJobs}
					onDismiss={() => setShowJobs(false)}
					setSurveyInput={setSurveyInput}
					surveyInput={surveyInput}
				/>
				<section class="row">
					<Button
						className="right"
						loading={loading}
						onClick={async () => {
							setLoading(true);
							await Request.mutation(
								survey.id ? UpdateSurvey : CreateSurvey,
								survey.id
									? {
											surveyInput,
											id: survey.id,
									  }
									: { surveyInput },
							);
							await Request.refreshAll();
							dismiss();
						}}
					>
						Enregistrer
					</Button>
				</section>
			</article>
		</div>
	);
};
