import React from "react";
import { compose } from "recompose";
import { Query, Mutation as ApolloMutation } from "react-apollo";
import Loading from "components/Loading";
import ErrorBox from "components/ErrorBox";
import { pollInterval } from "common/constants";
import { merge } from "lodash";
import useAuth0 from "common/useAuth0";

const makeGraphQLQuery = Component => ({
	data,
	error,
	networkStatus,
	hideLoading,
	...props
}) =>
	networkStatus === 1 || networkStatus === 2 ? (
		!hideLoading ? (
			<Loading />
		) : null
	) : error ? (
		<ErrorBox error={error} />
	) : (
		<Component {...props} {...data} />
	);

const addGraphql = ({
	query,
	options,
	requireAuthentication,
}) => Component => props => {
	const { isAuthenticated } = useAuth0();

	return (
		<Query
			query={query}
			skip={requireAuthentication && !isAuthenticated}
			notifyOnNetworkStatusChange={true}
			pollInterval={pollInterval}
			{...options(props)}
		>
			{args => {
				if (args.networkStatus === 2) {
					return <Loading />;
				}
				return <Component {...props} {...args} />;
			}}
		</Query>
	);
};

export default (
	query,
	options = () => {},
	{ requireAuthentication = true } = {},
) =>
	compose(
		addGraphql({ query, options, requireAuthentication }),
		makeGraphQLQuery,
	);

export const GraphQL = ({
	requireAuthentication,
	query,
	children,
	hideLoading,
	...options
}) => {
	const { isAuthenticated } = useAuth0();

	if (!options.noPoll && !options.pollInterval) {
		options.pollInterval = pollInterval;
	}

	return (
		<Query
			query={query}
			skip={requireAuthentication && !isAuthenticated}
			notifyOnNetworkStatusChange={true}
			{...options}
		>
			{({ data, error, networkStatus, ...props }) =>
				networkStatus === 1 || networkStatus === 2 ? (
					!hideLoading ? (
						<Loading />
					) : null
				) : error ? (
					<ErrorBox error={error} />
				) : (
					children({ ...props, ...data })
				)
			}
		</Query>
	);
};

export const updateById = (list, newElement, id) => [
	...list.map(e => (e.id !== id ? e : merge(e, newElement))),
];

export const toVariables = (obj, customize) => {
	if (!obj || !(typeof obj === "object")) {
		return obj;
	}

	const ignored = customize.skipProps
		? ["id", "__typename", ...customize.skipProps]
		: ["id", "__typename"];
	return Object.keys(obj).reduce((acc, prop) => {
		if (ignored.includes(prop)) {
			return acc;
		}

		if (obj[prop] instanceof Array) {
			return {
				...acc,
				[prop]: obj[prop].map(el => toVariables(el, customize)),
			};
		}

		if (typeof obj[prop] === "object") {
			return { ...acc, [prop]: toVariables(obj[prop], customize) };
		}

		return { ...acc, [prop]: obj[prop] };
	}, {});
};

export const Mutation = props => {
	const cleanedVars = props.variables
		? toVariables(props.variables, { skipProps: props.skipProps })
		: undefined;
	return <ApolloMutation {...props} variables={cleanedVars} />;
};
