import React from "react";
import styled from "styled-components";
import { Row } from "components/Card";
import Button from "components/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Lightbox from "components/Lightbox";
import SendFeedback from "./SendFeedback";
import apolloClient from "common/apolloClient";

const ImproveQuestion = styled.strong`
	font-size: 14px;
	margin-top: 15px;
	margin-bottom: 15px;
`;

const CommentForm = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	textarea {
		font-size: 14px;
	}
`;

const OtherReason = styled(Row)`
	margin-left: -14px;

	> div > div {
		top: 7px;
	}

	input {
		font-size: 14px;
	}
`;

export default class DebriefDialog extends React.Component {
	state = {
		comment: "",
		reasons: {
			skills: false,
			outfit: false,
			punctuality: false,
			teamIntegration: false,
			behaviourWithCustomers: false,
		},
		pickedCustom: false,
	};

	negativeFeedbackComment =
		"Nous sommes désolés que cette mission se soit mal passée ! " +
		"Donnez-nous plus d'informations pour nous aider à trouver des " +
		"profils qui vous conviennent mieux.";

	positiveFeedbackComment = workerName =>
		`En quoi la prestation de ${workerName} a-t-elle très bien répondu à vos attentes ?`;

	loveFeedbackComment = workerName => `Qu'avez-vous adoré chez ${workerName} ?`;

	reasons = [
		{
			key: "skills",
			label: "Sa maîtrise du métier",
		},
		{
			key: "outfit",
			label: "Son respect de la tenue demandée pour la mission",
		},
		{
			key: "teamIntegration",
			label: "L'intégration avec l'équipe",
		},
		{
			key: "behaviourWithCustomers",
			label: "Le savoir-être avec les clients",
		},
		{
			key: "punctuality",
			label: "Sa ponctualité",
		},
	];

	otherReason() {
		const { pickedCustom } = this.state;

		return (
			<OtherReason>
				<Checkbox
					checked={pickedCustom}
					onChange={(e, pickedCustom) => this.setState({ pickedCustom })}
				/>
				<div>
					<TextField
						type="text"
						placeholder="Autre"
						onFocus={() =>
							this.setState({
								pickedCustom: true,
							})
						}
						onChange={e =>
							this.setState({
								customAnswer: e.target.value,
							})
						}
					/>
				</div>
			</OtherReason>
		);
	}

	reasonCheckbox(reason) {
		const { reasons } = this.state;
		const isChecked = reasons[reason.key];

		return (
			<div key={reason.key}>
				<FormControlLabel
					control={
						<Checkbox
							checked={isChecked}
							onChange={e =>
								this.setState({
									reasons: {
										...reasons,
										[reason.key]: !isChecked,
									},
								})
							}
						/>
					}
					label={reason.label}
				/>
			</div>
		);
	}

	improveForm() {
		const { name } = this.props;

		return (
			<React.Fragment>
				<hr />
				<ImproveQuestion>
					Qu'est-ce que {name} devrait améliorer dans son travail ?
				</ImproveQuestion>
				{this.reasons.map(reason => this.reasonCheckbox(reason))}
				{this.otherReason()}
			</React.Fragment>
		);
	}

	get shouldImprove() {
		const { customAnswer, pickedCustom, reasons } = this.state;

		return {
			...reasons,
			custom: pickedCustom ? customAnswer : null,
		};
	}

	async sendFeedback(ignore) {
		const { score, contractId, onDismiss } = this.props;
		const { comment } = this.state;

		this.setState({ loading: true });

		const variables = {
			score,
			contractId,
			comment: ignore ? undefined : comment,
			shouldImprove: ignore ? undefined : this.shouldImprove,
		};

		console.log("Answered feedback");
		console.log(variables);

		await apolloClient.mutate({
			mutation: SendFeedback,
			variables,
		});

		await apolloClient.reFetchObservableQueries();

		try {
			this.setState({ loading: false });
			onDismiss(true);
		} catch (e) {
			//component is unmounted
		}
	}

	componentDidUpdate() {
		const { visible } = this.props;
		if (visible) {
			this.commentTextarea.focus();
		}
	}

	render() {
		const { visible, name, isHappy, onDismiss, score } = this.props;
		const { loading, comment } = this.state;

		return (
			<Lightbox visible={visible} onDismiss={() => onDismiss(false)}>
				<article class="col">
					<h2>Commentaire</h2>
					<CommentForm>
						<TextField
							inputRef={ref => (this.commentTextarea = ref)}
							multiline={true}
							fullWidth={true}
							variant="outlined"
							rows={4}
							onChange={e =>
								this.setState({
									comment: e.target.value,
								})
							}
							placeholder={
								isHappy
									? score === 3
										? this.loveFeedbackComment(name)
										: this.positiveFeedbackComment(name)
									: this.negativeFeedbackComment
							}
						/>
						{!isHappy ? this.improveForm() : null}
					</CommentForm>
					<section class="row">
						<Button
							className="right"
							disabled={!comment}
							loading={loading}
							onClick={() => this.sendFeedback(false)}
						>
							Envoyer
						</Button>
					</section>
				</article>
			</Lightbox>
		);
	}
}
