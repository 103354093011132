import { useState } from "react";
import { color } from "common/styles";
import { hasStarted, hasEnded } from "./shift";
import Request from "common/Request";
import store from "common/store";
import { addAlert } from "actions/alerts";
import apolloClient from "common/apolloClient";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import sum from "utils/sum";
import compare from "utils/compare";

export const statusElements = {
	timeout: {
		color: color("black", "bright"),
		label: "N'a pas été validé",
	},
	"waiting signature": {
		color: color("almostValid"),
		label: "En attente de validation",
	},
	signed: {
		color: color("valid"),
		label: "Validé",
	},
	"pending business tracking": {
		color: color("action"),
		label: "En attente de pointage",
	},
	"pending worker tracking": {
		color: color("almostValid"),
		label: "En attente de validation des horaires",
	},
	tracked: {
		color: color("valid"),
		label: "Horaires validés",
	},
	"expired auto tracking": {
		color: color("valid"),
		label: "Horaires validés automatiquement",
	},
	"rejected auto tracking": {
		color: color("important"),
		label: "Horaires contestés",
	},
	"pending auto tracking": {
		color: color("action"),
		label: "Horaires pointés automatiquement",
	},
};

export const getContractStatus = ({
	signed,
	businessTracked,
	tracked,
	shifts,
	businessAutoTracked,
	trackingValidated,
	businessRejectedAutoTracking,
}) => {
	if (hasStarted({ shifts }) && !signed) {
		return "timeout";
	}
	if (hasEnded({ shifts })) {
		if (!businessTracked) {
			return "pending business tracking";
		}
		if (businessAutoTracked) {
			if (trackingValidated) {
				return "expired auto tracking";
			}
			if (businessRejectedAutoTracking) {
				return "rejected auto tracking";
			}
			return "pending auto tracking";
		}
		if (!tracked) {
			return "pending worker tracking";
		}
		return "tracked";
	}
	if (!signed) {
		return "waiting signature";
	}
	return "signed";
};

export const makeContract = contract => {
	const status = getContractStatus(contract);
	const canCancel = status === "waiting signature";
	const onCancel = async () => {
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation($id: String!) {
						cancelContract(id: $id) {
							id
						}
					}
				`,
				{
					id: contract.id,
				},
			);
			await apolloClient.reFetchObservableQueries();
			store.dispatch(addAlert("Le contrat a été annulé."));
		} catch (error) {
			store.dispatch(addAlert("Le contrat n'a pas pu être annulé."));
		}
	};
	return {
		status,
		onCancel: canCancel ? onCancel : undefined,
		priorityColor: priorityColor(contract.startTime),
		...contract,
	};
};

export const priorityColor = startTime => {
	startTime = moment(startTime);
	let priority = "important";
	if (
		startTime.isAfter(moment()) &&
		startTime.isBefore(moment().add(12, "hours"))
	) {
		priority = "attention";
	} else if (startTime.isAfter(moment())) {
		priority = null;
	}
	return priority;
};

const getDirectContractDraft = gql`
	query directContractDrafts($query: QueryInput!) {
		businessScope {
			directContractDrafts(query: $query) {
				provisionalBillingAmount
			}
		}
	}
`;

const getTrackings = gql`
	query contractsToTrack {
		contractsToTrack {
			id
			businessAutoTracked
			startTime
			getWorker {
				id
				name
			}
			query {
				id
			}
		}
	}
`;

export const useDirectContractDraft = () => {
	const [query, setQuery] = useState({});

	const { loading, data } = useQuery(getDirectContractDraft, {
		variables: {
			query: {
				...query,
				justification: "Contrat de test",
			},
		},
		skip: !query.jobKey,
	});

	if (!query.jobKey) {
		return { loading: false, contract: undefined, setQuery };
	}

	if (loading || !data || !data.businessScope) {
		return { loading: true, contract: {}, setQuery };
	}

	return {
		loading,
		contract: {
			provisionalBillingAmount: sum(
				data.businessScope.directContractDrafts.map(
					c => c.provisionalBillingAmount,
				),
			),
		},
		setQuery,
	};
};

export const useTrackings = () => {
	const { data, loading } = useQuery(getTrackings);

	if (!data || !data.contractsToTrack || loading) {
		return { loading: true, trackings: [] };
	}

	const lateTrackings = moment().date() >= 25 ? data.contractsToTrack : [];

	const previousMonthTrackings = data.contractsToTrack.filter(
		t =>
			moment(t.startTime) <
			moment()
				.endOf("month")
				.subtract(1, "month"),
	);

	const emergencyTrackings = previousMonthTrackings.concat(
		lateTrackings.filter(
			lt =>
				lt.businessAutoTracked &&
				!previousMonthTrackings.some(pmt => pmt.id === lt.id),
		),
	);

	return {
		loading,
		trackings: data.contractsToTrack,
		emergencyTrackings,
		lateTrackings: [
			...lateTrackings.filter(
				t => !emergencyTrackings.some(et => et.id === t.id),
			),
			...emergencyTrackings,
		].sort(compare(e => moment(e.startTime), { asc: true })),
		isLockedOut: emergencyTrackings.length > 0,
	};
};
