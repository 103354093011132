export default (
	{
		farmer,
		hunter,
		location,
		owner: { defaultQueries, ...owner },
		contacts,
		company,
		category,
		...business
	} = { owner: {} },
) => ({
	...business,
	company: company || { address: {} },
	location: location || {},
	farmerId: (farmer || {}).id,
	hunterId: (hunter || {}).id,
	categoryKey: (category || {}).key,
	contacts: contacts || [],
	defaultQueries: (defaultQueries || []).map(
		({ job: { key }, comment, breakMinutes, rate, conditions }) => ({
			jobKey: key,
			comment,
			rate,
			breakMinutes,
			conditions: conditions
				? conditions.map(condition => ({ ...condition }))
				: [],
		}),
	),
	owner,
});
