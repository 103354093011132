import React from "react";
import { Document, DocumentTitle } from "./shared";
import { TextField, Checkbox, DatePicker } from "redux-form-material-ui";
import { Field } from "redux-form";
import moment from "moment";

export default props => {
	const { initialValues } = props;

	const expiresAt = initialValues.belongsToEU ? null : (
		<Field
			name="expiresAt"
			floatingLabelText="Date d'expiration"
			fullWidth={true}
			type="text"
			disabled={true}
			component={DatePicker}
			format={date => (date ? moment(date).toDate() : null)}
		/>
	);
	const number = initialValues.belongsToEU ? null : (
		<Field
			name="number"
			floatingLabelText="Numéro de titre de séjour"
			fullWidth={true}
			type="text"
			disabled={true}
			component={TextField}
		/>
	);

	return (
		<Document>
			<DocumentTitle>
				Pièce d'identité ({initialValues.nationality})
			</DocumentTitle>
			{expiresAt}
			{number}
			<Field name="validatedAt" label="Valider" component={Checkbox} />
		</Document>
	);
};
