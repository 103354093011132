import React, { useContext } from "react";
import Loading from "components/Loading";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import useAuth0 from "./useAuth0";

export const CurrentUserContext = React.createContext();

export default () => useContext(CurrentUserContext);

const CURRENT_USER = gql`
	query CurrentUser {
		currentUser {
			id
			roles
			primaryRole
			business {
				id
				mediaUrls
				name
				specifiesLocation
				specifiesReason
				defaultBreakMinutes
				locked
				completed
				verified
				managedByGofer
				shouldDisplaySatisfactionAndPredictionSurvey
				specifiesGrossRate
				canCreateJobPosts
				benefitsAmount
				benefitsDescription
				description
				dossier
			}
			defaultQueries {
				job {
					key
				}
				breakMinutes
				rate
				comment
			}
			firstName
			lastName
		}
	}
`;

export const CurrentUserProvider = ({ children }) => {
	const { isAuthenticated } = useAuth0();
	const { loading, data } = useQuery(CURRENT_USER, { skip: !isAuthenticated });
	if (loading) {
		return <Loading />;
	}

	if (
		window.location.search.includes("as-business") &&
		data &&
		data.currentUser
	) {
		data.currentUser.primaryRole = "business";
	}

	return (
		<CurrentUserContext.Provider
			value={isAuthenticated ? data.currentUser : null}
		>
			{children}
		</CurrentUserContext.Provider>
	);
};
