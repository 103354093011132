import React from "react";
import styled from "styled-components";
import { GraphQL } from "common/graphql";
import { GetWorkerResume, saveXp, jobsToValidate } from "models/worker/resume";
import Experience from "./Experience";
import Table from "components/Table";
import { Row } from "components/Card";
import { addModal } from "architecture/Modals";
import ExperienceModal from "./ExperienceModal";
import EditWorkerExperience from "modals/ReferenceValidator/EditWorkerExperience";
import Button from "components/Button";
import moment from "moment";

const ExperienceContainer = styled.div`
	min-height: 100%;
	width: 100%;
	margin: auto;
	background: #f5f5f5;
	padding-top: 15px;
	padding-left: 10%;
	padding-right: 10%;
	overflow: auto;
	> div {
		margin-bottom: 50px;
	}
	table {
		.business-name {
			margin-right: auto;
		}
	}
`;

const Experiences = ({ worker, workXp, trainings, match }) => (
	<React.Fragment>
		<div class="row">
			<Button
				className="right"
				onClick={() =>
					addModal(({ dismiss }) => (
						<EditWorkerExperience
							dismiss={dismiss}
							training={false}
							currentWorker={worker.id}
							endDate={moment().toISOString()}
							jobs={[]}
						/>
					))
				}
			>
				Ajouter une expérience
			</Button>
		</div>
		{worker.experiences.length === 0 && (
			<article class="card">
				{worker.name} n'a pas encore entré d'expériences
			</article>
		)}

		{workXp.length > 0 && (
			<article class="card col">
				<div class="row">
					<h2>Expérience professionnelle</h2>
				</div>
				{workXp.map(experience => (
					<section>
						<Experience
							key={experience.id}
							{...experience}
							currentWorker={match.params.workerId}
							onChange={newXp => saveXp(match.params.workerId, newXp)}
						/>
					</section>
				))}
			</article>
		)}
		{trainings.length > 0 && (
			<article class="card col">
				<div class="row">
					<h2>Formations</h2>
				</div>
				{trainings.map(experience => (
					<section>
						<Experience
							key={experience.id}
							{...experience}
							currentWorker={match.params.workerId}
							onChange={newXp => saveXp(match.params.workerId, newXp)}
						/>
					</section>
				))}
			</article>
		)}
	</React.Fragment>
);

export default ({ match }) => (
	<GraphQL
		query={GetWorkerResume}
		variables={{ workerId: match.params.workerId }}
		noPoll={true}
	>
		{({ referenceValidator: { worker } }) => {
			const workXp = worker.experiences.filter(xp => !xp.training);
			const trainings = worker.experiences.filter(xp => !!xp.training);
			const jobs = jobsToValidate(worker);

			return (
				<ExperienceContainer>
					<h1 className="main-header">{worker.name}</h1>
					{worker.oldReference || worker.oldExperience ? (
						<div className="error message">
							Attention ! La prise de référence a probablement déjà été faite
							sur la version 1 de l'appli.{" "}
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={`https://recruit.zoho.com/recruit/GlobalSearch1.do?sModules=AllEntities&searchword=${
									worker.id
								}`}
							>
								Rendez-vous sur Zoho
							</a>{" "}
							pour plus de détails.
						</div>
					) : null}
					{jobs.length === 0 ? null : (
						<Table
							rows={jobs}
							columns={[
								{
									label: "Métiers à valider",
									getter: ({ name }) => name,
								},
								{
									label: "Valide également",
									getter: ({ inferiorJobs }) => inferiorJobs.join(", "),
								},
								{
									label: "Expériences",
									getter: ({ experiences }) => (
										<div>
											{experiences.map(xp => (
												<Row key={xp.id}>
													<strong className="business-name">
														<button
															className="link"
															onClick={() =>
																addModal(() => (
																	<ExperienceModal
																		id={xp.id}
																		workerId={worker.id}
																	/>
																))
															}
														>
															{xp.businessName}
														</button>
													</strong>
													{xp.referrals.some(ref => ref.status === "contacted")
														? "Contacté"
														: "Pas encore contacté"}
												</Row>
											))}
										</div>
									),
								},
							]}
						/>
					)}
					<Experiences
						worker={worker}
						workXp={workXp}
						trainings={trainings}
						match={match}
					/>
				</ExperienceContainer>
			);
		}}
	</GraphQL>
);
