import React from "react";
import { Route, Switch } from "react-router";
import BTPAdmin from "./BTPAdmin";
import CHRAdmin from "./CHRAdmin";

const Component = () => (
	<div style={{ flex: 1 }}>
		<Switch>
			<Route exact path="/" component={CHRAdmin} />
			<Route exact path="/btp" component={BTPAdmin} />
			<Route exact path="/btp/:route" component={BTPAdmin} />
			<Route exact path="/:route" component={CHRAdmin} />
			<Route exact path="/:route/:type/:id" component={CHRAdmin} />
		</Switch>
	</div>
);

export const admin = { Component };
