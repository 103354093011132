import React from "react";
import gql from "graphql-tag";
import graphql from "common/graphql";
import styled from "styled-components";
import { color } from "common/styles";
import groupByBatch from "utils/groupByBatch";

export const MassTextMessage = ({ query: { getTargets } }) => (
	<Container>
		<Title>
			Numéros de téléphone des workers cible n'ayant pas encore répondu (par 50)
		</Title>
		<React.Fragment>
			{groupByBatch(
				getTargets.map(target => target.worker.phoneNumber).filter(Boolean),
				50,
			).map((phoneNumbers, index) => (
				<Batch phoneNumbers={phoneNumbers} key={index} />
			))}
		</React.Fragment>
	</Container>
);

const Batch = ({ phoneNumbers }) => <Content>{phoneNumbers.join(";")}</Content>;

export default graphql(
	gql`
		query getQueryTargets($queryId: String!) {
			query(id: $queryId) {
				id
				getTargets(answered: false, isGofer: false, sortBy: lastActivityDate) {
					worker {
						id
						phoneNumber
					}
				}
			}
		}
	`,
	({ actualQuery }) => ({ variables: { queryId: actualQuery.id } }),
)(MassTextMessage);

const Container = styled.div``;
const Title = styled.div`
	margin: 20px;
	font-size: 1.2em;
	font-weight: bold;
`;
const Content = styled.div`
	padding: 10px 15px;
	border: solid 1px ${color("black", "pale")};
	border-radius: 5px;
	background-color: ${color("black", "translucent")};
	margin-top: 10px;
	font-size: 0.5em;
	width: 800px;
	user-select: all;
`;
