import gql from "graphql-tag";
import moment from "moment";
import apolloClient from "common/apolloClient";
import { toVariables } from "common/graphql";
import compare from "utils/compare";
import { uniqBy, flatten } from "lodash";
import Request from "common/Request";

export const GetWorkerResume = gql`
	query worker($workerId: ID!) {
		referenceValidator {
			worker(id: $workerId) {
				id
				name
				oldExperience
				oldReference
				jobs {
					name
					key
				}
				jobPreferences {
					job {
						key
					}
				}
				experiences {
					id
					startDate
					endDate
					businessName
					hidden
					training
					description
					status
					referrals {
						id
						name
						phoneNumber
						status
					}
					jobs {
						name
						key
						inferiorJobKeys
					}
				}
			}
		}
	}
`;

const maxYears = 20;

export const capitalize = date =>
	date[0].toUpperCase() + date.substr(1).toLowerCase();

export const cvDates = (startDate, endDate) =>
	`${capitalize(moment(startDate).format("MMMM YYYY"))} - ${
		endDate === null
			? "Aujourd'hui"
			: capitalize(moment(endDate).format("MMMM YYYY"))
	}`;

export const computeJobsExperience = (experiences, includeInferiorJobKeys) => {
	const jobs = [];

	const addJob = (xp, xpJob) => {
		let job = jobs.find(j => j.key === xpJob.key);
		if (!job) {
			job = {
				...xpJob,
				months: [],
				nbMonths: 0,
			};
			jobs.push(job);
		}

		!job.name && xpJob.name && (job.name = xpJob.name);
		let dateIndex = xp.endDate ? moment(xp.endDate) : moment();

		while (moment(dateIndex).diff(xp.startDate, "months") > 0) {
			dateIndex = moment(dateIndex).subtract(1, "month");
			if (!job.months.includes(dateIndex.format("MM/YYYY"))) {
				job.months.push(dateIndex.format("MM/YYYY"));
			}
		}

		job.nbMonths = job.months.length;
		//Let's be generous with the dates
		job.nbMonths++;
	};

	experiences
		.filter(xp => !xp.training)
		.forEach(xp => {
			xp.jobs.forEach(xpJob => {
				addJob(xp, xpJob);
				includeInferiorJobKeys &&
					xpJob.inferiorJobKeys.forEach(jobKey => addJob(xp, { key: jobKey }));
			});
		});

	return jobs;
};

export const computeJobPercent = job =>
	Math.log(job.nbMonths || 1) / Math.log(12 * maxYears);

export const saveWorkerResume = async (saveResume, workerId, experiences) => {
	await saveResume({
		variables: toVariables(
			{
				experiences: experiences.map(xp => ({
					...xp,
					jobKeys: xp.jobs.map(j => j.key),
				})),
				workerId,
			},
			{ skipProps: ["jobs", "currentWorker"] },
		),
		update: () => {
			apolloClient.reFetchObservableQueries();
		},
	});
};

const CreateExperience = gql`
	mutation($workerId: ID!, $experience: ReferenceValidator_ExperienceInput!) {
		referenceValidator {
			createWorkerExperience(workerId: $workerId, experience: $experience) {
				id
			}
		}
	}
`;

const UpdateExperience = gql`
	mutation(
		$workerId: ID!
		$experienceId: ID!
		$experience: ReferenceValidator_ExperienceInput!
	) {
		referenceValidator {
			updateWorkerExperience(
				workerId: $workerId
				experienceId: $experienceId
				experience: $experience
			) {
				id
			}
		}
	}
`;

const RemoveExperience = gql`
	mutation($workerId: ID!, $experienceId: ID!) {
		referenceValidator {
			removeWorkerExperience(workerId: $workerId, experienceId: $experienceId) {
				id
			}
		}
	}
`;

export const removeXp = async (workerId, experienceId) => {
	await Request.mutation(RemoveExperience, {
		experienceId,
		workerId,
	});
	await Request.refreshAll();
};

export const saveXp = async (
	workerId,
	{
		id,
		startDate,
		endDate,
		businessName,
		description,
		hidden,
		jobs,
		training,
		referrals,
		status,
	},
) => {
	await Request.mutation(id ? UpdateExperience : CreateExperience, {
		...(id ? { experienceId: id } : {}),
		workerId,
		experience: {
			startDate,
			endDate,
			businessName,
			description,
			hidden,
			jobKeys: jobs.map(j => j.key),
			training,
			status,
			referrals: (referrals || []).map(({ name, phoneNumber, status }) => ({
				name,
				phoneNumber,
				status,
			})),
		},
	});
	await Request.refreshAll();
};

export const experienceStatusList = [
	{ key: "validated", name: "Expérience validée" },
	{ key: "rejected", name: "Expérience rejetée" },
	{ key: "insufficient", name: "Expérience non pertinente" },
	{ key: "validationInProgress", name: "En cours de validation" },
];

export const jobsToValidate = worker => {
	const jobs = uniqBy(
		flatten(
			worker.experiences.map(xp =>
				xp.jobs.map(j => ({
					...j,
					experiences: worker.experiences.filter(
						xp =>
							xp.status === "validationInProgress" &&
							xp.jobs.find(job => job.key === j.key) &&
							xp.referrals.find(
								ref =>
									ref.status === "contacted" ||
									ref.status === "notYetContacted",
							),
					),
				})),
			),
		),
		j => j.key,
	).filter(
		jobToValidate =>
			!worker.jobPreferences.some(jp => jp.job.key === jobToValidate.key) &&
			jobToValidate.experiences.length > 0,
	);
	return jobs
		.map(job => ({
			...job,
			inferiorJobs: jobs
				.filter(j => job.inferiorJobKeys.includes(j.key))
				.map(j => j.name),
		}))
		.sort(compare(job => job.inferiorJobs.length));
};
