import React from "react";
import Table from "components/Table";
import { addModal } from "architecture/Modals";
import AdminWorkerDocuments from "modals/AdminWorkerDocuments";
import moment from "moment";
import { formatBoolean } from "common/utilities";

export default ({ workers }) => {
	const columns = [
		{
			label: "Identifiant",
			getter: ({ id }) => id,
		},
		{
			label: "Name",
			getter: ({ name }) => name,
		},
		{
			label: "EEE",
			getter: ({ belongsToEU }) => formatBoolean(belongsToEU),
		},
		{
			label: "Références validées",
			getter: ({ referenceVerifiedAt }) =>
				referenceVerifiedAt
					? moment(referenceVerifiedAt).format("LLLL")
					: "Non",
		},
		{
			label: "Date de dernier upload",
			getter: ({ documentsLastUploadedAt }) =>
				moment(documentsLastUploadedAt).format("LLLL"),
		},
	];

	return (
		<Table
			id="WorkerDocumentsTable"
			columns={columns}
			rows={workers.map(worker => ({
				...worker,
				onClick: () => {
					addModal(({ dismiss }) => (
						<AdminWorkerDocuments worker={worker} dismiss={dismiss} />
					));
					window.open(
						`https://drive.google.com/drive/folders/${
							worker.documents.googleFolderId
						}`,
						"worker-documents-folder",
					);
				},
			}))}
			orderBy={4}
		/>
	);
};
