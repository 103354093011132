import React from "react";
import styled from "styled-components";
import { MdCheck } from "react-icons/md";
import UnstyledAvatar from "components/Avatar";
import { circle, color } from "common/styles";

const maxAvatars = 4;

export default ({ contracts, notFoundCount }) => {
	let more = null;
	if (notFoundCount) {
		more = <NotFound>{notFoundCount > 1 ? notFoundCount : "?"}</NotFound>;
	} else if (contracts.length >= maxAvatars) {
		more = <MoreContracts>+{contracts.length - maxAvatars + 1}</MoreContracts>;
	}
	const shownContracts = contracts.slice(0, maxAvatars - 1).reverse();
	return (
		<Container avatarsCount={shownContracts.length + (more ? 1 : 0)}>
			{shownContracts.map((contract, index) => (
				<ContractAvatar key={index} index={index} {...contract} />
			))}
			{more && (
				<ContractAvatar key={"more"} index={shownContracts.length}>
					{more}
				</ContractAvatar>
			)}
		</Container>
	);
};

const ContractAvatar = ({
	index = 0,
	getWorker: { smallAvatarURL },
	signed,
	children,
}) => {
	let indicator;
	if (signed !== null) {
		indicator = <Signed signed={signed}>{signed ? <MdCheck /> : "?"}</Signed>;
	}
	return (
		<Avatar
			image={smallAvatarURL}
			index={index}
			indicator={indicator}
			radius={height / 2}
		>
			{children}
		</Avatar>
	);
};

ContractAvatar.defaultProps = {
	getWorker: {},
	signed: null,
};

const height = 35;
const gap = 10;
const Container = styled.div`
	flex-shrink: 0;
	width: ${({ avatarsCount }) => height + (avatarsCount - 1) * gap}px;
	position: relative;
`;
const NotFound = styled.div`
	${circle(height - 8)};
	display: flex;
	align-items: center;
	justify-content: center;
	border: dashed 2px white;
`;
const MoreContracts = styled.div``;
const Avatar = styled(UnstyledAvatar)`
	position: absolute;
	left: ${({ index }) => index * gap}px;
`;
const Signed = styled.span`
	color: ${({ signed }) =>
		signed ? color("valid") : color("black", "bright")};
`;
