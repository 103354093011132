import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

const ListJobs = gql`
	{
		jobs {
			key
			name
			unit
		}
	}
`;

export const useJobs = () => {
	const { loading, data } = useQuery(ListJobs);

	if (loading || !data || !data.jobs) {
		return { loading: true, jobs: [], units: [] };
	}

	return {
		loading,
		jobs: data.jobs,
		units: _.map(_.groupBy(data.jobs, j => j.unit), (jobs, unit) => ({
			unit,
			jobs,
		})),
	};
};
