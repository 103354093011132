import React from "react";
import { Document, DocumentTitle } from "./shared";
import styled from "styled-components";
import { color } from "common/styles";
import { TextField } from "redux-form-material-ui";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from "ibantools";
import { updateInformations, bankModel } from "models/worker/documents";
import store from "common/store";

const validate = async ({
	iban,
	bic,
	accountHolder,
	token,
	obfuscatedIBAN,
}) => {
	const errors = {};
	const bank = { bic, accountHolder };
	if (!obfuscatedIBAN) {
		iban = electronicFormatIBAN(iban);
		if (iban && !isValidIBAN(iban)) {
			errors.iban = "L'IBAN n'est pas au bon format";
		}
		bank.iban = iban;
	}
	if (bic && !isValidBIC(bic)) {
		errors.bic = "Le BIC n'est pas au bon format";
	}
	if (Object.keys(errors).length !== 0) {
		throw new SubmissionError(errors);
	}
	await updateInformations({
		token,
		documents: { bank },
	});
	store.dispatch({
		type: "redux-form/INITIALIZE",
		form: "bankValidation",
		data: { ...bankModel(bank), token },
		fields: Object.keys(bank),
	});
};

const Bank = props => {
	const { handleSubmit, initialValues } = props;

	return (
		<Document>
			<form>
				<DocumentTitle color={initialValues.status}>
					Compte bancaire
				</DocumentTitle>
				<p>Pour recevoir vos virements</p>
				<Field
					name="iban"
					floatingLabelText="IBAN"
					component={TextField}
					fullWidth={true}
					type="text"
					disabled={Boolean(initialValues.validatedAt)}
					props={{ onBlur: handleSubmit(validate) }}
				/>
				<SpanIBAN>{initialValues.obfuscatedIBAN}</SpanIBAN>
				<Field
					name="bic"
					floatingLabelText="BIC"
					fullWidth={true}
					component={TextField}
					disabled={Boolean(initialValues.validatedAt)}
					type="text"
					props={{ onBlur: handleSubmit(validate) }}
				/>
				<Field
					name="accountHolder"
					floatingLabelText="Titulaire du compte"
					fullWidth={true}
					component={TextField}
					disabled={Boolean(initialValues.validatedAt)}
					type="text"
					props={{ onBlur: handleSubmit(validate) }}
				/>
			</form>
		</Document>
	);
};

export default reduxForm({
	form: "bankValidation",
	enableReinitialize: true,
})(Bank);

const SpanIBAN = styled.span`
	font-style: italic;
	color: ${color("black", "bright")};
`;
