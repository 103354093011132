import moment from "moment";
import compare from "utils/compare";
import splitArray from "utils/splitArray";
import mapValues from "utils/mapValues";
import sum from "utils/sum";

export const timeReference = moment("2018-01-01"); // it's a Monday

export const getShiftsPerDay = unsortedShifts => {
	const shifts = unsortedShifts
		.map(shift => ({
			startTime: moment(shift.startTime),
			endTime: moment(shift.endTime),
			breakMinutes: shift.breakMinutes || 0,
		}))
		.map(shift => ({
			...shift,
			totalHours:
				(shift.endTime.diff(shift.startTime, "minutes") - shift.breakMinutes) /
				60,
		}))
		.sort(compare(shift => shift.startTime, { ascending: true }));
	const shiftsPerDay = splitArray(shifts, shift =>
		shift.startTime.diff(timeReference, "days"),
	);
	const days = Object.keys(shiftsPerDay).map(day => parseInt(day, 10));
	const weeks = splitArray(days, day => Math.floor(day / 7));
	const quarters = splitArray(days, day =>
		moment(timeReference)
			.add(day, "days")
			.diff(timeReference, "quarter"),
	);
	const totalHours = mapValues(shiftsPerDay, shifts =>
		sum(shifts.map(shift => shift.totalHours)),
	);
	return {
		shiftsPerDay,
		days,
		weeks,
		quarters,
		totalHours,
	};
};
