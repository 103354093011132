import React from "react";
import Lightbox from "components/Lightbox";
import { useJobs } from "models/jobs";
import Button from "components/Button";
import SelectJob from "components/SelectJob";

export default ({ visible, onDismiss, surveyInput, setSurveyInput }) => {
	const { jobs, units } = useJobs();

	return (
		<Lightbox visible={visible} onDismiss={onDismiss}>
			<article class="col">
				<section>
					<h3>Ajouter tous les métiers de...</h3>
				</section>
				<section class="row">
					{units.map(u => (
						<Button
							secondary
							className="outline"
							onClick={() =>
								setSurveyInput({
									...surveyInput,
									targetedJobKeys: [
										...(surveyInput.targetedJobKeys || []).filter(
											j => !u.jobs.some(uj => uj.key === j),
										),
										...u.jobs.map(j => j.key),
									],
								})
							}
						>
							{u.unit}
						</Button>
					))}
				</section>
				<section>
					<SelectJob
						jobs={jobs.filter(j =>
							(surveyInput.targetedJobKeys || []).includes(j.key),
						)}
						onChange={jobs =>
							setSurveyInput({
								...surveyInput,
								targetedJobKeys: jobs.map(j => j.key),
							})
						}
					/>
				</section>
				<section class="row">
					<Button className="right" onClick={onDismiss}>
						Fermer
					</Button>
				</section>
			</article>
		</Lightbox>
	);
};
