import { logglyToken, DEV } from "common/constants";

const logLevels = {
	log: console.log,
	warn: console.warn,
	error: console.error,
	debug: console.debug,
};

const makeBodyAndHeaders = content => {
	const isString = typeof content === "string";

	return {
		body: isString ? content : JSON.stringify(content),
		headers: {
			"content-type": isString ? "text/plain" : "application/json",
		},
	};
};

Object.keys(logLevels).forEach(key => {
	console[key] = async (...content) => {
		try {
			logLevels[key](...content);
		} catch (e) {
			//IE being testy
			//https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/14495220/
		}

		const logglyUrl = `https://logs-01.loggly.com/inputs/${logglyToken}/tag/http`;

		try {
			if (!DEV) {
				await fetch(logglyUrl, {
					method: "POST",
					...makeBodyAndHeaders(...content),
				});
			}
		} catch (e) {
			logLevels.log("Failed to log to loggly");
		}
	};
});
