import React from "react";
import styled from "styled-components";
import { isMobile } from "common/styles";
import Button from "components/Button";
import Tooltip from "@material-ui/core/Tooltip";
import debriefScores from "common/debriefScores";
import FeedbackDialog from "scenes/Home/Business/Feedbacks/FeedbackDialog";

export default class extends React.Component {
	state = {
		score: undefined,
		giveNewDebrief: false,
	};

	onClickScore = async score => {
		this.setState({ score, showDialog: true });
	};

	onChangeComment = ({ target: { value } }) => {
		this.setState({ comment: value });
	};

	render() {
		const {
			id,
			getWorker: { firstName },
			matching: {
				debrief: {
					business: { currentScore },
				},
			},
		} = this.props;
		const { score, giveNewDebrief, showDialog } = this.state;

		if (
			currentScore !== undefined &&
			currentScore !== null &&
			!giveNewDebrief
		) {
			const { sentence, Emotion } = debriefScores.find(
				option => option.score === currentScore,
			);
			return (
				<AlreadyDebriefed>
					<AlreadyDebriefedDescription>
						<Tooltip
							disableFocusListener
							title={sentence({ firstName })}
							placement="top"
						>
							<span>
								<Emotion />
							</span>
						</Tooltip>
					</AlreadyDebriefedDescription>
					<Button
						secondary
						onClick={() => this.setState({ giveNewDebrief: true })}
					>
						J'ai changé d'avis
					</Button>
				</AlreadyDebriefed>
			);
		}

		return (
			<Container>
				<Evaluation>
					Souhaiteriez-vous travailler à nouveau avec {firstName} ?
				</Evaluation>
				<Options>
					{debriefScores.map(({ score, label, Emotion }) => (
						<Option key={score} onClick={() => this.onClickScore(score)}>
							<OptionIcon>
								<Emotion />
							</OptionIcon>
							<OptionText>{label}</OptionText>
						</Option>
					))}
				</Options>
				<FeedbackDialog
					visible={showDialog}
					isHappy={score > 1}
					name={firstName}
					score={score}
					contractId={id}
					onDismiss={() =>
						this.setState({ showDialog: false, giveNewDebrief: false })
					}
				/>
			</Container>
		);
	}
}

const Evaluation = styled.h4`
	margin-top: 20px;
`;

const Options = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column-reverse;
`;
const Option = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 15px;
	border-radius: 3px;
	transition: background-color 500ms ease;

	&:hover {
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.2);
	}

	&:active {
		background-color: rgba(0, 0, 0, 0.2);
	}
`;
const OptionIcon = styled.div`
	font-size: 2em;
	width: 50px;
	min-width: 50px;
	img {
		max-width: 100%;
	}
	margin-right: 15px;
`;
const OptionText = styled.div`
	margin-top: 10px;
	text-align: ${isMobile() ? "left" : "center"};
	font-size: 0.8em;
`;
const AlreadyDebriefed = styled.div`
	width: 150px;
	${isMobile() ? "margin-left: auto" : ""};
	${isMobile() ? "margin-right: auto" : ""};
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
`;

const AlreadyDebriefedDescription = styled.div`
	font-size: 4em;
	:first-child {
		margin-bottom: 20px;
	}
`;
const Container = styled.div`
	width: 100%;
`;
