import findOptimumInSortedArray from "./findOptimumInSortedArray";
import forAll from "./forAll";
import flattenArray from "./flattenArray";
import moment from "moment";

const makePeriodFromEvent = e => ({
	isPeriod: true,
	startTime: moment(e.startTime),
	endTime: moment(e.endTime),
	events: [e],
	fullDays: e.fullDays,
});

export function intersectingPeriodsInterval(periods, startTime, endTime) {
	const lower =
		findOptimumInSortedArray(periods, period =>
			period.endTime.isAfter(startTime),
		) + 1;
	const upper =
		periods.length -
		1 -
		findOptimumInSortedArray(periods.reverse(), period =>
			period.startTime.isBefore(endTime),
		);
	periods.reverse();
	return { lower, upper };
}

export const makePeriods = events => {
	const eventsArray = events;
	if (eventsArray.length === 0) {
		return [];
	} else {
		return eventsArray.slice(1).reduce(
			(periods, e) => {
				const extendedStart = moment(e.startTime).subtract(1, "minute");
				const extendedEnd = moment(e.endTime).add(1, "minute");
				const { lower, upper } = intersectingPeriodsInterval(
					periods,
					extendedStart,
					extendedEnd,
				);
				const mergingPeriods = periods.slice(lower, upper);
				return [
					...periods.slice(0, lower),
					{
						isPeriod: true,
						startTime:
							mergingPeriods.length === 0 ||
							mergingPeriods[0].startTime.isAfter(e.startTime)
								? moment(e.startTime)
								: mergingPeriods[0].startTime,
						endTime:
							mergingPeriods.length === 0 ||
							mergingPeriods.slice(-1)[0].endTime.isBefore(e.endTime)
								? moment(e.endTime)
								: mergingPeriods.slice(-1)[0].endTime,
						fullDays:
							e.fullDays && forAll(mergingPeriods, period => period.fullDays),
						events: [
							...flattenArray(mergingPeriods.map(period => period.events)),
							e,
						],
					},
					...periods.slice(upper),
				];
			},
			[makePeriodFromEvent(eventsArray[0])],
		);
	}
};

export default makePeriods;
