import React from "react";
import Table from "components/Table";
import Button from "components/Button";
import styled from "styled-components";
import OrganizationsContacts from "modals/OrganizationsContacts";
import AddOrganizationsContactsForm from "modals/AddOrganizationsContacts.js";
import { addModal } from "architecture/Modals";

export default ({ organizations, token }) => (
	<Table
		id="SearchOrganizationsTable"
		columns={[
			{
				label: "Nom de l'établissement",
				getter: ({ name }) => name,
			},
			{
				label: "Status",
				getter: ({ stages }) =>
					stages
						? stages.map((stage, index) => (
								<span key={index}>
									{stage}
									<br />
								</span>
						  ))
						: null,
			},
			{
				label: "Comercial",
				getter: ({ owners }) =>
					owners
						? owners.map((owner, index) => (
								<span key={index}>
									{owner}
									<br />
								</span>
						  ))
						: null,
			},
			{
				label: "Contact(s)",
				getter: ({ contacts, organizationId }) => (
					<ButtonContainer>
						<Button
							onClick={() =>
								addModal(() => <OrganizationsContacts contacts={contacts} />)
							}
						>
							Contact(s)
						</Button>
						<AddButton
							onClick={() =>
								addModal(({ dismiss }) => (
									<AddOrganizationsContactsForm
										contacts={contacts}
										organizationId={organizationId}
										token={token}
										dismiss={dismiss}
									/>
								))
							}
						>
							+
						</AddButton>
					</ButtonContainer>
				),
			},
		]}
		rows={organizations}
		orderBy={0}
		getId={({ organizationId }) => organizationId}
	/>
);

const ButtonContainer = styled.div`
	display: flex;
`;
const AddButton = styled(Button)`
	margin-left: 5px;
`;
