import React from "react";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Debriefs from "./Debriefs";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";

const titles = {
	business: {
		worker: "Avis des business",
		business: "Avis du business",
	},
	worker: {
		worker: "Avis du worker",
		business: "Avis des workers",
	},
};

export const History = ({
	name,
	workerDebriefs,
	businessDebriefs,
	relatedTo,
}) => (
	<Container>
		<Typography variant="h3" gutterBottom>
			{name}
		</Typography>
		<Debriefs
			title={titles.business[relatedTo]}
			debriefs={businessDebriefs}
			relatedTo={relatedTo}
		/>
		<Debriefs
			title={titles.worker[relatedTo]}
			debriefs={workerDebriefs}
			relatedTo={relatedTo}
		/>
	</Container>
);

const Container = styled.div`
	overflow: auto;
	flex: 1;
`;

export const WorkerHistory = ({ workerId }) => (
	<GraphQL
		query={gql`
			query($workerId: ID!) {
				worker(id: $workerId) {
					id
					businessDebriefs {
						matching {
							job {
								name
							}
							business {
								name
							}
						}
						collectedAt
						collectedByUser {
							name
							primaryRole
						}
						score
						comment
					}
					workerDebriefs {
						matching {
							job {
								name
							}
							business {
								name
							}
						}
						collectedAt
						collectedByUser {
							name
							primaryRole
						}
						score
						comment
					}
				}
			}
		`}
		variables={{ workerId }}
	>
		{({ worker }) => <History relatedTo="worker" {...worker} />}
	</GraphQL>
);

export const BusinessHistory = ({ businessId }) => (
	<GraphQL
		query={gql`
			query($businessId: ID!) {
				business(id: $businessId) {
					name
					businessDebriefs {
						matching {
							job {
								name
							}
							worker {
								name
							}
						}
						collectedAt
						collectedByUser {
							name
							primaryRole
						}
						score
						comment
					}
					workerDebriefs {
						matching {
							job {
								name
							}
							worker {
								name
							}
						}
						collectedAt
						collectedByUser {
							name
							primaryRole
						}
						score
						comment
					}
				}
			}
		`}
		variables={{ businessId }}
	>
		{({ business }) => <History relatedTo="business" {...business} />}
	</GraphQL>
);
