import React from "react";
import Table from "components/Table";
import Button from "components/Button";
import { addModal } from "architecture/Modals";
import AdminDebrief from "modals/Admin/AdminDebrief";
import debriefScores from "common/debriefScores";
import LineBrokenText from "components/LineBrokenText";
import Request from "common/Request";
import RequestButton from "./utils/RequestButton.js";
import moment from "moment";

export default ({ contracts }) => (
	<Table
		id="ContractsToDebriefTable"
		columns={[
			{
				label: "Identifiant",
				getter: ({ id }) => id,
			},
			{
				label: "Worker",
				getter: ({ getWorker: { name } }) => name,
			},
			{
				label: "Téléphone",
				getter: ({ getWorker: { phoneNumber } }) => phoneNumber,
			},
			{
				label: "Poste",
				getter: ({ getJob: { name } }) => name,
			},
			{
				label: "Business",
				getter: ({ getBusiness: { name } }) => name,
			},
			{
				label: "Téléphone",
				getter: ({
					getBusiness: {
						owner: { phoneNumber },
					},
				}) => phoneNumber,
			},
			{
				label: "Commercial",
				getter: ({ getBusiness: { salesPerson } }) =>
					(salesPerson || {}).name || "",
			},
			{
				label: "Shifts du contrat",
				sortGetter: ({ startTime }) => startTime,
				getter: ({ shiftsDescription }) => (
					<LineBrokenText>{shiftsDescription}</LineBrokenText>
				),
			},
			{
				label: "",
				getter: contract =>
					getDebriefContactButton({
						...contract,
						from: "worker",
					}),
			},
			{
				label: "",
				getter: contract =>
					getDebriefContactButton({
						...contract,
						from: "business",
					}),
			},
		]}
		rows={contracts}
		orderBy={7}
	/>
);

const getDebriefContactButton = ({
	id,
	matching,
	getWorker,
	getBusiness,
	getJob,
	from,
}) => {
	if (matching) {
		const { score } = matching.debrief[from];
		if (score !== null) {
			const { label, Emotion } = debriefScores[score];
			return (
				<React.Fragment>
					<Emotion /> {label}
				</React.Fragment>
			);
		}
	}
	const name = from === "business" ? getBusiness.name : getWorker.name;
	const phoneNumber =
		from === "business" ? getBusiness.owner.phoneNumber : getWorker.phoneNumber;
	return (
		<DebriefContractButton
			initialValues={{
				id,
				from,
				workerId: getWorker.id,
				businessId: getBusiness.id,
				jobKey: getJob.key,
				businessContactedForDebrief: matching.businessContactedForDebrief,
				businessContactedForDebriefBy: matching.businessContactedForDebriefBy,
				businessDebriefContactedAt: matching.businessDebriefContactedAt,
			}}
			name={name}
			phoneNumber={phoneNumber}
		/>
	);
};

class DebriefContractButton extends React.Component {
	showModal = () => {
		addModal(({ dismiss }) => (
			<AdminDebrief
				initialValues={this.props.initialValues}
				dismiss={dismiss}
				name={this.props.name}
				phoneNumber={this.props.phoneNumber}
			/>
		));
	};

	render() {
		const {
			jobKey,
			workerId,
			businessId,
			businessContactedForDebrief,
			businessContactedForDebriefBy,
			businessDebriefContactedAt,
		} = this.props.initialValues;
		return [
			<Button onClick={this.showModal}>
				debrief {this.props.initialValues.from}
			</Button>,
			this.props.initialValues.from !== "business" ? (
				""
			) : businessContactedForDebrief ? (
				`${businessContactedForDebriefBy.name} a pris contact
              ${moment(businessDebriefContactedAt).fromNow()}`
			) : (
				<RequestButton
					label="Business Contacté"
					onClick={async () => {
						await Request.mutation(
							/* GraphQL */ `
								mutation($workerId: ID!, $businessId: ID!, $jobKey: String!) {
									adminContactForDebriefBusiness(
										workerId: $workerId
										businessId: $businessId
										jobKey: $jobKey
									) {
										id
									}
								}
							`,
							{
								workerId,
								businessId,
								jobKey,
							},
						);
					}}
				/>
			),
		];
	}
}
