import React, { Component } from "react";
import styled from "styled-components";
import { color, card } from "common/styles";

export default class Periods extends Component {
	renderPeriod = (period, id) => (
		<PeriodWrapper key={id}>{period.render()}</PeriodWrapper>
	);

	renderPeriods = periods => (
		<Content>{periods.map(this.renderPeriod)}</Content>
	);

	render() {
		const { periods } = this.props;
		if (periods.length === 0) {
			return null;
		}
		return this.renderPeriods(periods);
	}
}

const Content = styled.div`
	margin: 3px;
	${card} padding: 0;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	position: relative;
	overflow: auto;
`;

const PeriodWrapper = styled.div`
	position: relative;
	border-bottom: solid 1px ${color("black", "translucent")};

	&:last-of-type {
		border-bottom-style: none;
	}
`;
