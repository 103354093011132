import React from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import SelectJob from "components/SelectJob";
import TextField from "@material-ui/core/TextField";
import Button from "components/Button";
import FormControl from "@material-ui/core/FormControl";
import { useJobs } from "models/jobs";
import { useCreateSkill } from "models/skills";

export default ({ dismiss }) => {
	const { units } = useJobs();
	const {
		skill,
		setSkill,
		loading: mutationLoading,
		saveSkill,
	} = useCreateSkill();

	return (
		<div class="grid">
			<article class="eight from-three card col">
				<h1>Nouvelle compétence</h1>
				<FormControl fullWidth={true}>
					<TextField
						fullWidth={true}
						label="Nom de la compétence"
						onChange={e => setSkill({ name: e.target.value })}
					/>
				</FormControl>
				<FormControl fullWidth={true}>
					<InputLabel id="select-category-label">Catégorie</InputLabel>
					<Select
						labelId="select-category-label"
						fullWidth={true}
						onChange={e => setSkill({ category: e.target.value })}
						value={skill.category}
					>
						<MenuItem value="">Aucune</MenuItem>
						<MenuItem value="PMS">PMS</MenuItem>
						<MenuItem value="language">Langue</MenuItem>
						<MenuItem value="diploma">Diplôme</MenuItem>
					</Select>
				</FormControl>
				<section>
					<h3>Ajouter tous les métiers de...</h3>
					<div class="row">
						{units.map(u => (
							<Button
								secondary
								className="outline"
								onClick={() =>
									setSkill({
										jobs: [
											...skill.jobs.filter(
												j => !u.jobs.some(uj => uj.key === j.key),
											),
											...u.jobs,
										],
									})
								}
							>
								{u.unit}
							</Button>
						))}
					</div>
					<SelectJob jobs={skill.jobs} onChange={jobs => setSkill({ jobs })} />
				</section>
				<section class="row">
					<Button
						className="right"
						loading={mutationLoading}
						onClick={async () => {
							await saveSkill();
							dismiss();
						}}
					>
						Ajouter
					</Button>
				</section>
			</article>
		</div>
	);
};
