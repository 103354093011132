import React from "react";
import { MdCheck, MdClose } from "react-icons/md";
import styled from "styled-components";
import { color } from "common/styles";

export default ({ acceptCount, rejectCount }) => (
	<Statistics>
		{acceptCount > 0 && (
			<Statistic>
				{acceptCount}
				<AcceptIcon>
					<MdCheck />
				</AcceptIcon>
			</Statistic>
		)}
		{rejectCount > 0 && (
			<Statistic>
				{rejectCount}
				<RejectIcon>
					<MdClose />
				</RejectIcon>
			</Statistic>
		)}
	</Statistics>
);
const Statistics = styled.div`
	display: flex;
	flex-direction: row;
`;
const Statistic = styled.div`
	margin-left: 6px;
	display: flex;
	flex-direction: row;
`;
const AcceptIcon = styled.div`
	color: ${color("valid")};
`;
const RejectIcon = styled.div`
	color: ${color("important")};
`;
