import React from "react";
import { Document, DocumentTitle } from "./shared";
import { Checkbox, DatePicker } from "redux-form-material-ui";
import { Field } from "redux-form";
import moment from "moment";

export default props => (
	<Document>
		<DocumentTitle>Sécurité sociale</DocumentTitle>
		<Field
			name="expiresAt"
			floatingLabelText="Date d'expiration"
			fullWidth={true}
			type="text"
			disabled={true}
			component={DatePicker}
			format={date => (date ? moment(date).toDate() : null)}
		/>
		<Field name="validatedAt" label="Valider" component={Checkbox} />
	</Document>
);
