import React from "react";
import { GraphQL } from "common/graphql";
import SearchOrganizationsPipedrive from "./SearchOrganizationsPipedrive";
import gql from "graphql-tag";

export default class SearchOrganizationsPipedriveQuery extends React.Component {
	render() {
		return (
			<GraphQL
				query={gql`
					query {
						pipedriveApiToken
					}
				`}
			>
				{({ pipedriveApiToken }) => (
					<div>
						<SearchOrganizationsPipedrive
							pipedriveApiToken={pipedriveApiToken}
						/>
					</div>
				)}
			</GraphQL>
		);
	}
}
