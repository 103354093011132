import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Request from "common/Request";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import client from "common/apolloClient";

const defaultAnswers = [
	`Bonjour. Pouvez-vous télécharger une photo où l'on voit votre visage en plus grand ? merci.`,
	`Bonjour. On ne voit pas votre visage sur la photo. Pouvez-vous en télécharger une autre ? merci.`,
	`Bonjour. Pouvez-vous télécharger une photo où on ne voit que vous ? merci.`,
	`Bonjour. Pouvez-vous en télécharger une photo où on voit votre visage sans lunettes de soleil ? merci.`,
	`Bonjour. Merci de télécharger une photo de vous où on ne voit pas le titre de séjour.`,
];

export default class AvatarsToValidate extends React.Component {
	state = { validate: "false", adminMessage: "" };

	handleChange = name => event => {
		if (name === "defaultAnswer") {
			this.setState({ adminMessage: event.target.value });
		}
		this.setState({
			[name]: event.target.value,
		});
	};

	render() {
		const { worker, dismiss } = this.props;
		return (
			<>
				<Name>
					{worker.name} - {worker.jobs.map(({ name }) => `${name} `)}
				</Name>
				<Container>
					<div>
						<img src={worker.mediumAvatarURL} alt="Avatar" />
					</div>
					<FormContainer>
						<div>
							<RadioGroup
								value={this.state.validate}
								onChange={this.handleChange("validate")}
							>
								<FormControlLabel
									value="true"
									control={<Radio />}
									label="Valider"
								/>
								<FormControlLabel
									value="false"
									control={<Radio />}
									label="Rejeter"
								/>
							</RadioGroup>
						</div>
						<StyledFormControl>
							<InputLabel>Réponse rapide</InputLabel>
							<Select
								value={this.state.defaultAnswer || ""}
								onChange={this.handleChange(`defaultAnswer`)}
							>
								{defaultAnswers.map(defaultAnswer => (
									<MenuItem key={defaultAnswer} value={defaultAnswer}>
										{defaultAnswer}
									</MenuItem>
								))}
							</Select>
						</StyledFormControl>
						<TextField
							id="outlined-name"
							multiline
							rows="4"
							label="Message"
							value={this.state.adminMessage}
							fullWidth
							onChange={this.handleChange("adminMessage")}
							margin="normal"
							variant="outlined"
						/>
						<ButtonContainer>
							<StyleButton
								loading={this.state.loading}
								onClick={async () => {
									this.setState({ loading: true });
									await Request.mutation(
										/* GraphQL */ `
											mutation(
												$workerId: ID!
												$validate: Boolean!
												$adminMessage: String
												$isProfessional: Boolean
											) {
												adminValidateAvatar(
													workerId: $workerId
													validate: $validate
													adminMessage: $adminMessage
													isProfessional: $isProfessional
												) {
													id
												}
											}
										`,
										{
											workerId: worker.id,
											validate: this.state.validate === "true",
											adminMessage: this.state.adminMessage,
											isProfessional: this.state.isProfessional,
										},
										{
											successMessage: "La review a été envoyé",
											errorMessage:
												"Une erreur s'est produite. Veuillez réessayer.",
										},
									);
									await client.reFetchObservableQueries();
									dismiss();
								}}
							>
								Send
							</StyleButton>
							{this.state.validate === "true" ? (
								<FormControlLabel
									key={this.state.isProfessional}
									control={
										<Checkbox
											checked={this.state.isProfessional || false}
											onChange={() =>
												this.setState({
													isProfessional: !this.state.isProfessional,
												})
											}
										/>
									}
									label="Photo très professionnelle"
								/>
							) : (
								<></>
							)}
						</ButtonContainer>
					</FormContainer>
				</Container>
			</>
		);
	}
}

const Container = styled.div`
	display: flex;
`;
const FormContainer = styled.div`
	margin-left: 50px;
	flex: 1;
`;

const StyleButton = styled(Button)`
	margin-right: 10px;
`;

const ButtonContainer = styled.div`
	display: flex;
`;

const StyledFormControl = styled(FormControl)`
	width: 75%;
`;

const Name = styled.div`
	font-weight: bold;
	text-align: center;
	margin-bottom: 25px;
`;
