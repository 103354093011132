import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import range from "utils/range";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const Time = styled.div`
	display: flex;
`;

export default ({ label, input: { value, onChange } }) => {
	const id = Math.random().toString();

	return (
		<Time>
			<FormControl>
				<InputLabel shrink id={id}>
					{label}
				</InputLabel>
				<Select
					labelId={id}
					value={moment(value)
						.hours()
						.toString()}
					onChange={e =>
						onChange(moment(value).set({ hour: parseInt(e.target.value) }))
					}
				>
					{range(24).map(hour => (
						<MenuItem key={hour} value={hour.toString()}>
							{String(hour).padStart(2, "0")}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl>
				<InputLabel shrink />
				<Select
					value={moment(value)
						.minutes()
						.toString()}
					onChange={e =>
						onChange(moment(value).set({ minute: parseInt(e.target.value) }))
					}
				>
					{range(60 / 5)
						.map(m => m * 5)
						.map(minutes => (
							<MenuItem key={minutes} value={minutes.toString()}>
								{String(minutes).padStart(2, "0")}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</Time>
	);
};
