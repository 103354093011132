import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import { statusElements } from "models/contract";
import formatPhoneNumber from "utils/formatPhoneNumber";
import DarkBox from "components/DarkBox";
import { EmotionScore } from "common/debriefScores";
import UserCard from "components/UserCard";

export default ({ contracts = [] }) =>
	contracts.length > 0 ? (
		<React.Fragment>
			<h2>Déjà sélectionnés</h2>
			<div class="cards-list">
				{contracts.map(contract => (
					<Contract key={contract.id} {...contract} />
				))}
			</div>
		</React.Fragment>
	) : null;

class Contract extends React.Component {
	state = {
		loading: false,
	};

	async cancel(onCancel) {
		this.setState({ loading: true });
		await onCancel();
		try {
			this.setState({ loading: false });
		} catch (e) {
			//this will only succeed if cancelling contract failed
			//since the component is unmounted if it worked
		}
	}

	render() {
		const {
			getWorker: {
				name,
				firstName,
				phoneNumber,
				mediumAvatarURL,
				adminReview: { score },
				medianDebriefScore,
			} = {},
			onCancel,
			status,
		} = this.props;
		const { color, label } = statusElements[status];
		const { loading } = this.state;

		return (
			<UserCard avatar={mediumAvatarURL} adminScore={score}>
				<h3>{name}</h3>

				<StatusBar background={color} />
				<DarkBox
					illustrationPosition="top"
					illustration={() =>
						medianDebriefScore !== null ? (
							<EmotionScore score={medianDebriefScore} />
						) : null
					}
				>
					<section class="centered">
						{phoneNumber ? (
							<div>
								{" "}
								<strong>
									<a href={`tel:${phoneNumber}`}>
										{formatPhoneNumber(phoneNumber)}
									</a>
								</strong>
							</div>
						) : null}
						{label}
						{["waiting signature", "signed"].includes(status)
							? ` par ${firstName}`
							: ""}
					</section>
					{onCancel ? (
						<section class="centered">
							<Button
								secondary
								className="outline"
								loading={loading}
								onClick={() => this.cancel(onCancel)}
							>
								Annuler
							</Button>
						</section>
					) : null}
				</DarkBox>
			</UserCard>
		);
	}
}

const StatusBar = styled.div`
	width: 18px;
	background-color: ${({ background }) => background};
`;
