import React from "react";
import styled from "styled-components";
import { Card } from "components/Card";
import Avatar from "components/Avatar";

const avatarRadius = 60;

const UserCardContainer = styled(Card)`
	margin-top: ${avatarRadius}px;
	margin-bottom: 30px;
	.avatar {
		margin-top: -${avatarRadius}px;
		margin-bottom: 10px;
	}
	align-items: center;
	width: 235px;
`;

export default ({ avatar, adminScore, onClick, children }) => (
	<UserCardContainer
		onClick={onClick}
		className={onClick ? "card clickable" : "card"}
	>
		<Avatar image={avatar} radius={avatarRadius} />
		{children}
	</UserCardContainer>
);
