const mapValue = (input, mapping, { recursive, key }) => {
	if (recursive && typeof input === "object") {
		return mapValues(input, mapping, { recursive });
	} else {
		return mapping(input, key);
	}
};

const mapValues = (input, mapping, { recursive = false } = {}) => {
	const output = {};
	for (const key of Object.keys(input)) {
		output[key] = mapValue(input[key], mapping, { recursive, key });
	}
	return output;
};

export default mapValues;
