import { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Request from "common/Request";

const CreateJobPost = gql`
	mutation($jobPost: Business_JobPostInput!) {
		business {
			createJobPost(jobPost: $jobPost) {
				id
			}
		}
	}
`;

const EditJobPost = gql`
	mutation($id: ID!, $jobPost: Business_JobPostInput!) {
		business {
			updateJobPost(id: $id, jobPost: $jobPost) {
				id
			}
		}
	}
`;

const JobPostProps = `
  id
  title
  grossMonthlyRate
  closed
  
  job {
    key
    name
    unit
  }
`;

const JobPostDetails = gql`
  query JobPostDetails ($id: ID!) {
    businessScope {
      jobPost(id: $id) {
        skills {
          key
          name
        }
        job {
          key
          name
        }
        description
		grossMonthlyRate
		prerequisites
		timesheet
        ${JobPostProps}
      }
    }
  }
`;

export const useJobPostDetails = id => {
	const { loading, data } = useQuery(JobPostDetails, {
		variables: { id },
	});

	if (loading || !data || !data.businessScope || !data.businessScope.jobPost) {
		return { loading: true, jobPost: { job: {} } };
	}

	return { loading, jobPost: data.businessScope.jobPost };
};

const JobPostStatus = gql`
	query JobPostStatus($id: ID!) {
		businessScope {
			jobPost(id: $id) {
				id
				closed
			}
		}
	}
`;

export const useJobPostStatus = id => {
	const { data } = useQuery(JobPostStatus, {
		variables: { id },
	});
	const [editJobPost, { loading }] = useMutation(EditJobPost);

	if (!data || !data.businessScope || !data.businessScope.jobPost) {
		return { loading: false, isClosed: false, saveStatus: undefined };
	}

	const saveStatus = async () => {
		await editJobPost({
			variables: {
				id,
				jobPost: {
					closed: !data.businessScope.jobPost.closed,
				},
			},
		});
		Request.refreshAll();
	};

	return { loading, isClosed: data.businessScope.jobPost.closed, saveStatus };
};

export const ClosedJobPosts = gql`
  query listClosedJobPosts($skip: Int, $limit: Int) {
    businessScope {
      jobPosts(closed: true, skip: $skip, limit: $limit) {
        ${JobPostProps}
        favoriteAnswers: answers(businessAccept: true) {
          id
          worker {
            id
            mediumAvatarURL
            medianDebriefScore
          }
        }
        newAnswers: answers(hasBusinessEvaluation: false) {
          id
          worker {
            id
            mediumAvatarURL
            medianDebriefScore
          }
        }
      }
    }
  }
`;

const OpenJobPosts = gql`
  query listOpenedJobPosts {
    businessScope {
      jobPosts(closed: false) {
        ${JobPostProps}
        favoriteAnswers: answers(accept: true, businessAccept: true) {
          id
          worker {
            id
            mediumAvatarURL
            medianDebriefScore
          }
        }
        newAnswers: answers(accept: true, hasBusinessEvaluation: false) {
          id
          worker {
            id
            mediumAvatarURL
            medianDebriefScore
          }
        }
      }
    }
  }
`;

export const useOpenJobPosts = () => {
	const { data, loading } = useQuery(OpenJobPosts, { pollInterval: 5000 });

	if (loading || !data || !data.businessScope || !data.businessScope.jobPosts) {
		return { loading: true, jobPosts: [] };
	}

	return { loading: false, jobPosts: data.businessScope.jobPosts };
};

export const useEditJobPost = initialJobPost => {
	const [jobPost, setJob] = useState(
		initialJobPost
			? { prerequisites: [], ...initialJobPost }
			: { skills: [], prerequisites: [] },
	);
	const [saveJob, { loading }] = useMutation(
		initialJobPost ? EditJobPost : CreateJobPost,
	);

	const saveJobPost = async () => {
		const job = {
			title: jobPost.title,
			closed: jobPost.closed,
			description: jobPost.description,
			grossMonthlyRate: jobPost.grossMonthlyRate,
			skillKeys: jobPost.skills.map(s => s.key),
			jobKey: jobPost.job.key,
			prerequisites: jobPost.prerequisites,
			timesheet: jobPost.timesheet,
		};

		await saveJob({
			variables: initialJobPost
				? { id: initialJobPost.id, jobPost: job }
				: { jobPost: job },
		});

		Request.refreshAll();
	};

	return {
		jobPost,
		setJobPost: val => setJob({ ...jobPost, ...val }),
		isValid: () => jobPost.title && jobPost.job && jobPost.description,
		saveJobPost,
		loading,
	};
};
