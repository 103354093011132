import moment from "moment";
import { getShiftsPerDay, timeReference } from "./helpers";
import last from "utils/last";
import sum from "utils/sum";

const format = add => {
	const date = moment(timeReference).add(add);
	return moment().isSame(date, "year")
		? moment(date).format("Do MMMM")
		: moment(date).format("Do MMMM YYYY");
};

export default shifts => {
	const { shiftsPerDay, days, weeks, quarters } = getShiftsPerDay(shifts);
	for (const day of days) {
		const shifts = shiftsPerDay[day];
		let index = 1;
		for (const shift of shifts.slice(1)) {
			const previousShift = shifts[index - 1];
			if (previousShift.endTime > shift.startTime) {
				return `Il ne peut pas y avoir 2 missions qui se chevauchent le ${format(
					{ day },
				)}.`;
			}
			index++;
		}
		const nextDayShifts = shiftsPerDay[day + 1];
		if (
			nextDayShifts &&
			nextDayShifts[0].startTime.diff(last(shifts).endTime, "minutes") < 11 * 60
		) {
			return `Il ne peut pas y avoir moins de 11h de repos après la journée du ${format(
				{ day },
			)}.`;
		}
		if (sum(shifts.map(shift => shift.totalHours)) > 12) {
			return `Il ne peut pas y avoir plus de 12h de mission le ${format({
				day,
			})}.`;
		}
	}
	for (const week of Object.keys(weeks)) {
		if (weeks[week].length > 6) {
			return `Il ne peut pas y avoir plus de 6 jours de travail la semaine du ${format(
				{ week },
			)}.`;
		}
	}
	for (const quarter of Object.keys(quarters)) {
		if (quarters[quarter].length > 60) {
			return `Il ne peut pas y avoir plus de 60 jours de travail au ${moment(
				timeReference,
			)
				.add({ quarter })
				.format("Qo [trimestre de] YYYY")}.`;
		}
	}
};
