import { serializeShift } from "../serializeShifts";
import moment from "moment";

const timeText = (dateISO, { editing }) => {
	const date = moment(dateISO);
	if (editing) {
		return date.format("HH[h]mm");
	}
	if (date.minutes() === 0) {
		if (date.hours() === 0) {
			return "minuit";
		}
		if (date.hours() === 12) {
			return "midi";
		}
	}
	return `${date.format("H")}h${date.minutes() ? date.format("mm") : ""}`;
};

const lengthText = length => {
	const hours = Math.floor(length / 60);
	const minutes = Math.round(length - hours * 60);
	if (hours === 0) {
		if (minutes === 30) {
			return "une demi heure";
		}
	}
	return [
		hours === 0 ? "" : hours === 1 ? "une heure" : `${hours} heures`,
		minutes === 0 ? "" : minutes === 30 ? "demi" : `${minutes} minutes`,
	]
		.filter(Boolean)
		.join(" et ");
};

export default (
	value,
	{ editing, breakMinutes = 0, startTime = moment("2018-01-01") } = {},
) => {
	if (!value || value.length < 2) {
		return "-";
	}
	const [startMinutes, endMinutes] = value;
	const shift = serializeShift({ date: startTime, timeRange: value });
	return `${timeText(shift.startTime, { editing })} - ${timeText(
		shift.endTime,
		{
			editing,
		},
	)}${
		editing
			? ""
			: `${
					breakMinutes ? `, ${breakMinutes} minutes de pause` : ""
			  } (${lengthText(endMinutes - startMinutes - breakMinutes)})`
	}`;
};
