import flattenArray from "./flattenArray";
import moment from "moment";

const makeSlots = periods =>
	flattenArray(
		periods.map(({ start, end, ...period }) => {
			let current = moment(start)
				.add(1, "day")
				.startOf("day");

			let previous = start;
			const periods = [];
			while (current.isBefore(end)) {
				periods.push({ ...period, start: previous, end: current });
				previous = moment(current);
				current = moment(current).add(1, "day");
			}
			periods.push({ ...period, start: previous, end });
			return periods;
		}),
	);

export default makeSlots;
