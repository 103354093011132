import React from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { DocumentTitle } from "./shared";
import { MdCloudUpload, MdInsertDriveFile } from "react-icons/md";
import { color } from "common/styles";
import { uploadDocuments } from "models/worker/documents";

export default class DropzoneDocuments extends React.Component {
	state = { files: [] };

	onDrop = async uploadedFiles => {
		await uploadDocuments({
			token: this.props.token,
			form: this.refs.formUpload,
		});
		const files = [...this.state.files, ...uploadedFiles];
		this.setState({
			files,
		});
	};

	render() {
		return (
			<form ref="formUpload">
				<DropzoneContainer>
					<DocumentTitle color="black" opacity="light">
						Merci de télécharger autant de documents que nécessaire, les uns
						après les autres
					</DocumentTitle>
					<StyledDropzone
						onDrop={this.onDrop.bind(this)}
						accept="image/gif, image/jpeg, image/png, image/tiff, application/pdf"
						name="documents"
						multiple={true}
					>
						<p>
							<MdCloudUpload className="icon" />
						</p>
					</StyledDropzone>
					<p>Seuls les formats jpeg, tiff, png et pdf sont acceptés</p>
				</DropzoneContainer>
				<Listing>
					<ul>
						{Boolean(this.props.data.length) && (
							<span>Fichiers déjà uploadés</span>
						)}
						{this.props.data.map((file, index) => (
							<li key={index}>
								<MdInsertDriveFile /> {file.name}
							</li>
						))}
					</ul>
				</Listing>
			</form>
		);
	}
}

const DropzoneContainer = styled.div`
	margin-top: 60px;

	& p {
		margin-top: 10px;
		color: ${color("black", "bright")};
	}
`;

const StyledDropzone = styled(Dropzone)`
	background-color: ${color("black", "translucent")};
	border: 5px dotted ${color("black", "bright")};
	height: 200px;

	& p {
		text-align: center;
		padding-top: 70px;
		color: ${color("black", "bright")};

		& svg {
			font-size: 40px;
		}
	}
`;

const Listing = styled.aside`
	margin-top: 10px;

	& ul {
		list-style-type: none;
	}
`;
