import React from "react";
import Button from "components/Button";
import EditBDescription from "modals/EditBDescription";
import EditJobPost from "modals/JobPosts/EditJobPost";
import { addModal } from "architecture/Modals";
import ListJobPosts from "./ListJobPosts";
import { isMobile } from "common/styles";

export default ({ openJobPosts, closedJobPosts, history }) => (
	<div>
		<section class="row">
			<Button
				secondary
				className="outline right"
				onClick={() =>
					addModal(({ dismiss }) => <EditBDescription dismiss={dismiss} />)
				}
			>
				{isMobile() ? "Établissement" : "Mon établissement"}
			</Button>
			<Button
				onClick={() =>
					addModal(({ dismiss }) => <EditJobPost dismiss={dismiss} />)
				}
			>
				{isMobile() ? "Ajouter" : "Nouvelle offre"}
			</Button>
		</section>
		<ListJobPosts
			openJobPosts={openJobPosts}
			closedJobPosts={closedJobPosts}
			history={history}
		/>
	</div>
);
