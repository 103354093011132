import { isNil, intersectionBy } from "lodash";
import MenuItem from "material-ui/MenuItem";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { compose } from "recompose";
import { change, Field, reduxForm } from "redux-form";
import { Checkbox, SelectField, TextField } from "redux-form-material-ui";
import styled from "styled-components";

import reasons from "common/reasons.json";
import { dispatch } from "common/store";
import { isMobile } from "common/styles";
import useCurrentUser from "common/useCurrentUser";
import Button from "components/Button";
import Loading from "components/Loading";
import MaxWorkers from "components/MaxWorkers";
import Rate from "components/Rate";
import isConflictingPlanning from "models/conflictingPlannings/isConflictingPlanning";
import compare from "utils/compare";
import formatCurrency from "utils/formatCurrency";
import { DossierField } from "./DossierField";
import ErrorMessage from "./ErrorMessage";
import Location from "./Location";
import Reason from "./Reason";
import Shifts from "./Shifts";
import validate from "./validate";
import AddTarget from "modals/AdminQuery/ManageQuery/AddNetworkWorker";
import { addAlert } from "actions/alerts";
import { DEV } from "common/constants";
import Request from "common/Request";

const Line = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-direction: ${!isMobile() ? "row" : "column"};
`;

export const NewQueryForm = ({
	businessId,
	buttonTitle,
	contractPreview,
	defaultBreakMinutes,
	directContract,
	dossierList = [],
	handleSubmit,
	jobs,
	networkWorkers = [],
	newQuery,
	pickJob,
	reason,
	shifts = [],
	specifiesGrossRate,
	specifiesLocation,
	specifiesReason,
	submitting,
	workerWasSelected,
}) => {
	const [isAddingWorker, setIsAddingWorker] = useState(false);

	const handleClick = () => {
		setIsAddingWorker(!isAddingWorker);
	};

	const handleNetworkWorkerSubmit = () => {
		setIsAddingWorker(false);
	};

	const addNetworkWorkerMutation = async ({ businessId, workerEmail }) => {
		console.log("addNetworkWorker", businessId, workerEmail);
		await Request.mutation(
			/* GraphQL */ `
				mutation($businessId: ID!, $workerEmail: String!) {
					addNetworkWorker(businessId: $businessId, workerEmail: $workerEmail)
				}
			`,
			{
				workerEmail,
				businessId,
			},
			{},
			{
				refetchQueries: ["networkWorkers"],
			},
		);
	};

	const addNetworkWorker = async workerEmail => {
		try {
			await addNetworkWorkerMutation({ businessId, workerEmail });
			dispatch(addAlert("Worker ajouté au réseau !"));
			handleNetworkWorkerSubmit();
		} catch (error) {
			DEV && console.error(error);
			dispatch(addAlert("Erreur lors de l'ajout de Worker au Réseau"));
		}
	};

	const shiftsKey = useMemo(
		() =>
			shifts
				.map(
					({ startTime, endTime }) =>
						`${moment(startTime).format()} - ${moment(endTime).format()}`,
				)
				.join("\n"),
		[shifts],
	);

	useEffect(() => {
		shifts.forEach(({ startTime, endTime, breakMinutes }, index) => {
			const expectedBreakMinutes =
				moment(endTime).diff(moment(startTime), "hours") < 6
					? 0
					: defaultBreakMinutes;
			if (breakMinutes !== expectedBreakMinutes) {
				dispatch(
					change(
						"NewQuery",
						`shifts[${index}].breakMinutes`,
						expectedBreakMinutes,
					),
				);
				dispatch(
					change(
						"NewContract",
						`shifts[${index}].breakMinutes`,
						expectedBreakMinutes,
					),
				);
			}
		});
	}, [defaultBreakMinutes, shiftsKey]);

	const invalidPlanningError = isConflictingPlanning(shifts);

	return (
		<form onSubmit={e => handleSubmit(e)} class="col">
			{directContract ? (
				<Line>
					<Field
						component={SelectField}
						floatingLabelText="Employé"
						fullWidth
						multiple
						name="workerId"
					>
						{networkWorkers.length === 0
							? [
									<MenuItem
										disabled
										key="noWorkers"
										primaryText="Pour proposer un contrat direct à un worker, rajoutez des Workers à votre équipe en évaluant les missions passées"
										value="noWorkers"
									/>,
							  ]
							: networkWorkers
									.sort(
										compare(networkWorker => networkWorker.firstName, {
											ascending: true,
										}),
									)
									.map(({ id, name }) => (
										<MenuItem key={id} value={id} primaryText={name} />
									))}
					</Field>
				</Line>
			) : null}

			<div style={{ paddingTop: "16px" }}>
				{isAddingWorker ? (
					<AddTarget handleClick={addNetworkWorker} />
				) : (
					<Button onClick={handleClick} secondary className="outline">
						Ajouter Nouveau
					</Button>
				)}
			</div>

			<Line>
				<Field
					fullWidth
					name="jobKey"
					component={SelectField}
					floatingLabelText="Métier"
					disabled={(directContract && !workerWasSelected) || !pickJob}
				>
					{jobs.map(({ key, name }) => (
						<MenuItem key={key} value={key} primaryText={name} />
					))}
				</Field>
			</Line>
			<Line>
				{!specifiesGrossRate ? (
					<Field
						name="rate"
						label="Taux horaire net"
						component={Rate}
						fullWidth={true}
					/>
				) : null}
			</Line>
			<section>
				<Shifts />
			</section>
			<ErrorMessage error={invalidPlanningError} />
			<DossierField
				businessId={businessId}
				dossierList={dossierList}
				newQuery={newQuery}
			/>
			<Field
				component={TextField}
				floatingLabelText="Réference"
				fullWidth
				name="reference"
				style={{ marginTop: -14 }}
			/>
			{specifiesLocation ? <Location /> : null}
			{specifiesReason ? <Reason reason={reason} /> : null}
			<React.Fragment>
				{directContract ? null : (
					<React.Fragment>
						<Field
							name="network"
							component={Checkbox}
							label="Restreindre la recherche à mon réseau"
						/>
						<Field
							name="businessDelegatesSelectionToGofer"
							component={Checkbox}
							label="Déléguer la sélection du worker à Gofer"
						/>
						<MaxWorkers />
					</React.Fragment>
				)}
				<Field
					name="comment"
					component={TextField}
					multiLine
					hintText={
						"Précisez ici la tenue, le logiciel ou toute autre information utile au bon déroulement de la mission"
					}
					fullWidth
					rows={1}
				/>
				{contractPreview ? (
					contractPreview.provisionalBillingAmount ? (
						<section class="row">
							<article class="dark-box card">
								<div class="row">
									<p>
										<strong>Total facturé</strong>
									</p>
									<p class="right">
										<strong>
											{formatCurrency(contractPreview.provisionalBillingAmount)}
										</strong>
									</p>
								</div>
								<div class="row">
									<p>
										Nous nous occupons de la Déclaration Préalable à l’Embauche,
										du contrat de travail et de la paie du worker.
									</p>
								</div>
							</article>
						</section>
					) : (
						<Loading />
					)
				) : null}
				<section class="row">
					<Button
						submit
						loading={submitting}
						onClick={() => {
							ReactGA.event({
								category: "Queries",
								action: "New query",
							});
						}}
						disabled={invalidPlanningError}
						className="right"
					>
						{buttonTitle
							? buttonTitle
							: directContract
							? "Proposer le contrat direct"
							: "Veuillez noter que nous ne traiterons pas cette demande de mission"}
					</Button>
				</section>
			</React.Fragment>
		</form>
	);
};

const getWorkerJobs = (workerIds = [], allWorkers = [], allJobs = []) => {
	console.log("workerIds");
	console.log(workerIds);

	const jobs = workerIds
		.map(id => allWorkers.find(worker => worker.id === id))
		.map(worker => (worker ? worker.jobs || [] : []))
		.filter(jbs => jbs.length > 0);

	return intersectionBy(...jobs, "key")
		.map(job => allJobs.find(j => j && job && j.key === job.key))
		.filter(job => !!job);
};

const getFormValues = ({ NewQuery = {} }) => {
	const { values } = NewQuery;
	return values || {};
};

const getInitialValues = ({
	currentUser: {
		defaultQueries,
		business: { managedByGofer, specifiesGrossRate, dossier },
	},
	form,
	jobKey,
	justification,
	shifts,
	worker,
}) => {
	const values = getFormValues(form);
	const initialValues = {
		businessDelegatesSelectionToGofer: managedByGofer,
		dossier: values.dossier || (dossier || [])[0],
		jobKey: jobKey || values.jobKey,
		justification,
		maxWorkers: 1,
		reason: reasons.habit,
		reference: values.reference,
		shifts,
		workerId: worker ? [worker.id] : values.workerId,
	};

	if (defaultQueries && defaultQueries.length) {
		console.log("defaultQueries");
		const jobKey = values && values.jobKey;
		if (jobKey) {
			const { rate, comment, breakMinutes } =
				defaultQueries.find(query => query.job && query.job.key === jobKey) ||
				{};
			initialValues.rate = specifiesGrossRate ? null : rate;
			initialValues.comment = comment;
			initialValues.breakMinutes = breakMinutes;
		}
	}

	return initialValues;
};

const EnhancedForm = compose(
	connect((state, props) => {
		const { networkWorkers, jobs, currentUser, justification, jobKey } = props;
		const {
			specifiesLocation,
			specifiesReason,
			defaultBreakMinutes,
			specifiesGrossRate,
			dossier,
			id: businessId,
		} = currentUser.business || {};
		const initialValues = getInitialValues({ ...state, ...props });
		const values = getFormValues(state.form);
		const { reason, shifts = [], workerId } = getFormValues(state.form);
		const worker = props.worker;

		console.log(getWorkerJobs(workerId, networkWorkers, jobs));

		return {
			defaultBreakMinutes: isNil(initialValues.breakMinutes)
				? defaultBreakMinutes
				: initialValues.breakMinutes,
			initialValues,
			reason,
			specifiesGrossRate,
			specifiesLocation,
			specifiesReason: specifiesReason && !justification,
			...props,
			businessId,
			dossierList: dossier || [],
			jobs: (workerId || []).length
				? getWorkerJobs(workerId, networkWorkers, jobs)
				: jobs,
			newQuery: values,
			pickJob: !jobKey,
			shifts,
			workerWasSelected: Boolean(workerId || worker),
		};
	}),
	reduxForm({
		enableReinitialize: true,
		form: "NewQuery",
		keepDirtyOnReinitialize: true,
		validate,
	}),
)(NewQueryForm);

export default props => {
	const user = useCurrentUser();
	return <EnhancedForm currentUser={user} {...props} />;
};
