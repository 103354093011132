import React from "react";
import Rate from "components/Rate";
import { maxRate, minGrossRate, defaultBillingFee } from "common/constants.js";
import Percentage from "components/Percentage";
import formatCurrency from "utils/formatCurrency";
import styled from "styled-components";
import moment from "moment";
import compare from "utils/compare";
import {
	computeCoefficient,
	computeBillingRate,
	computeBillingFee,
} from "./computeFunctions";

export const validateGrossRate = grossRate => {
	if (!grossRate) {
		return "Veuillez renseigner un taux horaire brut";
	}
	if (grossRate < minGrossRate) {
		return `Le taux horaire brut minimal est ${formatCurrency(minGrossRate)}`;
	}
	if (grossRate > maxRate) {
		return `Le taux horaire brut maximal est ${formatCurrency(maxRate)}`;
	}
};

const pickCondition = conditions => {
	conditions.sort(
		compare(({ validFrom }) => validFrom, {
			ascending: true,
		}),
	);
	let currentCondition;
	conditions.forEach((condition, indexConditions) => {
		if (moment(condition.validFrom).isSameOrBefore(moment())) {
			currentCondition = { ...condition, indexConditions };
		}
	});
	return currentCondition;
};

const getCondition = (
	conditions,
	updateConditions,
	foodCompensatoryAllowance,
) => {
	if (!conditions.length) {
		conditions = [{ validFrom: new Date(), billingFee: defaultBillingFee }];
	}

	const {
		grossRate,
		billingFee,
		billingRate,
		validFrom,
		indexConditions,
	} = pickCondition(conditions);

	const updateCondition = condition => {
		const newConditions = [...conditions];
		newConditions[indexConditions] = { ...condition, validFrom };
		updateConditions(newConditions);
	};

	return {
		grossRate,
		billingRate,
		billingFee:
			billingFee ||
			computeBillingFee({
				grossRate,
				billingRate,
				foodCompensatoryAllowance,
			}),
		updateGrossRate(grossRate) {
			updateCondition({
				grossRate,
				billingFee,
				billingRate: computeBillingRate({
					grossRate,
					billingFee,
					foodCompensatoryAllowance,
				}),
			});
		},
		updateBillingFee(billingFee) {
			updateCondition({
				grossRate,
				billingFee,
				billingRate: computeBillingRate({
					grossRate,
					billingFee,
					foodCompensatoryAllowance,
				}),
			});
		},
		updateBillingRate(billingRate) {
			updateCondition({
				grossRate,
				billingFee: computeBillingFee({
					grossRate,
					billingRate,
					foodCompensatoryAllowance,
				}),
				billingRate,
			});
		},
	};
};

export default ({
	conditions,
	updateConditions,
	foodCompensatoryAllowance,
}) => {
	const {
		grossRate,
		billingFee,
		billingRate,
		updateGrossRate,
		updateBillingFee,
		updateBillingRate,
	} = getCondition(conditions, updateConditions, foodCompensatoryAllowance);
	const coefficient = computeCoefficient({
		grossRate,
		foodCompensatoryAllowance,
		billingFee,
	});
	return (
		<SubContainer>
			<Rate
				fullWidth
				input={{
					value: grossRate,
					name: "grossRate",
					onChange: updateGrossRate,
				}}
				meta={{
					error: validateGrossRate(grossRate),
					dirty: true,
				}}
				label="Taux horaire brut *"
			/>
			<Percentage
				fullWidth
				input={{
					label: "Commission *",
					onChange: updateBillingFee,
					value: billingFee,
				}}
			/>
			<Line>
				<Rate
					fullWidth
					input={{
						value: billingRate,
						name: "billingRate",
						onChange: updateBillingRate,
					}}
					label="Taux de facturation *"
					disabled={!grossRate}
				/>
				<CoefficientContainer>
					Coefficient : {!isNaN(coefficient) ? coefficient : 0}
				</CoefficientContainer>
			</Line>
		</SubContainer>
	);
};

const SubContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const CoefficientContainer = styled.div`
	padding: 10px;
`;

const Line = styled.div`
	display: flex;
	align-items: baseline;
`;
