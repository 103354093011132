import React from "react";
import gql from "graphql-tag";
import graphql from "common/graphql";
import Table from "components/Table";
import { DeleteFromTargets } from "components/DeleteFromTargets";
import moment from "moment";
import styled from "styled-components";
import { addModal } from "architecture/Modals";
import WorkerProfile from "modals/WorkerProfile";
import { makeTarget } from "models/target";
import Button from "components/Button";
import debriefScores from "common/debriefScores";

const StyledTable = styled(Table)`
	margin: 20px;
`;

const TargetsTable = ({ query, columns }) => {
	const { getTargets: targets } = query;
	const targetsColumns = [
		{
			label: "Identifiant",
			getter: ({ worker: { id } }) => id,
		},
		{
			label: "Nom",
			getter: ({ worker: { name } }) => name,
		},
		{
			label: "Dernier Feedback",
			getter: ({
				matching: {
					debrief: {
						business: { score },
					},
				},
			}) => {
				const element = debriefScores.find(
					({ score: debriefScore }) => debriefScore === score,
				);
				if (element) {
					const { Emotion } = element;
					return <Emotion />;
				}
				return "";
			},
		},
		{
			label: "Mis à jour",
			getter: ({ answeredAt }) =>
				answeredAt ? moment(answeredAt).format("DD/MM/YYYY : HH:mm") : "",
		},
		{
			label: "Type de contrat",
			getter: ({ legalStatus }) => legalStatus,
		},
	];

	return (
		<div>
			<StyledTable
				id="AdminQuerySelected"
				columns={columns}
				rows={[query]}
				orderBy={0}
				title={"Requête sélectionnée"}
			/>
			<StyledTable
				id="AdminQueryAvailableWorkers"
				columns={[
					...targetsColumns,
					{
						label: "",
						getter: ({ onSelect, contract }) =>
							contract ? (
								contract.signed ? (
									"Signé"
								) : (
									"En attente de signature"
								)
							) : (
								<Button onClick={onSelect}>Proposer</Button>
							),
					},
					{
						label: "",
						getter: ({ worker, queryId }) => (
							<DeleteFromTargets queryId={queryId} workerId={worker.id} />
						),
					},
				]}
				rows={targets
					.filter(({ accepted }) => accepted)
					.map(target => ({
						...makeTarget(query)(target),
						onClick() {
							addModal(() => (
								<WorkerProfile workerId={target.worker.id} answer={target} />
							));
						},
					}))}
				orderBy={2}
				title={"Workers disponibles"}
			/>
			<StyledTable
				id="AdminQueryUnavailableWorkers"
				columns={targetsColumns}
				rows={targets.filter(({ accepted }) => !accepted)}
				orderBy={2}
				title={"Workers indisponibles"}
			/>
		</div>
	);
};

export default graphql(
	gql`
		query getAdminQuery($queryId: String!) {
			query(id: $queryId) {
				id
				getTargets(answered: true) {
					worker {
						id
						firstName
						name
						mediumAvatarURL
					}
					legalStatus
					accepted
					answeredAt
					answered
					businessRate
					provisionalBillingAmount
					contract {
						id
						signed
					}
					matching {
						businessSelectedWorkerAtLeastOnce
						debrief {
							business {
								score
							}
						}
					}
				}
				rate
				grossRateSpecifiedByBusiness
				providesNaturalAdvantage
				foodCompensatoryAllowance
				billingRate
				totalHours
				business {
					name
				}
				getJob {
					name
				}
				contracts {
					id
					signed
					getWorker {
						id
						name
					}
				}
				shiftsDescription
				shifts {
					breakMinutes
					startTime
					endTime
				}
				maxWorkers
				businessDelegatesSelectionToGofer
			}
		}
	`,
	({ actualQuery }) => ({ variables: { queryId: actualQuery.id } }),
)(TargetsTable);
