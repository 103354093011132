import React from "react";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";
import moment from "moment";

export default ({ isBadge, children, hideLoading }) => (
	<GraphQL
		hideLoading={hideLoading}
		query={gql`
      query contractsToBrief($ignoreBefore: DateTime) {
        admin {
          contractsToBrief(
            signed: true
            canceled: false
            startAfter: $ignoreBefore
            isGofer: false
          ) {
            id
            ${
							isBadge
								? ""
								: `
            getBusiness {
              name
              id
              farmingStartedAt
            }
            getWorker {
              name
              phoneNumber
              id
            }
            startTime
            getJob {
              name
              key
            }
            isFirst
            matching {
              workerContactedForBrief
              briefContactedAt
              briefContactedBy {
                name
              }
            }`
						}
          }
        }
      }
    `}
		variables={{
			ignoreBefore: moment()
				.startOf("day")
				.subtract(3, "days"),
		}}
	>
		{({ admin: { contractsToBrief } }) => children(contractsToBrief)}
	</GraphQL>
);
