import React from "react";
import Experience from "./Experience";
import { GraphQL } from "common/graphql";
import { GetWorkerResume, saveXp } from "models/worker/resume";
import { Card } from "components/Card";

export default ({ id, workerId, saveResume }) => (
	<Card>
		<GraphQL query={GetWorkerResume} variables={{ workerId }} noPoll={true}>
			{({ referenceValidator: { worker } }) => {
				const xp = worker.experiences.find(xp => xp.id === id);
				return (
					<article>
						<Experience
							{...xp}
							currentWorker={workerId}
							onChange={newXp => saveXp(workerId, newXp)}
						/>
					</article>
				);
			}}
		</GraphQL>
	</Card>
);
