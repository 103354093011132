import React from "react";
import gql from "graphql-tag";
import { GraphQL } from "common/graphql";
import Feedback from "./Feedbacks/Feedback";
import moment from "moment";

const feedbackProps = `
  shifts {
    endTime
  }
  getWorker {
    id
    name
    mediumAvatarURL
  }
  getJob {
    key
    name
  }
`;

export const getFeedback = ({ badge } = {}) => gql`
  query {
    contractsToDebrief {
      id
      ${badge ? "" : feedbackProps}
    }
  }
`;

export default class Feedbacks extends React.Component {
	render() {
		return (
			<GraphQL
				query={getFeedback()}
				variables={{ startAfter: moment().subtract(1, "month") }}
			>
				{({ contractsToDebrief }) => (
					<div class="desktop-grid with-gap">
						{contractsToDebrief.length === 0 ? (
							<article class="card twelve">
								Vous n'avez pas de feedback en attente ! Merci pour vos retours
								:)
							</article>
						) : null}
						{contractsToDebrief.map(({ getWorker, getJob, shifts, id }) => (
							<Feedback
								key={id}
								contractId={id}
								{...getWorker}
								shifts={shifts}
								job={getJob}
							/>
						))}
					</div>
				)}
			</GraphQL>
		);
	}
}
