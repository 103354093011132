import React from "react";
import { GoQuote } from "react-icons/go";

export default ({ children, className }) => (
	<blockquote class={className}>
		<GoQuote className="quote-icon" />
		{children}
		<GoQuote className="quote-icon" />
	</blockquote>
);
