import * as React from "react";
import { Pill, PillsList } from "components/Pills";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";

const ListSkills = gql`
	query {
		skills {
			key
			name
			category
			jobs {
				key
				unit
				name
			}
		}
	}
`;

export default ({ worker }) =>
	worker.skills ? (
		<GraphQL query={ListSkills} noPolling={true}>
			{({ skills }) => (
				<PillsList>
					{worker.skills
						.filter(skill => skills.find(s => s.key === skill.key))
						.map(skill => (
							<Pill key={skill.name}>{skill.name}</Pill>
						))}
				</PillsList>
			)}
		</GraphQL>
	) : null;
