import React, { useState } from "react";
import gql from "graphql-tag";
import { SideNav } from "components/Navs";
import { addModal } from "architecture/Modals";
import InfiniteScroll from "components/InfiniteScroll";
import Button from "components/Button";
import SurveyForm from "modals/Surveys/SurveyForm";
import SurveyResults from "modals/Surveys/SurveyResults";
import moment from "moment";
import Table from "components/Table";

const GetSurveys = gql`
	query($sortBy: SurveySortBy!, $skip: Int, $limit: Int) {
		admin {
			surveys(sortBy: $sortBy, skip: $skip, limit: $limit) {
				id
				question
				createdAt
				startAt
				endAt
				workerVisibility
				businessVisibility
			}
		}
	}
`;

export default () => {
	const [folder, setFolder] = useState("open");
	const [sortBy, setSortBy] = useState("createdAt");

	return (
		<div class="cover row">
			<SideNav>
				<button
					className={folder === "open" ? "selected" : ""}
					onClick={() => setFolder("open")}
				>
					Sondages ouverts
				</button>
				<button
					className={folder === "all" ? "selected" : ""}
					onClick={() => setFolder("all")}
				>
					Tous les sondages
				</button>
				<button
					className={folder === "workerVisibility" ? "selected" : ""}
					onClick={() => setFolder("workerVisibility")}
				>
					Sondages workers
				</button>
				<button
					className={folder === "businessVisibility" ? "selected" : ""}
					onClick={() => setFolder("businessVisibility")}
				>
					Sondages business
				</button>
			</SideNav>
			<article class="col expand">
				<section class="row">
					<Button
						className="right"
						onClick={() =>
							addModal(({ dismiss }) => (
								<SurveyForm
									dismiss={dismiss}
									survey={{
										predefinedAnswers: [],
										workerVisibility: false,
										businessVisibility: true,
									}}
								/>
							))
						}
					>
						Sondage B
					</Button>
					<Button
						onClick={() =>
							addModal(({ dismiss }) => (
								<SurveyForm
									dismiss={dismiss}
									survey={{
										predefinedAnswers: [],
										workerVisibility: true,
										businessVisibility: false,
									}}
								/>
							))
						}
					>
						Sondage W
					</Button>
				</section>
				<section class="scrollable expand">
					<InfiniteScroll
						query={GetSurveys}
						variables={{ sortBy }}
						extract={({ admin: { surveys } }) => surveys}
					>
						{loaded => (
							<Table
								rows={loaded
									.filter(
										survey =>
											folder === "all" ||
											(folder === "open" && moment(survey.endAt) > moment()) ||
											survey[folder],
									)
									.map(survey => ({
										...survey,
										onClick: e => {
											addModal(() => <SurveyResults surveyId={survey.id} />);
										},
									}))}
								columns={[
									{
										label: "Question",
										getter: ({ question }) => question,
										onHeaderClick: () => null,
									},
									{
										label: "Créé le",
										getter: ({ createdAt }) =>
											moment(createdAt).format("dddd DD MMMM YYYY"),
										onHeaderClick: () => setSortBy("createdAt"),
										active: sortBy === "createdAt",
									},
									{
										label: "Début le",
										getter: ({ startAt }) => moment(startAt).format("DD MMMM"),
										onHeaderClick: () => setSortBy("startAt"),
										active: sortBy === "startAt",
									},
									{
										label: "Fin le",
										getter: ({ endAt }) => moment(endAt).format("DD MMMM"),
										onHeaderClick: () => setSortBy("endAt"),
										active: sortBy === "endAt",
									},
								]}
							/>
						)}
					</InfiniteScroll>
				</section>
			</article>
		</div>
	);
};
