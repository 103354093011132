export const scope = key => fun => obj => ({
	...obj,
	[key]: fun(obj[key]),
});

export const toggle = bool => !bool;

export const arrayFromSet = (set = {}) => {
	const array = [];
	Object.keys(set).forEach(key => {
		if (set[key]) {
			array.push(key);
		}
	});
	return array;
};

export const formatBoolean = boolean => (boolean ? "oui" : "non");
