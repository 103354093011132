import React from "react";
import styled from "styled-components";
import graphql from "common/graphql";
import gql from "graphql-tag";
import moment from "moment";
import QueriesTable from "scenes/Home/Admin/QueriesTable";
import { compose, withState } from "recompose";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const defaultLimit = 20;

export default compose(
	withState("limit", "setLimit", defaultLimit),
	graphql(
		gql`
			query pendingQueries($now: DateTime, $limit: Int) {
				admin {
					queries(
						startAfter: $now
						allSigned: false
						canceled: false
						limit: $limit
						isGofer: false
					) {
						id
						rate
						business {
							name
						}
						getJob {
							name
						}
						contracts {
							id
							signed
							getWorker {
								id
								name
							}
						}
						shiftsDescription
						startTime
						maxWorkers
						businessDelegatesSelectionToGofer
					}
				}
			}
		`,
		({ limit }) => ({
			variables: {
				now: moment()
					.startOf("hour")
					.toISOString(),
				limit,
			},
		}),
	),
)(({ admin: { queries = [] }, setLimit, limit }) => (
	<React.Fragment>
		<QueriesTable queries={queries} />
		{queries.length >= defaultLimit ? (
			<Footer>
				{limit ? (
					<Text>Seuls les {defaultLimit} premiers resultats sont affichés</Text>
				) : null}
				<FormControlLabel
					control={
						<Checkbox
							checked={!limit}
							onChange={() => setLimit(limit ? null : defaultLimit)}
						/>
					}
					label="Afficher tous les résultats"
				/>
			</Footer>
		) : null}
	</React.Fragment>
));

const Text = styled.div``;
const Footer = styled.div`
	padding: 20px;
`;
