import moment from "moment";
import Request from "common/Request";
import store from "common/store";
import { addAlert } from "actions/alerts";

export const setInitialValues = data => {
	const bank = {
		...data.documents.bank,
		obfuscatedIBAN: data.obfuscatedIBAN,
	};

	const identity = {
		...data.documents.identity,
		belongsToEU: data.belongsToEU,
		nationality: data.belongsToEU
			? "nationalité zone EEE"
			: "nationalité hors zone EEE",
	};
	if (identity.expiresAt) {
		identity.expiresAt = moment(identity.expiresAt).toDate();
	}

	const socialSecurity = {
		...data.documents.socialSecurity,
	};
	if (socialSecurity.expiresAt) {
		socialSecurity.expiresAt = moment(socialSecurity.expiresAt).toDate();
	}

	const drive = {
		googleFolderId: data.documents.googleFolderId,
	};

	return {
		bank,
		identity,
		socialSecurity,
		drive,
		worker: {
			id: data.id,
			name: data.name,
		},
	};
};

export const prepareForSubmit = (data, workerId) => {
	const { bank, identity, refusal, socialSecurity } = data;
	return {
		workerId,
		message: refusal && refusal.adminMessage ? refusal.adminMessage : null,
		bank: Boolean(bank.validatedAt),
		identity: Boolean(identity.validatedAt),
		socialSecurity: Boolean(socialSecurity.validatedAt),
	};
};

export const updateInformations = async params => {
	try {
		await Request.mutation(
			/* GraphQL */ `
				mutation(
					$workerId: String!
					$bank: Boolean
					$socialSecurity: Boolean
					$identity: Boolean
					$message: String
				) {
					adminValidateWorkerDocuments(
						workerId: $workerId
						bank: $bank
						socialSecurity: $socialSecurity
						identity: $identity
						message: $message
					) {
						id
					}
				}
			`,
			params,
		);
		store.dispatch(addAlert("Vos informations ont été mises à jour."));
	} catch (error) {
		store.dispatch(
			addAlert("Vos informations n'ont pas pu être mises à jour."),
		);
	}
};
