import React from "react";
import cropText from "utils/cropText";
import { EmotionScore, feedbacks } from "common/debriefScores";
import UserCard from "components/UserCard";
import DarkBox from "components/DarkBox";
import { ActionsList } from "components/Button";
import { computeJobsExperience } from "models/worker/resume";
import { QuoteIcon } from "components/Decorations";
import moment from "moment";

const XpText = ({ experiences, job }) => {
	if (!experiences) {
		return null;
	}

	const xpJob = computeJobsExperience(
		experiences.filter(xp => xp.status === "validated"),
		true,
	).find(xpJob => xpJob.key === job.key);
	if (!xpJob) {
		return null;
	}

	const years = Math.floor(xpJob.nbMonths / 12);
	const months = xpJob.nbMonths % 12;

	return (
		<p className="centered">
			{years > 0 ? `${years} an${years > 1 ? "s " : " "}` : ""}
			{months > 0 && years < 2 ? `${months} mois` : ""} d'expérience vérifiée
			par Gofer pour le poste recherché
		</p>
	);
};

export default ({
	worker: {
		firstName,
		mediumAvatarURL,
		adminReview: { score },
		medianDebriefScore,
		featuredDebrief,
		pastContractsCount,
		experiences,
		verifiedAt,
	},
	matching,
	actions,
	job,
}) => {
	const isKnown = !!(
		matching &&
		matching.debrief &&
		matching.debrief.business &&
		matching.debrief.business.currentScore
	);
	const debrief = isKnown ? matching.debrief.business : undefined;

	return (
		<UserCard avatar={mediumAvatarURL}>
			<h3 class="centered">{firstName}</h3>

			<DarkBox
				illustrationPosition="top"
				className="col"
				illustration={() => (
					<EmotionScore
						score={isKnown ? debrief.currentScore : medianDebriefScore}
					/>
				)}
			>
				{isKnown ? (
					<article class="centered expand col">
						<strong>Votre avis</strong>
						<div>
							{debrief.comment
								? cropText(debrief.comment, 75)
								: feedbacks[debrief.currentScore].sentence({ firstName })}
						</div>
					</article>
				) : featuredDebrief && featuredDebrief.comment ? (
					<article class="centered expand col">
						<QuoteIcon />
						<em>{cropText(featuredDebrief.comment, 75)}</em>
						<strong>Un client Gofer</strong>
					</article>
				) : (
					<article class="centered col expand">
						<XpText experiences={experiences} job={job} />
						{pastContractsCount === 0 ? (
							<strong>{`Inscrit·e ${moment(verifiedAt).fromNow()}`}</strong>
						) : (
							<strong>{`${pastContractsCount} missions réalisées chez Gofer`}</strong>
						)}
					</article>
				)}
				<ActionsList actions={actions} />
			</DarkBox>
		</UserCard>
	);
};
