import React from "react";
import styled, { css } from "styled-components";
import debriefScores from "common/debriefScores";
import TextField from "@material-ui/core/TextField";
import Button from "components/Button";
import Request from "common/Request";
import { color, isMobile } from "common/styles";
import apolloClient from "common/apolloClient";
import WorkerProfile from "modals/WorkerProfile";

export default class WorkersToReview extends React.Component {
	state = { comment: "", privateComment: "" };

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleClick = (workerId, dismiss) => {
		if (this.state.score !== undefined) {
			Request.mutation(
				/* GraphQL */ `
					mutation(
						$workerId: ID!
						$score: Int
						$comment: String
						$privateComment: String
					) {
						adminReviewWorker(
							workerId: $workerId
							score: $score
							comment: $comment
							privateComment: $privateComment
						) {
							id
						}
					}
				`,
				{
					workerId,
					score: this.state.score,
					comment: this.state.comment,
					privateComment: this.state.privateComment,
				},
				{
					errorMessage: "Une erreur s'est produite. Veuillez réessayer.",
				},
			);
			apolloClient.reFetchObservableQueries();
			dismiss();
		} else {
			this.setState({ error: true });
		}
	};
	onClickScore(score) {
		this.setState({ score });
	}

	render() {
		const { workerId, dismiss } = this.props;
		return (
			<div>
				<div class="grid">
					<div class="from-two ten">
						<div>
							<Options isEmpty={this.state.error}>
								{debriefScores.map(({ score, Emotion }) => (
									<Option
										key={score}
										isSelected={score === this.state.score}
										onClick={() => this.onClickScore(score)}
									>
										<OptionIcon>
											<Emotion />
										</OptionIcon>
									</Option>
								))}
							</Options>
							<TextField
								label="Commentaire"
								onChange={this.handleChange("comment")}
								fullWidth
								margin="normal"
								variant="outlined"
							/>
							<TextField
								label="Commentaire interne"
								onChange={this.handleChange("privateComment")}
								fullWidth
								margin="normal"
								variant="outlined"
							/>
							<Button onClick={() => this.handleClick(workerId, dismiss)}>
								Review
							</Button>
						</div>
					</div>
				</div>
				<ProfileContainer>
					<WorkerProfile workerId={workerId} anonymizeFeedbacks={false} />
				</ProfileContainer>
			</div>
		);
	}
}

const ProfileContainer = styled.div`
	position: relative;
	flex: 1;
	> div {
		padding: 0 !important;
	}
`;

const Options = styled.div`
	display: flex;
	flex: 1;
	margin-top: 20px;
	display: flex;
	flex-direction: ${isMobile() ? "column" : "row"};
	${props =>
		props.isEmpty &&
		css`
			border-style: solid;
			border-width: 1px;
			border-color: #c00;
		`}
`;
const Option = styled.div`
	flex: 1;
	background-color: ${props =>
		props.isSelected ? color("grey", "pale") : css`white`};
	display: flex;
	flex-direction: ${isMobile() ? "row" : "column"};
	align-items: center;
	padding: 10px 15px;
	border-radius: 3px;

	&:hover {
		cursor: pointer;
		background-color: ${color("grey", "pale")};
	}

	&:active {
		background-color: ${color("black", "pale")};
	}
`;
const OptionIcon = styled.div`
	font-size: 2em;
	width: 50px;
	min-width: 50px;
	img {
		max-width: 100%;
	}
	margin-right: ${isMobile() ? 15 : 0}px;
`;
