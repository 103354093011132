import React, { useState } from "react";
import Lightbox from "components/Lightbox";
import Button from "components/Button";
import { EmotionScore } from "common/debriefScores";
import moment from "moment";
import compare from "utils/compare";
import { useHistory } from "models/worker/history";
import Loading from "components/Loading";
import capitalize from "utils/capitalize";

const Segment = ({
	businessDebriefs,
	startTime,
	endTime,
	pastContractCount,
}) => (
	<div>
		<div class="grid">
			<div class="one col vertical-centered">
				{businessDebriefs.length === 0 ? (
					<div class="empty-avatar" />
				) : (
					<EmotionScore score={businessDebriefs[0].score} />
				)}
			</div>
			<div class="eleven">
				<article>
					<p>
						{capitalize(moment(startTime).format("MMMM YYYY"))}
						{moment(startTime).format("MMMM YYYY") ===
						moment(endTime).format("MMMM YYYY")
							? ""
							: ` - ${moment(endTime).format("MMMM YYYY")}`}
					</p>
					<p class="discreet">
						{pastContractCount} mission
						{pastContractCount > 1 ? "s" : ""}
					</p>
				</article>
			</div>
		</div>
		{businessDebriefs.filter(d => d.comment).length > 0 ? (
			<div class="history">
				{businessDebriefs.map(debrief =>
					debrief.comment ? (
						<div>
							<article class="dark-box card">
								<div>
									<strong>
										Le {moment(debrief.collectedAt).format("DD MMMM")}
									</strong>
								</div>
								<div>
									<em>{debrief.comment}</em>
								</div>
							</article>
						</div>
					) : null,
				)}
			</div>
		) : null}
	</div>
);

const HistoryBox = ({ anonymizeFeedbacks, id }) => {
	const { loading, goferExperiences } = useHistory(id);

	if (loading) {
		return (
			<article>
				<Loading />
			</article>
		);
	}

	return (
		<article class="col">
			{goferExperiences
				.sort(
					compare(
						experience =>
							experience.segments[experience.segments.length - 1].startTime,
					),
				)
				.map(experience => (
					<section>
						<div class="row">
							<h3>
								{anonymizeFeedbacks !== false
									? experience.business.anonymousName
									: experience.business.name}
								<em>{` ${experience.job.name}`}</em>
							</h3>
						</div>

						{experience.segments.map(segment => (
							<Segment {...segment} />
						))}
					</section>
				))}
		</article>
	);
};

export default ({
	anonymizeFeedbacks,
	id,
	pastContractsCount,
	businessDebriefs,
}) => {
	const [showLightbox, setShowLightbox] = useState(false);

	return (
		<div class="centered">
			{pastContractsCount > 0 ? (
				<Button
					secondary
					className="outline"
					onClick={() => setShowLightbox(true)}
				>
					Voir {businessDebriefs.length} avis
				</Button>
			) : null}
			<Lightbox
				visible={showLightbox}
				onDismiss={() => setShowLightbox(undefined)}
			>
				{showLightbox ? (
					<HistoryBox id={id} anonymizeFeedbacks={anonymizeFeedbacks} />
				) : null}
			</Lightbox>
		</div>
	);
};
