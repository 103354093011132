import React from "react";
import Conditions from "./Conditions";
import { minRate, maxRate } from "common/constants";
import formatCurrency from "utils/formatCurrency";
import Select from "../customFields/Select";
import TextField from "@material-ui/core/TextField";
import Rate from "components/Rate";
import LineBrokenText from "components/LineBrokenText";
import DefaultBreakMinutes from "../customFields/DefaultBreakMinutes";
import styled from "styled-components";
import { color } from "common/styles";

const validateRate = rate => {
	if (!rate) {
		return "Veuillez renseigner un taux horaire";
	}
	if (rate < minRate) {
		return `Le taux horaire net minimal est ${formatCurrency(minRate)}`;
	}
	if (rate > maxRate) {
		return `Le taux horaire net maximal est ${formatCurrency(maxRate)}`;
	}
};

export default ({
	updateDefaultQuery,
	defaultQuery,
	specifiesGrossRate,
	foodCompensatoryAllowance,
	jobs,
	defaultBreakMinutes,
}) => {
	const updateConditions = conditions =>
		updateDefaultQuery(conditions, "conditions");

	const { jobKey, comment, rate, conditions, breakMinutes } = defaultQuery;
	return (
		<SubContainer>
			<Select
				label="Métier *"
				value={jobKey || ""}
				updateValue={value => updateDefaultQuery(value, "jobKey")}
				options={jobs.map(({ key, name }) => ({ key, value: name }))}
			/>
			<LineBrokenText>
				{`Consigne spécifique au métier:
                      - Grooming La tenue est elle fournie ?
                      - Quelles sont les exigences pour la tenue (pour les hommes / pour les femmes) ?
                      - Piercing autorisé ?`}
			</LineBrokenText>
			<TextField
				onChange={event => updateDefaultQuery(event.target.value, "comment")}
				fullWidth
				multiline
				label="Brief spécifique à ce métier *"
				variant="outlined"
				rows="4"
				value={comment || ""}
				margin="normal"
			/>
			{!specifiesGrossRate ? (
				<Rate
					fullWidth
					disabled={specifiesGrossRate === true}
					input={{
						value: rate,
						name: "rate",
						onChange: rate => updateDefaultQuery(rate, "rate"),
					}}
					meta={{
						error: validateRate(rate),
						dirty: specifiesGrossRate === false || specifiesGrossRate === null,
					}}
					label="Taux horaire net *"
				/>
			) : (
				<Conditions
					foodCompensatoryAllowance={foodCompensatoryAllowance}
					conditions={conditions}
					updateConditions={conditions => updateConditions(conditions)}
				/>
			)}
			<DefaultBreakMinutes
				value={
					typeof breakMinutes === "number" ? breakMinutes : defaultBreakMinutes
				}
				updateValue={value => updateDefaultQuery(value, "breakMinutes")}
			/>
		</SubContainer>
	);
};

const SubContainer = styled.div`
	border: 1px solid ${color("black", "pale")};
	padding: 10px;
	display: flex;
	flex-direction: column;
	margin: 5px 0;
	background-color: ${color("white")};
`;
