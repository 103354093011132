import createHash from "./createHash";

const splitArray = (array, whichCategory, categories = []) => {
	const result = createHash(categories, () => []);
	array.forEach(element => {
		const category = whichCategory(element);
		if (result[category]) {
			result[category].push(element);
		} else {
			result[category] = [element];
		}
	});
	return result;
};

export default splitArray;
