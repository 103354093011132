import React from "react";
import styled from "styled-components";
import { position } from "polished";
import HiddenBlock from "components/HiddenBlock";
import { color, card } from "common/styles";

export default ({ error }) => {
	console.log("error", error);
	return (
		<Background>
			<Box>
				<Icon>
					<span role="img" aria-label="dizzy-face">
						😵
					</span>
				</Icon>
				<Message>Oups ! Une erreur s'est produite ...</Message>
				<HiddenBlock>
					<ObjectAsList object={error} />
				</HiddenBlock>
			</Box>
		</Background>
	);
};

const ObjectAsList = ({ object }) => (
	<ul>
		{Object.keys(object).map(key => (
			<li key={key}>
				<b>{key} :</b> {JSON.stringify(object[key])}
			</li>
		))}
	</ul>
);

const Background = styled.div`
	${position("fixed", "0", "0", "0", "0")};
	background-color: ${color("black", "normal", 0.2)};
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Box = styled.div`
	padding: 0 20px;
	${card};
	display: flex;
	flex-direction: column;
	align-items: stretch;
`;
const Icon = styled.span`
	text-align: center;
	font-size: 3em;
	margin: 20px 0;
`;
const Message = styled.div``;
