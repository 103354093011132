const createHash = (array, fun) => {
	const hash = {};

	let i = 0;
	while (i < array.length) {
		const key = array[i];
		hash[key] = fun(key, i);
		i++;
	}
	return hash;
};

export default createHash;
