import { defaultBillingFee } from "common/constants.js";
import round from "utils/round";

const fillonReduction = ({ grossRate, foodCompensatoryAllowance }) =>
	(0.2849 / 0.6) *
	Math.max(
		0,
		1.6 *
			(18255 /
				(1820 *
					(1.21 * (grossRate / 100 + foodCompensatoryAllowance / 100 / 7)))) -
			1,
	) *
	1.1;

export const computeCoefficientWithoutBillingFee = ({
	grossRate,
	foodCompensatoryAllowance,
}) =>
	1.21 *
	(1 +
		0.4663 -
		fillonReduction({
			grossRate,
			foodCompensatoryAllowance,
		}));

export const computeCoefficient = ({
	grossRate,
	foodCompensatoryAllowance,
	billingFee = defaultBillingFee,
}) =>
	round(
		computeCoefficientWithoutBillingFee({
			grossRate,
			foodCompensatoryAllowance,
		}) *
			(1 + billingFee),
	);

export const computeBillingRate = ({
	grossRate,
	billingFee = defaultBillingFee,
	foodCompensatoryAllowance,
}) =>
	Math.round(
		grossRate *
			computeCoefficient({
				grossRate,
				foodCompensatoryAllowance,
				billingFee,
			}),
	);

export const computeBillingFee = ({
	billingRate,
	grossRate,
	foodCompensatoryAllowance,
}) =>
	computeBillingRate({
		grossRate,
		foodCompensatoryAllowance,
		billingFee: defaultBillingFee,
	}) === billingRate
		? defaultBillingFee
		: round(
				billingRate /
					(grossRate *
						computeCoefficientWithoutBillingFee({
							grossRate,
							foodCompensatoryAllowance,
						})) -
					1,
				4,
		  );
