import React from "react";
import styled from "styled-components";
import { SideNav } from "components/Navs";
import BudgetStats from "./Budget/BudgetStats";
import { color, isMobile } from "common/styles";
import { tabletBreakpoint } from "common/styles";

const BudgetRow = styled.div`
	display: flex;
	flex-direction: row;
	min-height: 100%;
	background-color: ${color("appBackground")};

	@media screen and (max-width: ${tabletBreakpoint}px) {
		flex-direction: column-reverse;
	}
`;

export default class Budget extends React.Component {
	state = {
		dataType: "billing",
	};

	render() {
		const { dataType } = this.state;

		return (
			<BudgetRow>
				<SideNav>
					<button
						className={dataType === "billing" ? "selected" : ""}
						onClick={() => this.setState({ dataType: "billing" })}
					>
						Dépenses
					</button>
					<button
						className={dataType === "hours" ? "selected" : ""}
						onClick={() => this.setState({ dataType: "hours" })}
					>
						{isMobile() ? "Heures" : "Nombre d'heures"}
					</button>
					<button
						className={dataType === "contracts" ? "selected" : ""}
						onClick={() => this.setState({ dataType: "contracts" })}
					>
						{isMobile() ? "Vacations" : "Nombre de vacations"}
					</button>
				</SideNav>
				<BudgetStats dataType={dataType} />
			</BudgetRow>
		);
	}
}
