import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import useAuth0 from "common/useAuth0";
import Loading from "components/Loading";

export default ({ component: Component, ...rest }) => {
	const { loading, isAuthenticated, authenticate } = useAuth0();

	useEffect(() => {
		if (loading || isAuthenticated) {
			return;
		}
		authenticate();
	}, [loading, isAuthenticated]);

	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated ? <Component {...props} /> : <Loading />
			}
		/>
	);
};
