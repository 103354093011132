import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ErrorMessage from "./ErrorMessage";
import { compose } from "recompose";
import { connect } from "react-redux";
import { reduxForm, Field, change, updateSyncWarnings } from "redux-form";
import { Checkbox, SelectField, TextField } from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import Button from "components/Button";
import Rate from "components/Rate";
import Location from "./Location";
import Reason from "./Reason";
import Shifts from "./Shifts";
import reasons from "common/reasons.json";
import { isMobile } from "common/styles";
import validate from "./validate";
import MaxWorkers from "components/MaxWorkers";
import isConflictingPlanning from "models/conflictingPlannings/isConflictingPlanning";
import ReactGA from "react-ga";
import AsyncSearch from "components/AsyncSearch";
import adminBusinesses from "components/AsyncSearch/adminBusinesses";
import adminWorkers from "components/AsyncSearch/adminWorkers";
import compare from "utils/compare";
import useCurrentUser from "common/useCurrentUser";
import moment from "moment";
import { dispatch } from "common/store";
import formatCurrency from "utils/formatCurrency";
import Loading from "components/Loading";
import Request from "common/Request";

const isSet = value => value !== undefined && value !== null;

const getShiftsKey = shifts =>
	shifts
		.map(
			({ startTime, endTime }) =>
				`${moment(startTime).format()} - ${moment(endTime).format()}`,
		)
		.join("\n");

const ButtonsRow = styled.div`
	display: flex;
	padding-top: 20px;
`;

export const Form = ({
	adminNewContract,
	businessId,
	buttonTitle,
	contractPreview,
	defaultBreakMinutes,
	directContract,
	dossierList = [],
	handleSubmit,
	jobs,
	networkWorkers,
	newQuery,
	onChange,
	pickJob,
	reason,
	shifts = [],
	specifiesGrossRate,
	specifiesLocation,
	specifiesReason,
	submitting,
	workerWasSelected,
}) => {
	const previousQuery = useRef();
	useEffect(() => {
		if (
			JSON.stringify(previousQuery.current) !== JSON.stringify(newQuery) &&
			newQuery.workerId
		) {
			onChange && onChange(newQuery);
		}
		previousQuery.current = newQuery;
	});

	useEffect(() => {
		shifts.forEach(({ startTime, endTime, breakMinutes }, index) => {
			const expectedBreakMinutes =
				moment(endTime).diff(moment(startTime), "hours") < 6
					? 0
					: defaultBreakMinutes;
			if (breakMinutes !== expectedBreakMinutes) {
				dispatch(
					change(
						"NewQuery",
						`shifts[${index}].breakMinutes`,
						expectedBreakMinutes,
					),
				);
				dispatch(
					change(
						"NewContract",
						`shifts[${index}].breakMinutes`,
						expectedBreakMinutes,
					),
				);
			}
		});
	}, [defaultBreakMinutes, getShiftsKey(shifts)]);

	const invalidPlanningError = isConflictingPlanning(shifts);

	const [isNewDossier, setIsNewDossier] = useState(false);

	const onToggleIsNewDossier = useCallback(() => {
		dispatch(change("NewQuery", "dossier", ""));
		setIsNewDossier(!isNewDossier);
	}, [isNewDossier]);

	const [isNewDosierSaving, setIsNewDosierSaving] = useState(false);

	const onCreateNewDossier = useCallback(async () => {
		try {
			setIsNewDosierSaving(true);
			dispatch(updateSyncWarnings("NewQuery", { dossier: "" }));
			await Request.mutation(
				/* GraphQL */ `
					mutation($businessId: ID!, $dossier: String!) {
						saveDossier(businessId: $businessId, dossier: $dossier) {
							id
							dossier
						}
					}
				`,
				{
					businessId,
					dossier: newQuery.dossier || "",
				},
			);

			setIsNewDossier(false);
		} catch (err) {
			console.log(err);
			dispatch(updateSyncWarnings("NewQuery", { dossier: err.message }));
		} finally {
			setIsNewDosierSaving(false);
		}
	}, [businessId, newQuery]);

	return (
		<form onSubmit={e => handleSubmit(e)} class="col">
			<Line>
				{directContract && networkWorkers ? (
					<Field
						fullWidth
						name="workerId"
						component={SelectField}
						floatingLabelText="Worker"
					>
						{networkWorkers.length === 0
							? [
									<MenuItem
										disabled={true}
										key={"noWorkers"}
										value={"noWorkers"}
										primaryText={
											"Pour proposer un contrat direct à un worker, rajoutez des Workers à votre équipe en évaluant les missions passées"
										}
									/>,
							  ]
							: networkWorkers
									.sort(
										compare(networkWorker => networkWorker.firstName, {
											ascending: true,
										}),
									)
									.map(({ id, name }) => (
										<MenuItem key={id} value={id} primaryText={name} />
									))}
					</Field>
				) : null}
				{adminNewContract ? (
					<React.Fragment>
						<Field
							name="workerId"
							component={AsyncSearch}
							fetchOptions={adminWorkers}
							placeHolder={"Worker"}
							fullWidth
						/>
						<Field
							name="businessId"
							component={AsyncSearch}
							fetchOptions={adminBusinesses}
							placeHolder={"Business"}
							fullWidth
						/>
					</React.Fragment>
				) : null}
				<Field
					fullWidth
					name="jobKey"
					component={SelectField}
					floatingLabelText="Métier"
					disabled={(directContract && !workerWasSelected) || !pickJob}
				>
					{jobs.map(({ key, name }) => (
						<MenuItem key={key} value={key} primaryText={name} />
					))}
				</Field>
			</Line>
			<Line>
				{!specifiesGrossRate ? (
					<Field
						name="rate"
						label="Taux horaire net"
						component={Rate}
						fullWidth={true}
					/>
				) : null}
			</Line>
			<section>
				<Shifts allowPastDate={adminNewContract} />
			</section>
			<ErrorMessage error={invalidPlanningError} />
			{isNewDossier ? (
				<>
					<Field
						component={TextField}
						floatingLabelText="Dossier"
						fullWidth
						name="dossier"
						inputStyle={{ paddingRight: 42 }}
					/>
					{isNewDosierSaving ? (
						<Loading />
					) : (
						<ButtonsRow>
							<Button
								className="outline"
								onClick={onToggleIsNewDossier}
								secondary
							>
								Annuler
							</Button>
							<Button
								disabled={(newQuery.dossier || "").trim().length === 0}
								onClick={onCreateNewDossier}
							>
								Confirmer
							</Button>
						</ButtonsRow>
					)}
				</>
			) : (
				<Field
					component={SelectField}
					floatingLabelText="Dossier"
					fullWidth
					name="dossier"
					style={{ marginTop: -14 }}
				>
					{dossierList.map(dossier => (
						<MenuItem key={dossier} value={dossier} primaryText={dossier} />
					))}
					<div
						key="createDossierButton"
						style={{
							padding: "0 20px 0",
							paddingTop: dossierList.length > 0 ? 10 : 0,
						}}
					>
						<Button
							className="outline"
							onClick={onToggleIsNewDossier}
							secondary
						>
							Créer un dossier
						</Button>
					</div>
				</Field>
			)}
			<Field
				component={TextField}
				floatingLabelText="Réference"
				fullWidth
				name="reference"
				style={{ marginTop: -14 }}
			/>
			{specifiesLocation ? <Location /> : null}
			{specifiesReason ? <Reason reason={reason} /> : null}
			{adminNewContract ? (
				<React.Fragment>
					<Field
						name="tracked"
						component={Checkbox}
						label="Pointer les horaires du contrat"
					/>
					<Button submit loading={submitting}>
						Créer le contrat
					</Button>
				</React.Fragment>
			) : (
				<React.Fragment>
					{directContract ? null : (
						<React.Fragment>
							<Field
								name="network"
								component={Checkbox}
								label="Restreindre la recherche à mon réseau"
							/>
							<Field
								name="businessDelegatesSelectionToGofer"
								component={Checkbox}
								label="Déléguer la sélection du worker à Gofer"
							/>
							<MaxWorkers />
						</React.Fragment>
					)}
					<Field
						name="comment"
						component={TextField}
						multiLine
						hintText={
							"Précisez ici la tenue, le logiciel ou toute autre information utile au bon déroulement de la mission"
						}
						fullWidth
						rows={1}
					/>
					{contractPreview ? (
						contractPreview.provisionalBillingAmount ? (
							<section class="row">
								<article class="dark-box card">
									<div class="row">
										<p>
											<strong>Total facturé</strong>
										</p>
										<p class="right">
											<strong>
												{formatCurrency(
													contractPreview.provisionalBillingAmount,
												)}
											</strong>
										</p>
									</div>
									<div class="row">
										<p>
											Nous nous occupons de la Déclaration Préalable à
											l’Embauche, du contrat de travail et de la paie du worker.
										</p>
									</div>
								</article>
							</section>
						) : (
							<Loading />
						)
					) : null}
					<section class="row">
						<Button
							submit
							loading={submitting}
							onClick={() => {
								ReactGA.event({
									category: "Queries",
									action: "New query",
								});
							}}
							disabled={invalidPlanningError}
							className="right"
						>
							{buttonTitle
								? buttonTitle
								: directContract
								? "Proposer le contrat direct"
								: "Envoyer la demande"}
						</Button>
					</section>
				</React.Fragment>
			)}
		</form>
	);
};
const Line = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-direction: ${!isMobile() ? "row" : "column"};
`;
const getWorkerJobs = (workerId, workers, jobs) => {
	const worker = workers.find(worker => workerId === worker.id);
	return worker.jobs
		.map(({ key }) => jobs.find(job => job.key === key))
		.filter(Boolean);
};

const EnhancedForm = compose(
	connect((state, props) => {
		const {
			networkWorkers,
			jobs,
			directContract,
			currentUser,
			justification,
			jobKey,
		} = props;
		const {
			specifiesLocation,
			specifiesReason,
			defaultBreakMinutes,
			specifiesGrossRate,
			dossier,
			id: businessId,
		} = currentUser.business || {};
		const initialValues = getInitialValues({ ...state, ...props });
		const values = getFormValues(state.form);
		const { reason, shifts = [], workerId } = getFormValues(state.form);
		const worker = props.worker;

		return {
			specifiesLocation,
			specifiesReason: specifiesReason && !justification,
			defaultBreakMinutes: isSet(initialValues.breakMinutes)
				? initialValues.breakMinutes
				: defaultBreakMinutes,
			specifiesGrossRate,
			initialValues,
			reason,
			...props, // why ????????
			shifts,
			workerWasSelected: Boolean(workerId || worker),
			pickJob: !jobKey,
			jobs: worker
				? worker.jobs
				: directContract && workerId
				? getWorkerJobs(workerId, networkWorkers, jobs)
				: jobs,
			newQuery: values,
			dossierList: dossier || [],
			businessId,
		};
	}),
	reduxForm({
		form: "NewQuery",
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		validate,
	}),
)(Form);

export default props => {
	const user = useCurrentUser();
	console.log(user);
	return <EnhancedForm currentUser={user} {...props} />;
};

const getFormValues = ({ NewQuery = {} }) => {
	const { values } = NewQuery;
	return values || {};
};

const getInitialValues = ({
	currentUser: {
		defaultQueries,
		business: { managedByGofer, specifiesGrossRate, dossier },
	},
	form,
	jobKey,
	justification,
	shifts,
	worker,
}) => {
	const values = getFormValues(form);
	const initialValues = {
		businessDelegatesSelectionToGofer: managedByGofer,
		dossier: values.dossier || (dossier || [])[0],
		jobKey: jobKey || values.jobKey,
		justification,
		maxWorkers: 1,
		reason: reasons.habit,
		reference: values.reference,
		shifts,
		workerId: worker ? worker.id : values.workerId,
	};
	if (defaultQueries && defaultQueries.length) {
		const jobKey = values.jobKey;
		if (jobKey) {
			const { rate, comment, breakMinutes } =
				defaultQueries.find(query => query.job.key === jobKey) || {};
			initialValues.rate = specifiesGrossRate ? null : rate;
			initialValues.comment = comment;
			initialValues.breakMinutes = breakMinutes;
		}
	}
	return initialValues;
};
