import React from "react";
import SurveyForm from "./SurveyForm";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Loading from "components/Loading";

const getEditableSurvey = gql`
	query AdminGetSurvey($id: ID!) {
		admin {
			survey(id: $id) {
				id
				question
				createdAt
				startAt
				endAt
				ignoringUserCount
				customResponse
				multipleResponse
				workerVisibility
				businessVisibility

				targetedJobs {
					key
					name
				}
				targetedUsers {
					id
					name
				}
				blacklistedUsers {
					id
					name
				}
				customAnsweringUsers {
					custom
					user {
						name
					}
				}
				predefinedAnsweringUsers {
					predefinedAnswer
					userCount
				}
			}
		}
	}
`;

export default ({ surveyId, dismiss }) => {
	const { loading, data } = useQuery(getEditableSurvey, {
		variables: { id: surveyId },
	});

	return loading || !data || !data.admin ? (
		<Loading />
	) : (
		<SurveyForm
			dismiss={dismiss}
			survey={{
				...data.admin.survey,
				predefinedAnswers: data.admin.survey.predefinedAnsweringUsers.map(
					pa => pa.predefinedAnswer,
				),
			}}
		/>
	);
};
