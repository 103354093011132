import Request from "common/Request";
import gql from "graphql-tag";

const workersMatchingRegex = gql`
	query workers($orderBy: WorkersOrderBy, $limit: Int, $regex: String) {
		admin {
			workers(orderBy: $orderBy, limit: $limit, regex: $regex) {
				id
				name
			}
		}
	}
`;

export default async regex => {
	const {
		admin: { workers },
	} = await Request.query(workersMatchingRegex, {
		variables: { limit: 10, regex, orderBy: "name" },
	});
	return workers;
};
