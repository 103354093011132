import React, { useState } from "react";
import { useSkills, categories } from "models/skills";
import TextField from "@material-ui/core/TextField";
import Loading from "components/Loading";
import Table from "components/Table";
import Button from "components/Button";
import CreateSkill from "modals/Admin/CreateSkill";
import ViewSkill from "modals/Admin/ViewSkill";
import { addModal } from "architecture/Modals";

export default () => {
	const { skills, loading } = useSkills();
	const [search, setSearch] = useState("");

	if (loading) {
		return <Loading />;
	}

	return (
		<div class="grid">
			<div class="ten from-two">
				<div class="col">
					<section class="row">
						<TextField
							onChange={event => {
								setSearch(event.target.value);
							}}
							placeholder="Recherche"
						/>
						<Button
							onClick={() =>
								addModal(({ dismiss }) => <CreateSkill dismiss={dismiss} />)
							}
							className="right"
						>
							Ajouter une compétence
						</Button>
					</section>
					<section>
						<Table
							columns={[
								{
									label: "Compétence",
									getter: ({ name }) => name,
								},
								{
									label: "Catégorie",
									getter: ({ category }) => categories[category],
								},
							]}
							rows={skills
								.filter(
									s =>
										!search ||
										s.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
										(s.category &&
											categories[s.category]
												.toLowerCase()
												.indexOf(search.toLowerCase()) !== -1),
								)
								.map(skill => ({
									...skill,
									onClick: () =>
										addModal(({ dismiss }) => (
											<ViewSkill {...skill} dismiss={dismiss} />
										)),
								}))}
							orderBy={0}
						/>
					</section>
				</div>
			</div>
		</div>
	);
};
