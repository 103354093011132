const makeOneMemoized = fun => {
	const results = {};
	return arg => {
		let result = results[arg];
		if (typeof result === "undefined") {
			result = fun(arg);
			results[arg] = result;
		}
		return result;
	};
};

export default makeOneMemoized;
