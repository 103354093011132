import React from "react";
import styled from "styled-components";
import { TextField } from "redux-form-material-ui";
import { reduxForm, Field } from "redux-form";
import Button from "components/Button";

const StyledForm = styled.form`
	overflow: auto;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

let Form = props => {
	const { handleSubmit, loading } = props;
	return (
		<StyledForm onSubmit={handleSubmit}>
			<Container>
				<Field
					name="workerId"
					floatingLabelText="Identifiant du worker"
					component={TextField}
					type="text"
				/>
				<Button submit loading={loading}>
					Synchroniser le worker
				</Button>
			</Container>
		</StyledForm>
	);
};

const createReduxForm = reduxForm({
	form: "syncWorker",
});

Form = createReduxForm(Form);

export default Form;
