import React from "react";
import PolitePrompt from "components/PolitePrompt";
import styled from "styled-components";
import gql from "graphql-tag";
import Request from "common/Request";
import { LightTextField, LightRadio } from "components/Inputs";
import Button from "components/Button";
import { mobileBreakpoint } from "common/styles";
import useCurrentUser from "common/useCurrentUser";

const WeatherList = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;

	img {
		height: 75px;
		width: 75px;
		margin-left: 5px;
		margin-right: 5px;
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0);
		transition: box-shadow 500ms ease;
		border-radius: 40px;
		cursor: pointer;
		&:hover {
			box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
		}
	}

	@media screen and (max-width: ${mobileBreakpoint}px) {
		img {
			height: 55px;
			width: 55px;
		}
	}
`;

const answerSatisfactionSurvey = gql`
	mutation($rating: SatisfactionSurveyRating) {
		answerSatisfactionSurvey(rating: $rating, ignored: false) {
			success
		}
	}
`;

const ignoreSatisfactionSurvey = gql`
	mutation {
		answerSatisfactionSurvey(rating: null, ignored: true) {
			success
		}
	}
`;

const ignorePredictionSurvey = gql`
	mutation {
		answerPredictionSurvey(prediction: null, comment: null, ignored: true) {
			success
		}
	}
`;

const answerPredictionSurvey = gql`
	mutation($prediction: PredictionSurveyPrediction, $comment: String) {
		answerPredictionSurvey(
			prediction: $prediction
			comment: $comment
			ignored: false
		) {
			success
		}
	}
`;

const PredictionLine = styled.label`
	display: flex;
	width: 100%;
	align-items: center;
	text-align: left;
	margin-bottom: 10px;
`;

const FormLine = styled.div`
	text-align: left;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	img {
		height: 75px;
		margin-left: 20px;
	}
`;

const SendContainer = styled.div`
	flex-direction: row-reverse;
	display: flex;
`;

const PreviousRating = styled.img`
	position: absolute;
	height: 80px;
	width: 80px;
	right: 15px;
	top: 70px;
`;

const satisfactionRatings = ["bad", "okay", "good", "veryGood"];
const predictions = [
	{ value: "increase", label: "Augmenter" },
	{ value: "decrease", label: "Diminuer" },
	{ value: "constant", label: "Rester stable" },
];

class Weathercast extends React.Component {
	state = {
		rating: undefined,
	};

	async ignoreSatisfactionSurvey() {
		this.setState({ dismissed: true });
		await Request.mutation(ignoreSatisfactionSurvey);
	}

	async answerSatisfactionSurvey(rating) {
		this.setState({ dismissed: true, rating });
		await Request.mutation(answerSatisfactionSurvey, {
			rating,
		});
		this.setState({ dismissed: false });
	}

	async ignorePredictionSurvey() {
		this.setState({ dismissed: true });
		await Request.mutation(ignorePredictionSurvey);
	}

	async answerPredictionSurvey(e) {
		e.preventDefault();

		const { prediction, comment } = this.state;

		this.setState({ dismissed: true });
		await Request.mutation(answerPredictionSurvey, {
			prediction,
			comment,
		});
		this.setState({ dismissed: false, answered: true });
		//auto close after some time
		setTimeout(() => this.setState({ dismissed: true }), 5000);
	}

	ignore() {
		const { rating } = this.state;

		if (!rating) {
			this.ignoreSatisfactionSurvey();
		}
		this.ignorePredictionSurvey();
	}

	weatherSurvey() {
		return <WeatherList></WeatherList>;
	}

	predictionSurvey() {
		const { prediction, rating } = this.state;
		return (
			<form onSubmit={e => this.answerPredictionSurvey(e)}>
				<PreviousRating src={`/weather/${rating}.svg`} />

				{predictions.map(p => (
					<PredictionLine key={p.value}>
						<LightRadio
							checked={prediction === p.value}
							onChange={(e, v) => v && this.setState({ prediction: p.value })}
						/>
						<div>{p.label}</div>
					</PredictionLine>
				))}
				<FormLine>
					<LightTextField
						fullWidth={true}
						placeholder="Commentaires utiles à l'équipe Gofer"
						multiline={true}
						onChange={e => this.setState({ comment: e.target.value })}
						variant="outlined"
					/>
				</FormLine>
				<SendContainer>
					<Button submit>Envoyer</Button>
				</SendContainer>
			</form>
		);
	}

	thanks =
		"Merci ! Vos réponses nous sont précieuses pour nous aider à améliorer votre expérience.";

	render() {
		const { user } = this.props;
		const { rating, dismissed, answered } = this.state;

		if (
			!user.business ||
			!user.business.shouldDisplaySatisfactionAndPredictionSurvey
		) {
			return null;
		}

		return (
			<PolitePrompt
				title={
					!rating
						? "🔒 Attention ! Votre application change, et nous ne traiterons plus les nouvelles missions ici. Vous conservez l'accès pour les missions en cours et les pointages. Merci de vous rendre sur https://webapp.gofer.fr  N'hésitez pas à contacter votre équipe Gofer si vous avez des questions. 🔒"
						: !answered
						? "Le mois prochain, vos besoins en intérim avec Gofer vont :"
						: this.thanks
				}
				onClose={() =>
					!answered ? this.ignore() : this.setState({ dismissed: true })
				}
				hide={dismissed}
			>
				<article>
					{answered
						? null
						: rating
						? this.predictionSurvey()
						: this.weatherSurvey()}
				</article>
			</PolitePrompt>
		);
	}
}

export default props => {
	const user = useCurrentUser();
	return <Weathercast user={user} {...props} />;
};
