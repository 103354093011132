import React from "react";
import styled from "styled-components";
import formatCurrency from "utils/formatCurrency";
import Button from "components/Button";
import Request from "common/Request";
import Table from "components/Table";
import sum from "utils/sum";
import Typography from "@material-ui/core/Typography";

export default ({ payments }) => (
	<Container>
		<Typography variant="h4" gutterBottom>
			Total : {formatCurrency(sum(payments.map(payment => payment.amount)))}
		</Typography>
		<Table
			id="PaymentsToValidateTable"
			columns={[
				{
					label: "Identifiant",
					getter: ({ id }) => id,
				},
				{
					label: "Prénom",
					getter: ({ worker: { firstName } }) => firstName,
				},
				{
					label: "Nom",
					getter: ({ worker: { lastName } }) => lastName,
				},
				{
					label: "Montant",
					getter: ({ amount }) => formatCurrency(amount),
				},
				{
					label: "",
					getter: payment => <ValidatePaymentButtons paymentId={payment.id} />,
				},
			]}
			rows={payments}
			orderBy={2}
		/>
	</Container>
);

class ValidatePaymentButtons extends React.Component {
	state = {
		validating: false,
	};

	validatePayment = async ({ ignoreEmail }) => {
		this.setState({ validating: true });
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation($paymentId: ID!, $ignoreEmail: Boolean) {
						validatePayment(paymentId: $paymentId, ignoreEmail: $ignoreEmail) {
							id
						}
					}
				`,
				{
					paymentId: this.props.paymentId,
					ignoreEmail,
				},
			);
		} catch (error) {
			console.error(error);
			this.setState({ validating: false });
		}
	};

	render() {
		const { validating } = this.state;
		return (
			<ButtonsContainer>
				<Button
					onClick={() => this.validatePayment({ ignoreEmail: false })}
					loading={validating}
				>
					Valider
				</Button>
				{validating ? null : (
					<Button
						onClick={() => this.validatePayment({ ignoreEmail: true })}
						secondary
					>
						Ignorer
					</Button>
				)}
			</ButtonsContainer>
		);
	}
}

const Container = styled.div`
	margin-top: 20px;
	overflow: auto;
`;

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	& {
		div + div {
			margin-left: 20px;
		}
	}
`;
