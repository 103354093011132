import React, { useContext } from "react";
import Loading from "components/Loading";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import useCurrentUser from "common/useCurrentUser";
import ErrorBox from "components/ErrorBox";

export const JobsContext = React.createContext();

export default () => useContext(JobsContext);

const JOBS = gql`
	query jobs {
		jobs {
			key
			name
			unit
		}
	}
`;

export const JobsProvider = ({ children }) => {
	const user = useCurrentUser() || {};
	const defaultQueries = user.defaultQueries || [];
	const { loading, data, error } = useQuery(JOBS);
	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <ErrorBox error={error} />;
	}
	const { jobs } = data;
	const configuredJobs = defaultQueries
		.map(({ job: { key } }) => jobs.find(job => job.key === key))
		.filter(Boolean);
	return (
		<JobsContext.Provider value={configuredJobs.length ? configuredJobs : jobs}>
			{children}
		</JobsContext.Provider>
	);
};
