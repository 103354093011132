import React from "react";
import styled from "styled-components";
import debriefScores from "common/debriefScores";
import { Row, Card } from "components/Card";
import objectFromEntries from "utils/objectFromEntries";
import moment from "moment";

const Emotions = objectFromEntries(
	debriefScores.map(({ score, Emotion }) => [score, Emotion]),
);

const displayByRelatedTo = {
	worker: "business",
	business: "worker",
};

export default ({ title, debriefs, relatedTo }) => (
	<Container>
		<h2>{title}</h2>
		{(debriefs || []).map(
			(
				{
					matching,
					score,
					comment,
					collectedAt,
					collectedByUser: { name: userName, primaryRole: role },
				},
				index,
			) => {
				const Emotion = Emotions[score];
				return (
					<DebriefContainer>
						<Row key={index}>
							<Emotion />
							<div>
								<strong>
									{matching[displayByRelatedTo[relatedTo]].name} (
									{matching.job.name}){" "}
								</strong>
								<br />
								{comment ? `"${comment}"` : ""}
								<br />
								<em>
									le {moment(collectedAt).format("D MMMM YYYY")}{" "}
									{role === relatedTo ? "" : `par ${userName}`}{" "}
									{role === "admin" ? "(admin)" : ""}
								</em>
							</div>
						</Row>
					</DebriefContainer>
				);
			},
		)}
	</Container>
);

const DebriefContainer = styled(Card)`
	margin-top: 20px;
	margin-bottom: 20px;
`;

const Container = styled.div`
	margin-bottom: 20px;
	img {
		max-width: 60px !important;
		margin-right: 30px;
	}
	em {
		font-style: italic;
	}
`;
