import React from "react";
import styled from "styled-components";
import { color } from "common/styles";
import { marginTop } from "./shared";
import Dropzone from "./Dropzone";
import Identity from "./Identity";
import SocialSecurity from "./SocialSecurity";
import Bank from "./Bank";
import model, { validateGlobalForm } from "models/worker/documents";
import store from "common/store";
import Button from "components/Button";

export default class DocumentsWorkerForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = { formState: true };
	}
	onClick = () => {
		this.setState({ formState: false });
	};

	render() {
		const {
			name,
			documents: { lastReviewedAt, files, bank, socialSecurity, identity },
		} = model(this.props.workerFromToken);
		const button = validateGlobalForm(store.getState().form)
			? {}
			: { disabled: true };

		return (
			<Container>
				<HeaderContainer>
					<HeaderContent>
						<BrandContainer>Gofer</BrandContainer>
						<WorkerName>{name}</WorkerName>
					</HeaderContent>
				</HeaderContainer>
				<Content>
					{this.state.formState ? (
						<div>
							<Title>
								Pour travailler via Gofer en toute légalité, nous vous demandons
								de télécharger les 3 documents suivants et de compléter les
								informations associées :
							</Title>
							<Documents>
								<Identity
									initialValues={{
										...identity,
										token: this.props.token,
										lastReviewedAt,
									}}
								/>
								<SocialSecurity
									initialValues={{
										...socialSecurity,
										token: this.props.token,
										lastReviewedAt,
									}}
								/>
								<Bank
									initialValues={{
										...bank,
										token: this.props.token,
										lastReviewedAt,
									}}
								/>
							</Documents>
							<Dropzone data={files} token={this.props.token} />
							<StyledButton {...button} onClick={this.onClick}>
								J’ai bien téléchargé les 3 documents
							</StyledButton>
						</div>
					) : (
						<div>
							<Title>Merci d'avoir téléchargé vos documents.</Title>
						</div>
					)}
				</Content>
			</Container>
		);
	}
}

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	overflow: scroll;
`;

const HeaderContainer = styled.div`
	background-color: ${color("primary")};
`;

const HeaderContent = styled.div`
	max-width: 670px;
	padding: 100px 5px 20px 5px;
	margin: 0 auto 0 auto;
	display: flex;
	align-items: flex-end;
	font-weight: bold;
`;

const BrandContainer = styled.div`
	flex: 1;
	font-size: 50px;
	color: ${color("white")};
`;

const WorkerName = styled.h1`
	flex: 1;
	text-align: right;
	font-size: 30px;
	color: ${color("white", "normal", 0.5)};
`;

const Content = styled.div`
	display: flex;
	align-items: flex-start;
	max-width: 670px;
	margin: 0 auto 0 auto;
	padding: 0 5px 0 5px;
`;

const Title = styled.div`
	font-size: 30px;
	margin-top: ${marginTop}px;
	font-size: 23px;
	text-align: justify;
`;

const Documents = styled.div`
	margin-top: ${marginTop}px;
`;

const StyledButton = styled(Button)`
	margin-top: ${marginTop}px;
	margin-bottom: 20px;
`;
