import React from "react";
import { Document, DocumentTitle } from "./shared";
import { TextField, Checkbox } from "redux-form-material-ui";
import { Field } from "redux-form";

export default props => {
	const { initialValues } = props;
	return (
		<Document>
			<DocumentTitle>Informations bancaires</DocumentTitle>
			<Field
				name="iban"
				floatingLabelText="IBAN"
				fullWidth={true}
				type="text"
				disabled={true}
				component={TextField}
			/>
			<span>{initialValues.bank.obfuscatedIBAN}</span>
			<Field
				name="bic"
				floatingLabelText="BIC"
				fullWidth={true}
				disabled={true}
				type="text"
				component={TextField}
			/>
			<Field
				name="accountHolder"
				floatingLabelText="Titulaire du compte"
				fullWidth={true}
				disabled={true}
				type="text"
				component={TextField}
			/>
			<Field name="validatedAt" label="Valider" component={Checkbox} />
		</Document>
	);
};
