import gql from "graphql-tag";
import React from "react";
import NewQuery from "./NewQuery";
import { useQuery } from "@apollo/react-hooks";
import Loading from "components/Loading";
import useJobs from "common/useJobs";

const getNetworkWorkers = gql`
	query networkWorkers {
		networkWorkers {
			id
			name
			firstName
			jobs {
				key
			}
		}
	}
`;

export default ({ dismiss }) => {
	const jobs = useJobs();
	const { loading, data } = useQuery(getNetworkWorkers, {
		fetchPolicy: "no-cache",
	});

	if (loading || !data || !data.networkWorkers) {
		return <Loading />;
	}

	return (
		<NewQuery
			networkWorkers={data.networkWorkers}
			directContract
			dismiss={dismiss}
			showPreview={true}
			jobs={jobs}
		/>
	);
};
