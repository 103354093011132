import styled from "styled-components";
import { color, tabletBreakpoint } from "common/styles";

export const SideNav = styled.nav`
	width: 230px;
	min-width: 230px;
	flex-direction: column;
	min-height: 100%;
	border-right: 1px solid #ddd;
	padding-top: 90px;

	@media print {
		display: none;
	}

	hr {
		border: none;
		height: 1px;
		width: 100%;
		background-color: ${color("border")};
		margin: 0;
	}

	h3 {
		text-align: center;
		font-size: 14px;
		margin-top: 30px;
		margin-bottom: 15px;
	}

	button {
		border: none;
		background: transparent;
		display: block;
		padding: 15px;
		transition: background-color 500ms ease, color 500ms ease;
		color: #666;
		width: 100%;
		text-align: left;
		font-family: Roboto;
		font-size: 14px;
		cursor: pointer;
		outline: none;
		padding-left: 35px;
		position: relative;

		&::before {
			content: " ";
			display: block;
			height: 12px;
			width: 12px;
			border-radius: 6px;
			background-color: transparent;
			transition: background-color 500ms ease;
			position: absolute;
			left: 15px;
			top: calc(50% - 6px);
		}

		&.selected {
			background: #eee;
			font-weight: bold;
			color: ${color("text")};
			&::before {
				background-color: ${color("primaryLight")};
			}
		}
		&:hover {
			background: #ddd;
		}
	}

	@media screen and (max-width: ${tabletBreakpoint}px) {
		min-height: 50px;
		flex-direction: row;
		width: 100%;
		display: flex;
		padding-top: 0px;
		justify-content: stretch;
		border-top: 1px solid #ddd;
		border-right: 0;
		button {
			height: auto;
			width: auto;
			flex: 1;
			text-align: center;
			font-size: 14px;
		}
	}
`;
