import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import client from "common/apolloClient";
import { addModal } from "architecture/Modals";
import Loading from "components/Loading";
import Lightbox from "components/Lightbox";
import Button from "components/Button";
import EditSurvey from "./EditSurvey";
import Bar from "components/Bar";
import { Pill, PillsList } from "components/Pills";
import gql from "graphql-tag";
import moment from "moment";
import styled from "styled-components";
import SurveyTargets from "./SurveyTargets";

const GetSurvey = gql`
	query AdminGetSurvey($id: ID!) {
		admin {
			survey(id: $id) {
				id
				question
				createdAt
				startAt
				endAt
				ignoringUserCount
				customResponse
				multipleResponse
				workerVisibility
				businessVisibility

				targetedJobs {
					key
					name
				}
				customAnsweringUsers {
					custom
					user {
						name
					}
				}
				predefinedAnsweringUsers {
					predefinedAnswer
					userCount
				}
			}
		}
	}
`;

const GetAnswerUsers = gql`
	query AdminSurveyGetAnswerUsers($id: ID!) {
		admin {
			survey(id: $id) {
				id
				predefinedAnsweringUsers {
					predefinedAnswer
					userCount
					users {
						name
						id
						... on Owner {
							business {
								name
							}
						}
					}
				}
			}
		}
	}
`;

const AnswerGraph = styled.div`
	cursor: pointer;
	&:hover {
		font-weight: bold;
	}
`;

const Divider = styled.hr`
	border-top: 1px solid #ddd;
	margin-top: 31px;
	margin-bottom: 31px;
`;

const UserName = styled.strong`
	display: block;
	padding-top: 20px;
`;

const SurveyDetails = ({ answerId, surveyId }) => {
	const { loading, data } = useQuery(GetAnswerUsers, {
		variables: { id: surveyId },
		client,
	});
	if (loading || !data || !data.admin) {
		return <Loading />;
	}

	const answer = data.admin.survey.predefinedAnsweringUsers.find(
		a => a.predefinedAnswer === answerId,
	);

	return answer ? (
		<div>
			<h2>{answerId}</h2>
			{answer.users.map(u => (
				<div>
					<UserName>
						{u.name} {u.business ? `(${u.business.name})` : null}
					</UserName>{" "}
					{u.id}
				</div>
			))}
		</div>
	) : null;
};

const Survey = props => {
	const {
		id,
		question,
		predefinedAnsweringUsers,
		startAt,
		endAt,
		customAnsweringUsers,
		ignoringUserCount,
		customResponse,
		multipleResponse,
		targetedJobs,
	} = props;
	const [answerId, setAnswerId] = useState(undefined);
	const [showTargets, setShowTargets] = useState(false);

	const nbAnsweringUsers =
		predefinedAnsweringUsers.reduce((acc, el) => acc + el.userCount, 0) +
		customAnsweringUsers.length;

	return (
		<div class="grid">
			<div class="twelve row">
				<Button
					className="right"
					onClick={() =>
						addModal(({ dismiss }) => (
							<EditSurvey dismiss={dismiss} surveyId={props.id} />
						))
					}
				>
					Modifier
				</Button>
			</div>
			<article class="card eight from-three col">
				<section>
					<h1>{question}</h1>
				</section>
				<section>
					<PillsList>
						{multipleResponse ? <Pill>Réponses multiples</Pill> : null}
						{customResponse ? <Pill>Champ libre</Pill> : null}
					</PillsList>
				</section>
				<section>
					Sondage du <strong>{moment(startAt).format("DD MMMM YYYY")}</strong>{" "}
					au <strong>{moment(endAt).format("DD MMMM YYYY")}</strong>.
					<br />
					<strong>{nbAnsweringUsers}</strong> réponses,{" "}
					<strong>{ignoringUserCount}</strong> ignorés.
					<br />
					Identifiant : {id}
				</section>
				<section>
					<Button
						secondary
						className="outline"
						onClick={() => setShowTargets(true)}
					>
						Utilisateurs cibles
					</Button>
					{targetedJobs.length > 0 ? (
						<PillsList>
							{targetedJobs.map(j => (
								<Pill key={j.key}>{j.name}</Pill>
							))}
						</PillsList>
					) : null}
				</section>
				{predefinedAnsweringUsers.map(answer => (
					<AnswerGraph onClick={() => setAnswerId(answer.predefinedAnswer)}>
						<Bar
							key={answer.predefinedAnswer}
							value={answer.userCount}
							max={
								predefinedAnsweringUsers.reduce(
									(acc, el) => acc + el.userCount,
									0,
								) || 1
							}
							label={`${answer.predefinedAnswer} (${answer.userCount})`}
						/>
					</AnswerGraph>
				))}
				<Divider />
				{customAnsweringUsers.map(answer => (
					<div>
						<UserName>{answer.user.name}</UserName> {answer.custom}
					</div>
				))}
				<Lightbox visible={answerId} onDismiss={() => setAnswerId(undefined)}>
					<section>
						<SurveyDetails surveyId={id} answerId={answerId} />
					</section>
				</Lightbox>
				{showTargets ? (
					<SurveyTargets id={id} onDismiss={() => setShowTargets(false)} />
				) : null}
			</article>
		</div>
	);
};

export default ({ surveyId }) => {
	const { loading, data } = useQuery(GetSurvey, {
		variables: { id: surveyId },
	});

	return loading ? (
		<Loading />
	) : data && data.admin ? (
		<Survey {...data.admin.survey} />
	) : null;
};
