import React from "react";
import graphql from "common/graphql";
import gql from "graphql-tag";
import TrackingsTable from "scenes/Home/Business/TrackingsTable";

const trackingsFields = /* GraphQL */ `
  getWorker {
    id
    name
  }
  shiftsDescription
  startTime
  businessAutoTracked
  shifts {
    id
    startTime
    endTime
    breakMinutes
  }
  query {
    id
  }
`;

export const getTrackings = ({ badge } = {}) => gql`
  query contractsToTrack {
    contractsToTrack {
      id
      ${badge ? "" : trackingsFields}
    }
  }
`;

export default graphql(getTrackings())(({ contractsToTrack, match }) => (
	<TrackingsTable match={match} contracts={contractsToTrack} />
));
