import React from "react";
import styled from "styled-components";
import { color, isMobile } from "common/styles";

const FocusLine = styled.div`
	transition: transform 350ms ease;
	display: block;
	transform: scaleX(${props => (props.error || props.focused ? 1 : 0)});
	background-color: ${props =>
		props.error ? "rgb(244, 67, 54)" : color("primary")};
	height: 2px;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 8px;
`;

const Label = styled.label`
	color: ${props =>
		props.focused
			? props.error
				? "rgb(244, 67, 54)"
				: color("primary")
			: "#b9b9b9"};
	position: absolute;
	bottom: ${props => (props.floating ? 35 : 15)}px;
	font-size: ${props => (props.floating ? 12 : 16)}px;
	transition: all 500ms ease;
	left: 0;
	pointer-events: none;
`;

const Container = styled.div`
	width: 100%;
	position: relative;
`;

//workaround for iPhone 5 bug, scroll is disabled when text input is inside relative div
const TransferClick = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	cursor: text;
`;

export default class MobileTextField extends React.Component {
	state = {
		text: "",
		focused: false,
	};

	textInput;

	static getDerivedStateFromProps(nextProps, state) {
		const value = nextProps.input ? nextProps.input.value : nextProps.value;
		if (value && value !== state.text) {
			return {
				text: value,
			};
		}

		return null;
	}

	blurred(e) {
		const { onChange } = this.props;

		this.updateValue(e);
		this.setState({ focused: false });
		onChange && onChange(e);
	}

	renderTextarea() {
		return this.renderField();
	}

	updateValue(e) {
		this.setState({ text: e.target.value });
		this.props.input && this.props.input.onChange(e.target.value);
	}

	renderField() {
		const {
			placeholder,
			floatingLabelText,
			errorText,
			name,
			hintText,
		} = this.props;
		const { text, focused } = this.state;

		return (
			<Container>
				<Label
					floating={!!text || focused}
					focused={focused}
					error={!!errorText}
				>
					{floatingLabelText || placeholder || hintText}
				</Label>
				<input
					name={name}
					ref={r => (this.textInput = r)}
					placeholder={placeholder}
					type="text"
					className="default-textfield"
					value={text}
					onChange={e => this.updateValue(e)}
					onBlur={e => this.blurred(e)}
					onFocus={e => this.setState({ focused: true })}
				/>
				<FocusLine error={!!errorText} focused={focused} />
				{errorText && <span className="error">{errorText}</span>}
				{isMobile() ? (
					<TransferClick onClick={() => this.textInput.focus()} />
				) : null}
			</Container>
		);
	}

	render() {
		const { multiLine } = this.props;

		return multiLine ? this.renderTextarea() : this.renderField();
	}
}
