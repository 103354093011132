import client from "./apolloClient";
import gql from "graphql-tag";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import { DEV } from "common/constants";

export const writeQuery = params => {
	try {
		client.writeQuery(params);
	} catch (e) {
		console.log(e);
	}
};

export const readQuery = params => {
	try {
		return client.readQuery(params);
	} catch (e) {
		console.log(e);
		return {};
	}
};

const refreshCallbacks = [];

export default {
	query: async (query, params) => {
		try {
			const response = await client.query({
				query: gql`
					${query}
				`,
				...params,
			});
			return response.data;
		} catch (error) {
			DEV && console.error(error);
		}
	},
	onRefresh: cb => {
		refreshCallbacks.push(cb);
	},
	refreshAll: async () => {
		await client.reFetchObservableQueries();
		refreshCallbacks.forEach(cb => cb());
	},
	mutation: async (
		query,
		variables = {},
		{ successMessage, errorMessage } = {},
		options = {},
		// eslint-disable-next-line max-params
	) => {
		try {
			const rep = await client.mutate({
				mutation: gql`
					${query}
				`,
				variables,
				...options,
			});
			if (successMessage) {
				dispatch(addAlert(successMessage));
			}
			return rep.data;
		} catch (error) {
			if (errorMessage) {
				DEV && console.error(error);
				dispatch(addAlert(errorMessage));
			} else {
				throw error;
			}
		}
	},
};
