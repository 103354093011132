import React from "react";
import styled from "styled-components";
import capitalize from "utils/capitalize";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Select from "./Select";

const SalesPersonSelector = ({
	salesPeople,
	updateValue,
	value,
	label,
	farmingStartedAt,
}) => (
	<Line>
		<Select
			label={capitalize(label)}
			value={value || ""}
			updateValue={value => {
				if (label === "farmer" && !farmingStartedAt) {
					updateValue(moment().format("YYYY-MM-DD"), "farmingStartedAt");
				}
				updateValue(value, `${label}Id`);
			}}
			options={[
				{ key: "", value: "..." },
				...salesPeople.map(({ id, name }) => ({ key: id, value: name })),
			]}
		/>
		{label === "farmer" ? (
			<StyledDatePicker
				fullWidth
				id="date"
				label="Date de début de farming"
				type="date"
				value={moment(farmingStartedAt).format("YYYY-MM-DD")}
				onChange={event => updateValue(event.target.value, "farmingStartedAt")}
				InputLabelProps={{
					shrink: true,
				}}
			/>
		) : null}
	</Line>
);

export default ({ salesPeople, updateParent, parent: business }) => (
	<Container>
		<SalesPersonSelector
			salesPeople={salesPeople}
			updateValue={(value, field) => {
				business = { ...business, [field]: value };
				updateParent(business);
			}}
			value={business.hunterId}
			label="hunter"
		/>
		<SalesPersonSelector
			salesPeople={salesPeople}
			updateValue={(value, field) => {
				business = { ...business, [field]: value };
				updateParent(business);
			}}
			value={business.farmerId}
			farmingStartedAt={business.farmingStartedAt}
			label="farmer"
		/>
	</Container>
);

const StyledDatePicker = styled(TextField)`
	flex: 1;
`;

const Line = styled.div`
	display: flex;
	flex: 1;
	margin-bottom: 10px;
`;

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-bottom: 10px;
`;
