import React from "react";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";
import Tooltip from "@material-ui/core/Tooltip";
import { Redirect } from "react-router";
import Navigation from "./Calendar/Navigation";
import QueryShift from "./Calendar/QueryShift";
import Button from "components/Button";
import { addModal } from "architecture/Modals";
import NewQuery from "modals/NewQuery";
import NewDirectContract from "modals/NewDirectContract";
import Query from "modals/Query";
import moment from "moment";
import Week from "./Calendar/Week";
import styled from "styled-components";
import { color } from "common/styles";
import { isMobile, isTablet } from "common/styles";
import { makeQuery } from "models/query";
import { Planning } from "models/queries/Planning";

const renderQueryShift = ({ endTime, queries, query, startTime }) => (
	<QueryShift
		endTime={endTime}
		queries={queries}
		query={query}
		startTime={startTime}
	/>
);

const Buttons = ({ disabled }) => (
	<React.Fragment>
	</React.Fragment>
);

export const DisabledButtons = ({ tooltip }) => (
	<Tooltip title={tooltip}>
		<TooltipContent>
			<Buttons disabled />
		</TooltipContent>
	</Tooltip>
);

const NewQueryButtons = ({ locked, verified, completed }) => {
	if (locked) {
		return <DisabledButtons tooltip={"Votre compte est bloqué"} />;
	}
	if (!completed) {
		return <DisabledButtons tooltip={"Votre compte n'est pas complet"} />;
	}
	if (!verified) {
		return (
			<DisabledButtons
				tooltip={
					"Votre compte n'a pas encore été vérifié par un administrateur"
				}
			/>
		);
	}
	return <Buttons />;
};

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-weight: 500;
	color: ${color("black", "light")};
	min-height: 65px;
	font-size: 16px;
`;

const DesktopCalendar = ({
	business,
	dossier,
	dossierList,
	queries,
	renderShift,
	setDossier,
	setWeek,
	week,
}) => (
	<Container>
		<Header>
			<Navigation
				dossier={dossier}
				dossierList={dossierList}
				setDossier={setDossier}
				setWeek={setWeek}
				week={week}
			/>
			<NewQueryButtons {...business} />
		</Header>
		<Week
			renderShift={renderShift}
			week={week}
			allowNewQueries={
				!business.locked && business.verified && business.completed
			}
		>
			{queries}
		</Week>
	</Container>
);

const MobileButtons = styled.div`
	width: 100%;
	justify-content: center;
	display: flex;
	margin-top: 15px;
	margin-bottom: 35px;
	flex-direction: column;
	align-items: center;
`;

const MobileHeader = styled(Header)`
	flex-direction: column-reverse;
	margin-bottom: 30px;
`;

const EmptyWeek = styled.em`
	text-align: center;
	color: #ddd;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-size: 25px;
	padding: 80px;
	font-weight: bold;
`;

const MobileCalendar = ({
	business,
	dossier,
	dossierList,
	queries,
	renderShift,
	setDossier,
	setWeek,
	week,
}) => (
	<Container>
		<MobileHeader>
			<Navigation
				dossier={dossier}
				dossierList={dossierList}
				setDossier={setDossier}
				setWeek={setWeek}
				week={week}
			/>
			<MobileButtons>
				<NewQueryButtons {...business} />
			</MobileButtons>
		</MobileHeader>
		{queries.length === 0 ? (
			<EmptyWeek>Aucune demande cette semaine</EmptyWeek>
		) : null}
		<Week renderShift={renderShift} week={week}>
			{queries}
		</Week>
	</Container>
);

const GetBusinessStatus = gql`
	{
		currentUser {
			id
			business {
				completed
				dossier
				id
				locked
				verified
			}
		}
	}
`;

export default class Calendar extends React.Component {
	state = {
		dossier: null,
		week: moment(),
	};

	get variables() {
		const { week } = this.state;

		return {
			start: moment(week).startOf("week"),
			end: moment(week).endOf("week"),
		};
	}

	openQuery() {
		const queryId = this.props.match.params.queryId;
		if (queryId && queryId !== this.queryId && queryId !== "home") {
			this.queryId = queryId;
			addModal(
				({ dismiss }) => (
					<>
						{queryId.split("-").map(id => (
							<Query key={id} dismiss={dismiss} queryId={id} />
						))}
					</>
				),
				{
					onDismiss: () => {
						this.setState({ redirectToHome: true });
					},
				},
			);
		}
		if (queryId === "home") {
			this.queryId = null;
		}
	}

	static getDerivedStateFromProps(nextProps, currentState) {
		if (nextProps.match.params.queryId === "home") {
			return {
				...currentState,
				redirectToHome: false,
			};
		}
		return currentState;
	}

	componentDidUpdate() {
		this.openQuery();
	}

	componentDidMount() {
		this.openQuery();
	}

	filterQuery(query) {
		return this.state.dossier ? query.dossier === this.state.dossier : query;
	}

	render() {
		const { week, redirectToHome } = this.state;

		if (redirectToHome) {
			return <Redirect to="/planning/home" />;
		}

		return (
			<GraphQL variables={this.variables} noPoll query={GetBusinessStatus}>
				{({ currentUser }) => (
					<GraphQL variables={this.variables} query={Planning} noPoll>
						{({ queries }) => {
							console.log("foo:queries", queries);
							const business = currentUser.business;

							const calendarProps = {
								business,
								dossier: this.state.dossier,
								dossierList: (business || {}).dossier,
								openQuery: redirect => this.setState({ redirect }),
								queries: (queries || [])
									.filter(q => this.filterQuery(q))
									.map(makeQuery),
								renderShift: renderQueryShift,
								setDossier: dossier => this.setState({ dossier }),
								setWeek: week => this.setState({ week }),
								week,
							};

							return isTablet() ? (
								<MobileCalendar {...calendarProps} />
							) : (
								<DesktopCalendar {...calendarProps} />
							);
						}}
					</GraphQL>
				)}
			</GraphQL>
		);
	}
}

const Container = styled.div`
	${isMobile() ? "overflow: auto" : "flex: 1"};
	position: relative;
	display: ${isMobile() ? "block" : "flex"};
	align-items: stretch;
	flex-direction: column;
	padding: 0 10px;
	background-color: #fff;
`;

const StyledButton = styled(Button)`
	margin-right: 10px;
`;
const TooltipContent = styled.span`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
