export default (totalHours, { compact, showMinutes } = {}) => {
	const hours = Math.floor(totalHours);
	const minutes = Math.round((totalHours % 1) * 60);
	if (compact) {
		return `${hours}h${
			minutes || showMinutes ? `${minutes < 10 ? "0" : ""}${minutes}` : ""
		}`;
	}
	const plural = number => (number > 1 ? "s" : "");
	return [{ value: hours, label: "heure" }, { value: minutes, label: "minute" }]
		.map(({ value, label }) =>
			value ? `${value} ${label}${plural(value)}` : "",
		)
		.filter(Boolean)
		.join(" et ");
};
