import React from "react";
import { FaBell } from "react-icons/fa";
import styled from "styled-components";
import Badge from "components/Badge";
import { Link } from "react-router-dom";
import { useTrackings } from "models/contract";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const Feedbacks = gql`
	query {
		contractsToDebrief {
			id
		}
	}
`;

const NotifsContainer = styled.div`
	position: relative;

	.badge {
		right: -5px;
	}

	&.drop-nav-opener .drop-nav {
		right: 0;
		width: 315px;
	}
`;

export default () => {
	const { loading, trackings } = useTrackings();
	const { loading: feedbacksLoading, data: feedbacks } = useQuery(Feedbacks);

	if (loading || feedbacksLoading || !feedbacks) {
		return null;
	}

	return (
		<NotifsContainer className="notifs drop-nav-opener">
			<FaBell />
			<Badge count={trackings.length + feedbacks.contractsToDebrief.length} />
			<div class="drop-nav">
				{trackings.length ? (
					<Link to="/trackings">
						Vous avez {trackings.length} missions à pointer
					</Link>
				) : null}
				{feedbacks &&
				feedbacks.contractsToDebrief &&
				feedbacks.contractsToDebrief.length ? (
					<Link to="/feedbacks">
						{feedbacks.contractsToDebrief.length} workers attendent votre
						feedback
					</Link>
				) : null}
			</div>
		</NotifsContainer>
	);
};
