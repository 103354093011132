import React from "react";
import styled from "styled-components";
import SimpleTextField from "material-ui/TextField";
import { addModal } from "architecture/Modals";
import NewContract from "modals/NewContract";
import CancelContract from "./Operations/CancelContract";
import Button from "components/Button";
import objectFromEntries from "utils/objectFromEntries";
import Request from "common/Request";
import AutoForm, { Enum, Section, Header } from "./Operations/AutoForm";
import Onboarding from "./Onboarding/Onboarding";
import AsyncSearch from "components/AsyncSearch";
import adminWorkers from "components/AsyncSearch/adminWorkers";
import { confirm } from "components/Lightbox";
import WorkerProfile from "modals/WorkerProfile";
import { Redirect } from "react-router-dom";
import useJobs from "common/useJobs";

class Contracts extends React.Component {
	state = {
		contractId: "",
		businessId: "",
		ownerId: "",
		workerId: "",
		queryId: "",
		cleanWorkerReason: "",
	};

	editContract = contractId => {
		const { jobs } = this.props;
		const schema = {
			id: String,
			worker: {
				workerId: String,
				signedAt: Date,
				as: Enum(["interim", "freelance"]),
			},
			business: {
				businessId: String,
				signedAt: Date,
			},
			shifts: [
				{
					recrutopContractId: String,
					recrutopTrackingId: String,
					startTime: Date,
					endTime: Date,
					startTimeTracked: Date,
					endTimeTracked: Date,
					workerAutoTracked: Boolean,
					workerTrackedAt: Date,
					businessAutoTracked: Boolean,
					businessTrackedAt: Date,
					breakMinutes: Number,
					breakMinutesTracked: Number,
				},
			],
			queryId: String,
			cancelledAt: Date,
			job: Enum(jobs),
			reason: String,
			justification: String,
			rate: Number,
			billingFee: Number,
		};

		addModal(({ dismiss }) => (
			<AutoForm
				id={contractId}
				dismiss={dismiss}
				schema={schema}
				queryEndpoint={"adminContract"}
				mutation={contract =>
					Request.mutation(
						/* GraphQL */ `
							mutation($contract: Admin_ContractInput) {
								adminUpdateContract(contract: $contract) {
									id
								}
							}
						`,
						{
							contract,
						},
					)
				}
			/>
		));
	};

	openModals() {
		const id = this.props.match.params.id;
		const type = this.props.match.params.type;
		const paths = {
			contracts: this.editContract,
			queries: this.editQuery,
		};

		if (id && id !== this.id) {
			this.id = id;

			for (const prop in paths) {
				type === prop && paths[prop](id);
			}
		}
	}

	componentDidMount() {
		this.openModals();
	}

	componentDidUpdate() {
		this.openModals();
	}

	editBusiness = () => {
		const schema = {
			id: String,
			wantSpecifyLocation: Boolean,
			wantSpecifyReason: Boolean,
			defaultBreakMinutes: Number,
			owners: [
				{
					userId: String,
					delegateRights: Boolean,
				},
			],
			managers: [
				{
					userId: String,
					delegateRights: Boolean,
				},
			],
			addresses: [
				{
					streetName: String,
					postCode: String,
					city: String,
					country: String,
					verified: Boolean,
				},
			],
			company: {
				siret: String,
				name: String,
				address: {
					streetName: String,
					postCode: String,
					city: String,
					country: String,
					verified: Boolean,
				},
				retraite: String,
				conventionCollective: String,
			},
			name: String,
			recrutopId: String,
			sourcedWorkerIds: [String],
			reportingEmailAddress: String,
			locked: Boolean,
			managedByGofer: Boolean,
			brief: String,
			briefLink: String,
			nafCode: String,
			billingReference: String,
			overtimeWeeklyHoursThreshold: Number,
			operationalEmailAddress: String,
			verifiedAt: Date,
			billingFee: Number,
			hunterId: String,
			farmerId: String,
			farmingStartedAt: Date,
			operationalPhoneNumber: String,
			optoutWorkerAcceptNotification: Boolean,
		};
		addModal(({ dismiss }) => (
			<AutoForm
				id={this.state.businessId}
				dismiss={dismiss}
				schema={schema}
				queryEndpoint={"adminBusiness"}
				mutation={business =>
					Request.mutation(
						/* GraphQL */ `
							mutation($business: Admin_BusinessInput) {
								adminUpdateBusiness(business: $business) {
									id
								}
							}
						`,
						{
							business,
						},
					)
				}
			/>
		));
	};

	editOwner = () => {
		const { jobs } = this.props;
		const schema = {
			id: String,
			app: {
				phones: [
					{
						number: String,
						verified: Boolean,
					},
				],
				firstName: String,
				lastName: String,
				lastActivityDate: Date,
			},
			device: {
				platform: Enum(["iOS", "Android"]),
			},
			pushToken: String,
			emails: [
				{
					address: String,
					verified: Boolean,
				},
			],
			network: {
				workers: [String],
			},
			roles: [Enum(["admin", "freelance", "interim"])],
			businessData: {
				jobs: [
					{
						key: Enum(jobs),
						defaultWage: Number,
						comment: String,
					},
				],
			},
		};
		addModal(({ dismiss }) => (
			<AutoForm
				id={this.state.ownerId}
				dismiss={dismiss}
				schema={schema}
				queryEndpoint={"adminOwner"}
				mutation={owner =>
					Request.mutation(
						/* GraphQL */ `
							mutation($owner: Admin_OwnerInput) {
								adminUpdateOwner(owner: $owner) {
									id
								}
							}
						`,
						{
							owner,
						},
					)
				}
			/>
		));
	};

	editQuery = queryId => {
		const schema = {
			id: String,
			shifts: [
				{
					startTime: Date,
					endTime: Date,
					breakMinutes: Number,
				},
			],
		};
		addModal(({ dismiss }) => (
			<AutoForm
				id={queryId}
				dismiss={dismiss}
				schema={schema}
				queryEndpoint={"adminQuery"}
				mutation={query =>
					Request.mutation(
						/* GraphQL */ `
							mutation($query: Admin_QueryInput) {
								adminUpdateQuery(query: $query) {
									id
								}
							}
						`,
						{
							query,
						},
					)
				}
			/>
		));
	};

	createContract = () => {
		addModal(({ dismiss }) => <NewContract dismiss={dismiss} />);
	};

	cancelContract = () => {
		addModal(({ dismiss }) => (
			<CancelContract contractId={this.state.contractId} dismiss={dismiss} />
		));
	};

	syncRecrutopContract = async () => {
		await Request.mutation(
			/* GraphQL */ `
				mutation($contractId: ID!) {
					adminSynchronizeRecrutopContract(contractId: $contractId) {
						id
					}
				}
			`,
			{
				contractId: this.state.contractId,
			},
			{
				successMessage: "Contrat synchronisé",
				errorMessage:
					"Une erreur s'est produite dans la synchronisation, veuillez réessayer.",
			},
		);
	};

	async deleteWorker(dismiss) {
		const { workerId, cleanWorkerReason } = this.state;

		const answer = await confirm("Voulez-vous vraiment supprimer ce worker ?");
		if (!answer) {
			return;
		}

		dismiss();

		await Request.mutation(
			/* GraphQL */ `
				mutation($userId: ID!, $reason: String) {
					cleanUpUser(userId: $userId, reason: $reason) {
						id
					}
				}
			`,
			{
				userId: workerId,
				reason: cleanWorkerReason,
			},
			{
				successMessage: "Le worker a bien été supprimé",
				errorMessage: "Il y a eu une erreur lors de la suppression",
			},
		);
		this.setState({ workerId: "" });
	}

	editWorker = async () => {
		const { jobs } = this.props;
		const schema = {
			id: String,
			app: {
				rank: Number,
				tagline: String,
				firstName: String,
				lastName: String,
				phones: [
					{
						number: String,
						verified: Boolean,
					},
				],
			},
			workerData: {
				minWage: Number,
				jobs: [Enum(jobs)],
				locations: Enum(["75", "77", "78", "91", "92", "93", "94", "95"]),
				dateOfBirth: Date,
				socialSecurityNumber: String,
				addresses: [
					{
						streetName: String,
						postCode: String,
						city: String,
					},
				],
				citizenship: String,
			},
			documents: {
				adminMessage: String,
				identity: {
					category: Enum([
						"passport",
						"identityCard",
						"visa",
						"residencePermit",
						"residencePermitRenewalRequest",
					]),
					number: String,
					validatedAt: Date,
					expiresAt: Date,
				},
				socialSecurity: {
					category: Enum(["card", "certificate"]),
					number: String,
					validatedAt: Date,
					expiresAt: Date,
				},
				bank: {
					iban: String,
					bic: String,
					accountHolder: String,
					validatedAt: Date,
				},
			},
			experiences: [
				{
					id: String,
					startDate: Date,
					endDate: Date,
					description: String,
					businessName: String,
					jobKeys: [Enum(jobs)],
					hidden: Boolean,
					training: Boolean,
					contractKind: Enum(["partTime", "fullTime", "extras"]),
					referrals: [
						{
							id: String,
							name: String,
							phoneNumber: String,
						},
					],
				},
			],
			emails: [
				{
					address: String,
					verified: Boolean,
				},
			],
			profile: {
				name: String,
			},
			adminReview: {
				collectedById: String,
				score: Enum([0, 1, 2, 3]),
				comment: String,
				privateComment: String,
			},
		};
		addModal(({ dismiss }) => (
			<div>
				<Header>Suppression</Header>
				<Section>
					<SimpleTextField
						fullWidth
						onChange={e => this.setState({ cleanWorkerReason: e.target.value })}
						placeholder="Je supprime ce worker parce que..."
					/>
					<Button onClick={() => this.deleteWorker(dismiss)}>
						Supprimer le worker
					</Button>
				</Section>
				<AutoForm
					id={this.state.workerId}
					dismiss={dismiss}
					schema={schema}
					queryEndpoint={"adminWorker"}
					mutation={worker =>
						Request.mutation(
							/* GraphQL */ `
								mutation($worker: Admin_WorkerInput) {
									adminUpdateWorker(worker: $worker) {
										id
									}
								}
							`,
							{
								worker,
							},
						)
					}
				/>
			</div>
		));
	};

	render() {
		const { redirect, contractId, queryId } = this.state;

		if (redirect && redirect !== this.redirect) {
			this.redirect = redirect;
			return <Redirect to={`/operations/${redirect}`} />;
		}

		return (
			<div>
				<Line>
					<SimpleTextField
						name="contractId"
						value={this.state.contractId}
						onChange={event =>
							this.setState({ contractId: event.target.value })
						}
					/>
					<StyledButton
						onClick={() =>
							this.setState({ redirect: `contracts/${contractId}` })
						}
					>
						Éditer le contrat
					</StyledButton>
					<StyledButton onClick={this.cancelContract}>
						Annuler le contrat
					</StyledButton>
					<StyledButton asynchronous onClick={this.syncRecrutopContract}>
						Synchronisation Recrutop
					</StyledButton>
				</Line>
				<Button onClick={this.createContract}>Créer un contrat</Button>
				<Line>
					<SimpleTextField
						name="queryId"
						value={this.state.queryId}
						onChange={event => this.setState({ queryId: event.target.value })}
					/>
					<StyledButton
						onClick={() => this.setState({ redirect: `queries/${queryId}` })}
					>
						Éditer la requête
					</StyledButton>
				</Line>
				<Line>
					<SimpleTextField
						name="ownerId"
						value={this.state.ownerId}
						onChange={event => this.setState({ ownerId: event.target.value })}
					/>
					<StyledButton onClick={this.editOwner}>
						Éditer le manager
					</StyledButton>
				</Line>
				<Line>
					<AsyncSearch
						onSelection={value => this.setState({ workerId: value })}
						fetchOptions={adminWorkers}
						placeHolder={"Worker"}
					/>
					<StyledButton onClick={this.editWorker}>
						Éditer le worker
					</StyledButton>
					<StyledButton
						onClick={() =>
							addModal(() => <WorkerProfile workerId={this.state.workerId} />)
						}
					>
						Voir le profil
					</StyledButton>
				</Line>
				<Onboarding />
			</div>
		);
	}
}

const Line = styled.div`
	display: flex;
`;
const StyledButton = styled(Button)`
	margin-left: 10px;
`;

export default props => {
	const jobs = useJobs();
	return (
		<Contracts
			{...props}
			jobs={objectFromEntries(jobs.map(({ key, name }) => [key, name]))}
		/>
	);
};
