const findOptimumInSortedArray = (array, isTooBig) => {
	const n = array.length;
	if (n === 0) {
		return -1;
	} else if (n === 1) {
		if (isTooBig(array[0])) {
			return -1;
		} else {
			return 0;
		}
	} else {
		const half = Math.floor(n / 2);
		if (isTooBig(array[half])) {
			return findOptimumInSortedArray(array.slice(0, half), isTooBig);
		} else {
			return (
				half + 1 + findOptimumInSortedArray(array.slice(half + 1), isTooBig)
			);
		}
	}
};

export default findOptimumInSortedArray;
