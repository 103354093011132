import gql from "graphql-tag";
import React from "react";
import { GraphQL } from "common/graphql";
import { makeQuery } from "models/query";
import Contracts from "./Query/Contracts";
import PastContracts from "./Query/PastContracts";
import Header from "./Query/Header";
import SelectWorkers from "./Query/SelectWorkers";

const ReadQuery = gql`
	query getBusinessQuery($queryId: String!) {
		query(id: $queryId) {
			id
			getJob {
				name
				key
			}
			rate
			maxWorkers
			createdAt
			location {
				streetName
				postCode
				city
			}
			shifts {
				startTime
				endTime
			}
			shiftsDescription
			contracts {
				id
				getWorker {
					id
					name
					firstName
					phoneNumber
					mediumAvatarURL
					medianDebriefScore
					adminReview {
						score
					}
				}
				legalStatus
				signed
				tracked
				businessTracked
				businessAutoTracked
				businessRejectedAutoTracking
				trackingValidated
				shifts {
					id
					startTime
					endTime
					startTimeTracked
					endTimeTracked
					breakMinutes
					breakMinutesTracked
				}
				matching {
					debrief {
						business {
							currentScore: score
						}
					}
				}
			}
			acceptCount
			rejectCount
			getTargets(selectable: true) {
				answeredAt
				matching {
					debrief {
						business {
							currentScore: score
							comment
						}
					}
				}
				worker {
					id
					name
					firstName
					age
					tagline
					mediumAvatarURL
					medianDebriefScore
					pastContractsCount
					verifiedAt

					featuredDebrief {
						score
						comment
						matching {
							business {
								name
							}
						}
					}

					adminReview {
						score
						comment
					}
					experiences {
						startDate
						endDate
						training
						status
						jobs {
							key
							inferiorJobKeys
						}
					}
				}
				legalStatus
				businessRate
				provisionalBillingAmount
				matching {
					businessSelectedWorkerAtLeastOnce
				}
			}
			grossRateSpecifiedByBusiness
			providesNaturalAdvantage
			totalHours
			foodCompensatoryAllowance
			billingRate
			rejectedDirectContract {
				id
				getWorker {
					id
					name
				}
			}
			comment
			dossier
			reference
		}
	}
`;

const enhanceQuery = component => ({ query, ...props }) =>
	component({ query: makeQuery(query), ...props });

export const Query = enhanceQuery(({ query, dismiss }) => (
	<div class="desktop-grid">
		<div class="twelve">
			<section>
				<Header
					query={query}
					nbContracts={query.contracts.length}
					dismiss={dismiss}
				/>
			</section>
			<section>
				<SelectWorkers {...query} />
			</section>
			{query.getTargets.length === 0 &&
			query.contracts.length < query.maxWorkers ? (
				<section>Aucun worker n'est disponible pour le moment.</section>
			) : null}
			{query.hasStarted ? (
				<section>
					<PastContracts {...query} />
				</section>
			) : (
				<section>
					<Contracts {...query} />
				</section>
			)}
		</div>
	</div>
));

export default props => (
	<GraphQL variables={{ queryId: props.queryId }} query={ReadQuery}>
		{({ query }) => <Query {...props} query={query} />}
	</GraphQL>
);
