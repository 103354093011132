import React from "react";
import styled from "styled-components";
import { addModal } from "architecture/Modals";
import Button from "components/Button";
import EditBusiness from "./EditBusiness";
import BusinessesTable from "./BusinessesTable";
import { GraphQL } from "common/graphql";
import gql from "graphql-tag";
import { color } from "common/styles";
import AsyncSearch from "components/AsyncSearch";
import adminBusinesses from "components/AsyncSearch/adminBusinesses";
import Request from "common/Request";

export default class Onboarding extends React.Component {
	state = {};
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};
	render() {
		return (
			<Container>
				<Line>
					<StyledButton
						onClick={() =>
							addModal(({ dismiss }) => (
								<EditBusiness create dismiss={dismiss} />
							))
						}
					>
						Créer nouveau business
					</StyledButton>
				</Line>
				<Line>
					<AsyncSearch
						onSelection={value => this.setState({ businessId: value })}
						fetchOptions={adminBusinesses}
						placeHolder={"Business"}
					/>
					<StyledButton
						onClick={() =>
							addModal(({ dismiss }) => (
								<EditBusiness
									businessId={this.state.businessId}
									dismiss={dismiss}
								/>
							))
						}
					>
						Éditer le business (nouveau)
					</StyledButton>
					<StyledButton
						onClick={() =>
							Request.mutation(
								/* GraphQL */ `
									mutation($businessId: ID!) {
										admin {
											fillFakeBusinessPlanning(businessId: $businessId) {
												id
											}
										}
									}
								`,
								{ businessId: this.state.businessId },
								{
									errorMessage: "Le remplissage du planning a échoué",
									successMessage: "Le planning a été rempli avec succès",
								},
							)
						}
					>
						Remplir le planning
					</StyledButton>
				</Line>
				<GraphQL
					query={gql`
						query businesses(
							$limit: Int
							$orderBy: BusinessesOrderBy
							$descending: Boolean
						) {
							admin {
								businesses(
									orderBy: $orderBy
									limit: $limit
									descending: $descending
								) {
									id
									name
									createdAt
								}
							}
						}
					`}
					variables={{ limit: 10, orderBy: "createdAt", descending: true }}
				>
					{({ admin: { businesses } }) => (
						<BusinessesTable
							businesses={businesses}
							title="10 derniers comptes créés"
						/>
					)}
				</GraphQL>
			</Container>
		);
	}
}

const Line = styled.div`
	display: flex;
`;
const Container = styled.div`
	margin-top: 25px;
	border: 1px solid ${color("black", "pale")};
	padding: 10px;
	display: flex;
	flex-direction: column;
	margin: 5px 0;
	border-radius: 5px;
`;

const StyledButton = styled(Button)`
	margin-left: 10px;
`;
