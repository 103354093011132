import React from "react";
import styled from "styled-components";
import { color } from "common/styles";

const LabelRow = styled.div`
	width: ${props => props.percent}%;
	white-space: nowrap;
	strong {
		margin-right: 10px;
	}
`;

const BarGraph = styled.div`
	height: 20px;
	width: ${props => props.percent}%;
	background-color: ${color("action")};
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: ${props => (props.percent < 100 ? 0 : 10)}px;
	border-top-right-radius: ${props => (props.percent < 100 ? 0 : 10)}px;
	position: relative;
`;

const GraphContainer = styled.div`
	margin-top: 10px;
	margin-bottom: 10px;
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	.background {
		width: 100%;
		height: 20px;
		position: absolute;
		border-radius: 10px;
		background-color: #eee;
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
	}
`;

export default ({ title, value, max, label }) => (
	<GraphContainer className="graph-bar">
		<LabelRow className="row" percent={(value * 100) / max}>
			{title ? <strong>{title}</strong> : null}
			<p class={title ? "right" : ""}>{label}</p>
		</LabelRow>

		<div class="background" />
		<BarGraph percent={(value * 100) / max} className="filled" />
	</GraphContainer>
);
