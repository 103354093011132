import React from "react";
import Profile from "./Profile";
import WorkerProfile from "modals/WorkerProfile";
import { addModal } from "architecture/Modals";
import DropDown from "components/DropDown";
import useWorkersSort from "../JobPosts/useWorkersSort";

export default ({
	getTargets: targets = [],
	contracts = [],
	maxWorkers = 1,
	hasStarted,
	...query
}) => {
	const { sort, sortOptions, setSort } = useWorkersSort(query.getJob);

	return contracts.length < maxWorkers && targets.length > 0 && !hasStarted ? (
		<React.Fragment>
			<DropDown
				label={selected => `Trier par${selected}`}
				options={sortOptions}
				selected={sort}
				onChange={newVal => setSort(newVal)}
			/>
			<section class="cards-list">
				{sort.value(targets).map(target => (
					<Profile
						key={target.worker.id}
						{...target}
						job={query.getJob}
						actions={[
							{
								name: "Voir",
								onClick: () =>
									addModal(() => (
										<WorkerProfile
											answer={target}
											workerId={target.worker.id}
										/>
									)),
							},
							{ name: "Choisir", onClick: target.onSelect },
						]}
					/>
				))}
			</section>
		</React.Fragment>
	) : null;
};
