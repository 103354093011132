import React from "react";
import styled from "styled-components";
import { color as makeColor } from "common/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

export default ({ color = makeColor("action"), size }) => (
	<Container>
		<CircularProgress style={{ color }} size={size} />
	</Container>
);

const Container = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const notify = (text, classes) => {
	const notification = document.createElement("div");
	notification.innerText = text;
	notification.setAttribute("class", `app-notification ${classes || ""}`);
	const body = document.querySelector("body");
	body.appendChild(notification);
	setTimeout(() => body.removeChild(notification), 3000);
};
