import React from "react";
import AsyncSelect from "react-select/async";
import styled from "styled-components";

export default class AsyncSearch extends React.Component {
	state = { selectedOption: undefined };

	handleChange = selectedOption => {
		this.setState({ selectedOption });
		const onSelectionChange = this.props.onSelection
			? this.props.onSelection
			: this.props.input.onChange;
		onSelectionChange(
			selectedOption
				? Array.isArray(selectedOption)
					? selectedOption.map(option => option.value)
					: selectedOption.value
				: undefined,
		);
	};

	static getDerivedStateFromProps(nextProps, state) {
		return {
			selectedOption: state.selectedOption
				? state.selectedOption
				: nextProps.defaultValue
				? nextProps.defaultValue.map(key => ({
						label: key,
						value: key,
				  }))
				: undefined,
		};
	}

	synchronizeOptions = async input => {
		const data = await this.props.fetchOptions(input);
		return data.map(option => ({
			label: `${option.name} ${option.id}`,
			value: option.id,
		}));
	};

	setupCopy(container) {
		if (!container) {
			return;
		}

		container.addEventListener("keyup", e => {
			if (!(e.key === "v" && e.ctrlKey) && !e.key === "Meta") {
				return;
			}
			const input = container.getElementsByTagName("input")[0];

			const selectedOption = input.value.split(" ").map(key => ({
				label: key,
				value: key,
			}));

			if (selectedOption[0].label.length !== 17) {
				//doesn't look like an ID
				return;
			}

			this.handleChange(selectedOption);
			setTimeout(() => {
				input.value = "";
				input.blur();
			}, 500);

			console.log(e);
		});
	}

	render() {
		const { selectedOption } = this.state;
		console.log(selectedOption);
		return (
			<SearchContainer innerRef={r => this.setupCopy(r)}>
				<AsyncSelect
					isMulti={this.props.isMulti}
					value={selectedOption}
					onChange={this.handleChange}
					loadOptions={this.synchronizeOptions}
					placeholder={this.props.placeHolder}
				/>
			</SearchContainer>
		);
	}
}

const SearchContainer = styled.div`
	width: 400px;
`;
