export default {
	label: "Informations de l'établissement",
	fields: () => ({
		location: {
			streetName: { label: "Rue" },
			postCode: { label: "Code postal" },
			city: { label: "Ville" },
			country: { label: "Pays", type: "selector", selectorType: "countries" },
		},
	}),
};
