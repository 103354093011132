import React from "react";
import WorkerSkills from "./WorkerSkills";
import TextResume from "./TextResume";
import XpBars from "./XpBars";
import Experience from "./Experience";
import compare from "utils/compare";
import Avatar from "components/Avatar";
import { isMobile } from "common/styles";
import Quote from "components/Quote";

export default ({ worker, answer }) => {
	const experiences = worker.experiences.sort(compare(xp => xp.startDate));
	const workXp = experiences.filter(xp => !xp.training);
	const trainings = experiences.filter(xp => !!xp.training);

	return (
		<React.Fragment>
			<div class="col">
				<section>
					<article class="card col">
						<section>
							<div class="desktop-grid print-grid">
								{isMobile() ? (
									<section class="desktop-four print-three">
										<Avatar image={worker.mediumAvatarURL} />
									</section>
								) : (
									<div class="desktop-four print-three">
										<Avatar image={worker.mediumAvatarURL} />
									</div>
								)}

								<div class="desktop-eight desktop-from-six print-eight print-from-two">
									<h1 class="mobile-centered">{worker.name}</h1>
									<paragraph>
										<em>{worker.tagline}</em>
									</paragraph>
									<paragraph>
										<strong>Autorisé·e à travailler en France</strong>
										<br />
										{worker.belongsToEU ? (
											<em>Nationalité européenne</em>
										) : (
											<em>Titre de séjour vérifié</em>
										)}
									</paragraph>

									{worker.resumeUrl ? (
										<paragraph class="col align-left">
											<strong>
												{worker.firstName} a aussi inclus son CV (pdf) dans son
												profil
											</strong>
											<a
												href={worker.resumeUrl}
												secondary
												className="button outline"
											>
												Consulter ce CV
											</a>
										</paragraph>
									) : (
										<paragraph>
											{worker.firstName} n'a pas encore envoyé son CV (pdf)
										</paragraph>
									)}
								</div>
							</div>
						</section>
						<section>
							<WorkerSkills worker={worker} />
						</section>
						<XpBars experiences={worker.experiences} />
					</article>
				</section>
			</div>

			{answer.motivationalLetter ? (
				<div class="col">
					<section>
						<h2>
							{worker.firstName} aimerait travailler chez vous parce que...
						</h2>
						<Quote className="card highlight">
							{answer.motivationalLetter}
						</Quote>
					</section>
				</div>
			) : null}
			{!worker.experiences ||
			!worker.experiences.filter(
				xp => !!xp.businessName || !!xp.jobs.length || !!xp.startDate,
			).length ? (
				<TextResume {...worker} />
			) : null}

			<div class="col">
				{workXp.filter(
					xp =>
						!xp.training &&
						(!!xp.businessName || !!xp.jobs.length || !!xp.startDate),
				).length > 0 && (
					<section>
						<h2>Expérience professionnelle</h2>
					</section>
				)}
				{workXp.map((xp, i) => (
					<Experience key={i} {...xp} />
				))}
				{trainings.length > 0 && (
					<section>
						<h2>Formations</h2>
					</section>
				)}
				{trainings.map((xp, i) => (
					<Experience key={i} {...xp} />
				))}
			</div>
		</React.Fragment>
	);
};
