import React, { useState } from "react";
import MobileTextField from "components/MobileTextField";
import { TextField } from "redux-form-material-ui";
import { isMobile } from "common/styles";

const useFormatOnBlur = ({ format, value, onUserInputChange }) => {
	const [focused, setFocused] = useState(false);
	const [localValue, setLocalValue] = useState(value);
	return {
		onBlur() {
			setFocused(false);
		},
		onFocus() {
			if (value) {
				setLocalValue(value);
			}
			setFocused(true);
		},
		onUserInputChange: value => {
			setLocalValue(value);
			onUserInputChange(value);
		},
		value: focused ? localValue : format(value),
	};
};

export default ({
	input: { value, name, onChange },
	meta: { error, dirty, submitFailed } = {},
	convertToUserInput,
	format,
	parseUserInput,
	label,
	...props
}) => {
	const {
		onFocus,
		onBlur,
		value: displayedValue,
		onUserInputChange,
	} = useFormatOnBlur({
		format,
		value: convertToUserInput(value),
		onUserInputChange: userInput => onChange(parseUserInput(userInput)),
	});
	const Input = isMobile() ? MobileTextField : TextField;
	return (
		<Input
			errorText={(dirty || submitFailed) && error}
			floatingLabelText={label}
			name={name}
			type="text"
			value={displayedValue}
			onBlur={onBlur}
			onFocus={onFocus}
			onChange={({ target: { value } }) => onUserInputChange(value)}
			{...props}
		/>
	);
};
