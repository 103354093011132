import React from "react";
import LineBrokenText from "components/LineBrokenText";
import { Card } from "components/Card";

export default ({ experience }) => (
	<section>
		<Card>
			<article>
				<h2>Parcours Professionnel</h2>
				<LineBrokenText>{experience}</LineBrokenText>
			</article>
		</Card>
	</section>
);
