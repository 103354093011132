import React from "react";
import styled from "styled-components";
import { MdError } from "react-icons/md";
import { color } from "common/styles";

export default ({ error }) =>
	error ? (
		<Container>
			<MdError /> {error}
		</Container>
	) : null;

const Container = styled.div`
	color: ${color("important")};
	padding: 10px 0;
	display: flex;
	& svg {
		width: 50px;
	}
`;
