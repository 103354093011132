import React from "react";
import { color, ripple, mobileBreakpoint } from "common/styles";
import { MdClose } from "react-icons/md";
import styled from "styled-components";

const PromptContainer = styled.div`
	position: fixed;
	top: 100%;
	z-index: 1;
	left: calc(50% - 200px);
	width: 400px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	text-align: center;
	transition: all 250ms ease;
	@media screen and (max-width: ${mobileBreakpoint}px) {
		width: 100%;
		left: 0;
		border-radius: 0;
	}
	.prompt-content {
		&.error {
			&::before {
				display: block;
				content: " ";
				background: ${color("important")};
				height: 10px;
				width: 100%;
			}
		}
	}
`;

export const CloseContainer = styled.div`
	position: absolute;
	right: 5px;
	top: 5px;
	padding: 5px;
	cursor: pointer;
	border-radius: 15px;
	background: transparent;
	transition: background 250ms ease;
	width: 30px;
	height: 30px;
	${ripple("hover")}
`;

export default class PolitePrompt extends React.Component {
	state = {
		status: "small",
	};

	promptSizes = {
		collapsed: 0,
		small: 0,
		expanded: 0,
	};

	updateSizes() {
		const { status } = this.state;

		if (!this.container) {
			return;
		}

		this.container.style.transition = "none";
		this.container.style.top = `calc(100% - ${this.promptSizes[status]}px)`;

		setTimeout(() => (this.container.style.transition = "all .25s"), 1);
	}

	calcSizes() {
		if (!this.container) {
			return;
		}

		const titleMargin = 20;
		const content = this.container.querySelector(".prompt-content");
		const title = this.container.querySelector(".prompt-title");
		const expandedSize =
			(title ? title.scrollHeight + titleMargin : 0) +
			(content ? content.scrollHeight : 0);

		let refresh = false;

		if (this.promptSizes.expanded !== expandedSize) {
			refresh = true;
		}

		this.promptSizes = {
			collapsed: 0,
			expanded: expandedSize,
		};

		refresh && this.updateSizes();
	}

	static getDerivedStateFromProps(nextProps, state) {
		return {
			status: nextProps.hide ? "collapsed" : "expanded",
		};
	}

	componentDidUpdate() {
		this.calcSizes();
	}

	componentWillUnmount() {
		document.removeEventListener("keyup", this.unlock);
	}

	componentDidMount() {
		this.calcSizes();

		// we let the user process the page before popping up
		setTimeout(() => {
			this.setState({ status: "expanded" });
		}, 700);

		this.unlock = document.addEventListener(
			"keyup",
			e => e.keyCode === 27 && this.setState({ unlock: true }),
		);
	}

	render() {
		const { children, title, onClose, isLockedOut, highlight } = this.props;
		const { status, unlock } = this.state;

		if (unlock) {
			return null;
		}

		return (
			<div class="container">
				{isLockedOut ? <div class="backdrop" /> : null}
				<PromptContainer
					className="dark-box card"
					aria-hidden={status === "collapsed"}
					innerRef={ref => (this.container = ref)}
					style={{
						top: `calc(100% - ${this.promptSizes[status]}px)`,
						opacity: status === "collapsed" ? 0 : 1,
					}}
				>
					{title ? (
						<PromptTitle
							onClick={() =>
								status === "small" && this.setState({ status: "expanded" })
							}
							className={`prompt-title ${highlight || ""}`}
						>
							{title}
						</PromptTitle>
					) : null}
					{children && (
						<div class={`prompt-content ${highlight || ""}`}>{children}</div>
					)}
					{onClose && (
						<CloseContainer onClick={() => onClose()}>
							<MdClose size={18} />
						</CloseContainer>
					)}
				</PromptContainer>
			</div>
		);
	}
}

export const PromptTitle = styled.h3`
	padding-top: 20px;
	margin-right: 35px;
	margin-left: 35px;
`;
