import React from "react";
import styled from "styled-components";
import { color, mobileBreakpoint } from "common/styles";
import { MdClose } from "react-icons/md";
import Button from "components/Button";

const LightboxContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3000;
	overflow: auto;
	transition: opacity 500ms ease;
	opacity: ${props => (props.visible ? 1 : 0)};
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: ${props => (props.visible ? "all" : "none")};
`;

const Backdrop = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
`;

const Content = styled.div`
	margin: auto;
	width: 600px;
	position: relative;
	border-radius: 3px;
	background: #fff;
	box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
	transition: transform 500ms ease;
	transform: ${props => (props.visible ? "scaleY(1)" : "scaleY(0)")};
	transform-origin: center;
	color: ${color("text")};
	text-align: left;
	max-height: 90%;
	overflow: auto;

	h2 {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 20px;
		text-align: center;
	}

	@media screen and (max-width: ${mobileBreakpoint}px) {
		height: calc(100% - 85px);
		width: calc(100% - 40px);
		margin-top: 65px;
		overflow: auto;
	}
`;

const CloseContainer = styled.div`
	padding: 25px;
	margin-left: auto;
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	z-index: 1;

	svg {
		transform: scale(1);
		transition: transform 350ms ease;
	}
	&:hover svg {
		transform: scale(1.5);
	}
	h2:first-of-type {
		padding-right: 30px;
	}
`;

export default class Lightbox extends React.Component {
	visible = false;

	componentWillUnmount() {
		document.body.className = "";
	}

	componentDidUpdate() {
		const { visible } = this.props;
		if (!visible && this.visible) {
			document.body.className = "";
			return;
		}
		document.body.className = visible
			? "has-lightbox"
			: document.body.className;
	}

	render() {
		const { children, visible, onDismiss } = this.props;

		return (
			<LightboxContainer
				visible={visible}
				className={visible ? "opened-lightbox" : ""}
			>
				<Backdrop onClick={onDismiss} />

				<Content visible={visible}>
					<CloseContainer onClick={onDismiss}>
						<MdClose size={22} />
					</CloseContainer>
					{children}
				</Content>
			</LightboxContainer>
		);
	}
}

export const confirm = async text => {
	const result = await Confirm.ask(text);
	return result;
};

const ConfirmText = styled.p`
	margin-bottom: 30px;
`;

const RowOfButtons = styled.div`
	display: flex;
	justify-content: space-between;
`;

export class Confirm extends React.Component {
	state = {
		visible: false,
		text: "",
	};

	static ask(text) {
		return new Promise((resolve, reject) => {
			this.component.setState({ visible: true, text });
			this.component.onAnswer = answer => {
				resolve(answer);
			};
		});
	}

	componentDidMount() {
		Confirm.component = this;
	}

	answer(answer) {
		this.setState({ visible: false });
		this.onAnswer && this.onAnswer(answer);
	}

	render() {
		const { text, visible } = this.state;

		return (
			<Lightbox visible={visible} onDismiss={() => this.answer(false)}>
				<article>
					<h2>Êtes-vous sûr ?</h2>
					<ConfirmText>{text}</ConfirmText>
					<RowOfButtons>
						<Button
							secondary
							className="outline"
							onClick={() => this.answer(false)}
						>
							Annuler
						</Button>
						<Button onClick={() => this.answer(true)}>Oui, vraiment !</Button>
					</RowOfButtons>
				</article>
			</Lightbox>
		);
	}
}
