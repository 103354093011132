import React from "react";
import Table from "components/Table";
import LineBrokenText from "components/LineBrokenText";
import moment from "moment";
import Chip from "@material-ui/core/Chip";

export default ({ contracts }) => (
	<Table
		id="ContractsTable"
		columns={[
			{
				label: "Identifiant",
				getter: ({ id }) => id,
			},
			{
				label: "Worker",
				getter: ({ getWorker: { name } }) => name,
			},
			{
				label: "",
				getter: ({
					getWorker: {
						documents: {
							identity: { expiresAt },
						},
					},
				}) =>
					expiresAt && moment().isAfter(expiresAt) ? (
						<Chip label="TDS expiré" />
					) : null,
			},
			{
				label: "Poste",
				getter: ({ getJob: { name } }) => name,
			},
			{
				label: "Business",
				getter: ({ getBusiness: { name } }) => name,
			},
			{
				label: "Shifts",
				sortGetter: ({ startTime }) => startTime,
				getter: ({ shiftsDescription }) => (
					<LineBrokenText>{shiftsDescription}</LineBrokenText>
				),
			},
		]}
		rows={contracts}
		orderBy={4}
	/>
);
