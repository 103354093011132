import React from "react";
import Table from "components/Table";
import { addModal } from "architecture/Modals";
import ReviewAvatar from "modals/ReviewAvatar";

export default ({ avatarsToValidate }) => (
	<Table
		id="AvatarsToValidateTable"
		columns={[
			{
				label: "Identifiant",
				getter: ({ id }) => id,
			},
			{
				label: "Nom",
				getter: ({ name }) => name,
			},
			{
				label: "Photo",
				getter: ({ smallAvatarURL }) => (
					<img src={smallAvatarURL} height="60" width="60" alt="Avatar" />
				),
			},
		]}
		rows={avatarsToValidate.map(worker => ({
			...worker,
			onClick() {
				addModal(({ dismiss }) => (
					<ReviewAvatar worker={worker} dismiss={dismiss} />
				));
			},
		}))}
		orderBy={1}
	/>
);
