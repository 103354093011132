import React from "react";
import TargetsTable from "./AdminQuery/TargetsTable";
import ManageQuery from "./AdminQuery/ManageQuery";
import MassTextMessage from "./AdminQuery/MassTextMessage";

export default ({ actualQuery, columns }) => (
	<div>
		<ManageQuery query={actualQuery} />
		<TargetsTable actualQuery={actualQuery} columns={columns} />
		<MassTextMessage actualQuery={actualQuery} />
	</div>
);
