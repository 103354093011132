import React from "react";
import Table from "components/Table";

export default ({ workersToReview, openModal }) => (
	<Table
		getId={({ id }) => id}
		id="workersToReviewTable"
		columns={[
			{
				label: "Identifiant",
				getter: ({ id }) => id,
			},
			{
				label: "Nom",
				getter: ({ name }) => name,
			},
			{
				label: "Nombre de missions",
				getter: ({ pastContractsCount }) => pastContractsCount,
			},
			{
				label: "Nombre de débriefs",
				getter: ({ uniqueBusinessDebriefsCount }) =>
					uniqueBusinessDebriefsCount,
			},
		]}
		rows={workersToReview.map(worker => ({
			...worker,
			onClick() {
				openModal(worker.id);
			},
		}))}
		orderBy={1}
	/>
);
