import React from "react";
import BudgetGraph from "./BudgetGraph";
import BudgetTable from "./BudgetTable";
import moment from "moment";
import styled from "styled-components";
import Button from "components/Button";
import { Row } from "components/Card";
import { mobileBreakpoint } from "common/styles";

const BudgetStatsContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	flex: 1;
	padding-left: 100px;
	padding-right: 100px;
	padding-top: 25px;

	@media screen and (max-width: ${mobileBreakpoint}px) {
		padding-left: 25px;
		padding-right: 25px;
		height: calc(100% - 50px);
	}

	@media print {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

const BudgetActions = styled(Row)`
	justify-content: flex-end;

	@media screen and (max-width: ${mobileBreakpoint}px) {
		display: none;
	}
`;

export default class BudgetStats extends React.Component {
	state = {
		month: moment().toISOString(),
	};

	render() {
		const { month } = this.state;
		const { dataType } = this.props;

		return (
			<BudgetStatsContainer>
				<BudgetActions>
					<Button onClick={() => window.print()}>Imprimer</Button>
				</BudgetActions>
				<BudgetGraph
					dataType={dataType}
					onPickMonth={month => this.setState({ month })}
					selectedMonth={month}
				/>
				<BudgetTable month={month} dataType={dataType} />
			</BudgetStatsContainer>
		);
	}
}
