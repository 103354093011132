import React from "react";
import { useJobPostDetails, useJobPostStatus } from "models/jobPost";
import { useJobPostAnswers, useAnswersFolders } from "models/jobPostAnswers";
import { addModal } from "architecture/Modals";
import SelectWorkers from "./JobPosts/SelectWorkers";
import Loading from "components/Loading";
import Button from "components/Button";
import EditJobPost from "modals/JobPosts/EditJobPost";
import { SideNav } from "components/Navs";
import useWorkersSort from "./JobPosts/useWorkersSort";
import { isMobile } from "common/styles";

export default ({ id, dismiss }) => {
	const { loading, jobPost } = useJobPostDetails(id);
	const { loading: mutationLoading, isClosed, saveStatus } = useJobPostStatus(
		id,
	);
	const { folders, folder, setFolder } = useAnswersFolders();
	const { answers, loading: answersLoading } = useJobPostAnswers({
		jobPostId: id,
		folder,
	});
	const { sort, sortOptions, setSort } = useWorkersSort(jobPost.job);

	if (loading) {
		return <Loading />;
	}

	return (
		<div class="cover row tablet-col-reverse">
			<SideNav>
				<h3 class="hide-tablet">Candidatures</h3>
				{folders.map(f => (
					<button
						key={f.key}
						onClick={() => setFolder(f.key)}
						class={f.key === folder.key ? "selected" : ""}
					>
						{isMobile() ? f.mobileLabel : f.label}
					</button>
				))}

				<hr class="hide-tablet" />
				<h3 class="hide-tablet">Trier par</h3>
				{sortOptions.map(option => (
					<button
						key={option.jobPostLabel || option.label}
						class={
							option.label === sort.label
								? "selected hide-tablet"
								: "hide-tablet"
						}
						onClick={() => setSort(option)}
					>
						{option.jobPostLabel || option.label}
					</button>
				))}
			</SideNav>
			<article class="col expand scrollable">
				<div class="row">
					<h1 class="hide-tablet">{jobPost.title}</h1>
					<Button
						className="right outline"
						secondary
						onClick={() =>
							addModal(({ dismiss }) => (
								<EditJobPost dismiss={dismiss} jobPost={jobPost} />
							))
						}
					>
						Modifier
					</Button>
					<Button
						loading={mutationLoading}
						onClick={async () => {
							await saveStatus();
							dismiss();
						}}
					>
						{isClosed ? "Réouvrir" : "Clôturer"}
					</Button>
				</div>
				<div class="col expand vertical-centered">
					{answersLoading ? (
						<Loading />
					) : answers.length === 0 ? (
						<article class="card">{folder.emptyText}</article>
					) : (
						<SelectWorkers jobPost={jobPost} answers={answers} sort={sort} />
					)}
				</div>
			</article>
		</div>
	);
};
