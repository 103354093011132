import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { acceleration, color, desceleration } from "common/styles";
import { MdDone } from "react-icons/md";
import { getAlert } from "selectors/alerts";
import Button from "components/Button";
import { addAlert } from "actions/alerts";
import { dispatch } from "common/store";

class Alerts extends Component {
	render() {
		const { alert } = this.props;
		if (alert) {
			return (
				<Position>
					<Alert key={alert.id} {...alert} />
				</Position>
			);
		}
		return null;
	}
}

const Alert = ({ text, mounted, action }) => (
	<Container mounted={mounted}>
		{text}
		{action ? (
			<ActionContainer>
				<Action {...action} />
			</ActionContainer>
		) : null}
	</Container>
);

class Action extends Component {
	state = {
		running: false,
		done: false,
	};

	render() {
		const { onClick, label } = this.props;
		const { running, done } = this.state;
		if (done) {
			return <MdDone />;
		}
		return (
			<Button
				secondary
				darkBackground
				loading={running}
				onClick={async () => {
					this.setState({ running: true });
					try {
						await onClick();
						this.setState({ done: true });
					} catch (error) {
						this.setState({ running: false });
						dispatch(addAlert("Une erreur s'est produite."));
					}
				}}
			>
				{label}
			</Button>
		);
	}
}

export default connect(state => ({
	alert: getAlert(state),
}))(Alerts);

const Position = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
`;
const Container = styled.div`
	margin: 0 24px;
	padding: 14px 24px;
	background-color: ${color("black", "intense")};
	color: white;
	border-radius: 2px;
	transform: translate(0, ${({ mounted }) => (mounted ? "0" : "100%")});
	transition: transform 400ms
		${({ mounted }) => (mounted ? desceleration : acceleration)};
	box-shadow: 0 0 3px ${color("black", "intense")};
	display: flex;
	align-items: center;
`;
const ActionContainer = styled.div`
	font-size: 24px;
	margin-left: 10px;
`;
