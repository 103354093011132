import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import styled from "styled-components";

const getKeyValue = option =>
	typeof option === "string" ? { key: option, value: option } : option;

export default ({ value, updateValue, label, multiple, options }) => (
	<StyledFormControl>
		<InputLabel>{label}</InputLabel>
		<Select
			multiple={multiple}
			value={value}
			onChange={event => updateValue(event.target.value)}
			renderValue={
				multiple
					? selected => (
							<div>
								{selected.map(value => (
									<Chip key={value} label={value} />
								))}
							</div>
					  )
					: undefined
			}
		>
			{options.map(option => {
				const { key, value } = getKeyValue(option);
				return (
					<MenuItem key={key} value={key}>
						{value}
					</MenuItem>
				);
			})}
		</Select>
	</StyledFormControl>
);

const StyledFormControl = styled(FormControl)`
	flex: 1;
`;
