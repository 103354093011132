import React from "react";
import Badge from "components/Badge";
import { GraphQL } from "common/graphql";
import { getTrackings } from "../Trackings";

export default () => (
	<GraphQL query={getTrackings({ badge: true })} hideLoading={true}>
		{({ contractsToTrack }) => <Badge count={contractsToTrack.length} />}
	</GraphQL>
);
