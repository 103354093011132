import gql from "graphql-tag";
import moment from "moment";
import { writeQuery, readQuery } from "common/Request";

export const queryProperties = `
	acceptCount
	contracts {
		businessAutoTracked
		businessRejectedAutoTracking
		businessTracked
		getWorker {
			id
			name
			smallAvatarURL
		}
		id
		matching {
			debrief {
				business {
					currentScore: score
				}
			}
		}
		signed
		tracked
		trackingValidated
	}
	dossier
	#reference
	getJob {
		name
	}
	id
	maxWorkers
	rejectCount
	shifts {
		startTime
		endTime
	}
	groupId
`;

export const Planning = gql`
  query getCalendarQueries($start: DateTime!, $end: DateTime!) {
    queries(startBefore: $end, endAfter: $start, canceled: false) {
      ${queryProperties}
    }
  }
`;

const addByShift = (shift, query) => {
	const variables = {
		start: moment(shift.startTime)
			.startOf("week")
			.toISOString(),
		end: moment(shift.startTime)
			.endOf("week")
			.toISOString(),
	};

	const { queries } = readQuery({
		query: Planning,
		variables,
	});

	if (queries) {
		writeQuery({
			query: Planning,
			variables,
			data: {
				queries: [...queries.filter(q => q.id !== query.id), query],
			},
		});
	}
};

export const addQuery = newQuery => {
	newQuery.shifts.forEach(shift => addByShift(shift, newQuery));
};
