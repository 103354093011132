import React from "react";
import styled from "styled-components";
import { statusElements } from "models/query";
import moment from "moment";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import { isMobile } from "common/styles";

export default ({ status, createdAt }) => {
	const { color, Icon, label } = statusElements[status];
	return (
		<Container color={color}>
			<div>
				<Icon size={20} />
				{label}
			</div>
			{!isMobile() && capitalizeFirstLetter(moment(createdAt).fromNow())}
		</Container>
	);
};

const Container = styled.div`
	background-color: ${({ color }) => color};
	font-size: 1.2em;
	color: white;
	font-weight: bold;
	padding: 10px 16px 10px 14px;
	border-radius: 5px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	margin-top: 10px;
	margin-bottom: 10px;

	& > div:first-child {
		display: flex;
		* {
			margin-right: 6px;
		}
	}
`;
