import { all } from "redux-saga/effects";
import { watchAddAlert } from "sagas/alerts";

export default function* rootSaga() {
	try {
		yield all([watchAddAlert()]);
	} catch (error) {
		console.error(error);
	}
}
