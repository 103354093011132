import React from "react";
import { FieldArray, reduxForm } from "redux-form";
import styled from "styled-components";
import moment from "moment";
import Button from "components/Button";
import Shift, { getStartEndFromValue } from "./Trackings/Shift";
import { connect } from "react-redux";
import Request from "common/Request";
import ReactGA from "react-ga";
import { LightTextField } from "components/Inputs";
import { mobileBreakpoint, isMobile } from "common/styles";

const submitTrackings = (contractId, shifts) => async ({ trackings }) => {
	ReactGA.event({
		category: "Trackings",
		action: "Tracked hours sent",
	});
	await Request.mutation(
		/* GraphQL */ `
			mutation($contractId: ID!, $trackings: [TrackingInput]!) {
				businessTrackContract(contractId: $contractId, trackings: $trackings) {
					id
				}
			}
		`,
		{
			contractId,
			trackings: trackings.map(
				({ shiftId, startTime, endTime, breakMinutes }, index) => ({
					shiftId,
					...getStartEndFromValue(
						{ startTime, endTime, breakMinutes },
						shifts[index].startTime,
					),
				}),
			),
		},
		{
			successMessage: "Le pointage des horaires a été transmis",
			errorMessage:
				"Une erreur s'est produite et la mission n'a pas été pointée",
		},
	);

	Request.refreshAll();
};

export default class extends React.Component {
	state = {
		rejectingAutoTracking: false,
	};

	rejectAutoTracking = async () => {
		this.setState({ submitting: true });
		try {
			await Request.mutation(
				/* GraphQL */ `
					mutation($contractId: ID!, $comment: String!) {
						businessRejectAutoTracking(
							contractId: $contractId
							comment: $comment
						) {
							id
						}
					}
				`,
				{
					contractId: this.props.id,
					comment: this.state.comment,
				},
			);
			this.setState({ submited: true });
		} catch (error) {
			console.error(error);
			this.setState({ submitting: false });
		}
	};

	onChangeComment = ({ target: { value } }) => {
		this.setState({ comment: value });
	};

	render() {
		const {
			shifts,
			status,
			id,
			businessTracked,
			businessAutoTracked,
			trackingValidated,
			businessRejectedAutoTracking,
			getWorker: { firstName },
		} = this.props;
		const { rejectingAutoTracking, submitting, submited } = this.state;
		if (status === "pending business tracking") {
			return (
				<TrackingsForm
					shifts={shifts}
					contractId={id}
					onSubmit={submitTrackings(id, shifts)}
					form={`businessTrackings_${id}`}
				/>
			);
		}
		if (businessTracked) {
			return (
				<Container>
					{shifts.map(shift => (
						<Shift key={shift.id} {...shift} contractId={id} />
					))}
					{businessAutoTracked ? (
						trackingValidated ? (
							<Inform>
								Nous avons payé {firstName} sur la base des horaires théoriques.
								Il n'est plus possible de contester ces horaires.
							</Inform>
						) : businessRejectedAutoTracking || submited ? (
							<Inform>
								Vous avez contesté ces horaires. Ils sont en cours de validation
								par nos équipes.
							</Inform>
						) : rejectingAutoTracking ? (
							<React.Fragment>
								<CommentInput
									name="comment"
									label="Quels sont les horaires faits par l'extra ?"
									onChange={this.onChangeComment}
									fullWidth
									multiline
									autoFocus
									disabled={submitting}
								/>
								<Button onClick={this.rejectAutoTracking} loading={submitting}>
									Envoyer
								</Button>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Inform>Les horaires ont été validés automatiquement.</Inform>
								<ButtonsContainer>
									<Button
										secondary
										className="outline"
										onClick={() =>
											this.setState({ rejectingAutoTracking: true })
										}
										asynchronous
									>
										{isMobile() ? "Contester" : "Contester ces horaires"}
									</Button>
									<Button onClick={validateAutoTracking(id)} asynchronous>
										Valider ces horaires
									</Button>
								</ButtonsContainer>
							</React.Fragment>
						)
					) : null}
				</Container>
			);
		}
		return null;
	}
}

const Inform = styled.div`
	margin-top: 15px;
	font-style: italic;
`;
const ButtonsContainer = styled.div`
	margin-top: 35px;
	display: flex;
	justify-content: space-between;
	& * {
		margin-right: 10px;
	}
	@media screen and (max-width: ${mobileBreakpoint}px) {
		flex-direction: column;
		& * {
			margin-right: 0px;
		}
	}
`;

const validateAutoTracking = contractId => async () => {
	await Request.mutation(
		/* GraphQL */ `
			mutation($contractId: ID!) {
				businessValidateAutoTracking(contractId: $contractId) {
					id
				}
			}
		`,
		{
			contractId,
		},
		{
			successMessage: "Le pointage a été validé",
			errorMessage: "Une erreur s'est produite. Veuillez réessayer.",
		},
	);

	Request.refreshAll();
};

// const WorkerDidNotWork = styled.div`
//   ${"" /* border: solid 1px ${color("important")};
//   border-radius: 5px;
//   padding: 20px 25px; */};
// `
// const Title = styled.div`
//   font-weight: bold;
//   font-size: 1.1em;
// `

const TrackingsForm = reduxForm({})(
	connect((state, { form }) => {
		const values = (state.form[form] || {}).values || {};
		return { values };
	})(({ shifts, contractId, values, handleSubmit }) => (
		<form onSubmit={handleSubmit}>
			<Container>
				{/* <WorkerDidNotWork>
          <Title>Le worker n'a pas travaillé</Title>
          <SelectField
            name="maxWorkers"
            floatingLabelText="Que s'est-il passé ?"
            fullWidth
          >
            {[
              "Le worker n'est pas venu sans prévenir",
              "Le worker a prévenu qu'il ne viendrait pas",
              "Le worker est venu mais a choisi de repartir avant de commencer à travailler",
              "J'ai renvoyé le worker chez lui / chez elle",
            ].map((value, index) => (
              <MenuItem key={index} value={index + 1} primaryText={value} />
            ))}
          </SelectField>
          <TextField
            name="comment"
            multiLine
            hintText={"Dites nous en davantage"}
            fullWidth
            rows={3}
          />
          <Row>
            <Button submit>Enregistrer</Button>
            <Button secondary onClick={() => {}}>
              Annuler
            </Button>
          </Row>
        </WorkerDidNotWork> */}
				<FieldArray
					name="trackings"
					component={TrackingsInput}
					shifts={shifts}
					contractId={contractId}
					trackings={values.trackings}
				/>
				<Row>
					<Button submit>Valider les pointages</Button>
					{/* <Button secondary onClick={() => {}}>
            Le worker n'a pas travaillé
          </Button> */}
				</Row>
			</Container>
		</form>
	)),
);

const makeTimeValue = time => ({
	hours: moment(time).hours(),
	minutes: moment(time).minutes(),
});

class TrackingsInput extends React.Component {
	componentDidMount() {
		const { fields, shifts } = this.props;
		if (fields.length === 0) {
			for (const shift of shifts) {
				fields.push({
					shiftId: shift.id,
					startTime: makeTimeValue(shift.startTimeTracked || shift.startTime),
					endTime: makeTimeValue(shift.endTimeTracked || shift.endTime),
					breakMinutes: shift.breakMinutesTracked || shift.breakMinutes,
				});
			}
		}
	}

	render() {
		const { fields, shifts, trackings, contractId } = this.props;
		return (
			<div>
				{fields.map((member, index) => {
					const shift = shifts[index];
					return (
						<Shift
							key={shift.id}
							{...shift}
							contractId={contractId}
							input
							member={member}
							value={trackings[index]}
						/>
					);
				})}
			</div>
		);
	}
}

const Row = styled.div`
	display: flex;
	margin-top: 25px;
	justify-content: center;
`;

const Container = styled.div``;
const CommentInput = styled(LightTextField)`
	&& {
		margin: 20px 0;
	}
`;
