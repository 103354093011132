import React from "react";
import Table from "components/Table";
import { addModal } from "architecture/Modals";
import EditBusiness from "./EditBusiness";

export default ({ businesses, title }) => (
	<Table
		id="BusinessesTable"
		title={title}
		columns={[
			{
				label: "ID",
				getter: ({ id }) => id,
			},
			{
				label: "Nom de l'établissement",
				getter: ({ name }) => name,
			},
			{
				label: "Date de création",
				getter: ({ createdAt }) => createdAt,
			},
		]}
		rows={businesses.map(business => ({
			...business,
			onClick() {
				addModal(({ dismiss }) => (
					<EditBusiness businessId={business.id} dismiss={dismiss} />
				));
			},
		}))}
		orderBy={2}
		ascending={false}
	/>
);
