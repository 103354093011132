import Request from "common/Request";
import { recursiveDiff } from "scenes/Home/Admin/Operations/AutoForm.js";
import { DEV } from "common/constants";
import { dispatch } from "common/store";
import { addAlert } from "actions/alerts";
import validateCondition from "./validateCondition";

const convertIfSet = value =>
	value === undefined || value === null ? value : Number(value);

export default async (businessInDB, { verified, ...business }) => {
	businessInDB.verified = undefined;

	try {
		await Promise.all(
			business.defaultQueries.map(
				async defaultQuery =>
					await Promise.all(defaultQuery.conditions.map(validateCondition)),
			),
		);
		const after = {
			...business,
			overtimeWeeklyHoursThreshold: convertIfSet(
				business.overtimeWeeklyHoursThreshold,
			),
			guarantee: convertIfSet(business.guarantee),
			defaultBreakMinutes: convertIfSet(business.defaultBreakMinutes),
			defaultQueries: business.defaultQueries.map(defaultQuery => ({
				...defaultQuery,
				rate: convertIfSet(defaultQuery.rate),
				breakMinutes: convertIfSet(defaultQuery.breakMinutes),
				conditions: defaultQuery.conditions.map(
					({ billingFee, ...condition }) => ({
						...condition,
						validFrom: condition.validFrom,
						grossRate: convertIfSet(condition.grossRate),
						billingRate: convertIfSet(condition.billingRate),
					}),
				),
			})),
		};
		if (JSON.stringify(businessInDB) !== JSON.stringify(after)) {
			const change = recursiveDiff(businessInDB, after);
			await Request.mutation(
				/* GraphQL */ `
					mutation($business: BusinessInput) {
						editBusiness(business: $business) {
							id
						}
					}
				`,
				{
					business: { id: businessInDB.id, ...change },
				},
			);
			dispatch(addAlert("Business édité avec succès !"));
		}
	} catch (error) {
		console.log(error);
		DEV && console.error(error);
		const { message } = error;
		const result = /GoferDisplayableError: (.+)$/.exec(message);
		throw new Error(result ? result[1] : message);
	}
};
