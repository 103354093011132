export const DEV = process.env.NODE_ENV === "development";
export const baseURL = process.env.REACT_APP_BASE_URL;
export const graphqlURL = `${baseURL}/v2/graphql`;
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN || "";
export const touchEnabled = "ontouchstart" in document.documentElement;
export const pollInterval = 30 * 1000;
export const gaTrackingId = "UA-139531114-1";

export const minGrossRate = 1015;
export const minRate = Math.ceil(minGrossRate * 1.21 * 0.774);
export const maxRate = 3000;
export const minMonthlyRate = 1521.22;
export const logglyToken = "f3e04314-4767-4b61-954a-0b638acf83c5";
export const defaultBillingFee = 0.18;
export const auth0 = JSON.parse(process.env.REACT_APP_AUTH0);
