import React, { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "components/Button";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const AddTarget = ({ handleClick }) => {
	const ref = useRef(null);

	const onClick = () => {
		handleClick(ref.current.value);
	};

	return (
		<Container>
			<TextField
				inputRef={ref}
				placeholder="Entrer le mail du Worker"
				fullWidth
				type="email"
			/>
			<Button onClick={onClick}>Ajouter un Worker</Button>
		</Container>
	);
};

export default AddTarget;
