import * as React from "react";
import Button from "components/Button";
import moment from "moment";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { saveXp, experienceStatusList } from "models/worker/resume";
import PickMonth from "./PickMonth";
import Jobs from "./Jobs";

const Label = styled.p`
	font-size: 14px;
	margin-bottom: 5px;
`;

export default class EditWorkerExperience extends React.Component {
	state = {};

	static getDerivedStateFromProps(props, state) {
		return { ...props, ...state };
	}

	startDate() {
		const { startDate } = this.state;

		return (
			<div>
				<Label>Date de début</Label>
				<PickMonth
					date={startDate}
					onChange={startDate => this.setState({ startDate })}
				/>
				<TextField
					type="number"
					defaultValue={moment(startDate).format("YYYY")}
					onBlur={e =>
						this.setState({
							startDate: moment(startDate)
								.year(e.target.value)
								.toISOString(),
						})
					}
				/>
			</div>
		);
	}

	endDate() {
		const { endDate } = this.state;
		const initialEndDate = this.props.endDate;

		return (
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checked={!endDate}
							onChange={e =>
								this.setState({
									endDate: e.target.checked
										? undefined
										: moment(initialEndDate || undefined).toISOString(),
								})
							}
						/>
					}
					label="Le worker est toujours en poste"
				/>
				{!!endDate ? (
					<div>
						<Label>Date de fin</Label>
						<PickMonth
							date={endDate}
							onChange={endDate => this.setState({ endDate })}
						/>
						<TextField
							type="number"
							defaultValue={moment(endDate).format("YYYY")}
							onBlur={e =>
								this.setState({
									endDate: moment(endDate)
										.year(e.target.value)
										.toISOString(),
								})
							}
						/>
					</div>
				) : null}
			</div>
		);
	}

	dates() {
		return (
			<div>
				{this.startDate()}
				{this.endDate()}
			</div>
		);
	}

	removeJob(job) {
		const { jobs } = this.state;

		this.setState({
			jobs: jobs.filter(j => j.key !== job.key),
		});
	}

	addJob(job) {
		const { jobs } = this.state;

		this.setState({
			jobs: [...jobs, job],
		});
	}

	render() {
		const { businessName, jobs, status, training } = this.state;
		const { currentWorker, dismiss, description, id } = this.props;

		return (
			<div class="grid">
				<article class="card six from-four col">
					<h1 className="main-header">
						{businessName || "Ajouter une expérience"}
					</h1>
					<div>
						{id ? (
							<Select
								value={status}
								onChange={e => this.setState({ status: e.target.value })}
							>
								{experienceStatusList.map(status => (
									<MenuItem key={status.key} value={status.key}>
										{status.name}
									</MenuItem>
								))}
							</Select>
						) : null}
						<div>
							<TextField
								defaultValue={businessName}
								fullWidth={true}
								onChange={e => this.setState({ businessName: e.target.value })}
								label="Nom de l'établissement"
							/>
						</div>
						<div>
							<TextField
								defaultValue={description}
								multiline={true}
								fullWidth={true}
								onChange={e => this.setState({ description: e.target.value })}
								label="Description"
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										checked={training}
										onChange={e =>
											this.setState({
												training: e.target.checked,
											})
										}
									/>
								}
								label="Cette expérience est une formation"
							/>
						</div>
						{this.dates()}
						<section>
							<Jobs
								jobs={jobs}
								onAdd={job => this.addJob(job)}
								onRemove={job => this.removeJob(job)}
							/>
						</section>
						<div class="row">
							<Button
								className="right"
								onClick={() => {
									saveXp(currentWorker, this.state);
									dismiss();
								}}
							>
								Enregistrer
							</Button>
						</div>
					</div>
				</article>
			</div>
		);
	}
}
