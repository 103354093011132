import React from "react";
import styled from "styled-components";
import Table from "components/Table";
import { addModal } from "architecture/Modals";
import Button from "components/Button";
import LineBrokenText from "components/LineBrokenText";
import store from "common/store";
import { addAlert } from "actions/alerts";
import Request from "common/Request";
import Query from "modals/Query";
import { Pill } from "components/Pills";
import ReactGA from "react-ga";
import { Redirect } from "react-router-dom";
import { isTablet } from "common/styles";

export default class Trackings extends React.Component {
	state = {
		redirect: undefined,
	};

	columns = [
		{
			label: "Extra",
			getter: ({ getWorker: { name } }) => name,
		},
		{
			label: "Vacation",
			getter: ({ shiftsDescription, businessAutoTracked }) => (
				<Line>
					<LineBrokenText>{shiftsDescription}</LineBrokenText>
					{businessAutoTracked ? <Pill>Auto</Pill> : null}
				</Line>
			),
			sortGetter: ({ startTime }) => startTime,
		},
		{
			label: "",
			getter: ({ id, shifts, businessAutoTracked }) => (
				<TrackingOkButton
					shifts={shifts}
					id={id}
					businessAutoTracked={businessAutoTracked}
				/>
			),
		},
		{
			label: "",
			getter: ({ onClick }) => (
				<Button onClick={onClick}>
					{isTablet() ? "Modifier" : "Modifier les horaires"}
				</Button>
			),
		},
	];

	openQuery() {
		const queryId = this.props.match.params.queryId;
		if (queryId && queryId !== this.queryId) {
			this.queryId = queryId;
			addModal(
				({ dismiss }) => (
					<Query
						dismiss={() => {
							dismiss();
						}}
						queryId={queryId}
					/>
				),
				{
					onDismiss: () => window.history.replaceState(null, "", "/trackings"),
				},
			);
		}
	}

	componentDidMount() {
		this.openQuery();
	}

	componentDidUpdate() {
		this.openQuery();
	}

	render() {
		const { redirect } = this.state;
		const { contracts } = this.props;

		if (redirect && redirect !== this.redirect) {
			this.redirect = redirect;
			return <Redirect to={`/trackings/${redirect}`} />;
		}

		return (
			<Table
				id="BusinessTrackingsTable"
				columns={this.columns}
				rows={contracts.map(contract => ({
					...contract,
					onClick: () => this.setState({ redirect: contract.query.id }),
				}))}
				orderBy={1}
				getId={contract => contract.id}
			/>
		);
	}
}

class TrackingOkButton extends React.Component {
	state = {
		validating: false,
	};

	validateTracking = async () => {
		ReactGA.event({
			category: "Trackings",
			action: "Tracked hours sent",
		});
		const { businessAutoTracked, id: contractId, shifts } = this.props;
		const trackings = shifts.map(
			({ startTime, endTime, breakMinutes, id }) => ({
				startTime,
				endTime,
				breakMinutes,
				shiftId: id,
			}),
		);
		this.setState({ validating: true });
		try {
			await Request.mutation(
				businessAutoTracked
					? /* GraphQL */ `
							mutation($contractId: ID!) {
								businessValidateAutoTracking(contractId: $contractId) {
									id
								}
							}
					  `
					: /* GraphQL */ `
							mutation($contractId: ID!, $trackings: [TrackingInput]!) {
								businessTrackContract(
									contractId: $contractId
									trackings: $trackings
								) {
									id
								}
							}
					  `,
				{
					contractId,
					trackings,
				},
			);
			store.dispatch(addAlert("Le pointage a été validé."));
		} catch (error) {
			this.setState({ validating: false });
			store.dispatch(addAlert("Le pointage n'a pas pu être validé."));
		}
	};

	render() {
		return (
			<Button onClick={this.validateTracking} loading={this.state.validating}>
				{isTablet() ? "Valider" : "Valider les horaires"}
			</Button>
		);
	}
}

const Line = styled.div`
	display: flex;
	align-items: center;
	& > * {
		margin-right: 10px;
	}
`;
