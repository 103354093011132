import React from "react";
import NumberField from "./NumberField";

export default props => (
	<NumberField
		format={value => (value || value === 0 ? value : "")}
		convertToUserInput={value => String(value)}
		parseUserInput={string => parseInt(string)}
		{...props}
	/>
);
