import React from "react";

export default ({ contacts }) => (
	<div>
		{contacts.map((contact, index) => {
			if (contact) {
				return (
					<div key={index}>
						<ul>
							<li>
								Nom: {contact.name} {contact.lname}
							</li>
							<li>Tel: {contact.phone}</li>
							<li>Email: {contact.mail}</li>
							<li>Fonction: {contact.fonction}</li>
						</ul>
						<br />
					</div>
				);
			} else {
				return null;
			}
		})}
	</div>
);
